.twLoader.loader-reach {
  font-size: 1.2em;
  margin-bottom: 3px;
  position: relative;
  top: -5px;
}

.reachNAInfo {
  display: inline-block;
  margin-left: 5px;
}

.twInterests .annotatedList.inset {
  height: 235px;
}

.twInterests .annotatedList {
  height: 262px;
}

.twKwEditing {
  font-weight: bold;
}

.loader-twGridLoading {
  position: absolute;
}

.modalsSubmit {
  min-width: inherit;
  margin-left: 10px;
}

.form .value.followersTextarea {
  height: 200px;
  width: 100%;
  resize: none;
}

.form .value.followersTextarea.smallHeight {
  height: 50px;
}

.left-spacer-5 {
  margin-left: 5px;
}

.twFollowerSelectable {
  cursor: pointer;
}

.twFollowerUnselected {
  opacity: 0.5;
}

.suggestion-select .twFollowerUnselected {
  opacity: 1;
}

.twFollowersSelectionContainer {
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #d3d3d3;
  padding: 4px 8px;
}

.twFollowersSelectionContainer img {
  margin-top: 1px;
}

.inlineErrorMsg {
  display: inline;
  margin-left: 10px;
}

.twFollowerSelectable:hover .followerBulkUnselect {
  display: inline-block;
}

.twFollowerSelectable:hover {
  box-shadow: inset 0 0 1px #ccc;
  background: #f9f9f9;
}

.twFollowerBulkSingle {
  padding: 3px;
}

.followerBulkUnselect {
  display: none;
  float: right;
  font-size: 0.8em;
  margin: 5px 5px;
  color: #888;
}

.keywordsSuggestionListIcon {
  display: inline-block !important;
  opacity: 0;
}

.keywordsSuggestionListItem:hover .keywordsSuggestionListIcon{
  opacity: 1;
}

.twActivityTitle {
  font-size: 11px;
  color: #999999;
  text-transform: uppercase;
  display: block;
  padding-bottom: 3px;
}

.keywordSuggestionsTrigger {
  position: relative;
  top: -20px;
}

.form .ondemand-box.ondemand-box-noTopArrow:before {
  display: none;
}

.twKeywordValue {
  cursor: pointer;
}

.Select-option .tailored-audience-disabled {
  cursor: auto;
}

.tailored-audience .Select-option {
  position: relative;
}

.tailored-audience .form-optional {
  font-size: 1em;
}

.react-select-wrapper {

  .tailored-audience{
    .react-select__multi-value__label {
      .form-optional {
        color: white;
      }
    }
  }
}

.twLoader.inline-small {
  font-size: 1em;
  display: inline-block;
  margin-right: 10px;
}

.devices-min-version-select {
  display: inline-block;
  min-width: 200px;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.devices-min-version-toggle {
  position: relative;
  top: -7px;
}

.precise-devices-all-radio {
  margin-left: 10px;
}

.precise-devices-specific-device-select {
  width: 97%;
  float: right;
  margin: 5px 0;
}
.form.label-no-padding label.caption {
  padding: 0;
}
