/* Entity jump */
.entity-jump {

  .select2-choice {
    color: #C2BFBF;
    border: 0;
    padding: 3px 0 3px 0;
    margin-left: 5px;
    height: 22px;
    line-height: 18px;
    .box-shadow(none);
  }

  .select2-container {
    //max-width: 400px;
    //overflow: hidden;
  }

  .select2-container,
  .select2-choice {
    display: inline-block;
  }

  .select2-dropdown-open .select2-choice,
  .select2-choice:hover {
    color: #858585;
  }

  .select2-chosen,
  .select2-container  {
    max-width:250px !important;
  }

  .select2-container .select2-choice .select2-arrow b {
    right: 5px;
  }

  .select2-container .select2-choice>.select2-chosen {
    margin-right: 22px;
  }

  &.hide-choosen {
    .select2-container .select2-choice>.select2-chosen {
      display: none;
    }
    .select2-container .select2-choice .select2-arrow {
      position: relative;
    }
  }

}

.breadcrumb>li+li.entity-jump:before {
  padding-right: 3px;
}

.bootstrap-select .input-block-level {
  height: 27px;
  border: 1px solid #d3d3d3 !important;
  color: #858585;
  font-size: 12px !important;
  padding: 4px 8px !important;
}

.entityJumpDropdown {
  min-width: 270px;
}
