.twCards .reminderHeading {
	display: inline-block;
	vertical-align: top;
	margin-top: 5px;
}

.twCards .reminderIcon {
	font-size: 40px;
	padding: 6px;
}

.modal-reminder-list{
	margin-top: 20px;
	height: 200px;
	overflow-y: auto;
}

.linkToReminderContainer{
	cursor: pointer;
	background-color: #FBFBFB;
	padding: 0 15px 0 15px;

	.linkReminderHeader {
		margin: 5px 0 0 0;
	}

	.twReminderStatistic {
		font-size: 11px;
		width: 80px;

		i {
			margin-right: 5px;
			font-size: 13px;
		}
	}
}

.accountReminder {
	height: 80px !important;
	border: 1px solid #E0E0E0;
	padding-left: 24px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;

	.reminderHeading {
		font-size: 18px;
		margin-top: 15px;
		margin-bottom: 16px;
		color: rgb(25, 36, 48);
		font-weight: 400;
		font-family: Roboto, sans-serif;
	}

	.cardActionContainer {
		border: none;
		background: none;
	}

	.twReminderStatisticsContainer {
		cursor: pointer;

		.twReminderStatistic {
			top: 45px;
			width: 120px;

			i {
				margin-right: 10px;
				font-size: 15px;
			}
		}
	}
}

.addReminder {
	.twReminderStatisticsContainer {
		cursor: pointer;
		background-color: #fbfbfb;

		.twReminderStatistic {
			top: 28px;
			width: 65px;
			font-size: 11px;

			i {
				margin-right: 5px;
				font-size: 13px;
			}
		}
	}
}

.twReminderStatistic {
	float: left;
	position: relative;
}

.twReminderReply {
	float: left;
	max-width: 440px;
	padding: 10px 15px;
	background: #f2f2f2;
	margin-bottom: 10px;
	margin-right: 5px;

	#trigger {
		height: 27px;
		box-shadow: none;
		padding-right: 20px;
		width: 100%;
		font-size: 12px;
		margin: 0;

		b {
			font-size: 11px;
		}
	}

	#trigger {
		font-weight: 400;
		background-color: transparent !important;
		color: @dodgerBlue;
		border: none;
		padding-left: 0;
		&:hover, &:focus, &:active {
			background-color: transparent !important;
			color: @scienceBlue !important;
			border: none;
		}
	}

	.dropdown-toggle.btn-primary {
		font-size: 12px;
	}

	.bootstrap-select-react .caret {
		margin-top: -1px;
	}

	.startTimeDatePicker {
		margin-top: 0;
		margin-right: 0;
		float: right;
	}

	.datetimepicker-wrap .value {
		padding: 2px 3px !important;
		line-height: 18px !important;
		text-align: center;
	}

	.datetimepicker-datepicker.value {
		width: 80px !important;
	}

	.replyPrepend {
		border-bottom-left-radius: 3px;
		border-top-left-radius: 3px;
		background: white;
		padding: 6px;
		padding-right: 0;
		color: #000;
		font-weight: bold;
		font-size: 12px;
		vertical-align: top;

		&.disabled {
			background: #eee;
			color: #777 !important;
		}
	}

	.input-group .form-control {
		border-left: 0 !important;
		padding-left: 4px !important;
		box-shadow: none !important;
		//width: 278px;
	}

	.input-group-addon:first-child {
		border-right: 0 !important;
	}

	div.images label {
		cursor: inherit;
	}
}

.twReminderReply {
	div.images .imgContainer {
		width: 100px;
		margin: 0 3px 0 0;
	}
	div.images .imgContainer:last-child {
		margin-right: 0;
	}
	.imgContainer label img {
		max-width: 80px;
	}
}

.h2r-opt-out-text {
	width: 100%;
	background-color: white;
	font-weight: bold;
	border: 1px solid #d3d3d3 !important;
	padding: 6px;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}


