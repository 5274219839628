/* Tabs */
.nav-tabs {
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
}
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
  background: #f2f2f2;
  cursor: pointer;
}
.nav-tabs,
.nav-tabs>li>a,
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
  border: 0;
}

.nav-tabs>li>a {
  background: #E4E4E4;
  color: #555;
  cursor: pointer;
}
.nav-tabs.tab-space-2 > li > a {
  margin-right: 2px;
}
.nav-tabs.tab-space-3 > li > a {
  margin-right: 3px;
}
.nav-tabs.tab-space-4 > li > a {
  margin-right: 4px;
}
.nav-tabs.tab-space-7 > li > a {
  margin-right: 7px;
}
/*Dropdown on tabs*/
.dropdown-menu-tabs{
  border: 0;
  padding: 0;
  background: #f2f2f2;
  min-width: 200px;
  .box-shadow(0 7px 12px rgba(0,0,0,0.175));
}
.dropdown-menu-tabs > li >a {
  padding: 7px 15px;
}
.dropdown-menu-tabs > li {
  > a:hover,
  > a:focus{
    background: #E4E4E4;
  }
}

.dropdown-menu-tabs > .active > a{
  font-weight: bold;
}

.dropdown-with-titles {
  padding-top: 10px;
}

.dropdown-with-titles > li.active > a,
.dropdown-with-titles > li.active > a:hover,
.dropdown-with-titles > li.active > a:focus {
  background: #E4E4E4;
  font-weight: normal;
}

.dropdown-with-titles > li >a:hover,
.dropdown-with-titles > li.active > a:hover {
  background: #D9D9D9;
}

/** white tabs */
.white-tabs .nav-tabs {
  background: #f2f2f2;
  > li.active > a,
  > li.active > a:hover,
  > li.active > a:focus {
    background: #fff !important;
    border: 1px solid #e4e4e4;
    border-bottom: 0;
  }
}

.white-tabs .nav-tabs>li>a:hover,
.white-tabs .nav-tabs>li>a:focus {
  background: #D9D9D9;
}

/** vertical tab separator */
.nav-tabs-separator {
  height: 1px;
  background: #e4e4e4;
  overflow: hidden;
}
