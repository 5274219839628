@import "../twitterAds/variables.less";
@import "../fonts/font-awesome/_mixins.less";

.header-filter {

  .dropdown-button-title {
    padding-left: 3px;
  }

  .split-button-left {
    width: 100%;

    .fa.fa-plus {
      margin-right: 6px;
    }
  }

  .form-control,
  #reportrange,
  .bootstrap-select .btn,
  .select2-container .select2-choice {
    width: 100%;
    height: 34px;
    padding: 5px 23px 5px 10px;
    font-size: 13px !important;
    line-height: 1.4em !important;
    margin-top: 0 !important;
  }

  input[type="text"] {
    .placeholder(#c4c4c4);
  }

  .select2-container .select2-choice {
    height: auto;
    min-height: 34px;
  }

  .bootstrap-select.form-control,
  .select2-container {
    height: 34px;
  }

  .select2-container {
    margin-top: 0 !important;
  }

  #reportrange > span {
    display: inline-block;
    padding: 2px 0;
  }

  .filter-container {
    .react-bootstrap-daterangepicker-container {
      display: block !important;
    }
  }

  .select2-container-multi .select2-choices {
    min-height: 34px;

    .select2-search-choice {
      height: 27px;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 12px;
      color: #666;
    }

    .select2-search-choice-close {
      top: 6px;
    }
  }

  .selected-date-range-btn,
  .Select-control,
  .bootstrap-select-react.fat {
    color: #858585;
    height: 34px;
    font-size: 13px;
    line-height: 1.7em;
    text-align: left;
  }

  .Select-arrow {
    top: 16px;
  }

  .Select--single > .Select-control .Select-value,
  .Select-control .Select-placeholder {
    line-height: 32px;
  }

  .selected-date-range-btn .caret {
    margin: 7px 0;
    vertical-align: top;
  }

  .sm .Select-control {
    height: 27px;

    .Select-value,
    .Select-placeholder {
      line-height: 27px;
    }

    .Select-arrow-zone {
      top: 2px;
    }

    .Select-input > input {
      padding-top: 0;
    }

  }

  .Select-input > input {
    padding-top: 9px;
  }

  .link .Select-arrow {
    display: block;
    margin-top: 10px;
  }

  .Select-placeholder,
  .link.Select--single > .Select-control .Select-value {
    padding-right: 15px;
  }

}

.header-filter-flat {
  .selected-date-range-btn {
    background: transparent;
    border: 0;
    padding: 0;
    min-width: inherit;
  }

  .filter-item {
    border-left: 1px solid #e8ecef;
    padding: 0 15px;
    display: inline-block;
    font-size: 13px;
    line-height: 29px;
    vertical-align: text-top;
  }

  @media (max-width: 1100px) {
    .filter-item {
      padding: 0 8px;
    }
  }

  .filter-item:last-child {
    padding-right: 4px;
  }

  .btn-group {
    .btn.btn-default {
      color: @submit-background-color;
      border-color: @submit-background-color;
      background-color: #fff;
    }
  }

  .btn-group .bootstrap-select-react.btn-link.active,
  .bootstrap-select-react.btn-link.active {
    color: #000;
    font-weight: bold;
  }

  .Select.link .Select-menu-outer {
    min-width: 350px;
  }
}

body:not(.twitter-style) .dashboard-page-tw .header-filter-flat .selected-date-range-btn {
  background: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px 8px 6px;
  min-width: 210px;
}

.filter-dropdown.dropdown-menu {
  box-shadow: 0 3px 9px rgba(0, 0, 0, .2);

  li {
    border-bottom: 1px solid #e8ecef;

    a {
      padding-right: 43px;
    }

    .fa {
      margin-right: -23px;
      font-weight: bold;
      font-size: 14px;
    }
  }

  li:last-child {
    border-bottom: 0;
  }
}

.adplatform-style .header-tab-content {
  padding-top: 25px;

  .menu-create .btn {
    margin-top: 4px;
  }
}

