.budget-limit-name {
  .formWidth();
}

.budget-limit-currency, .budget-limit-email {
  margin-left: 10px;
}

.total-right-align {
  text-align: right;
}
