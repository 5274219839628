.twLinkFocusColor.entityName,
.twLinkFocusColor.groupName,
.twLinkFocusColor.campaignName {
  display: inline-block;
  margin-bottom: -3px;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twLinkFocusColor.groupName {
  max-width: 300px;
}

.campaignListCampaignName {
  font-weight: bold;
}

.twLoader.loader-campaign-info {
  font-size: 1em;
  padding: 0 -8px 0 7px;
}

.campaignNameContainer .campaignInfoLoaderContainer {
  margin-left: 5px;
}

.twGridCollapsable .gridAdCountCol .public_fixedDataTableCell_cellContent {
  font-size: inherit;
  margin: 0;
}

.campaignListPacing .fdt-statValue {
  padding-top: 10px;
}

.campaignListPacing .twProgress {
  margin-top: -3px;
}

.twGridName .campaign-info {
  margin-top: -3px;
}

.twGridName a {
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.twBulkActions {
  margin-left: 3px;
  display:inline-block;
}

.twBulkActions .httpool-checkbox-button {
  margin-right: 30px;
}

.twBulkActions button {
  margin-right: 3px;
}

.twGridOpenPreview {
  cursor: pointer;
  display: none;
  position: absolute;
  z-index: 9;
  font-size: 26px;
  left: 45%;
  top: 20%;
  color: #00a1cb;
}

.hide-search-icon {
  display: none !important;
}

.hide-pointer {
  cursor: default !important;

  &:hover {
    cursor: default !important;
  }
}

.twOpenAdPreview {
  display: none;
  position: absolute;
  z-index: 9;
  font-size: 26px;
  right: calc(50% - 18px);
  padding-top: 5px;
  color: #00a1cb;
  cursor: pointer;
  height:100%;
  top: 8px;
  text-shadow: 0 0 3px white;
}

.tw-adCellAdPreview {
  padding: 0 5px;
}

.ag-cell:hover .twOpenAdPreview  {
  display: block;
}

.syncing {
  margin-right: 5px;
  margin-top: 40px;
  margin-left: 35px;
}

.icon-status-in_creation {
  font-size: .8em;
}

.noPreviewSelection .showPreviewControls {
  display: none;
}

.status-text .icon-status {
  font-size: 14px;
}

/*Fixed Data Table Cell*/

.fdt-cell {
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  padding: 8px;
}

.fdt-cell.fdt-cellTransparent {
  background-color: transparent;
  border-bottom: none;
  border-right: none;
}

.fdt-cellNotTransparent {
  background-color: #f2f2f2;
}

.fdt-cellHeader,
.fdt-cellFooter {
  border-left: none !important;
  border-right: none !important;
}

.fdt-cellFooter {
  border-bottom: none !important;
}

.fdt-cell:not(.text-left),
.fdt-cellHeader:not(.text-left),
.fdt-cellFooter:not(.text-left) {
  text-align: right;
}

.fdt-cell.text-center {
  text-align: center;
}

.fdt-cellHeader:not(.fdt-cellTransparent){
  border-bottom: 1px solid #e4e4e4;
}

.fdt-cellHeader.fdt-firstCell {
  border-bottom: none;
}

.fdt-nameHeader {
  padding-top: 3px;
}

.fdt-cellName {
  text-align: left;
  font-size: 14px;
  padding-left: 14px;
}

.fdt-cellFooter {
  font-size: 13px;
}
.fdt-nameHeader,
.fdt-cellFooter,
.fdt-cellHeader:not(.fdt-cellTransparent) {
  background: #f2f2f2;
}

.fdt-cellHeader.fdt-cellMenuHeader {
  background-color: #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.fdt-cellNameContent {
  margin-top: -4px;
}

.fdt-cellAction {
  padding: 0;
  margin-left: -1px;
  background-color: #f2f2f2;
}

.fdt-cellActionFirstRow {
  border-top: 0!important;
}

.fdt-cellCheckbox {
  padding-top: 2px;
  padding-left: 4px;
  float: left;
  border-right: 0;
  border-bottom: 0;
}

.fdt-statCol .fdt-statValue {
  margin-top: 9px;
}

.fdt-accountPreviewCell {
  position: relative;
  border: 1px solid #e4e4e4;
  border-top: 0;
}

.fdt-accountPreviewCell img {
  margin-left: 10px;
}

.fdt-accountPreviewCell label {
  position: absolute;
  left: 80px;
  top: 5px;
  color: #4E4E4E;
  font-weight: bold;
}

.fdt-accountPreviewCell .card-button {
  position: absolute;
  left: 80px;
  top: 20px;
}

.fdt-cellAdPreview {
  padding: 0 5px;
}

.fdt-cellAdPreview:hover {
  opacity: 0.5;
}

.fdt-cellAdPreview:hover .twGridOpenPreview {
  display: block;
}

.adPreviewIframe {
  border: none;
  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -o-transform: scale(0.75);
  -webkit-transform: scale(0.75);
  margin-top: -13px;
  margin-left: -25px;
}

.adPreviewContainer {
  position: relative;
  top: 5px;
  border: 1px solid #00A6C8;
  height: 70px;
  width: 194px;
  overflow: hidden;
}

.fdt-cell .txt-label {
  padding-bottom: 0;
  float: inherit;
  padding-right: 0;
  margin-top: -4px;
  min-height: unset;
  font-size: 11px;
}

.promoted-object-name {
  white-space: nowrap;
  color: inherit !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.composed-stat-additional-info .help-icon {
	font-size: 0.8em;
}
