.keywordMatchPicker .close {
  margin-top: 2px;
  font-size: 1.2em;
}

.keywordMatchPicker .ondemand-box {
  padding-bottom: 7px;
}

.keywordMatchPicker .keyword {
  display: inline-block;
  padding: 0 20px 0 0;
}

.keywordMatchPicker .btn-group {
  margin-top: -3px;
}
