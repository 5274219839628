//
// Mixins
// ------------------------------


// Utilities

.size(@width; @height) {
	width: @width;
	height: @height;
}
.square(@size) {
	.size(@size; @size);
}
.border-top-radius(@radius) {
	border-top-right-radius: @radius;
	border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
	border-bottom-left-radius: @radius;
	border-top-left-radius: @radius;
}


// Vendor Prefixes

.animation(@animation) {
	-webkit-animation: @animation;
	-o-animation: @animation;
	animation: @animation;
}
.box-sizing(@boxmodel) {
	-webkit-box-sizing: @boxmodel;
	-moz-box-sizing: @boxmodel;
	box-sizing: @boxmodel;
}
.rotate(@degrees) {
	-webkit-transform: rotate(@degrees);
	-ms-transform: rotate(@degrees); // IE9 only
	-o-transform: rotate(@degrees);
	transform: rotate(@degrees);
}
.transform(@transform) {
	-webkit-transform: @transform;
	-moz-transform: @transform;
	-ms-transform: @transform;
	transform: @transform;
}
