.add-user-button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 9px;
}

.add-user-button-container .btn {
  margin: 0 !important;
}

.add-user-button-container .btn-sm {
  padding: 6px 12px !important;
}
