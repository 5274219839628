.target-limit-start-after {
  display: inline-block;

  input {
	margin-right: 10px;
    margin-left: 10px;

    &:disabled {
      cursor: pointer !important;
    }
  }
}

.start-title {
  display: block;
  font-size: 12px;
  color: #666;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.metric-description {
  vertical-align: sub;
}

.top-performance-metric {
  display: inline-table;
  width: 200px;
  margin-left: 10px;
}

.target-limit-pause-ads {
  display: inline-block;

  input {
    margin-top: 10px;
    margin-left: 10px;
    width: 151px !important;
  }
}

.target-limit-validation, .target-limits-metrics-validation {
  display: table;
  margin-bottom: -10px;
}

.target-limit-validation {
  margin-left: 10px;
}

.target-limits-metrics-validation {
  margin-left: 10px;
}

.target-limits-eff-mode {
  display: inline-block;

  input {
    margin-right: 10px;
    width: 260px !important;
  }
}

.right {
  margin-left: 46px;
}

.target-limit-days input {
  width: 150px !important;

  &:disabled {
    cursor: not-allowed !important;
  }
}

.table-validation {
  margin-left: 0;
}

.target-limit-wrap {
  width: 100%;
  max-width: 640px;
}
