//
// Imports
// ------------------------------
@import "select-multi-tag/select.less";

.Select-control{
  cursor: pointer;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.Select--multi .Select-value {
  margin: @select-item-gutter;
}


// icon
.Select--multi .Select-value-icon {
  cursor: pointer;
  float: right;
  font-weight: bold;
  font-size: @select-icon-font-size;
  line-height: 1em;
  color: #949494;

  // move the baseline up by 1px
  padding: (@select-item-padding-vertical - 2) @select-item-padding-horizontal (@select-item-padding-vertical - 2 );

  &:hover,
  &:focus {
    color: @select-item-hover-color;
    background-color: @select-item-bg;
  }
  &:active {
    background-color: @select-item-border-color;
  }
}

.is-focused:not(.is-open) > .Select-control {
  border-color: @select-input-border-color;
  box-shadow: none;
}

.Select-clear {
  color: @select-clear-color;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  font-size: @select-clear-size;
  padding: 3px 5px;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.4;

  &:hover {
    color: @select-clear-hover-color;
  }

  > span {
    font-size: 1.1em;
  }
}

.Select--multi .Select-clear {
  padding: @select-padding-vertical @select-padding-horizontal;
}

// arrow indicator
.Select--multi:not(.show-arrow) .Select-arrow-zone {
  display: none;
}

.Select-loading-zone {
  right: 10px;
}

.statusFilter .Select-value-label {
  line-height: 32px;
}

.entityFilter .Select-value-label {
  line-height: 32px;
}

.Select.medium-box {
  width: 200px;
}

.Select.medium-box-left {
  width: 200px;
  display: inline-block;
  top: 10px;
  left: 3px;
}
:not(.Select--multi)>.Select-control .Select-value {
  max-width: 96%;
}

.form .value .Select {
  font-size: 12px;
}

.Select.small-box {
  width: 100px;
}

.Select.bsSelect {
  font-size: 0.9em;
}

.Select.bsSelect .Select-arrow-zone {
  width: 15px;
}

.Select.bsSelect .Select-arrow {
  border-width: 4px 4px 2.5px;
}

#fbPricingAnchor .Select-arrow-zone {
  width: 16px;
}

#fbPricingAnchor .Select-value {
  font-size: 13px;
  line-height: 25px;
}

.Select.valueWidth {
  width: 220px;
}

.input-invalid .Select-control {
  box-shadow: none;
  border: none;
}

.Select-option .avatar,
.Select-value .avatar {
  margin: 4px 7px 0 -3px;
  width: 17px;
  height: 17px;
  vertical-align: top;
}
.Select-option .avatar {
  margin-top: 0;
}

.Select-option {
  .text {
    color: #333;
    font-size: 12px;
  }
}

.Select-group .Select-option {
  padding-left: 25px;
}

.Select-group .Select-option.is-disabled {
  color: #333;
  cursor: default;
  font-weight: 700;
  padding-left: 10px;
}

.Select-option.is-disabled {
  color: #ccc;
}
