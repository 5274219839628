.widget-button {
  height: 110px;
  margin: 10px 0;
  border: 1px solid @mystic; /* @geyser */
  background-color: white;
  color: @dodgerBlue;
  border-radius: 5px;

  &:hover {
	cursor: pointer;
	background-color: @mystic;
  }

  i {
    font-size: 25px;
    margin-top: 15px;
    text-align: center;
    display: block;
  }
}

.widget-disabled {
  height: 110px;
  margin: 10px 0;
  border: 1px solid @mystic; /* @geyser */
  background-color: @grayColor;
  color: @scienceBlue;
  border-radius: 5px;
  opacity: 0.5;
  pointer-events: none;

  i {
    opacity: 0.5;
    font-size: 25px;
    margin-top: 15px;
    text-align: center;
    display: block;
  }
}

.widget-active {
  background-color: @dodgerBlue;
  color: white;

  &:hover {
    background-color: @submitButtonHoverColor;
  }
}

.widget-label {
  text-align: center;
  position: absolute;
  font-size: 20px;
  height: 1em;
  top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 20px;
  font-weight: lighter;
}
