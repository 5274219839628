.counter-round {
  width: 18px;
  height: 18px;
  background-color: #2196F3;
  color: white !important;
  border: 1px solid #2196F3;
  border-radius: 100%;
  line-height: 18px;
  text-align: center;
  font-size: 11px;
}

.counter-round-empty {
  color: #2196F3;
  background-color: white;
}

.counter-round.budget-counter {
  float: right;
  margin-right: 5px;
}
