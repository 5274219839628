
#rocket-chat {
	position: fixed;
	width: 370px;
	height: 30px;
	border: none;
	bottom: 0;
	right: 0;
	z-index: 1000;
	left: auto;
}