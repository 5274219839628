.hide-text {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.input-block-level {
	display: block;
	width: 100%;
	min-height: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.btn-file {
	overflow: hidden;
	position: relative;
	vertical-align: middle;
}
.btn-file>input {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	opacity: 0;
	filter: alpha(opacity=0);
	transform: translate(-300px, 0) scale(4);
	font-size: 23px;
	direction: ltr;
	cursor: pointer;
}
.fileupload {
	margin-bottom: 9px;
}
.fileupload .uneditable-input {
	display: inline-block;
	margin-bottom: 0px;
	vertical-align: middle;
	cursor: text;
}
.fileupload .thumbnail {
	overflow: hidden;
	display: inline-block;
	margin-bottom: 5px;
	vertical-align: middle;
	text-align: center;
}
.fileupload .thumbnail>img {
	display: inline-block;
	vertical-align: middle;
	max-height: 100%;
}
.fileupload .btn {
	vertical-align: middle;
}
.fileupload-exists .fileupload-new,
.fileupload-new .fileupload-exists {
	display: none;
}
.fileupload-inline .fileupload-controls {
	display: inline;
}
.fileupload-new .input-append .btn-file {
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
}
.thumbnail-borderless .thumbnail {
	border: none;
	padding: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.fileupload-new.thumbnail-borderless .thumbnail {
	border: 1px solid #ddd;
}
.control-group.warning .fileupload .uneditable-input {
	color: #a47e3c;
	border-color: #a47e3c;
}
.control-group.warning .fileupload .fileupload-preview {
	color: #a47e3c;
}
.control-group.warning .fileupload .thumbnail {
	border-color: #a47e3c;
}
.control-group.error .fileupload .uneditable-input {
	color: #b94a48;
	border-color: #b94a48;
}
.control-group.error .fileupload .fileupload-preview {
	color: #b94a48;
}
.control-group.error .fileupload .thumbnail {
	border-color: #b94a48;
}
.control-group.success .fileupload .uneditable-input {
	color: #468847;
	border-color: #468847;
}
.control-group.success .fileupload .fileupload-preview {
	color: #468847;
}
.control-group.success .fileupload .thumbnail {
	border-color: #468847;
}
.fileupload .close.delete-file{
	vertical-align: bottom;
}
#cancel-upload {
	font-size: 13px;
	padding: 1px 0;
	margin-left: 7px;
	text-align: center;
}
