@switch-label-width: 25px;
@switch-bg-enabled: #47cf00;
@switch-bg-disabled: #c7c7c7;

.rules {
    .panel {
        &:before {
            width: 0;
            border: none;
        }

        &.enabled {
            border-left: 5px solid @switch-bg-enabled;
        }

        &.disabled {
            border-left: 5px solid @switch-bg-disabled;
        }
    }

    .colapsing-selector {
        display: none;
    }

    h6 {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 0 3px 0;
    }

    .submit-container {
        display: flex;
        border-top: 2px solid #ddd;
        padding: 20px 0 10px;
        margin-top: 20px;

        .button_container {
            margin: 0;

            button {
                height: 50px;
                line-height: 35px;
                text-transform: uppercase;
                padding: 0 30px;
            }
        }
    }

    .button-switch {
        height: 28px;
        color: #fff;
        text-align: center;
        line-height: 26px;
        cursor: pointer;
        animation: bg-disable 0.5s forwards;

        &.active {
            animation: bg-enable 0.5s forwards;
        }

        .switch-label {
            float: left;
            overflow: hidden;
            width: @switch-label-width;
            animation: shrink-label 0.5s forwards;


            &.active {
                width: 0;
                padding: 0;
                animation: grow-label 0.5s forwards;
            }

            &:nth-child(1) {
                margin-left: 2px;
            }

            &:nth-last-child(1) {
                margin-right: 2px;
            }
        }

        .switch-handle {
            float: left;
            width: 22px;
            height: 22px;
            background: #fff;
            margin: 3px 1px;
        }
    }

    .rules-edit {

        .panel-title {
            color: #707070;

            &:hover {
                cursor: default !important;
            }
        }

        .rules-edit-panel {

            .panel-title {
                font-weight: normal;

                &:hover {
                    cursor: default !important;
                }
            }

            .panel-body {
                border: none;

                .form {
                    label.caption {
                        width: 100px;
                    }

                    div.value {
                        margin-left: 100px;
                    }
                }

                .rules-then {
                    background: transparent;
                    border: none;
                    padding: 0;
                }

                .condition-no-bg {
                    padding: 0;
                    margin-bottom: 10px;
                }

                .condition-no-bg > div:nth-child(1) {
                    padding-left: 0;
                }
            }
        }
    }

    /*Autopilot*/
    .autopilot {
        .panel {
          position: relative;
        }
        position: relative;
        padding-bottom: 40px;
        margin-bottom: 40px;

        .overview .panel-body {
           border: 1px solid #e0e0e0;
           border-top-right-radius: 8px;
           border-bottom-right-radius: 8px;
        }

        .help.no-padding {
                padding: 0;
        }

        .amount-input input {
            text-align: right;
        }

        &.show-details {
            padding-bottom: 0;
        }

        .panel {
            margin-bottom: 0;

            .react-panel-content-margin {
                margin: 15px 20px;
            }
        }

        .button-help {
            float: right;
            text-transform: uppercase;
            cursor: pointer;
            position: absolute;
            right: 0;
            z-index: 1;
        }

        .overview {
            .header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;

                .info {
                    padding: 0;
                    flex-grow: 2;

                    h5 {
                        color: #00A1CB;
                        cursor: pointer;
                        margin: 0 0 3px 0;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .details {
                border-top: 1px solid #e1e1e1;
                padding-top: 15px;
                display: flex;
                justify-content: flex-start;

                div {
                    margin-right: 100px;

                    p {
                        margin: 0;
                    }
                }
            }
        }

        .details {
            .panel-title {
                color: #707070;

                &:hover {
                    cursor: default !important;
                }
            }

            .datetimepicker-wrap {
                .datetimepicker-datepicker.value {
                    min-width: 82px !important;
                }

                .datetime-withicon {
                    border: 1px solid #d3d3d3 !important;
                    display: inline-block;
                    padding-right: 10px;
                    margin-right: 10px;

                    .datetimepicker-datepicker.value {
                        border: none !important;
                    }

                    .datetimepicker-timepicker {
                        min-width: 44px !important;
                        width: 44px !important;
                        padding: 4px 0 !important;
                        border: none !important;
                    }
                }
            }

            .spend-until-checkbox {
                display: block;
                margin: 5px 0 10px;
            }

            .optimisation-goal > div {
                margin: 20px 0;
                justify-content: flex-start;

                label {
                    float: left;
                }

                .Select, .amount-input {
                    display: inline-block;
                    margin-left: 10px;
                    margin-top: -4px;
                }
            }

            .optimisation-goal-notice {
                span {
                    margin-left: 5px;
                    color: #00A1CB;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .help {
            background: transparent;
            border: none;
            padding: 20px;

            h4 {
                margin: 40px 0 5px;
                font-weight: bold;
            }

            p {
                margin: 0;
                text-align: left;
                max-width: 800px;
                font-size: 14px;
            }
        }

        .controls {
            li {
                float:left;
                padding-left: 14px;

                a {
                    font-size: 16px;
                }
            }
        }

        .autopilot-optimization-bellow {
            padding-left: 10px;
        }

        .twCondAlert {
            padding-top: 0;
        }

        .budget-warning {
            padding-top: 10px;
        }
    }

    /*Rules*/
    #fb_add_new_ads_button, .edit-bid {
        height: 28px;
        line-height: 18px;

        .title {
            padding: 0 3px 0 8px;
            font-size: 12px;
        }
    }

    #fb_add_new_ads_button {
        margin-top: 0;
    }

    .rules-group {
        margin-bottom: 40px;

        h5 {
            margin: 0 0 20px;
        }

        .edit-bid {
            padding: 0 13px 0 10px;
            font-size: 12px;
            text-transform: uppercase;
            line-height: 26px;

            i {
                margin-right: 3px;
                position: relative;
                top: -1px;
            }
        }

        .react-panel-content-margin {
            margin: 15px;
        }

        .single-rule-container {
            padding-bottom: 20px;

            .rules-container {
                border-top: 1px solid #e1e1e1;
                padding-top: 15px;
                display: flex;
                justify-content: flex-start;

                .rule-condition {
                    margin-right: 70px;
                    width: 400px;
                }

                .condition.full {
                    margin: 0;
                    padding: 0;
                    width: auto;
                    border: none;
                    background: transparent;

                    .condition-operator.spacer {
                        display: none;
                    }

                    .onEntity {
                        margin-right: 0;
                    }
                }

                .condition.full > span {
                    width: auto;
                    margin-right: 4px;
                    padding-top: 0;
                    text-transform: lowercase;

                    &:nth-child(1) {
                        text-transform: capitalize;
                        width: 100%;
                        font-weight: normal;
                    }

                    &:nth-child(2) {
                        text-transform: capitalize;
                    }

                    span {
                        width: auto;
                        padding-top: 0;
                    }
                }

                .action.condition.full > span > .condition-rule {
                    padding: 0 4px;
                }

                .action.condition.full {
                    .action-limit {
                        width: 100%;
                        display: block;

                        .condition-operator.spacer {
                            display: inline;
                            text-transform: capitalize;
                        }

                        .condition-rule.short {
                            text-transform: none;
                        }
                    }
                }
            }
        }
    }
}

.report-list .rules-group,
.report-list .rules .single-rule-container {
    margin-bottom: 0;
    height: 100%;
}

.autopilot, .autopilot-popover {
    h5 {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 22px;
        margin: 50px 0 10px;
        font-weight: normal;

        &:nth-child(1) {
            margin-top: 30px;
        }
    }

    .heading {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            font-weight: bold;
            width: ~"calc(100% - 50px)";
        }
    }

    .close-button {
        width: 30px;
        height: 30px;
        color: #08a2ce;
        border-radius: 50%;
        position: relative;
        font-weight: bold;
        cursor: pointer;

        div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 35px;
        }
    }

    .amount-checkbox {
        margin: 15px 0 7px;
    }

    .radio-inline:nth-child(1) {
        padding-left: 0;
    }

    .amount-input {
        input {
            width: 100px;
        }
    }

    .input-inline {
        margin-right: 10px;
    }

    .input-percent {
        width: 45px!important;
        padding-right: 0;
    }

    .invalid-email {
        display: inline-block;
        margin-left: 10px;
        color: red;
    }
}

.autopilot .form-row-inline, .autopilot-popover .form-row-inline {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;

    label {
        float: left;
    }

    .Select, .amount-input {
        display: inline-block;
        margin-left: 10px;
        margin-top: -4px;
    }
}

.autopilot-popover {
    min-width: 550px;
    padding: 5px 10px 30px;

    .popover-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.3);
    }

    .amount-checkbox {
        margin: 5px 0 0;
    }

    .form-row-inline {
        margin: 5px 0;
    }
}

.campaigns-selector {
    .formWidth();
}

.legacy-campaigns-selector {
    width: 600px !important;
}

.adplatform-style .autopilot .controls {
    li {
        float:left;
        padding-left: 0px;

        span {
            font-size: 16px;
            color: #00a1cb;
            border: none;
            padding: 0 0 0 10px;
        }

        span:hover {
            background-color: initial;
            color: #616161;
        }
    }
}

@keyframes bg-disable {
    0%   { background: @switch-bg-enabled; }
    100% { background: @switch-bg-disabled; }
}

@keyframes bg-enable {
    0%   { background: @switch-bg-disabled; }
    100% { background: @switch-bg-enabled; }
}

@keyframes shrink-label {
    0%   { width: @switch-label-width; }
    100% { width: 0; }
}

@keyframes grow-label {
    0%   { width: 0; }
    100% { width: @switch-label-width; }
}
