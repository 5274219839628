// Variables
// --------------------------

@fa-font-path:        "../fonts";
@fa-font-size-base:   16px;
@fa-font-display:     auto;
@fa-line-height-base: 1;
@fa-css-prefix:       fa;
@fa-version:          "5.8.1";
@fa-border-color:     #eee;
@fa-inverse:          #fff;
@fa-li-width:         2em;

@fa-var-500px: "\f26e";
@fa-var-accessible-icon: "\f368";
@fa-var-accusoft: "\f369";
@fa-var-acquisitions-incorporated: "\f6af";
@fa-var-ad: "\f641";
@fa-var-address-book: "\f2b9";
@fa-var-address-card: "\f2bb";
@fa-var-adjust: "\f042";
@fa-var-adn: "\f170";
@fa-var-adobe: "\f778";
@fa-var-adversal: "\f36a";
@fa-var-affiliatetheme: "\f36b";
@fa-var-air-freshener: "\f5d0";
@fa-var-airbnb: "\f834";
@fa-var-algolia: "\f36c";
@fa-var-align-center: "\f037";
@fa-var-align-justify: "\f039";
@fa-var-align-left: "\f036";
@fa-var-align-right: "\f038";
@fa-var-alipay: "\f642";
@fa-var-allergies: "\f461";
@fa-var-amazon: "\f270";
@fa-var-amazon-pay: "\f42c";
@fa-var-ambulance: "\f0f9";
@fa-var-american-sign-language-interpreting: "\f2a3";
@fa-var-amilia: "\f36d";
@fa-var-anchor: "\f13d";
@fa-var-android: "\f17b";
@fa-var-angellist: "\f209";
@fa-var-angle-double-down: "\f103";
@fa-var-angle-double-left: "\f100";
@fa-var-angle-double-right: "\f101";
@fa-var-angle-double-up: "\f102";
@fa-var-angle-down: "\f107";
@fa-var-angle-left: "\f104";
@fa-var-angle-right: "\f105";
@fa-var-angle-up: "\f106";
@fa-var-angry: "\f556";
@fa-var-angrycreative: "\f36e";
@fa-var-angular: "\f420";
@fa-var-ankh: "\f644";
@fa-var-app-store: "\f36f";
@fa-var-app-store-ios: "\f370";
@fa-var-apper: "\f371";
@fa-var-apple: "\f179";
@fa-var-apple-alt: "\f5d1";
@fa-var-apple-pay: "\f415";
@fa-var-archive: "\f187";
@fa-var-archway: "\f557";
@fa-var-arrow-alt-circle-down: "\f358";
@fa-var-arrow-alt-circle-left: "\f359";
@fa-var-arrow-alt-circle-right: "\f35a";
@fa-var-arrow-alt-circle-up: "\f35b";
@fa-var-arrow-circle-down: "\f0ab";
@fa-var-arrow-circle-left: "\f0a8";
@fa-var-arrow-circle-right: "\f0a9";
@fa-var-arrow-circle-up: "\f0aa";
@fa-var-arrow-down: "\f063";
@fa-var-arrow-left: "\f060";
@fa-var-arrow-right: "\f061";
@fa-var-arrow-up: "\f062";
@fa-var-arrows-alt: "\f0b2";
@fa-var-arrows-alt-h: "\f337";
@fa-var-arrows-alt-v: "\f338";
@fa-var-artstation: "\f77a";
@fa-var-assistive-listening-systems: "\f2a2";
@fa-var-asterisk: "\f069";
@fa-var-asymmetrik: "\f372";
@fa-var-at: "\f1fa";
@fa-var-atlas: "\f558";
@fa-var-atlassian: "\f77b";
@fa-var-atom: "\f5d2";
@fa-var-audible: "\f373";
@fa-var-audio-description: "\f29e";
@fa-var-autoprefixer: "\f41c";
@fa-var-avianex: "\f374";
@fa-var-aviato: "\f421";
@fa-var-award: "\f559";
@fa-var-aws: "\f375";
@fa-var-baby: "\f77c";
@fa-var-baby-carriage: "\f77d";
@fa-var-backspace: "\f55a";
@fa-var-backward: "\f04a";
@fa-var-bacon: "\f7e5";
@fa-var-balance-scale: "\f24e";
@fa-var-ban: "\f05e";
@fa-var-band-aid: "\f462";
@fa-var-bandcamp: "\f2d5";
@fa-var-barcode: "\f02a";
@fa-var-bars: "\f0c9";
@fa-var-baseball-ball: "\f433";
@fa-var-basketball-ball: "\f434";
@fa-var-bath: "\f2cd";
@fa-var-battery-empty: "\f244";
@fa-var-battery-full: "\f240";
@fa-var-battery-half: "\f242";
@fa-var-battery-quarter: "\f243";
@fa-var-battery-three-quarters: "\f241";
@fa-var-battle-net: "\f835";
@fa-var-bed: "\f236";
@fa-var-beer: "\f0fc";
@fa-var-behance: "\f1b4";
@fa-var-behance-square: "\f1b5";
@fa-var-bell: "\f0f3";
@fa-var-bell-slash: "\f1f6";
@fa-var-bezier-curve: "\f55b";
@fa-var-bible: "\f647";
@fa-var-bicycle: "\f206";
@fa-var-bimobject: "\f378";
@fa-var-binoculars: "\f1e5";
@fa-var-biohazard: "\f780";
@fa-var-birthday-cake: "\f1fd";
@fa-var-bitbucket: "\f171";
@fa-var-bitcoin: "\f379";
@fa-var-bity: "\f37a";
@fa-var-black-tie: "\f27e";
@fa-var-blackberry: "\f37b";
@fa-var-blender: "\f517";
@fa-var-blender-phone: "\f6b6";
@fa-var-blind: "\f29d";
@fa-var-blog: "\f781";
@fa-var-blogger: "\f37c";
@fa-var-blogger-b: "\f37d";
@fa-var-bluetooth: "\f293";
@fa-var-bluetooth-b: "\f294";
@fa-var-bold: "\f032";
@fa-var-bolt: "\f0e7";
@fa-var-bomb: "\f1e2";
@fa-var-bone: "\f5d7";
@fa-var-bong: "\f55c";
@fa-var-book: "\f02d";
@fa-var-book-dead: "\f6b7";
@fa-var-book-medical: "\f7e6";
@fa-var-book-open: "\f518";
@fa-var-book-reader: "\f5da";
@fa-var-bookmark: "\f02e";
@fa-var-bootstrap: "\f836";
@fa-var-bowling-ball: "\f436";
@fa-var-box: "\f466";
@fa-var-box-open: "\f49e";
@fa-var-boxes: "\f468";
@fa-var-braille: "\f2a1";
@fa-var-brain: "\f5dc";
@fa-var-bread-slice: "\f7ec";
@fa-var-briefcase: "\f0b1";
@fa-var-briefcase-medical: "\f469";
@fa-var-broadcast-tower: "\f519";
@fa-var-broom: "\f51a";
@fa-var-brush: "\f55d";
@fa-var-btc: "\f15a";
@fa-var-buffer: "\f837";
@fa-var-bug: "\f188";
@fa-var-building: "\f1ad";
@fa-var-bullhorn: "\f0a1";
@fa-var-bullseye: "\f140";
@fa-var-burn: "\f46a";
@fa-var-buromobelexperte: "\f37f";
@fa-var-bus: "\f207";
@fa-var-bus-alt: "\f55e";
@fa-var-business-time: "\f64a";
@fa-var-buysellads: "\f20d";
@fa-var-calculator: "\f1ec";
@fa-var-calendar: "\f133";
@fa-var-calendar-alt: "\f073";
@fa-var-calendar-check: "\f274";
@fa-var-calendar-day: "\f783";
@fa-var-calendar-minus: "\f272";
@fa-var-calendar-plus: "\f271";
@fa-var-calendar-times: "\f273";
@fa-var-calendar-week: "\f784";
@fa-var-camera: "\f030";
@fa-var-camera-retro: "\f083";
@fa-var-campground: "\f6bb";
@fa-var-canadian-maple-leaf: "\f785";
@fa-var-candy-cane: "\f786";
@fa-var-cannabis: "\f55f";
@fa-var-capsules: "\f46b";
@fa-var-car: "\f1b9";
@fa-var-car-alt: "\f5de";
@fa-var-car-battery: "\f5df";
@fa-var-car-crash: "\f5e1";
@fa-var-car-side: "\f5e4";
@fa-var-caret-down: "\f0d7";
@fa-var-caret-left: "\f0d9";
@fa-var-caret-right: "\f0da";
@fa-var-caret-square-down: "\f150";
@fa-var-caret-square-left: "\f191";
@fa-var-caret-square-right: "\f152";
@fa-var-caret-square-up: "\f151";
@fa-var-caret-up: "\f0d8";
@fa-var-carrot: "\f787";
@fa-var-cart-arrow-down: "\f218";
@fa-var-cart-plus: "\f217";
@fa-var-cash-register: "\f788";
@fa-var-cat: "\f6be";
@fa-var-cc-amazon-pay: "\f42d";
@fa-var-cc-amex: "\f1f3";
@fa-var-cc-apple-pay: "\f416";
@fa-var-cc-diners-club: "\f24c";
@fa-var-cc-discover: "\f1f2";
@fa-var-cc-jcb: "\f24b";
@fa-var-cc-mastercard: "\f1f1";
@fa-var-cc-paypal: "\f1f4";
@fa-var-cc-stripe: "\f1f5";
@fa-var-cc-visa: "\f1f0";
@fa-var-centercode: "\f380";
@fa-var-centos: "\f789";
@fa-var-certificate: "\f0a3";
@fa-var-chair: "\f6c0";
@fa-var-chalkboard: "\f51b";
@fa-var-chalkboard-teacher: "\f51c";
@fa-var-charging-station: "\f5e7";
@fa-var-chart-area: "\f1fe";
@fa-var-chart-bar: "\f080";
@fa-var-chart-line: "\f201";
@fa-var-chart-pie: "\f200";
@fa-var-check: "\f00c";
@fa-var-check-circle: "\f058";
@fa-var-check-double: "\f560";
@fa-var-check-square: "\f14a";
@fa-var-cheese: "\f7ef";
@fa-var-chess: "\f439";
@fa-var-chess-bishop: "\f43a";
@fa-var-chess-board: "\f43c";
@fa-var-chess-king: "\f43f";
@fa-var-chess-knight: "\f441";
@fa-var-chess-pawn: "\f443";
@fa-var-chess-queen: "\f445";
@fa-var-chess-rook: "\f447";
@fa-var-chevron-circle-down: "\f13a";
@fa-var-chevron-circle-left: "\f137";
@fa-var-chevron-circle-right: "\f138";
@fa-var-chevron-circle-up: "\f139";
@fa-var-chevron-down: "\f078";
@fa-var-chevron-left: "\f053";
@fa-var-chevron-right: "\f054";
@fa-var-chevron-up: "\f077";
@fa-var-child: "\f1ae";
@fa-var-chrome: "\f268";
@fa-var-chromecast: "\f838";
@fa-var-church: "\f51d";
@fa-var-circle: "\f111";
@fa-var-circle-notch: "\f1ce";
@fa-var-city: "\f64f";
@fa-var-clinic-medical: "\f7f2";
@fa-var-clipboard: "\f328";
@fa-var-clipboard-check: "\f46c";
@fa-var-clipboard-list: "\f46d";
@fa-var-clock: "\f017";
@fa-var-clone: "\f24d";
@fa-var-closed-captioning: "\f20a";
@fa-var-cloud: "\f0c2";
@fa-var-cloud-download-alt: "\f381";
@fa-var-cloud-meatball: "\f73b";
@fa-var-cloud-moon: "\f6c3";
@fa-var-cloud-moon-rain: "\f73c";
@fa-var-cloud-rain: "\f73d";
@fa-var-cloud-showers-heavy: "\f740";
@fa-var-cloud-sun: "\f6c4";
@fa-var-cloud-sun-rain: "\f743";
@fa-var-cloud-upload-alt: "\f382";
@fa-var-cloudscale: "\f383";
@fa-var-cloudsmith: "\f384";
@fa-var-cloudversify: "\f385";
@fa-var-cocktail: "\f561";
@fa-var-code: "\f121";
@fa-var-code-branch: "\f126";
@fa-var-codepen: "\f1cb";
@fa-var-codiepie: "\f284";
@fa-var-coffee: "\f0f4";
@fa-var-cog: "\f013";
@fa-var-cogs: "\f085";
@fa-var-coins: "\f51e";
@fa-var-columns: "\f0db";
@fa-var-comment: "\f075";
@fa-var-comment-alt: "\f27a";
@fa-var-comment-dollar: "\f651";
@fa-var-comment-dots: "\f4ad";
@fa-var-comment-medical: "\f7f5";
@fa-var-comment-slash: "\f4b3";
@fa-var-comments: "\f086";
@fa-var-comments-dollar: "\f653";
@fa-var-compact-disc: "\f51f";
@fa-var-compass: "\f14e";
@fa-var-compress: "\f066";
@fa-var-compress-arrows-alt: "\f78c";
@fa-var-concierge-bell: "\f562";
@fa-var-confluence: "\f78d";
@fa-var-connectdevelop: "\f20e";
@fa-var-contao: "\f26d";
@fa-var-cookie: "\f563";
@fa-var-cookie-bite: "\f564";
@fa-var-copy: "\f0c5";
@fa-var-copyright: "\f1f9";
@fa-var-couch: "\f4b8";
@fa-var-cpanel: "\f388";
@fa-var-creative-commons: "\f25e";
@fa-var-creative-commons-by: "\f4e7";
@fa-var-creative-commons-nc: "\f4e8";
@fa-var-creative-commons-nc-eu: "\f4e9";
@fa-var-creative-commons-nc-jp: "\f4ea";
@fa-var-creative-commons-nd: "\f4eb";
@fa-var-creative-commons-pd: "\f4ec";
@fa-var-creative-commons-pd-alt: "\f4ed";
@fa-var-creative-commons-remix: "\f4ee";
@fa-var-creative-commons-sa: "\f4ef";
@fa-var-creative-commons-sampling: "\f4f0";
@fa-var-creative-commons-sampling-plus: "\f4f1";
@fa-var-creative-commons-share: "\f4f2";
@fa-var-creative-commons-zero: "\f4f3";
@fa-var-credit-card: "\f09d";
@fa-var-critical-role: "\f6c9";
@fa-var-crop: "\f125";
@fa-var-crop-alt: "\f565";
@fa-var-cross: "\f654";
@fa-var-crosshairs: "\f05b";
@fa-var-crow: "\f520";
@fa-var-crown: "\f521";
@fa-var-crutch: "\f7f7";
@fa-var-css3: "\f13c";
@fa-var-css3-alt: "\f38b";
@fa-var-cube: "\f1b2";
@fa-var-cubes: "\f1b3";
@fa-var-cut: "\f0c4";
@fa-var-cuttlefish: "\f38c";
@fa-var-d-and-d: "\f38d";
@fa-var-d-and-d-beyond: "\f6ca";
@fa-var-dashcube: "\f210";
@fa-var-database: "\f1c0";
@fa-var-deaf: "\f2a4";
@fa-var-delicious: "\f1a5";
@fa-var-democrat: "\f747";
@fa-var-deploydog: "\f38e";
@fa-var-deskpro: "\f38f";
@fa-var-desktop: "\f108";
@fa-var-dev: "\f6cc";
@fa-var-deviantart: "\f1bd";
@fa-var-dharmachakra: "\f655";
@fa-var-dhl: "\f790";
@fa-var-diagnoses: "\f470";
@fa-var-diaspora: "\f791";
@fa-var-dice: "\f522";
@fa-var-dice-d20: "\f6cf";
@fa-var-dice-d6: "\f6d1";
@fa-var-dice-five: "\f523";
@fa-var-dice-four: "\f524";
@fa-var-dice-one: "\f525";
@fa-var-dice-six: "\f526";
@fa-var-dice-three: "\f527";
@fa-var-dice-two: "\f528";
@fa-var-digg: "\f1a6";
@fa-var-digital-ocean: "\f391";
@fa-var-digital-tachograph: "\f566";
@fa-var-directions: "\f5eb";
@fa-var-discord: "\f392";
@fa-var-discourse: "\f393";
@fa-var-divide: "\f529";
@fa-var-dizzy: "\f567";
@fa-var-dna: "\f471";
@fa-var-dochub: "\f394";
@fa-var-docker: "\f395";
@fa-var-dog: "\f6d3";
@fa-var-dollar-sign: "\f155";
@fa-var-dolly: "\f472";
@fa-var-dolly-flatbed: "\f474";
@fa-var-donate: "\f4b9";
@fa-var-door-closed: "\f52a";
@fa-var-door-open: "\f52b";
@fa-var-dot-circle: "\f192";
@fa-var-dove: "\f4ba";
@fa-var-download: "\f019";
@fa-var-draft2digital: "\f396";
@fa-var-drafting-compass: "\f568";
@fa-var-dragon: "\f6d5";
@fa-var-draw-polygon: "\f5ee";
@fa-var-dribbble: "\f17d";
@fa-var-dribbble-square: "\f397";
@fa-var-dropbox: "\f16b";
@fa-var-drum: "\f569";
@fa-var-drum-steelpan: "\f56a";
@fa-var-drumstick-bite: "\f6d7";
@fa-var-drupal: "\f1a9";
@fa-var-dumbbell: "\f44b";
@fa-var-dumpster: "\f793";
@fa-var-dumpster-fire: "\f794";
@fa-var-dungeon: "\f6d9";
@fa-var-dyalog: "\f399";
@fa-var-earlybirds: "\f39a";
@fa-var-ebay: "\f4f4";
@fa-var-edge: "\f282";
@fa-var-edit: "\f044";
@fa-var-egg: "\f7fb";
@fa-var-eject: "\f052";
@fa-var-elementor: "\f430";
@fa-var-ellipsis-h: "\f141";
@fa-var-ellipsis-v: "\f142";
@fa-var-ello: "\f5f1";
@fa-var-ember: "\f423";
@fa-var-empire: "\f1d1";
@fa-var-envelope: "\f0e0";
@fa-var-envelope-open: "\f2b6";
@fa-var-envelope-open-text: "\f658";
@fa-var-envelope-square: "\f199";
@fa-var-envira: "\f299";
@fa-var-equals: "\f52c";
@fa-var-eraser: "\f12d";
@fa-var-erlang: "\f39d";
@fa-var-ethereum: "\f42e";
@fa-var-ethernet: "\f796";
@fa-var-etsy: "\f2d7";
@fa-var-euro-sign: "\f153";
@fa-var-evernote: "\f839";
@fa-var-exchange-alt: "\f362";
@fa-var-exclamation: "\f12a";
@fa-var-exclamation-circle: "\f06a";
@fa-var-exclamation-triangle: "\f071";
@fa-var-expand: "\f065";
@fa-var-expand-arrows-alt: "\f31e";
@fa-var-expeditedssl: "\f23e";
@fa-var-external-link-alt: "\f35d";
@fa-var-external-link-square-alt: "\f360";
@fa-var-eye: "\f06e";
@fa-var-eye-dropper: "\f1fb";
@fa-var-eye-slash: "\f070";
@fa-var-facebook: "\f09a";
@fa-var-facebook-f: "\f39e";
@fa-var-facebook-messenger: "\f39f";
@fa-var-facebook-square: "\f082";
@fa-var-fantasy-flight-games: "\f6dc";
@fa-var-fast-backward: "\f049";
@fa-var-fast-forward: "\f050";
@fa-var-fax: "\f1ac";
@fa-var-feather: "\f52d";
@fa-var-feather-alt: "\f56b";
@fa-var-fedex: "\f797";
@fa-var-fedora: "\f798";
@fa-var-female: "\f182";
@fa-var-fighter-jet: "\f0fb";
@fa-var-figma: "\f799";
@fa-var-file: "\f15b";
@fa-var-file-alt: "\f15c";
@fa-var-file-archive: "\f1c6";
@fa-var-file-audio: "\f1c7";
@fa-var-file-code: "\f1c9";
@fa-var-file-contract: "\f56c";
@fa-var-file-csv: "\f6dd";
@fa-var-file-download: "\f56d";
@fa-var-file-excel: "\f1c3";
@fa-var-file-export: "\f56e";
@fa-var-file-image: "\f1c5";
@fa-var-file-import: "\f56f";
@fa-var-file-invoice: "\f570";
@fa-var-file-invoice-dollar: "\f571";
@fa-var-file-medical: "\f477";
@fa-var-file-medical-alt: "\f478";
@fa-var-file-pdf: "\f1c1";
@fa-var-file-powerpoint: "\f1c4";
@fa-var-file-prescription: "\f572";
@fa-var-file-signature: "\f573";
@fa-var-file-upload: "\f574";
@fa-var-file-video: "\f1c8";
@fa-var-file-word: "\f1c2";
@fa-var-fill: "\f575";
@fa-var-fill-drip: "\f576";
@fa-var-film: "\f008";
@fa-var-filter: "\f0b0";
@fa-var-fingerprint: "\f577";
@fa-var-fire: "\f06d";
@fa-var-fire-alt: "\f7e4";
@fa-var-fire-extinguisher: "\f134";
@fa-var-firefox: "\f269";
@fa-var-first-aid: "\f479";
@fa-var-first-order: "\f2b0";
@fa-var-first-order-alt: "\f50a";
@fa-var-firstdraft: "\f3a1";
@fa-var-fish: "\f578";
@fa-var-fist-raised: "\f6de";
@fa-var-flag: "\f024";
@fa-var-flag-checkered: "\f11e";
@fa-var-flag-usa: "\f74d";
@fa-var-flask: "\f0c3";
@fa-var-flickr: "\f16e";
@fa-var-flipboard: "\f44d";
@fa-var-flushed: "\f579";
@fa-var-fly: "\f417";
@fa-var-folder: "\f07b";
@fa-var-folder-minus: "\f65d";
@fa-var-folder-open: "\f07c";
@fa-var-folder-plus: "\f65e";
@fa-var-font: "\f031";
@fa-var-font-awesome: "\f2b4";
@fa-var-font-awesome-alt: "\f35c";
@fa-var-font-awesome-flag: "\f425";
@fa-var-font-awesome-logo-full: "\f4e6";
@fa-var-fonticons: "\f280";
@fa-var-fonticons-fi: "\f3a2";
@fa-var-football-ball: "\f44e";
@fa-var-fort-awesome: "\f286";
@fa-var-fort-awesome-alt: "\f3a3";
@fa-var-forumbee: "\f211";
@fa-var-forward: "\f04e";
@fa-var-foursquare: "\f180";
@fa-var-free-code-camp: "\f2c5";
@fa-var-freebsd: "\f3a4";
@fa-var-frog: "\f52e";
@fa-var-frown: "\f119";
@fa-var-frown-open: "\f57a";
@fa-var-fulcrum: "\f50b";
@fa-var-funnel-dollar: "\f662";
@fa-var-futbol: "\f1e3";
@fa-var-galactic-republic: "\f50c";
@fa-var-galactic-senate: "\f50d";
@fa-var-gamepad: "\f11b";
@fa-var-gas-pump: "\f52f";
@fa-var-gavel: "\f0e3";
@fa-var-gem: "\f3a5";
@fa-var-genderless: "\f22d";
@fa-var-get-pocket: "\f265";
@fa-var-gg: "\f260";
@fa-var-gg-circle: "\f261";
@fa-var-ghost: "\f6e2";
@fa-var-gift: "\f06b";
@fa-var-gifts: "\f79c";
@fa-var-git: "\f1d3";
@fa-var-git-square: "\f1d2";
@fa-var-github: "\f09b";
@fa-var-github-alt: "\f113";
@fa-var-github-square: "\f092";
@fa-var-gitkraken: "\f3a6";
@fa-var-gitlab: "\f296";
@fa-var-gitter: "\f426";
@fa-var-glass-cheers: "\f79f";
@fa-var-glass-martini: "\f000";
@fa-var-glass-martini-alt: "\f57b";
@fa-var-glass-whiskey: "\f7a0";
@fa-var-glasses: "\f530";
@fa-var-glide: "\f2a5";
@fa-var-glide-g: "\f2a6";
@fa-var-globe: "\f0ac";
@fa-var-globe-africa: "\f57c";
@fa-var-globe-americas: "\f57d";
@fa-var-globe-asia: "\f57e";
@fa-var-globe-europe: "\f7a2";
@fa-var-gofore: "\f3a7";
@fa-var-golf-ball: "\f450";
@fa-var-goodreads: "\f3a8";
@fa-var-goodreads-g: "\f3a9";
@fa-var-google: "\f1a0";
@fa-var-google-drive: "\f3aa";
@fa-var-google-play: "\f3ab";
@fa-var-google-plus: "\f2b3";
@fa-var-google-plus-g: "\f0d5";
@fa-var-google-plus-square: "\f0d4";
@fa-var-google-wallet: "\f1ee";
@fa-var-gopuram: "\f664";
@fa-var-graduation-cap: "\f19d";
@fa-var-gratipay: "\f184";
@fa-var-grav: "\f2d6";
@fa-var-greater-than: "\f531";
@fa-var-greater-than-equal: "\f532";
@fa-var-grimace: "\f57f";
@fa-var-grin: "\f580";
@fa-var-grin-alt: "\f581";
@fa-var-grin-beam: "\f582";
@fa-var-grin-beam-sweat: "\f583";
@fa-var-grin-hearts: "\f584";
@fa-var-grin-squint: "\f585";
@fa-var-grin-squint-tears: "\f586";
@fa-var-grin-stars: "\f587";
@fa-var-grin-tears: "\f588";
@fa-var-grin-tongue: "\f589";
@fa-var-grin-tongue-squint: "\f58a";
@fa-var-grin-tongue-wink: "\f58b";
@fa-var-grin-wink: "\f58c";
@fa-var-grip-horizontal: "\f58d";
@fa-var-grip-lines: "\f7a4";
@fa-var-grip-lines-vertical: "\f7a5";
@fa-var-grip-vertical: "\f58e";
@fa-var-gripfire: "\f3ac";
@fa-var-grunt: "\f3ad";
@fa-var-guitar: "\f7a6";
@fa-var-gulp: "\f3ae";
@fa-var-h-square: "\f0fd";
@fa-var-hacker-news: "\f1d4";
@fa-var-hacker-news-square: "\f3af";
@fa-var-hackerrank: "\f5f7";
@fa-var-hamburger: "\f805";
@fa-var-hammer: "\f6e3";
@fa-var-hamsa: "\f665";
@fa-var-hand-holding: "\f4bd";
@fa-var-hand-holding-heart: "\f4be";
@fa-var-hand-holding-usd: "\f4c0";
@fa-var-hand-lizard: "\f258";
@fa-var-hand-middle-finger: "\f806";
@fa-var-hand-paper: "\f256";
@fa-var-hand-peace: "\f25b";
@fa-var-hand-point-down: "\f0a7";
@fa-var-hand-point-left: "\f0a5";
@fa-var-hand-point-right: "\f0a4";
@fa-var-hand-point-up: "\f0a6";
@fa-var-hand-pointer: "\f25a";
@fa-var-hand-rock: "\f255";
@fa-var-hand-scissors: "\f257";
@fa-var-hand-spock: "\f259";
@fa-var-hands: "\f4c2";
@fa-var-hands-helping: "\f4c4";
@fa-var-handshake: "\f2b5";
@fa-var-hanukiah: "\f6e6";
@fa-var-hard-hat: "\f807";
@fa-var-hashtag: "\f292";
@fa-var-hat-wizard: "\f6e8";
@fa-var-haykal: "\f666";
@fa-var-hdd: "\f0a0";
@fa-var-heading: "\f1dc";
@fa-var-headphones: "\f025";
@fa-var-headphones-alt: "\f58f";
@fa-var-headset: "\f590";
@fa-var-heart: "\f004";
@fa-var-heart-broken: "\f7a9";
@fa-var-heartbeat: "\f21e";
@fa-var-helicopter: "\f533";
@fa-var-highlighter: "\f591";
@fa-var-hiking: "\f6ec";
@fa-var-hippo: "\f6ed";
@fa-var-hips: "\f452";
@fa-var-hire-a-helper: "\f3b0";
@fa-var-history: "\f1da";
@fa-var-hockey-puck: "\f453";
@fa-var-holly-berry: "\f7aa";
@fa-var-home: "\f015";
@fa-var-hooli: "\f427";
@fa-var-hornbill: "\f592";
@fa-var-horse: "\f6f0";
@fa-var-horse-head: "\f7ab";
@fa-var-hospital: "\f0f8";
@fa-var-hospital-alt: "\f47d";
@fa-var-hospital-symbol: "\f47e";
@fa-var-hot-tub: "\f593";
@fa-var-hotdog: "\f80f";
@fa-var-hotel: "\f594";
@fa-var-hotjar: "\f3b1";
@fa-var-hourglass: "\f254";
@fa-var-hourglass-end: "\f253";
@fa-var-hourglass-half: "\f252";
@fa-var-hourglass-start: "\f251";
@fa-var-house-damage: "\f6f1";
@fa-var-houzz: "\f27c";
@fa-var-hryvnia: "\f6f2";
@fa-var-html5: "\f13b";
@fa-var-hubspot: "\f3b2";
@fa-var-i-cursor: "\f246";
@fa-var-ice-cream: "\f810";
@fa-var-icicles: "\f7ad";
@fa-var-id-badge: "\f2c1";
@fa-var-id-card: "\f2c2";
@fa-var-id-card-alt: "\f47f";
@fa-var-igloo: "\f7ae";
@fa-var-image: "\f03e";
@fa-var-images: "\f302";
@fa-var-imdb: "\f2d8";
@fa-var-inbox: "\f01c";
@fa-var-indent: "\f03c";
@fa-var-industry: "\f275";
@fa-var-infinity: "\f534";
@fa-var-info: "\f129";
@fa-var-info-circle: "\f05a";
@fa-var-instagram: "\f16d";
@fa-var-intercom: "\f7af";
@fa-var-internet-explorer: "\f26b";
@fa-var-invision: "\f7b0";
@fa-var-ioxhost: "\f208";
@fa-var-italic: "\f033";
@fa-var-itch-io: "\f83a";
@fa-var-itunes: "\f3b4";
@fa-var-itunes-note: "\f3b5";
@fa-var-java: "\f4e4";
@fa-var-jedi: "\f669";
@fa-var-jedi-order: "\f50e";
@fa-var-jenkins: "\f3b6";
@fa-var-jira: "\f7b1";
@fa-var-joget: "\f3b7";
@fa-var-joint: "\f595";
@fa-var-joomla: "\f1aa";
@fa-var-journal-whills: "\f66a";
@fa-var-js: "\f3b8";
@fa-var-js-square: "\f3b9";
@fa-var-jsfiddle: "\f1cc";
@fa-var-kaaba: "\f66b";
@fa-var-kaggle: "\f5fa";
@fa-var-key: "\f084";
@fa-var-keybase: "\f4f5";
@fa-var-keyboard: "\f11c";
@fa-var-keycdn: "\f3ba";
@fa-var-khanda: "\f66d";
@fa-var-kickstarter: "\f3bb";
@fa-var-kickstarter-k: "\f3bc";
@fa-var-kiss: "\f596";
@fa-var-kiss-beam: "\f597";
@fa-var-kiss-wink-heart: "\f598";
@fa-var-kiwi-bird: "\f535";
@fa-var-korvue: "\f42f";
@fa-var-landmark: "\f66f";
@fa-var-language: "\f1ab";
@fa-var-laptop: "\f109";
@fa-var-laptop-code: "\f5fc";
@fa-var-laptop-medical: "\f812";
@fa-var-laravel: "\f3bd";
@fa-var-lastfm: "\f202";
@fa-var-lastfm-square: "\f203";
@fa-var-laugh: "\f599";
@fa-var-laugh-beam: "\f59a";
@fa-var-laugh-squint: "\f59b";
@fa-var-laugh-wink: "\f59c";
@fa-var-layer-group: "\f5fd";
@fa-var-leaf: "\f06c";
@fa-var-leanpub: "\f212";
@fa-var-lemon: "\f094";
@fa-var-less: "\f41d";
@fa-var-less-than: "\f536";
@fa-var-less-than-equal: "\f537";
@fa-var-level-down-alt: "\f3be";
@fa-var-level-up-alt: "\f3bf";
@fa-var-life-ring: "\f1cd";
@fa-var-lightbulb: "\f0eb";
@fa-var-line: "\f3c0";
@fa-var-link: "\f0c1";
@fa-var-linkedin: "\f08c";
@fa-var-linkedin-in: "\f0e1";
@fa-var-linode: "\f2b8";
@fa-var-linux: "\f17c";
@fa-var-lira-sign: "\f195";
@fa-var-list: "\f03a";
@fa-var-list-alt: "\f022";
@fa-var-list-ol: "\f0cb";
@fa-var-list-ul: "\f0ca";
@fa-var-location-arrow: "\f124";
@fa-var-lock: "\f023";
@fa-var-lock-open: "\f3c1";
@fa-var-long-arrow-alt-down: "\f309";
@fa-var-long-arrow-alt-left: "\f30a";
@fa-var-long-arrow-alt-right: "\f30b";
@fa-var-long-arrow-alt-up: "\f30c";
@fa-var-low-vision: "\f2a8";
@fa-var-luggage-cart: "\f59d";
@fa-var-lyft: "\f3c3";
@fa-var-magento: "\f3c4";
@fa-var-magic: "\f0d0";
@fa-var-magnet: "\f076";
@fa-var-mail-bulk: "\f674";
@fa-var-mailchimp: "\f59e";
@fa-var-male: "\f183";
@fa-var-mandalorian: "\f50f";
@fa-var-map: "\f279";
@fa-var-map-marked: "\f59f";
@fa-var-map-marked-alt: "\f5a0";
@fa-var-map-marker: "\f041";
@fa-var-map-marker-alt: "\f3c5";
@fa-var-map-pin: "\f276";
@fa-var-map-signs: "\f277";
@fa-var-markdown: "\f60f";
@fa-var-marker: "\f5a1";
@fa-var-mars: "\f222";
@fa-var-mars-double: "\f227";
@fa-var-mars-stroke: "\f229";
@fa-var-mars-stroke-h: "\f22b";
@fa-var-mars-stroke-v: "\f22a";
@fa-var-mask: "\f6fa";
@fa-var-mastodon: "\f4f6";
@fa-var-maxcdn: "\f136";
@fa-var-medal: "\f5a2";
@fa-var-medapps: "\f3c6";
@fa-var-medium: "\f23a";
@fa-var-medium-m: "\f3c7";
@fa-var-medkit: "\f0fa";
@fa-var-medrt: "\f3c8";
@fa-var-meetup: "\f2e0";
@fa-var-megaport: "\f5a3";
@fa-var-meh: "\f11a";
@fa-var-meh-blank: "\f5a4";
@fa-var-meh-rolling-eyes: "\f5a5";
@fa-var-memory: "\f538";
@fa-var-mendeley: "\f7b3";
@fa-var-menorah: "\f676";
@fa-var-mercury: "\f223";
@fa-var-meteor: "\f753";
@fa-var-microchip: "\f2db";
@fa-var-microphone: "\f130";
@fa-var-microphone-alt: "\f3c9";
@fa-var-microphone-alt-slash: "\f539";
@fa-var-microphone-slash: "\f131";
@fa-var-microscope: "\f610";
@fa-var-microsoft: "\f3ca";
@fa-var-minus: "\f068";
@fa-var-minus-circle: "\f056";
@fa-var-minus-square: "\f146";
@fa-var-mitten: "\f7b5";
@fa-var-mix: "\f3cb";
@fa-var-mixcloud: "\f289";
@fa-var-mizuni: "\f3cc";
@fa-var-mobile: "\f10b";
@fa-var-mobile-alt: "\f3cd";
@fa-var-modx: "\f285";
@fa-var-monero: "\f3d0";
@fa-var-money-bill: "\f0d6";
@fa-var-money-bill-alt: "\f3d1";
@fa-var-money-bill-wave: "\f53a";
@fa-var-money-bill-wave-alt: "\f53b";
@fa-var-money-check: "\f53c";
@fa-var-money-check-alt: "\f53d";
@fa-var-monument: "\f5a6";
@fa-var-moon: "\f186";
@fa-var-mortar-pestle: "\f5a7";
@fa-var-mosque: "\f678";
@fa-var-motorcycle: "\f21c";
@fa-var-mountain: "\f6fc";
@fa-var-mouse-pointer: "\f245";
@fa-var-mug-hot: "\f7b6";
@fa-var-music: "\f001";
@fa-var-napster: "\f3d2";
@fa-var-neos: "\f612";
@fa-var-network-wired: "\f6ff";
@fa-var-neuter: "\f22c";
@fa-var-newspaper: "\f1ea";
@fa-var-nimblr: "\f5a8";
@fa-var-nintendo-switch: "\f418";
@fa-var-node: "\f419";
@fa-var-node-js: "\f3d3";
@fa-var-not-equal: "\f53e";
@fa-var-notes-medical: "\f481";
@fa-var-npm: "\f3d4";
@fa-var-ns8: "\f3d5";
@fa-var-nutritionix: "\f3d6";
@fa-var-object-group: "\f247";
@fa-var-object-ungroup: "\f248";
@fa-var-odnoklassniki: "\f263";
@fa-var-odnoklassniki-square: "\f264";
@fa-var-oil-can: "\f613";
@fa-var-old-republic: "\f510";
@fa-var-om: "\f679";
@fa-var-opencart: "\f23d";
@fa-var-openid: "\f19b";
@fa-var-opera: "\f26a";
@fa-var-optin-monster: "\f23c";
@fa-var-osi: "\f41a";
@fa-var-otter: "\f700";
@fa-var-outdent: "\f03b";
@fa-var-page4: "\f3d7";
@fa-var-pagelines: "\f18c";
@fa-var-pager: "\f815";
@fa-var-paint-brush: "\f1fc";
@fa-var-paint-roller: "\f5aa";
@fa-var-palette: "\f53f";
@fa-var-palfed: "\f3d8";
@fa-var-pallet: "\f482";
@fa-var-paper-plane: "\f1d8";
@fa-var-paperclip: "\f0c6";
@fa-var-parachute-box: "\f4cd";
@fa-var-paragraph: "\f1dd";
@fa-var-parking: "\f540";
@fa-var-passport: "\f5ab";
@fa-var-pastafarianism: "\f67b";
@fa-var-paste: "\f0ea";
@fa-var-patreon: "\f3d9";
@fa-var-pause: "\f04c";
@fa-var-pause-circle: "\f28b";
@fa-var-paw: "\f1b0";
@fa-var-paypal: "\f1ed";
@fa-var-peace: "\f67c";
@fa-var-pen: "\f304";
@fa-var-pen-alt: "\f305";
@fa-var-pen-fancy: "\f5ac";
@fa-var-pen-nib: "\f5ad";
@fa-var-pen-square: "\f14b";
@fa-var-pencil-alt: "\f303";
@fa-var-pencil-ruler: "\f5ae";
@fa-var-penny-arcade: "\f704";
@fa-var-people-carry: "\f4ce";
@fa-var-pepper-hot: "\f816";
@fa-var-percent: "\f295";
@fa-var-percentage: "\f541";
@fa-var-periscope: "\f3da";
@fa-var-person-booth: "\f756";
@fa-var-phabricator: "\f3db";
@fa-var-phoenix-framework: "\f3dc";
@fa-var-phoenix-squadron: "\f511";
@fa-var-phone: "\f095";
@fa-var-phone-slash: "\f3dd";
@fa-var-phone-square: "\f098";
@fa-var-phone-volume: "\f2a0";
@fa-var-php: "\f457";
@fa-var-pied-piper: "\f2ae";
@fa-var-pied-piper-alt: "\f1a8";
@fa-var-pied-piper-hat: "\f4e5";
@fa-var-pied-piper-pp: "\f1a7";
@fa-var-piggy-bank: "\f4d3";
@fa-var-pills: "\f484";
@fa-var-pinterest: "\f0d2";
@fa-var-pinterest-p: "\f231";
@fa-var-pinterest-square: "\f0d3";
@fa-var-pizza-slice: "\f818";
@fa-var-place-of-worship: "\f67f";
@fa-var-plane: "\f072";
@fa-var-plane-arrival: "\f5af";
@fa-var-plane-departure: "\f5b0";
@fa-var-play: "\f04b";
@fa-var-play-circle: "\f144";
@fa-var-playstation: "\f3df";
@fa-var-plug: "\f1e6";
@fa-var-plus: "\f067";
@fa-var-plus-circle: "\f055";
@fa-var-plus-square: "\f0fe";
@fa-var-podcast: "\f2ce";
@fa-var-poll: "\f681";
@fa-var-poll-h: "\f682";
@fa-var-poo: "\f2fe";
@fa-var-poo-storm: "\f75a";
@fa-var-poop: "\f619";
@fa-var-portrait: "\f3e0";
@fa-var-pound-sign: "\f154";
@fa-var-power-off: "\f011";
@fa-var-pray: "\f683";
@fa-var-praying-hands: "\f684";
@fa-var-prescription: "\f5b1";
@fa-var-prescription-bottle: "\f485";
@fa-var-prescription-bottle-alt: "\f486";
@fa-var-print: "\f02f";
@fa-var-procedures: "\f487";
@fa-var-product-hunt: "\f288";
@fa-var-project-diagram: "\f542";
@fa-var-pushed: "\f3e1";
@fa-var-puzzle-piece: "\f12e";
@fa-var-python: "\f3e2";
@fa-var-qq: "\f1d6";
@fa-var-qrcode: "\f029";
@fa-var-question: "\f128";
@fa-var-question-circle: "\f059";
@fa-var-quidditch: "\f458";
@fa-var-quinscape: "\f459";
@fa-var-quora: "\f2c4";
@fa-var-quote-left: "\f10d";
@fa-var-quote-right: "\f10e";
@fa-var-quran: "\f687";
@fa-var-r-project: "\f4f7";
@fa-var-radiation: "\f7b9";
@fa-var-radiation-alt: "\f7ba";
@fa-var-rainbow: "\f75b";
@fa-var-random: "\f074";
@fa-var-raspberry-pi: "\f7bb";
@fa-var-ravelry: "\f2d9";
@fa-var-react: "\f41b";
@fa-var-reacteurope: "\f75d";
@fa-var-readme: "\f4d5";
@fa-var-rebel: "\f1d0";
@fa-var-receipt: "\f543";
@fa-var-recycle: "\f1b8";
@fa-var-red-river: "\f3e3";
@fa-var-reddit: "\f1a1";
@fa-var-reddit-alien: "\f281";
@fa-var-reddit-square: "\f1a2";
@fa-var-redhat: "\f7bc";
@fa-var-redo: "\f01e";
@fa-var-redo-alt: "\f2f9";
@fa-var-registered: "\f25d";
@fa-var-renren: "\f18b";
@fa-var-reply: "\f3e5";
@fa-var-reply-all: "\f122";
@fa-var-replyd: "\f3e6";
@fa-var-republican: "\f75e";
@fa-var-researchgate: "\f4f8";
@fa-var-resolving: "\f3e7";
@fa-var-restroom: "\f7bd";
@fa-var-retweet: "\f079";
@fa-var-rev: "\f5b2";
@fa-var-ribbon: "\f4d6";
@fa-var-ring: "\f70b";
@fa-var-road: "\f018";
@fa-var-robot: "\f544";
@fa-var-rocket: "\f135";
@fa-var-rocketchat: "\f3e8";
@fa-var-rockrms: "\f3e9";
@fa-var-route: "\f4d7";
@fa-var-rss: "\f09e";
@fa-var-rss-square: "\f143";
@fa-var-ruble-sign: "\f158";
@fa-var-ruler: "\f545";
@fa-var-ruler-combined: "\f546";
@fa-var-ruler-horizontal: "\f547";
@fa-var-ruler-vertical: "\f548";
@fa-var-running: "\f70c";
@fa-var-rupee-sign: "\f156";
@fa-var-sad-cry: "\f5b3";
@fa-var-sad-tear: "\f5b4";
@fa-var-safari: "\f267";
@fa-var-salesforce: "\f83b";
@fa-var-sass: "\f41e";
@fa-var-satellite: "\f7bf";
@fa-var-satellite-dish: "\f7c0";
@fa-var-save: "\f0c7";
@fa-var-schlix: "\f3ea";
@fa-var-school: "\f549";
@fa-var-screwdriver: "\f54a";
@fa-var-scribd: "\f28a";
@fa-var-scroll: "\f70e";
@fa-var-sd-card: "\f7c2";
@fa-var-search: "\f002";
@fa-var-search-dollar: "\f688";
@fa-var-search-location: "\f689";
@fa-var-search-minus: "\f010";
@fa-var-search-plus: "\f00e";
@fa-var-searchengin: "\f3eb";
@fa-var-seedling: "\f4d8";
@fa-var-sellcast: "\f2da";
@fa-var-sellsy: "\f213";
@fa-var-server: "\f233";
@fa-var-servicestack: "\f3ec";
@fa-var-shapes: "\f61f";
@fa-var-share: "\f064";
@fa-var-share-alt: "\f1e0";
@fa-var-share-alt-square: "\f1e1";
@fa-var-share-square: "\f14d";
@fa-var-shekel-sign: "\f20b";
@fa-var-shield-alt: "\f3ed";
@fa-var-ship: "\f21a";
@fa-var-shipping-fast: "\f48b";
@fa-var-shirtsinbulk: "\f214";
@fa-var-shoe-prints: "\f54b";
@fa-var-shopping-bag: "\f290";
@fa-var-shopping-basket: "\f291";
@fa-var-shopping-cart: "\f07a";
@fa-var-shopware: "\f5b5";
@fa-var-shower: "\f2cc";
@fa-var-shuttle-van: "\f5b6";
@fa-var-sign: "\f4d9";
@fa-var-sign-in-alt: "\f2f6";
@fa-var-sign-language: "\f2a7";
@fa-var-sign-out-alt: "\f2f5";
@fa-var-signal: "\f012";
@fa-var-signature: "\f5b7";
@fa-var-sim-card: "\f7c4";
@fa-var-simplybuilt: "\f215";
@fa-var-sistrix: "\f3ee";
@fa-var-sitemap: "\f0e8";
@fa-var-sith: "\f512";
@fa-var-skating: "\f7c5";
@fa-var-sketch: "\f7c6";
@fa-var-skiing: "\f7c9";
@fa-var-skiing-nordic: "\f7ca";
@fa-var-skull: "\f54c";
@fa-var-skull-crossbones: "\f714";
@fa-var-skyatlas: "\f216";
@fa-var-skype: "\f17e";
@fa-var-slack: "\f198";
@fa-var-slack-hash: "\f3ef";
@fa-var-slash: "\f715";
@fa-var-sleigh: "\f7cc";
@fa-var-sliders-h: "\f1de";
@fa-var-slideshare: "\f1e7";
@fa-var-smile: "\f118";
@fa-var-smile-beam: "\f5b8";
@fa-var-smile-wink: "\f4da";
@fa-var-smog: "\f75f";
@fa-var-smoking: "\f48d";
@fa-var-smoking-ban: "\f54d";
@fa-var-sms: "\f7cd";
@fa-var-snapchat: "\f2ab";
@fa-var-snapchat-ghost: "\f2ac";
@fa-var-snapchat-square: "\f2ad";
@fa-var-snowboarding: "\f7ce";
@fa-var-snowflake: "\f2dc";
@fa-var-snowman: "\f7d0";
@fa-var-snowplow: "\f7d2";
@fa-var-socks: "\f696";
@fa-var-solar-panel: "\f5ba";
@fa-var-sort: "\f0dc";
@fa-var-sort-alpha-down: "\f15d";
@fa-var-sort-alpha-up: "\f15e";
@fa-var-sort-amount-down: "\f160";
@fa-var-sort-amount-up: "\f161";
@fa-var-sort-down: "\f0dd";
@fa-var-sort-numeric-down: "\f162";
@fa-var-sort-numeric-up: "\f163";
@fa-var-sort-up: "\f0de";
@fa-var-soundcloud: "\f1be";
@fa-var-sourcetree: "\f7d3";
@fa-var-spa: "\f5bb";
@fa-var-space-shuttle: "\f197";
@fa-var-speakap: "\f3f3";
@fa-var-speaker-deck: "\f83c";
@fa-var-spider: "\f717";
@fa-var-spinner: "\f110";
@fa-var-splotch: "\f5bc";
@fa-var-spotify: "\f1bc";
@fa-var-spray-can: "\f5bd";
@fa-var-square: "\f0c8";
@fa-var-square-full: "\f45c";
@fa-var-square-root-alt: "\f698";
@fa-var-squarespace: "\f5be";
@fa-var-stack-exchange: "\f18d";
@fa-var-stack-overflow: "\f16c";
@fa-var-stamp: "\f5bf";
@fa-var-star: "\f005";
@fa-var-star-and-crescent: "\f699";
@fa-var-star-half: "\f089";
@fa-var-star-half-alt: "\f5c0";
@fa-var-star-of-david: "\f69a";
@fa-var-star-of-life: "\f621";
@fa-var-staylinked: "\f3f5";
@fa-var-steam: "\f1b6";
@fa-var-steam-square: "\f1b7";
@fa-var-steam-symbol: "\f3f6";
@fa-var-step-backward: "\f048";
@fa-var-step-forward: "\f051";
@fa-var-stethoscope: "\f0f1";
@fa-var-sticker-mule: "\f3f7";
@fa-var-sticky-note: "\f249";
@fa-var-stop: "\f04d";
@fa-var-stop-circle: "\f28d";
@fa-var-stopwatch: "\f2f2";
@fa-var-store: "\f54e";
@fa-var-store-alt: "\f54f";
@fa-var-strava: "\f428";
@fa-var-stream: "\f550";
@fa-var-street-view: "\f21d";
@fa-var-strikethrough: "\f0cc";
@fa-var-stripe: "\f429";
@fa-var-stripe-s: "\f42a";
@fa-var-stroopwafel: "\f551";
@fa-var-studiovinari: "\f3f8";
@fa-var-stumbleupon: "\f1a4";
@fa-var-stumbleupon-circle: "\f1a3";
@fa-var-subscript: "\f12c";
@fa-var-subway: "\f239";
@fa-var-suitcase: "\f0f2";
@fa-var-suitcase-rolling: "\f5c1";
@fa-var-sun: "\f185";
@fa-var-superpowers: "\f2dd";
@fa-var-superscript: "\f12b";
@fa-var-supple: "\f3f9";
@fa-var-surprise: "\f5c2";
@fa-var-suse: "\f7d6";
@fa-var-swatchbook: "\f5c3";
@fa-var-swimmer: "\f5c4";
@fa-var-swimming-pool: "\f5c5";
@fa-var-symfony: "\f83d";
@fa-var-synagogue: "\f69b";
@fa-var-sync: "\f021";
@fa-var-sync-alt: "\f2f1";
@fa-var-syringe: "\f48e";
@fa-var-table: "\f0ce";
@fa-var-table-tennis: "\f45d";
@fa-var-tablet: "\f10a";
@fa-var-tablet-alt: "\f3fa";
@fa-var-tablets: "\f490";
@fa-var-tachometer-alt: "\f3fd";
@fa-var-tag: "\f02b";
@fa-var-tags: "\f02c";
@fa-var-tape: "\f4db";
@fa-var-tasks: "\f0ae";
@fa-var-taxi: "\f1ba";
@fa-var-teamspeak: "\f4f9";
@fa-var-teeth: "\f62e";
@fa-var-teeth-open: "\f62f";
@fa-var-telegram: "\f2c6";
@fa-var-telegram-plane: "\f3fe";
@fa-var-temperature-high: "\f769";
@fa-var-temperature-low: "\f76b";
@fa-var-tencent-weibo: "\f1d5";
@fa-var-tenge: "\f7d7";
@fa-var-terminal: "\f120";
@fa-var-text-height: "\f034";
@fa-var-text-width: "\f035";
@fa-var-th: "\f00a";
@fa-var-th-large: "\f009";
@fa-var-th-list: "\f00b";
@fa-var-the-red-yeti: "\f69d";
@fa-var-theater-masks: "\f630";
@fa-var-themeco: "\f5c6";
@fa-var-themeisle: "\f2b2";
@fa-var-thermometer: "\f491";
@fa-var-thermometer-empty: "\f2cb";
@fa-var-thermometer-full: "\f2c7";
@fa-var-thermometer-half: "\f2c9";
@fa-var-thermometer-quarter: "\f2ca";
@fa-var-thermometer-three-quarters: "\f2c8";
@fa-var-think-peaks: "\f731";
@fa-var-thumbs-down: "\f165";
@fa-var-thumbs-up: "\f164";
@fa-var-thumbtack: "\f08d";
@fa-var-ticket-alt: "\f3ff";
@fa-var-times: "\f00d";
@fa-var-times-circle: "\f057";
@fa-var-tint: "\f043";
@fa-var-tint-slash: "\f5c7";
@fa-var-tired: "\f5c8";
@fa-var-toggle-off: "\f204";
@fa-var-toggle-on: "\f205";
@fa-var-toilet: "\f7d8";
@fa-var-toilet-paper: "\f71e";
@fa-var-toolbox: "\f552";
@fa-var-tools: "\f7d9";
@fa-var-tooth: "\f5c9";
@fa-var-torah: "\f6a0";
@fa-var-torii-gate: "\f6a1";
@fa-var-tractor: "\f722";
@fa-var-trade-federation: "\f513";
@fa-var-trademark: "\f25c";
@fa-var-traffic-light: "\f637";
@fa-var-train: "\f238";
@fa-var-tram: "\f7da";
@fa-var-transgender: "\f224";
@fa-var-transgender-alt: "\f225";
@fa-var-trash: "\f1f8";
@fa-var-trash-alt: "\f2ed";
@fa-var-trash-restore: "\f829";
@fa-var-trash-restore-alt: "\f82a";
@fa-var-tree: "\f1bb";
@fa-var-trello: "\f181";
@fa-var-tripadvisor: "\f262";
@fa-var-trophy: "\f091";
@fa-var-truck: "\f0d1";
@fa-var-truck-loading: "\f4de";
@fa-var-truck-monster: "\f63b";
@fa-var-truck-moving: "\f4df";
@fa-var-truck-pickup: "\f63c";
@fa-var-tshirt: "\f553";
@fa-var-tty: "\f1e4";
@fa-var-tumblr: "\f173";
@fa-var-tumblr-square: "\f174";
@fa-var-tv: "\f26c";
@fa-var-twitch: "\f1e8";
@fa-var-twitter: "\f099";
@fa-var-twitter-square: "\f081";
@fa-var-typo3: "\f42b";
@fa-var-uber: "\f402";
@fa-var-ubuntu: "\f7df";
@fa-var-uikit: "\f403";
@fa-var-umbrella: "\f0e9";
@fa-var-umbrella-beach: "\f5ca";
@fa-var-underline: "\f0cd";
@fa-var-undo: "\f0e2";
@fa-var-undo-alt: "\f2ea";
@fa-var-uniregistry: "\f404";
@fa-var-universal-access: "\f29a";
@fa-var-university: "\f19c";
@fa-var-unlink: "\f127";
@fa-var-unlock: "\f09c";
@fa-var-unlock-alt: "\f13e";
@fa-var-untappd: "\f405";
@fa-var-upload: "\f093";
@fa-var-ups: "\f7e0";
@fa-var-usb: "\f287";
@fa-var-user: "\f007";
@fa-var-user-alt: "\f406";
@fa-var-user-alt-slash: "\f4fa";
@fa-var-user-astronaut: "\f4fb";
@fa-var-user-check: "\f4fc";
@fa-var-user-circle: "\f2bd";
@fa-var-user-clock: "\f4fd";
@fa-var-user-cog: "\f4fe";
@fa-var-user-edit: "\f4ff";
@fa-var-user-friends: "\f500";
@fa-var-user-graduate: "\f501";
@fa-var-user-injured: "\f728";
@fa-var-user-lock: "\f502";
@fa-var-user-md: "\f0f0";
@fa-var-user-minus: "\f503";
@fa-var-user-ninja: "\f504";
@fa-var-user-nurse: "\f82f";
@fa-var-user-plus: "\f234";
@fa-var-user-secret: "\f21b";
@fa-var-user-shield: "\f505";
@fa-var-user-slash: "\f506";
@fa-var-user-tag: "\f507";
@fa-var-user-tie: "\f508";
@fa-var-user-times: "\f235";
@fa-var-users: "\f0c0";
@fa-var-users-cog: "\f509";
@fa-var-usps: "\f7e1";
@fa-var-ussunnah: "\f407";
@fa-var-utensil-spoon: "\f2e5";
@fa-var-utensils: "\f2e7";
@fa-var-vaadin: "\f408";
@fa-var-vector-square: "\f5cb";
@fa-var-venus: "\f221";
@fa-var-venus-double: "\f226";
@fa-var-venus-mars: "\f228";
@fa-var-viacoin: "\f237";
@fa-var-viadeo: "\f2a9";
@fa-var-viadeo-square: "\f2aa";
@fa-var-vial: "\f492";
@fa-var-vials: "\f493";
@fa-var-viber: "\f409";
@fa-var-video: "\f03d";
@fa-var-video-slash: "\f4e2";
@fa-var-vihara: "\f6a7";
@fa-var-vimeo: "\f40a";
@fa-var-vimeo-square: "\f194";
@fa-var-vimeo-v: "\f27d";
@fa-var-vine: "\f1ca";
@fa-var-vk: "\f189";
@fa-var-vnv: "\f40b";
@fa-var-volleyball-ball: "\f45f";
@fa-var-volume-down: "\f027";
@fa-var-volume-mute: "\f6a9";
@fa-var-volume-off: "\f026";
@fa-var-volume-up: "\f028";
@fa-var-vote-yea: "\f772";
@fa-var-vr-cardboard: "\f729";
@fa-var-vuejs: "\f41f";
@fa-var-walking: "\f554";
@fa-var-wallet: "\f555";
@fa-var-warehouse: "\f494";
@fa-var-water: "\f773";
@fa-var-wave-square: "\f83e";
@fa-var-waze: "\f83f";
@fa-var-weebly: "\f5cc";
@fa-var-weibo: "\f18a";
@fa-var-weight: "\f496";
@fa-var-weight-hanging: "\f5cd";
@fa-var-weixin: "\f1d7";
@fa-var-whatsapp: "\f232";
@fa-var-whatsapp-square: "\f40c";
@fa-var-wheelchair: "\f193";
@fa-var-whmcs: "\f40d";
@fa-var-wifi: "\f1eb";
@fa-var-wikipedia-w: "\f266";
@fa-var-wind: "\f72e";
@fa-var-window-close: "\f410";
@fa-var-window-maximize: "\f2d0";
@fa-var-window-minimize: "\f2d1";
@fa-var-window-restore: "\f2d2";
@fa-var-windows: "\f17a";
@fa-var-wine-bottle: "\f72f";
@fa-var-wine-glass: "\f4e3";
@fa-var-wine-glass-alt: "\f5ce";
@fa-var-wix: "\f5cf";
@fa-var-wizards-of-the-coast: "\f730";
@fa-var-wolf-pack-battalion: "\f514";
@fa-var-won-sign: "\f159";
@fa-var-wordpress: "\f19a";
@fa-var-wordpress-simple: "\f411";
@fa-var-wpbeginner: "\f297";
@fa-var-wpexplorer: "\f2de";
@fa-var-wpforms: "\f298";
@fa-var-wpressr: "\f3e4";
@fa-var-wrench: "\f0ad";
@fa-var-x-ray: "\f497";
@fa-var-xbox: "\f412";
@fa-var-xing: "\f168";
@fa-var-xing-square: "\f169";
@fa-var-y-combinator: "\f23b";
@fa-var-yahoo: "\f19e";
@fa-var-yammer: "\f840";
@fa-var-yandex: "\f413";
@fa-var-yandex-international: "\f414";
@fa-var-yarn: "\f7e3";
@fa-var-yelp: "\f1e9";
@fa-var-yen-sign: "\f157";
@fa-var-yin-yang: "\f6ad";
@fa-var-yoast: "\f2b1";
@fa-var-youtube: "\f167";
@fa-var-youtube-square: "\f431";
@fa-var-zhihu: "\f63f";
