.kpis {
    .Select {
        width: 220px;
        position: relative;
        float: left;
        margin-right: 10px;
    }

    .Select.is-disabled .Select-control {
        background: #eee !important;
    }

    .kpi-type {
        float: left;

        .Select {
            margin-bottom: 0;
        }
    }

    .kpi-value {
        float: left;
    }

    .kpi-currency {
        display: inline-block;
        width: 40px;
    }

    .btn-default {
        margin-top: 4px;
    }
}

.adplatform-style {
    .form .kpis div.value {
        margin-left: 0;
    }

    .kpis .btn-default {
        margin-top: 0;
    }
}

.modal {
    .form {
        .kpis {
            div.value {
                margin-left: 0;
            }

            input.value,
            .Select {
                width: 180px;
            }
        }
    }
}
