div.images {
  max-width: 700px;
  max-height: 324px;
  overflow-y: auto;
  overflow-x: hidden;
}

div.images label,
.imgContainer label {
  display: block;
  height: 105px;
  padding: 3px;
  background-color: #fbfbfb;
  border: 1px solid #F0F0F0;
  text-align: center;
}

div.images label.selected {
  background: #F0F0F0;
  border: 1px solid #ccc;
}

div.images label.disabled {
  opacity: 0.75;
}

div.images label.errorImage {
  border: 1px solid #E54028;
}

div.images label:before,
div.images label:after {
  content: "";
  display: table;
}

div.images label:after {
  clear: both;
}

div.images label {
  zoom: 1; /* For IE 6/7 (trigger hasLayout) */
  cursor: pointer;
}

.imgContainer {
  width: 119px;
  height: 92px;
  overflow: hidden;
  margin: 0 5px 0 0;
  position: relative;
  float: left;
}

div.images .imgContainer {
  margin: 0 10px 0 0;
  height: 115px;
}

div.images label div.httpool-checkbox-button {
  float: left;
  height: 13px;
}

.imgContainer label img {
  clear: left;
  display: inline;
  max-width: 110px;
  background-size: cover;
  max-height: 65px;
}

.imgFormat {
  text-align: left;
  display: block;
  float: left;
  height: 13px;
  width: 78px;
  color: #808080;
  font-style: italic;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 3px;
}

.imgName {
  display: block;
  font-size: 13px;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
}

.imgModal {
  max-height: 0;
  height: auto;
  width: 500px;
  padding: 0.5em 1em 0.5em 1em;
  border: 1px solid #00A6C8;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -225px;
  margin-top: -80px;
  overflow: hidden;
}

.close-topRight {
  position: absolute;
  right: 3px;
  top: 0;
}

.close-normal {
  font-size: 22px !important;
}

.carouselImage {
  width: 150px;
  margin-right: 10px;
  display: inline-grid;
  cursor: pointer;

  img {
    width: 100px;
    pointer-events: none;
    border: 1px solid #DDDDDD;
    margin: auto;
  }
}
