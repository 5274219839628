.details-screen .small-bulk-button .btn.dropdown-toggle{
  margin-top:0 !important;
}

.details-screen .small-bulk-button > div{
  padding:0;
}

.details-screen .small-bulk-button .title {
  text-transform: lowercase;
  display: inline-block;
}

.details-screen .small-bulk-button .title::first-letter {
  text-transform: uppercase;
}

.details-screen .small-bulk-button {
  display:inline-block;
}

.details-screen .ag-ltr .ag-header-select-all{
  float:none;
}

.details-screen .ag-bootstrap .ag-pinned-left-cols-viewport .ag-row-odd .ag-cell.select-checkbox{
  background:transparent;
  border:none;
}

.details-screen .ag-bootstrap .ag-pinned-left-cols-viewport .ag-row-even .ag-cell.select-checkbox{
  background:transparent;
  border:none;
}

.details-screen .ag-bootstrap.details-page-twitter .ag-pinned-left-cols-viewport {
  border-left: none;
}

.details-screen .ag-bootstrap.details-page-twitter .ag-header-cell {
  background:transparent;
}

.details-screen .select-all-checkbox {
  border-right: none !important;
}

.bulk-edit-mode-selector .btn[disabled] {
  color: #aaa !important;
}

.details-screen {
  @-webkit-keyframes live-blink {
    50% {
      opacity: 20%;
    }
  }

  @-moz-keyframes live-blink {
    50% {
      opacity: 20%;
    }
  }

  @keyframes live-blink {
    50% {
      opacity: 20%;
    }
  }

  .live-refresh {
    -webkit-animation: live-blink 0.75s ease-in-out 0s;
    -moz-animation: live-blink 0.75s ease-in-out 0s;
    -o-animation: live-blink 0.75s ease-in-out 0s;
    animation: live-blink 0.75s ease-in-out 0s;
  }
}
