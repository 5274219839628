.overview-loader {
  position: relative;
  top: 20px;
}

.save-form {
  .button_container {
    width: 100%;

    .btn-group {
      display: block;
    }
  }
}

.report-grid {
  min-height: 400px;

  span {
    .ag-group-value {
      padding-left: 8px;
    }
  }

  .ag-header-icon {
    color: #707070;

    .ag-icon-asc {
      background: 0;
      height: 5px;
      vertical-align: middle;

      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f0de';
        display:block;
      }
    }

    .ag-icon-desc {
      background: 0;
      line-height: 5px;
      vertical-align: middle;

      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f0dd';
        display:block;
      }
    }
  }
}

.overview {

  .date-range {
    padding-bottom: 20px;
  }

  .advertiser-panel,
  .inventory-overview,
  .products-overview,
  .performance {
    &.panel-body {
      background-color: #f2f2f2;
      border: 0;
    }
  }

  .overview-metric {
    padding-bottom: 20px;

    .metric-panel {
      padding-bottom: 20px;
    }

    .title {
      padding-bottom: 10px;
    }
  }

  .default-chart {
    height: 400px;
  }

  .breakdown-charts {
    position: relative;
    top: 20px;
  }


  .top-performances {
    .top-performance {
      height: 120px;
    }
  }

  .breakdown-charts, .top-performances {
    clear: both;

    h5 {
      font-weight: bold;
    }
  }

  .panel-default .panel-body {

    .top-performance table {
      width: 100%;
      max-width: 100%;
      table-layout: fixed;
      background-color: transparent;

      th, td {
        border: none;
        padding: 0;
      }

      tbody tr td:first-child {
        padding-left: 0;
      }
    }

    .single-performance td.name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .single-performance td.value {
      white-space: nowrap;
      text-align: right;
      vertical-align: top;
    }

  }

  .highcharts-container {
    overflow: visible !important;

    svg {
      overflow: visible;
    }
  }

}
.currencies-label {
  display: inline-block;
  padding-right: 20px;
}
.report-form .Select-option.is-disabled {
  color: #ccc;
  cursor: not-allowed;
}

.metric-picker button {
  margin-bottom: 20px;
}

.dimensions-metrics-picker,
.metric-picker {
  .btn-group-sm>.btn, .btn-sm {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.5
  }
}

.dimensions-metrics-picker #download-button{
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.5
}

.report-list {
  margin-top: 15px;
}

#download-button-container {
  display: inline-block;
  padding-left: 120px;

  .dropdown-menu {
    margin-top: 32px !important;
  }
}

.report-button-container {
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;
}

.report-button-container .btn-sm {
  padding: 6px 12px;

  &:hover {
    padding: 6px 12px;
  }
  &:focus {
    padding: 6px 12px;
  }
}

.view-style-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.view-style-button-container .view-style-title {
  font-size: 13px;
  font-weight: bold;
  color: #1997F0;
}

.view-style-button-container .btn-default {
  border: none;
  color: #D5D5D5 !important;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    color: #1997F0 !important;
  }
}

.button-divider {
  border-left: 1px solid #D5D5D5;
  height: 20px;
}

.view-style-button-container .btn-default.active {
  color: #1997F0 !important;
  border: none;
  background-color: transparent;
  box-shadow: none;

  &:focus {
    outline: none;
  }
}

.list-view-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  overflow: visible;

}

.rules.reports-list .header h3,
.rules.reports-list .menu-action {
  overflow: visible !important;
}

.rules.reports-list .menu-action {
  min-width: 600px;
  margin-left: 20px;
}

.list-view-header .dropdown-menu {
  left: unset;
  right: 0;
}

.no-results-container {
  margin-top: 50px;
  text-align: center;
}

.no-results-container span {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #707070;
}

.list-view {
  margin-bottom: 0 !important;
}

.report-pulse {
	animation: pulse-reports 2s infinite;
}

@keyframes pulse-reports{
	0% {
		transform: scale(1.01);
	}
	
	70% {
		transform: scale(1);
	}
	
	100% {
		transform: scale(1.01);
	}
}