.valueFilter {
  .Select-control {
    font-size: 12px;
    height: 27px;

    .Select-value,
    .Select-placeholder {
      //line-height: 1.4em;
    }

    .Select-arrow-zone {
      top: 2px;
    }
  }
}

.header-filter .valueFilter .Select--single > .Select-control .Select-value,
.header-filter .valueFilter .Select-control .Select-placeholder {
  line-height: 27px;
}

.header-filter .valueFilter .Select-input > input {
  padding-top: 6px;
}

.selected-filters {
  > ul {
    padding: 0;
    line-height: 1em;

    > li {
      cursor: pointer;
      position: relative;
      background: #16bf64;
      display: inline-block;
      margin-right: 3px;
      padding: 8px 28px 8px 8px;
      border-radius: 3px;
      height: 30px;

      &.has-menu {
        padding-right: 63px;
      }

      > a {
        color: #fff;
        font-size: 12px;
        line-height: 1em;
      }

      .value {
        color: #fff;
        font-size: 13px;
        line-height: 1em;
        float: left;
        &:hover {
          opacity: .7;
        }
      }

      .entity-menu {
        position: absolute;
        top: 0;
        right: 26px;
        padding: 8px 12px;
        height: 100%;

          > a {
            color: #fff;
          }

          > a:hover {
            opacity: .7;
          }

          &.disabled {
            .fa.fa-ellipsis-v.disabled {
              color: rgba(255, 255, 255, 0.5) !important;
            }
          }

          &:before,
          &:after {
            content: " ";
            width: 1px;
            position: absolute;
            top: 0px;
            bottom: 0px;
            background: #fff;
            opacity: .3;
          }

          &:before {
            left: 0;
          }

          &:after {
            right: 0;
          }
      }

      .close {
        position: absolute;
        color: #fff;
        margin-left: 5px;
        opacity: 1;
        font-size: 21px;
        line-height: 15px;
        text-shadow: none;
        top: 0;
        right: 0;
        padding: 8px;
      }

      .close:hover {
        opacity: .7;
      }
    }
  }
}
