.main-navigation {
	padding: 0;
	max-height: none !important;
	z-index: 1000;
}
ul.main-navigation-menu {
	list-style: none;
	margin: 0;
	padding: 0;
}
.sidebar-fixed .wrap-menu {
	overflow: hidden;
	position: fixed;
	width: 225px;
	height: 300px;
}
ul.main-navigation-menu > li {
	border: 0 none;
	display: block;
	margin: 0;
	padding: 0;
}
ul.main-navigation-menu > li > a {
	border: 0 none;
	display: block;
	font-size: 14px;
	font-weight: 300;
	margin: 0;
	padding: 5px 0;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid #DDDDDD;
	border-top: 1px solid #FFFFFF;
}
ul.main-navigation-menu li  a .badge {
    float: right;
    margin-right: 13px;
    margin-top: 1px;
}
ul.main-navigation-menu > li:last-child > a, ul.main-navigation-menu > li.last > a {
	border-bottom-color: transparent !important;
}
ul.main-navigation-menu > li > a i {
	font-size: 16px;
	margin-right: 5px;
	text-shadow: none;
	display: inline-block;
}

ul.main-navigation-menu li a .icon-arrow {
	float: right;
	margin-right: 6px;
}
ul.main-navigation-menu li > a > .icon-arrow:before {
	content: "\e163";
	font-family: "clip-font";
}
ul.main-navigation-menu li.open > a > .icon-arrow:before {
	content: "\e164";
}
ul.main-navigation-menu > li > a:hover .icon-arrow, ul.main-navigation-menu > li.open > a .icon-arrow {
	opacity: 1;
}
/* 2nd level sub menu */
ul.main-navigation-menu li > ul.sub-menu {
	clear: both;
	list-style: none;
	margin: 8px 0;
	display: none;
	padding: 0;
}
ul.main-navigation-menu li > ul.sub-menu > li {
	background: none;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
}
ul.main-navigation-menu li > ul.sub-menu > li > a {
	background: none;
	display: block;
	font-size: 14px;
	font-weight: 300;
	margin: 0;
	padding-bottom: 5px;
	padding-left: 40px !important;
	padding-right: 0;
	padding-top: 5px;
	text-decoration: none;
}
ul.main-navigation-menu > li > ul.sub-menu li > a .icon-arrow {
	margin-right: 22px;
}
/* 3rd level sub menu */
ul.main-navigation-menu > li > ul.sub-menu li > ul.sub-menu {
	margin: 0;
}
ul.main-navigation-menu li.active > ul.sub-menu {
	display: block;
	padding: 0;
}
ul.main-navigation-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
	padding-left: 60px !important;
}
ul.main-navigation-menu > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
	padding-left: 80px !important;
}
/*navigation toggler*/
.navigation-toggler {
	cursor: pointer;
	height: 30px;
	position: absolute;
	right: 3px;
	top: 10px;
	width: 30px;
	line-height: 30px;
	text-align: center;
	z-index: 1;
}
/*horizontal menu*/
.horizontal-menu {
	clear:both;
	border-top: 1px solid #C8C7CC !important;
	margin-left: -15px !important;
	margin-right: -15px !important;
	padding: 0 15px !important;
	background: #FFFFFF;
}
.horizontal-menu ul.nav li.open > a, .horizontal-menu ul.nav li > a:hover, .horizontal-menu ul.nav li > a:focus {
    background: #F5F5F5 !important;
    color: #555555 !important;
}
.horizontal-menu .dropdown-menu li:hover > a, .horizontal-menu ul.nav li.active > a, .horizontal-menu ul.nav li.active > a:hover {
    /*background: #007AFF !important;
    color: #FFFFFF !important;*/
}
.horizontal-menu ul.nav li.current .selected, .horizontal-menu ul.nav li.active .selected {
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    border-top: 6px solid #007AFF;
    bottom: 0;
    display: inline-block;
    height: 0;
    left: 50%;
    margin: 0 0 -6px -7px;
    position: absolute;
    width: 0;
}
.horizontal-menu .dropdown-menu {
    //box-shadow: none;
    margin-top: -1px !important;
    border-radius: 0;
}

.horizontal-menu .dropdown-submenu > .dropdown-menu {
    top: 0;
}
.horizontal-menu .dropdown-submenu > a:after {
    margin-right: 0;
    top: 8px;
}
.horizontal-menu .dropdown-menu li > a {
    margin-bottom: 1px;
    padding: 7px 18px !important;
    font-size: 13px;
}
.horizontal-menu .dropdown-menu .arrow {
    display: none;
}
.horizontal-menu .dropdown-submenu > .dropdown-menu {
    top: 0;
}
.horizontal-menu .dropdown-submenu > a:after {
    margin-right: 0;
    top: 8px;
}
.horizontal-menu ul.nav li {
    position: relative;
}
.horizontal-menu .dropdown-submenu > a:after {
    content: "\f105";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 14px;
    font-weight: 300;
    height: auto;
    position: absolute;
    right: 10px;
    top: 7px;
}
.horizontal-menu .dropdown-submenu > .dropdown-menu {
    left: 100%;
    margin-left: 0;
    margin-top: -6px;
    top: 5px;
}
.horizontal-menu .dropdown-submenu:hover > .dropdown-menu {
    display: block;
}
.horizontal-menu .dropup .dropdown-submenu > .dropdown-menu {
    bottom: 0;
    margin-bottom: -2px;
    margin-top: 0;
    top: auto;
}

