.toggleButton{
  margin-top: 12px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;

  .button-container{
    position: relative;
  }

  .tri-up {
    display: block;
    position: absolute;
    bottom: -31px;
    cursor: default;
    width: 28px;
    height: 14px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}

.form .toggleButton div.value{
  margin-bottom: 0;
}
