/*
.twGridErrors.gridErrors {
  margin: 178px 24px -50px -35px;
}*/

.report-campaign-select .select2-results li.select2-result-with-children>.select2-result-label {
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-top: 7px;
  cursor: default;
}

.emojiPickerTrigger {
  position: absolute;
  top: 0px;
  margin-left: 5px;
  font-size: 32px;
  color: #bbb;
  cursor: pointer;
}

.adplatform-style .emojiPickerTrigger {
  margin-left: 15px;
}

.emojiPickerTrigger:hover, .emojiPickerTrigger.active{
  color: #1997f0;
}

.emoji-mart {
  z-index: 1003 !important; // above group edit floater
}
