.selected-date-range-btn {
    min-width: 210px;
    font-size: 13px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    color: #858585 !important;
    text-align: left;
    line-height: 13px;
    height: 27px;
    padding-left: 8px;

    div.pull-right {
        margin-top: -2px;
    }
}

.daterangepicker {
    .ranges ul {
        width: 185px;
    }

    .next {
        background: none;
    }

    td.active,
    td.active:hover,
    .ranges li.active {
        background: #00a1cb;
    }

    .drp-buttons .btn.cancelBtn {
        font-weight: normal;
    }

    .ranges li:nth-child(2),
    .ranges li:nth-child(4),
    .ranges li:nth-child(7) {
        border-bottom: 1px solid #e9ecef;
    }

    .calendar-table th {
        border-radius: 4px !important;
        float: initial !important;
        display: table-cell;
    }
}
