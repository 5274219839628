/* ----- Panels ----- */
.panel {
  border-radius: 0;
  border: none;
  background: none;
  margin-bottom: 20px;
  position: relative;
}

.panel-default {
  .panel-heading {
    position: relative;
    margin-left: 0;
    text-transform: uppercase;
    font-size: 85%;
    font-weight: bold;
    cursor: inherit;
    padding: 0;
    border: none;
    height: auto;
    background: none;
    margin-bottom: 12px;
  }
  a.panel-heading:hover {
    color: #000;
  }
}

.panel-default.panel-empty {
  .goals-label {
    display: none;
  }
}

.panel-heading {
  .addCampaign {
    display: inline-block;
    margin-left: 10px;
    color: #999;
    &:before {
      content: "+";
      padding-right: 3px;
    }
    &:hover {
      text-decoration: none;
      color: #707070;
    }
  }
}

.panel-heading {
  a,
  a:hover {
    text-decoration: none;
  }
  .panel-title {
    font-size: 14px;
    display: inline-block;
  }
  a.panel-title {
    display: initial;
  }
}

.colapsing-selector {
  position: absolute;
  left: -24px;
  top: -1px;
  display: inline-block;
  color: #333;
  font-size: 20px;
  padding: 0 5px;
  cursor: pointer;
  line-height: 1;
}

.panel:before {
  content: "";
  width: 1px;
  border-left: 1px dashed rgb(215, 215, 215);
  height: auto;
  position: absolute;
  left: -14px;
  top: 24px;
  bottom: 0;
  margin: auto;
}

.panel-light {
  .panel-body {
    background: #fff;
    padding-top: 25px;
    border: 1px solid #E4E4E4;
    position: relative;
  }
}

.panel .sub-panel {
  .panel-heading {
    left: 20px;

    .panel-title {
      font-size: 13px;
    }

    .colapsing-selector {
      font-size: 18px;
    }
  }

  .panel-body {
    border: 0;
    padding: 0;
  }

  .react-panel-content-margin {
    margin: 0;
  }
}

.panel .sub-panel::before {
  border: 0;
}

.panel-heading .panel-title a {
  cursor: pointer;
}

.panel-flat {

  &.panel:before {
    content: inherit;
    width: inherit;
    border-left: inherit;
    height: inherit;
    position: inherit;
    left: inherit;
    top: inherit;
    bottom: inherit;
    margin: inherit;
  }

  &.panel {
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 11px;
  }

  .panel-heading {
    margin-bottom: 0;
  }

  .panel-body {
    padding-top: 0;
  }

  .panel-title {
    display: block;
    margin-bottom: 9px;

    a {
      display: block;
      overflow: hidden;
    }

  }

  .panel-heading + .panel-collapse > .panel-body {
    border-top-color: transparent;
  }

}

.panel-group {
  .panel {
    + .panel {
      margin-top: 20px;
    }
  }
}

.panel-action-overlay {
  width: 100% !important;
  max-width: 450px !important;
}

.panel-menu {
  text-transform: initial;
}

.radio-panel {
  margin-left: 3px;
  margin-top: 12px;

  .panel-group {
    margin-bottom: 0;
  }
}

.panel-icon {
  color: #788087;
}
