.page-header {
	margin: 0;
	padding: 30px 0px 20px 0px;
	position: relative;
	margin: 0;
	margin-bottom: 20px;
	background: #ffffff;

	h1, .h1 {
		font-family: 'Raleway', sans-serif;
		font-weight: 200;
		letter-spacing: -1px;
		margin: 0 0 15px;
		padding: 0;
		color: #666666;
	}

	h1 small,
	.h1 small {
		color: #888888;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0;
	}

	/*&:before{
		position: absolute;
		bottom: -24px;
		left: 55px;
		display: inline-block;
		border-top: 25px solid #FFF;
		border-right: 25px solid transparent;
		border-left: 25px solid transparent;
		content: '';
	}*/
}