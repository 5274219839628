.text-metric {
  padding: 20px 0;
  background: #fff;
  text-align: center;
  min-height: 7em;
  display: inline-block;
}

.panel .text-metric-overview{
  background-color: #f2f2f2;
  border: 0;
}

.text-metric-value {
  font-size: 16px;
}

.text-metric-label {
  font-size: 12px;
}

@media (min-width: 912px) {
  .text-metric-value {
    font-size: 19px;
  }

  .text-metric-label {
    font-size: 13px;
  }
}
