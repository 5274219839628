.u-slider {
  cursor: pointer;
  position: relative;
  display: inline-block;
  background-color: #dddddd;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.u-slider .value {
  position: absolute;
  background-color: #00A1CB;
  margin-left: 0 !important;
  border-radius: 3px;
}
.u-slider .handle {
  position: absolute;
  width: 8px;
  height: 8px;
}
.u-slider .handle:after {
  position: relative;
  display: block;
  content: '';
}
.u-slider-x .handle:after,
.u-slider-y .handle:after {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 6px solid #00A1CB;
  border-radius: 6px;
}
.u-slider-x {
  height: 8px;
}
.u-slider-x .handle {
  height: 100%;
}
.u-slider-x .handle:after {
  top: -2px;
  left: -6px;
}
.u-slider-x .value {
  top: 0;
  height: 100%;
}
.u-slider-y {
  width: 8px;
}
.u-slider-y .handle {
  width: 100%;
}
.u-slider-y .handle:after {
  top: -6px;
  left: -2px;
}
.u-slider-y .value {
  left: 0;
  width: 100%;
}
.u-slider-xy {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #00A1CB;
  border-radius: 0;
}
.u-slider-xy .handle {
  position: absolute;
}
.u-slider-xy .handle:after {
  position: relative;
  display: block;
  top: -4px;
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
  border-radius: 50%;
  content: '';
}
