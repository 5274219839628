/* ---------------------------------------------------------------------- */
/*	Sidebar
/* ---------------------------------------------------------------------- */


.sidebar-wrapper{
	display: block;
	float: left;
	z-index: 1;
	padding: 0;
	width: 35px;
	background: #f5f5f5;
	/*border-bottom: 1px solid #c8c7cc;
	margin-bottom: -1px;
    box-shadow: inset 0px -2px 8px rgba(0,0,0,.08);
	#gradient > .horizontal(#f5f5f5; #e9e9e9; 0%; 100%);
	*/
	h2{
		color: #000;
		font-weight: 300;
		font-size: 18px;
		text-transform: uppercase;
	}
	hr.topSepartor{
		border-color: #E4E5E6;
		width: 100%;
		margin-left: -15px;
		margin-right: -15px;
		content: "";
		position: absolute;
		top: 30px;
	}
	hr.separtor{
		border-color: #c8c7cc;
		content: "";
		margin: 40px -15px;
	}
}
div.sidebar.estimates{
	//display: none;
}
.sidebar-widget{
	margin-top: 32px;
	display: none;
	color: #999;
	hr{
		margin: .5em 0;
	}
	select,
	.bootstrap-select{
		max-width: 200px;
	}
	> p{
		margin-top: 1em;
	}
}
.sidebar_bottom {
	 margin-top: 40px!important;
}

/* ---------------------------------------------------------------------- */
/*	Action Menu
/* ---------------------------------------------------------------------- */
.action-menu {
	padding: 0;
	max-height: none !important;
	z-index: 1000;

	&.buttons {
		margin-bottom: 40px;
	}
}

ul.action-menu {
	list-style: none;
	margin: 0 -15px;
	padding: 0;
}
ul.action-menu > li {
	border: 0 none;
	display: block;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid @border-color;
}
ul.action-menu > li:first-child {
	border-top: 1px solid @border-color;
}
ul.action-menu > li > a {
	border: 0 none;
	display: block;
	font-size: 13px;
	margin: 0;
	padding: 10px 25px 10px 40px;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid @border-color;
	border-top: 1px solid #FFFFFF;
	border: 0;
}
ul.action-menu li a .badge {
    float: right;
    margin-right: 13px;
    margin-top: 1px;
}
ul.action-menu > li:last-child > a,
ul.action-menu > li.last > a {
	border-bottom-color: transparent !important;
}
ul.action-menu > li > a i {
	font-size: 15px;
	margin-right: 5px;
	text-shadow: none;
	display: inline-block;
	position: absolute;
	top: 12px;
	left: 15px;
}
ul.action-menu li a .icon-arrow {
	top: 10px;
	left: auto;
	right: 10px;
	margin-right: 0;
}
ul.action-menu li > a > .icon-arrow:before {
	content: "\e162";
	font-family: "clip-font";
}
ul.action-menu li.open > a > .icon-arrow:before {
	content: "\e164";
}
ul.action-menu > li > a:hover .icon-arrow,
ul.action-menu > li.open > a .icon-arrow {
	opacity: 1;
}
/* 2nd level sub menu */
ul.action-menu li > ul.sub-menu {
	clear: both;
	list-style: none;
	margin: 8px 0;
	display: none;
	padding: 0;
}
ul.action-menu li > ul.sub-menu > li {
	background: none;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	margin-top: 1px !important;
	padding: 0;
}
ul.action-menu li > ul.sub-menu > li > a {
	background: none;
	display: block;
	font-size: 14px;
	font-weight: 300;
	margin: 0;
	padding-bottom: 5px;
	padding-left: 40px !important;
	padding-right: 0;
	padding-top: 5px;
	text-decoration: none;
}
ul.action-menu > li > ul.sub-menu li > a .icon-arrow {
	margin-right: 22px;
}
/* 3rd level sub menu */
ul.action-menu > li > ul.sub-menu li > ul.sub-menu {
	margin: 0;
}
ul.action-menu li.active > ul.sub-menu {
	display: block;
	padding: 0;
}
ul.action-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
	padding-left: 60px !important;
}
ul.action-menu > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
	padding-left: 80px !important;
}
ul.action-menu > li a {
	border-bottom: none;
	border-top: none;
	border-top-color: @border-color;
	color: @text-color;
}
ul.action-menu > li a > i {
	color: @brand-cyan;
	font-weight: normal;
}
ul.action-menu > li.active > a {
	background: @brand-cyan !important;
	border-top: none !important;
	color: #fff;
}
ul.action-menu > li.active > a .selected:before {
	color: @brand-cyan !important;
}
ul.action-menu > li.active > a i {
	color: #fff;
}
ul.action-menu > li.open > a,
ul.action-menu > li > a:hover,
ul.action-menu > li:hover > a {
	background-color: #E4E5E6;
}
ul.action-menu li > ul.sub-menu > li.open > a,
ul.action-menu li > ul.sub-menu > li.active > a,
ul.action-menu li > ul.sub-menu > li > a:hover {
	color: #000000 !important;
	background: #E4E5E6 !important;
}
ul.action-menu > li > a.disabled,
ul.action-menu > li > a:hover.disabled{
	color: darken(#f5f5f5,20%);
	background-color: transparent;
	& >i {
		color: darken(#f5f5f5,20%)!important;
	}
}

/* ---------------------------------------------------------------------- */
/*	ESTIMATES
/* ---------------------------------------------------------------------- */

#edit-group-floater-anchor .estimates {
	padding: 0;
}
.estimates {
	background: #fff;
	border: 1px solid #e4e4e4!important;
	text-align: left;
	padding: 25px 15px;
	margin-top: 31px;
	width: 210px;
	position: relative;

	@media (min-width: 992px){
		width: 230px;
	}
	@media (min-width: 1200px){
		width: 240px;
	}
	&.affix {
		position: fixed;
		top: 40px;
	}

	.estimate-btn{
		.btn;
		.btn-primary;
		.btn-block;
		margin-top: 15px;

		&.disabled,
		&[disabled],
		fieldset[disabled] & {
		  .opacity(1);
		  background-color: @grey-info-bg;
		  border-color: darken(@grey-info-bg, 2%);
		  color: @grey-info-text;
		}

	}
	.estimate-alert{
	 	.alert;
	 	.alert-warning;
	 	padding: 8px;
	 	font-size: 12px;
	}

	.spinner {
		text-align: center;
		display: none;
		position: absolute;
		margin: 0 auto;
		height: 18px;
		width: 18px;
		top: 0px; left: 0; bottom: 0; right: 0;
	}
	h3{
		margin-top:0;
		margin-bottom:20px;
	}
	.table{
		margin-bottom: 0;
		font-size: 11px;
		th{
			font-weight: normal;
			text-transform: uppercase;
		}
		td{
			text-align: right;
			vertical-align: top;
		}
		td.text-left{
			text-align: left;
		}
		td.text-right{
			text-align: left;
		}
		.subgroup{
			th,td{
				color: #aeaeae;
			}
		}
		&.total{
			margin-top: 11px;
	      th,td {
			background: transparent;
			border: 0;
			}
		}
		&.warning{
	      th,td {
			color: #d9534f;
			}
		}
	}

	.greyBg{
		background: #f9f9f9;
	}

	.help-text{
		padding: 8px;
		font-size: 12px;
		color: #aeaeae;
	}
	.cancel-link{
		text-decoration: underline;
	}
}

.sidebar_targeting{
	.subtitle{
		color: #707070;
		padding-bottom: 4px;
		display: inline-block;
		&.second{
			padding-top: 4px;
		}
	}
}

