.grid-ad-preview-iframe, .adPreview {
    iframe {
        width: 100% !important;
        border: none;
        height: 100%;
        max-width: 700px;
    }
}

.adPreview.new-preview div {
    height: 700px;
}

.grid-ad-preview-iframe.new-preview {
    height: 950px;
}

.action-overlay-content {
    .new-preview {
        width: 600px !important;
    }
}

@fadeinTime: 0.5s;
.video-cta-container {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(0, 0, 0, 0.8);
    box-sizing: content-box;
    border: 1px solid rgb(255, 255, 255);
    outline-style: none;
    user-select: none;
    font-size: 13px;
    cursor: pointer;
    padding: 5px 8px;
    box-shadow: none;
    line-height: normal;
    vertical-align: middle;
    align-items: center;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    max-width: 75%;
    margin-top: 60px;
    margin-left: 70px;
    position: absolute;

    span {
        padding: 3px 5px;
        color: white;
    }

    .cta-value {
        display: flex;
        align-items: center;
        height: 30px !important;
    }

    -webkit-animation: fadein @fadeinTime;
    -moz-animation:    fadein @fadeinTime;
    -ms-animation:     fadein @fadeinTime;
    -o-animation:      fadein @fadeinTime;
    animation:         fadein @fadeinTime;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.adplatform-style {
    .adplatform-preview {
        height: 700px;
        width: 600px;

        iframe {
            width: 100% !important;
            border: none;
            height: 700px;
            max-width: 700px;
            overflow:hidden;
        }
    }
}
