.nav-tabs-icons {
	padding: 0;
	margin: 0;
	background: transparent;
}
.nav-tabs-icons > li > a {
	font-size: 16px;
	color: #696969;
	position: relative;
	display: inline-block;
}
.nav-tabs-icons > li > a {
	background: transparent;
	color: #555;
	padding: 4px 15px 0;
}
.nav-tabs-icons > li.active > a,
.nav-tabs-icons > li.active > a:hover,
.nav-tabs-icons > li.active > a:focus {
	background: #ddd;
}
.nav-tabs-icons > li.active >a:before {
	position: absolute;
	bottom: -8px;
	display: inline-block;
	border-top: 8px solid #ddd;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	content: '';
}
.nav-tabs-icons .badge {
	top: 14%;
}
.nav-tabs-icons  > li {
	display: inline-block;
}