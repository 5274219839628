.circle-icon {
	background-color: #333333;
	border-radius: 75px 75px 75px 75px;
	color: #FFFFFF;
	margin-top: 2px;
	text-align: center;
	height: 55px;
	line-height: 56px;
	width: 55px;
	display: inline-block;
	font-size: 24px !important;
}
.circle-icon.circle-green {
	background-color: #3D9400;
	border-color: #327B00;
	color: #FFFFFF;
}
.circle-icon.circle-teal {
	background-color: #569099;
	border-color: #4D8189;
	color: #FFFFFF;
}
.circle-icon.circle-bricky {
	background-color: #C83A2A;
	border-color: #b33426;
	color: #ffffff;
}
.core-box {
	margin-bottom: 20px;
	display: inline-block;
	padding-bottom: 10px;
	border-bottom: 1px solid #EEEEEE;
}
.core-box .heading {
	margin-bottom: 15px;
}
.core-box .heading .circle-icon {
	float: left;
}

.core-box .heading h2 {
	line-height: 55px !important;
	margin: 0;
	padding-left: 65px;
	font-weight: normal;
	color: #666666;
	font-size: 18px;
}
.core-box .content {
	color: #747474 !important;
	line-height: 20px;
	margin-bottom: 15px;
}
.core-box .view-more {
	float: right;
}
.core-box .view-more i{
	display: inline-block;
}
.core-box .view-more:hover {
	text-decoration: none;
}
.discussion {
	list-style: none;
	margin: 0;
	padding: 0;
}
.discussion li {
	overflow: hidden;
	padding: 5px 5px 10px;
}
.discussion .avatar {
	position: relative;
	width: 50px;
	height: 50px;
	display: inline;
	overflow: hidden;
}

.other .messages:after {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-color: #E5E5EA #E5E5EA rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
	border-style: solid;
	border-width: 5px;
	content: "";
	height: 0;
	position: absolute;
	left: -9px;
	top: 0;
	width: 0;
}
.other .messages {
	margin-left: 62px;
	background: #E5E5EA;
}
.other .avatar {
	float: left;
}
.self .messages {
	border-bottom-right-radius: 0;
	margin-right: 62px;
	background: #33CA22;
	color: #ffffff;
}
.self .avatar {
	float: right;
}
.self .messages:after {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-color: #33CA22 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #33CA22;
	border-style: solid;
	border-width: 5px;
	top: 0;
	content: "";
	height: 0;
	right: -9px;
	position: absolute;
	width: 0;
}
.messages {
	border-radius: 2px 2px 2px 2px;
	padding: 10px;
	position: relative;
}
.messages p {
	font-size: 14px;
	margin: 0 0 0.2em;
}
.messages time {
	color: #333333;
	font-size: 11px;
}
.self .messages time {
	color: #ffffff;
}
.chat-form{
	margin-bottom: 20px;
}