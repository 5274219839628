.ag-grid-creative-modal{
  .ag-header-row{
    height: 40px !important;
    color: #707070;
    font-family: 'Roboto', sans-serif;
    background-color: #e9ecef !important;
    font-weight: bold;
  }

  .ag-row{
    padding: 0px 0px 20px 0px;
    color: #707070;
    font-family: 'Roboto', sans-serif;
  }

  .ag-header-cell-label{
    padding: 4px 0px 0px 15px;
  }
}

.creative-modal {
    padding-top: 10px;
}

.creative-modal-alert-container {
  padding-left: 40px;
  padding-right: 20px;

  .alert {
    margin-bottom: unset;
  }
}

.error-container {
  .twCondAlert {
      padding-top: 0;
  }
}
