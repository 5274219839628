.notification-container {
	background: @brand-cyan;
	position: relative;
	overflow: hidden;
	margin-top: 15px;
	&,
	a {
		color: #fff;
	}
}
.notification-title {
	color: #fff;
	margin: 0 0 17px;
	font-size: 14px;
	line-height: 1.2;
	padding: 12px;
	background: #0D8DB2;
	small {
		font-size: 12px;
		display: block;
	}
}
.notification-list {
	display: block;
	font-size: 12px;
	&.is-navbar {
		margin: 0 -20px;
	}
	li {
		border-bottom: 4px solid @brand-cyan-inverted;
		padding: 12px;
	}
	li:first-child {
		padding-top: 0;
	}
	.time {
		color: darken(@brand-cyan-inverted, 10%);
		display: table;
	}
}
.view-all {
	background: @brand-cyan-inverted;
	text-align: center;
	padding: 10px 0;
	margin: 12px;
	&.is-navbar {
		position: absolute;
		bottom: 5px;
		left: 5px;
		right: 5px;
		margin: auto;
	}
}
.notification-icon {
	color: #086c86;
	font-size: 17px;
}
.notification-msg-icon {
	content: "\f0e0";
}
.errors-msg-icon {
	content: "\f075";
}
.notification-light {
	// variables
	@grey: #E7E7E7;
	@grey-dark: #555;
	@grey-mid: #999;
	// style
	background: @grey;
	border: 1px solid darken(@grey, 5%);
	.notification-title {
		background: @grey-mid;
	}
	.notification-icon {
		color: @grey-dark;
	}
	.notification-list {
		&,a {
			color: @grey-mid;
		}
		li {
			border-bottom-color: darken(@grey, 5%);
		}
		.time {
			color: lighten(@grey-mid, 10%)
		}
	}
	.view-all{
		//background: darken(@grey, 20%);
		background: @brand-cyan;
	}
	.view-all-light {
	   padding: 12px;

	   &,a {
	      color: @brand-cyan;
	   }
	}
}

.noNotifs {
	text-align: center;
	height: 85%;
	justify-content: center;
	align-items: center;
	display: flex;
}
