.keywordMatchPicker {
  .ondemand-box {
    .fa-times {
      line-height: 25px;
    }
  }
}


.firstUseSelects {
  .Select, .react-select-wrapper {
    display: inline-block;
    margin-right: 5px;
    width: 200px;
  }

  margin-top: 10px;
}

.bid-amount > span.submit.daily-budget-submit {
  font-size: 10px;
  width: initial;
  margin-right: 0;

  &.success[disabled] {
    background-color: #1997f0 !important;
    border-color: #1997f0 !important;
    color:white !important;
  }
}

.group-daily-budget-change {
  padding-top: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 20px;
}

.bulkModalErrors > .alert.alert-warning > ul > li {
  list-style: none;
}

.bulkAddSummary  .alert.alert-warning > ul > li {
  list-style: none;
}

.bulkAddSummary ul ul {
  margin-bottom: 0 !important;
}
