@import "../bootstrap/mixins/vendor-prefixes.less";
@zindexBase: 1000;

.overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindexBase - 1;
  background-color: @modal-backdrop-bg;
  opacity: .5;
}

.overlay-backdrop {
  .animation(fadeIn05 .3s ease-in);
}
