.scrollable {

  .fdt-cellFooter {
    padding-top: 10px;
    padding-bottom: 0;
    background: #f2f2f2;
  }

  .public_fixedDataTable_footer .fixedDataTableCellGroupLayout_cellGroup>.public_fixedDataTableCell_main {
    border: 1px solid #f2f2f2;
    border-top: 0;
  }

  .fixedDataTableLayout_horizontalScrollbar {
    background: #f2f2f2;
    bottom:74px;
    height: 7px!important;
    left: 36px;
    z-index: 1;
  }

  .fixedDataTableLayout_horizontalScrollbar .ScrollbarLayout_face {
    border-radius: 0;
    margin-left: 0;
  }

}
