.rulesMinWidthContainer {
	min-width: 1024px;
}
/* FB rules new look  */
.rules-wrapper{
  margin-top: 10px;
  margin-bottom:10px;
}
.rules-wrapper h4{
  margin-bottom: 15px;
}
.rules-holder{
  margin:10px 0;
}
.rules-holder .input-sm {
  height: 30px;
}
.rules-holder .bootstrap-select .btn{
  margin-top: 0;
}
.rules-holder .control-label{
  margin-bottom: 0;
}
.rules-holder .control-label,
.rules-holder .form-group p,
.rules-holder button.close{
  padding-top: 3px;
}
.rules-holder .alert{
  margin-top: 10px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .rules-wrapper .no-gutter {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}
.rules-when,
.rules-then{
  padding: 10px 20px;
}
.rules-then{
  margin: 0;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
// fb rules when listed (old look))
.fb-rule-list-holder{
  .action-condition-col {
  }
  .action-col {
  }
  .condition {
    border: 1px solid #e0e0e0;//#c8c7cc;
    background-color: #fcfcfc;
    padding: 10px;
    margin: 15px 15px 0 0;
    width: 275px;
    float: left;
    span.condition-operator.spacer {
      width:7%;
    }
    span.condition-operator.long {
      width:7%;
    }
    span.condition-rule {
      display:block;
      width:20%;
      float:left;
      white-space: nowrap;
    }
    span.condition-rule.first {
      width:25%;
    }
	span.condition-operator {
 	 	display:block;
 	 	float:left;
 	 	width:5%;
 	 	white-space: nowrap;
	}
	.condition-limit span {
		padding-left: 30%;
	}

    span.condition-rule.short {
      width:15%;
      white-space: nowrap;
    }
    span.condition-rule.half, span.condition-operator.half {
      width:22.5%;
    }
    i.condition-caret {
      position: absolute;
      right: 0px;
      margin-top: 3px;
    }
  }
  .action {
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  .condition.full {
    margin: 15px 0 0 0;
    width: 100%;
  }
  .rules-container {
    margin-right: 24px;
  }
}
.rule-example {
  font-style: italic;
}
/*Twitter rules*/
.rules {
    h5 {
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		margin: 0;
    }

	.single-rule-container {
        position: relative;
        margin-bottom: 20px;
        border: 1px solid #E0E0E0;
        padding-left: 24px;
        padding-bottom: 15px;
		background: #fff;
	}

	.single-rule-info-container {
        padding: 10px 0 15px 0;

        .single-rule-info {
		  	max-width: 490px;
            .info-more {
              display: flex;
              clear: both;

              p {
                margin: 0 15px 0 0;
              }
            }
        }

        .controls {
            position: absolute;
            top: 18px;
            right: 20px;

            .single-rule-edit-btn {
              margin-right: 15px;
            }
        }

		.redirect-button {
		  	top: 14px;
		}

        .rule-popover {
          position: absolute;
          top: 20px;
          right: 130px;
          color: #999;
        }
	}

  	.parent-rule-info {
	  	i {
		  padding: 15px 15px 0 5px;
		  font-size: 50px;
		  color: grey;
		}

	  	.single-rule-info {
			display: inline-block;
			vertical-align: text-bottom;

		  	&:hover {
			  	cursor: pointer;
			  	color: @dodgerBlue;
			}
	  	}
	}

	.single-rule-btn-group {
		display: inline-block;
	}

  	.delete-rule-error {
	  	margin: -10px 10px -10px 0;
	}

	.single-rule-edit-btn {
		margin-right: 10px;
	}

	.rules-menu-container {
		clear: both;
	}

	.add-new-rule-btn {
		margin-right: 5px;
	}

    .menu-create {
      margin-bottom: 20px;
    }

	.condition-no-bg {
		border: none;
		background-color: transparent;
	}

	.rules-amount-col-no-affix {
		margin-left: -15px;
	}

	.condition .condition-operator{
		display: inline-block;
        padding-top: 4px;
	}

	.close {
		padding-right: 50%;
		padding-top: 3px;
	}

	.rules-action-amount-col {
		padding: 0 15px;
	}

	.action-amount-amount {
		padding-right: 0;
	}

	.action-amount-type {
		padding-left: 0;
	}

	.action-amount-amount input {
		width: 95%;
	}

	.condition .select2-container {
		margin-top: 0;
	}

    .fb-rule-list-holder.rules-then .condition.full,
	.fb-rule-list-holder.new-rules .condition.full {
		margin-top: 0;
	}

    input[type="text"] {
        min-height: 27px;
    }

    .select2-container {
        margin-top: 0;
    }

    .fb-rule-list-holder .condition-rule {
        padding-top: 4px;
    }

    .condition-list-entity {
        width: 20%;
    }

    .controls {

      li {
          float: left;
          padding-left: 14px;

          a {
              font-size: 16px;
              cursor: pointer;
          }

		  a.disable-edit {
			color: @input-border-color !important;

			&:hover {
			  cursor: not-allowed;
			}
		  }
        }
    }
}

.fb-rule-list-holder .condition.action span.condition-rule.first {
  width: 45%;
}

.rules .onEntity {
  font-weight: normal;
  display: inline-block;
  width: 30%;
}

.fb-rule-list-holder .condition span.condition-rule.short.condition-amount {
  white-space: normal;
}


/* Reports screens is using rules markup to display reports, lets customize it*/

.reports {
  &.rules {

    position: relative;

    .single-rule-info-container {
      padding: 0 0 5px 5px;
    }

    .single-rule-container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .rules-group .single-rule-container .rules-container {
      margin-top: 7px;
      padding-top: 7px;
    }

    .header h3 {
      font-size: 18px;
      font-family: 'Open Sans', sans-serif;
      margin-top: 20px;
      margin-bottom: 20px;

      .campaign-name {
        display: inline-block;
      }
    }

    .header .menu-action {
      vertical-align: bottom;
    }

    .info-more {
      .text-row {
        margin-top: 3px;
        .caption{ width: 130px; }
        .value{ margin-left: 130px; }
      }
    }

    .reports-footer {
      margin-top: 20px;
    }

    @media (min-width: 1200px) {
      .single-rule-container {
        min-height: 370px;
      }
      .reports-footer {
        height: 35px;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
      }
    }

  }
}

.rule-details {
  .react-select-wrapper {
    width: 220px;
    margin-right: 0;

    .react-select__control {
      height: 34px;
    }

    .react-select__dropdown-indicator {
      padding: 6px;
    }
  }
}

.new-rules {
  .react-select-wrapper {
    .react-select {
      margin-right: 0;

      .react-select__control {
        height: 34px;
      }

      .react-select__dropdown-indicator {
        padding: 6px;
      }

      .react-select__value-container {
        height: 32px;
      }
    }
  }
}

.condition {
  .react-select-wrapper {
    .react-select {
      margin-right: 0;

      .react-select__dropdown-indicator {
        padding: 6px;
      }
    }
  }
}

.acc-rules-title {
  margin: 30px 0 15px;
  text-transform: uppercase;
  font-weight: 300;
}
