@import "../elements";
@import "../facelift/variables";

/* General styles for all menus */

.submenu-loader {
  margin-left: 20px;
}

.navbar .container .navbar-nav {
  margin-left: 0 !important;
}

.cbp-spmenu {
	background: @brand-cyan;
	position: fixed;
	font-size: 14px;
	.navbar-nav{
		padding: 20px;
		position: relative;
		display: block;
		width: 100%;
	}
	.close{
		font-size: 35px;
		position: absolute;
		top: 6px;
		right: 10px;
		margin: 1px 10px 0 0;
		z-index: 1;
	}
	.inset{
		text-shadow: 1px 1px 0 #19ACD8;
		color: #085A6B;
	}
}

.cbp-spmenu h3 {
	color: #afdefa;
	font-size: 1.9em;
	padding: 20px;
	margin: 0;
	font-weight: 300;
	background: #0d77b6;
}

.cbp-spmenu a {
	display: block;
	color: #fff;
}

.cbp-spmenu .txt-label{
	color: #01506B;
	font-weight: bold;
}

.navbar-items .title:not(:first-child):before {
  content:" ";
  width: auto;
  border-bottom: 1px solid #19b1dd;
  height: 3px;
  position: absolute;
  margin: -14px auto 20px auto;
  box-shadow: inset 0px -1px 0px 0px @brand-cyan-inverted;
  left: 20px;
  right: 20px;
}

.navbar-items:after {
  content:" ";
  width: 200px;
  border-bottom: 1px solid #19b1dd;
  height: 3px;
  position: absolute;
  margin: 10px auto 20px auto;
  box-shadow: inset 0px -1px 0px 0px @brand-cyan-inverted;
}

.navbar-inverted{
	background: @brand-cyan-inverted;
}

.header:after {
	display: inline-block;
	border-top: 12px solid @brand-cyan-inverted;
	border-right: 12px solid transparent;
	border-left: 12px solid transparent;
	position: absolute;
	margin: 10px auto 20px auto;
  	bottom: -31px;
}

.navbar-title{
	color: #fff;
	margin: 12px 0 24px;
	font-size: 18px;
	line-height: 1.2;
	small{
		font-size: 12px;
		display: block;
	}

  .user-icon {
	display: inline-block;
	margin-right: 5px;
  }
}

.cbp-spmenu{
	.notification-msg .navbar-submenu:after,
	.errors-msg .navbar-submenu:after{
		@width: 70px;
		.fa;
		border: 0;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		font-size: @width;
		width: @width;
		height: @width;
		color: darken(@brand-cyan-inverted, 10%);
		opacity: .2;
		z-index: 1;
	}
	.notification-msg .navbar-submenu:after{
		content: "\f0e0";
	}
	.errors-msg .navbar-submenu:after{
		content:"\f075";
	}
}

/* Orientation-dependent styles for the content of the menu */

.cbp-spmenu-vertical {
	width: 240px;
	top: 0;
	z-index: 1039;
	height: calc(100% - 46px);
}

body:not(.navigation-small) .cbp-spmenu-vertical {
	z-index: 1040;
}

.cbp-spmenu-vertical a {
	padding: 5px 0;
}

.cbp-spmenu-horizontal {
	width: 100%;
	height: 150px;
	left: 0;
	z-index: 1000;
	overflow: hidden;
}

.cbp-spmenu-horizontal h3 {
	height: 100%;
	width: 20%;
	float: left;
}

.cbp-spmenu-horizontal a {
	float: left;
	width: 20%;
	padding: 0.8em;

}

.navbar-nav .title {
	padding-top: 24px;
	padding-bottom: 12px;
}

/* Vertical menu that slides from the left or right */

.adplatform-style {
	&.navigation-small .cbp-spmenu-vertical {
		left: -260px;
	}

	.cbp-spmenu-vertical {
		left: 0;
		top: 0 !important;
		height: 100% !important;
		transition: left 0.2s ease !important
	}
}

.cbp-spmenu-right {
	right: -270px;
}

.cbp-spmenu-left.cbp-spmenu-open {
	.box-shadow(4px 0 0 rgba(72,72,72,.2));

}

.cbp-spmenu-right.cbp-spmenu-open {
	right: 0;
	.box-shadow(-4px 0 0 rgba(72,72,72,.2));
}

/* Horizontal menu that slides from the top or bottom */

.cbp-spmenu-top {
	top: -150px;
}

.cbp-spmenu-bottom {
	bottom: -150px;
}

.cbp-spmenu-top.cbp-spmenu-open {
	top: 0;
}

.cbp-spmenu-bottom.cbp-spmenu-open {
	bottom: 0;
}

/* Push classes applied to the body */

.cbp-spmenu-push {
	overflow-y: hidden;
	//position: relative;
	left: 0;
}

.cbp-spmenu-push-toright {
	left: 240px;
	position:absolute;
}

.cbp-spmenu-push-toleft {
	left: -240px;
}

/* Example media queries */

@media screen and (max-width: 55.1875em){

	.cbp-spmenu-horizontal {
		font-size: 75%;
		height: 110px;
	}

	.cbp-spmenu-top {
		top: -110px;
	}

	.cbp-spmenu-bottom {
		bottom: -110px;
	}

}

.twitter-style {
  .cbp-spmenu-left {
    left: 0 !important;
  }
}

@media screen and (max-height: 26.375em){

	.cbp-spmenu-vertical {
		font-size: 90%;
		width: 190px;
	}

	.cbp-spmenu-left,
	.cbp-spmenu-push-toleft {
		left: -190px;
	}

	.cbp-spmenu-right {
		right: -190px;
	}

	.cbp-spmenu-push-toright {
		left: 190px;
	}
}

/*Theming sidebar*/

@greenInverted: darken(@brand-green, 10%);

.cbp-spmenu.green{
	background: @brand-green;
	.navbar-inverted{
		background: @greenInverted;
	}
	.navbar-inverted .navbar-submenu:after{
		border-top-color: @greenInverted;
	}
	.notification-list{
		li{
			border-bottom-color: @greenInverted;
		}
		.time{
			color: darken(@greenInverted, 10%);
		}
	}
	.view-all{
		background: @greenInverted;
	}
}

.cbp-spmenu.changelog-list {
	background: #525555;
	color: #fff;
}

.menuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1035;
  background: rgba(0, 0, 0, .2);
}

/* Transitions */
.cbp-spmenu,
.menuOverlay{
	.transition(width 0.3s ease) !important;
}


.cbp-spmenu {
	ul.main-navigation-menu.user-menu li > ul.sub-menu {
		display: block;
		ul {
			margin-top: 20px;
		}
	}
}
