//CSS for entity selector widget (react/list/entitySelector)

.entity-selector {
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.42857143;

  .entity-search {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 15px;
  }

  .entity-search-option {
    .option-name {
      font-size: 13px;
    }
    .option-info {
      font-size: 85%;
      &:after {
        content: '\00B7';
        width: 12px;
        height: 12px;
        font-size: 1em;
        padding: 0 6px;
      }
      &:last-child:after {
        content: ' ';
        padding: 0;
      }
      .info-label {
        padding-right: 5px;
      }
      .info-value {
        color: #b7b7b7;
      }
    }
  }

  .react-select-wrapper .react-select {
    margin-right: 0;
  }

  .entity-selector-inner{
    border-radius: 3px;
    border: 1px solid #d3d3d3;
    height: 270px;
    width: 100%;
    padding: 7px 0 7px 7px;
    color: #888888;
  }

  .entity-selector-footer{
    padding: 12px 25px 12px 31px;
    border: 1px solid #d5d5d5;
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: @background-color;
  }

  /* AG-GRID */
  .ag-center-cols-container {
    width: 100% !important;

    .ag-cell {
      width: 100% !important;

      .ag-group-value {
        line-height: 23px;
      }

      &:focus {
        outline: none;
      }
    }

    .autopilot-icon {
      width: 50px!important;
    }
  }

  .ag-icon {
    background: none;
    cursor: pointer;
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;

    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;

    &.ag-icon-contracted:after {
      content: @fa-var-minus-square !important;
    }

    &.ag-icon-expanded:after {
      content: @fa-var-plus-square !important;
    }

    &.ag-icon-loading {
      animation: fa-spin 2s infinite linear;
    }

    &.ag-icon-loading:after {
      content: @fa-var-spinner !important;
    }
  }

  .ag-loading-icon {
    padding-left: 1px;
  }

  .ag-row {
    padding: 2px 5px;

    &.ag-row-hover {
      background-color: #F5F5F5;
    }

    &.ag-row-group-contracted, &.ag-row-group-expanded {
      &.ag-full-width-row {
        cursor: pointer;
      }
    }

    &.no-data-row{
      color:#ccc;
      .ag-icon{
        display: none;
      }
      .ag-group-value {
        padding-left: 8px;
      }
    }

    &.row-disabled {
      color:#ccc;
    }

    &.ag-row-level-1 {
      .ag-stub-cell {
        padding-left: 17px;
      }
    }

    &:not(.ag-row-group-contracted):not(.ag-row-group-expanded) {
      cursor: pointer;

      .ag-row-group-indent-1 {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        padding-left: 25px;
      }

      .ag-row-group-indent-2 {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        padding-left: 35px;
      }

      &.ag-row-level-0 {
        .ag-cell,
        .ag-stub-cell {
          padding-left: 7px;
        }
      }

      &.ag-row-level-1 {
        .ag-cell {
          padding-left: 22px;
        }
        .ag-stub-cell {
          padding-left: 17px;
        }
      }

      &.ag-row-level-2 {
        .ag-cell {
          padding-left: 32px;
        }
        .ag-stub-cell {
          padding-left: 27px;
        }
      }

      &.ag-row-selected {
        background: #F5F5F5;

        &:after {
          float: right;
          position: relative;
          height: 12px;
          width: 12px;
          content: '';
          background: url(../../images/new/tw-checked.png);
          background-repeat: no-repeat;
          top: 3px;
          right: -2px;
          margin-right: 10px;
          font-weight: bold;
        }
      }
    }

    &.autopilot-row:after {
      font-family: 'Font Awesome 5 Free';
      font-style: normal;
      font-size: 13px;
      content: "\f1d8";
      float: right;
      padding-right: 10px;
    }
  }

  .toggle-selector-box,
  .toggle-selector-box label{
    color: #888;
    font-size: 12px;
  }
}
