.importEntityModal {
  .paddingLeft70 {
    padding-left: 70px;
  }

  .statisticsCheckbox, .dateRangeFilter {
    float: left;
  }

  .dateRangeFilter {
    width: 40%;
    left: 10px;
    margin-top: -5px;
  }
}

.importCampaign {
  .importButton {
    margin-right: 10px;
  }
}

.modal.importCampaignModal {
  .modal-dialog {
    width: 840px;
  }

  .form label.caption {
    padding: 0;
  }

  .loadDeleted {
    label {
      margin-bottom: 10px;
    }
  }

  .value {
    position: relative;
    top: -5px;
  }

  .importStatisticsElement {
    margin-top: 20px;
  }

  .inputContainer input {
    .placeholder(@select-input-placeholder);
  }

  .twCondAlert {
    padding-top: 0;
  }

  .annotatedListSpacer {
    height: 360px;
  }

  .annotatedList.inset {
    height: 351px;
  }
  .annotatedList {
    height: 388px;

    li {
      border-bottom: 1px solid #eee;
      padding: 6px 0 6px 8px;
    }

    li:after {
      top: 12px;
    }


    .title {
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 18px 5px 0;
    }

    .subTitle{
      font-size: 10px;
    }

    .annotatedListSpacer::before {
      top: 52%;
    }

    &.annotatedListAvailable {
      height: 360px;
    }
  }

  .status-indicator {
    width: 3px;
  }

  .loading {
    position: absolute;
    top: 150px;
    z-index: 999;
    left: 200px;
    font-size: 16px;
  }

  .campaignListErrors {
    .alert.alert-info{
      margin-top: 10px;
      margin-bottom: 0;
    }

    .alert.alert-warning{
      margin-top: 20px;
      margin-bottom: 0;
    }

    .alert {
      ul {
        padding-left: 18px;
      }

      li {
        padding-top: 0;
        list-style: disc;
      }

      li:after {
        padding-top: 0;
      }
    }
  }

  .campaignsMarkup {
    .panel-heading {
      margin-left: 20px;
    }

    .panel:before {
      border: none;
    }

    .value {
      top: 0;
    }

    .campaignsMarkupInput {
      width: 154px;
    }
  }
}

.front-visible-modal > .modal {
  z-index: 10000;
}

.bulkAddSummary {
  ul, li {
    list-style: initial;
  }

  ul {
    padding-left: 30px;
  }
}
