.bootstrap-timepicker-widget table td input {
	width: 32px !important;
}
.bootstrap-timepicker .add-on i {
	height: auto !important;
	width: auto !important;
}
.datetimepicker-datepicker {
    width: 85px !important;
}
.datetimepicker-timepicker {
    margin-left: 5px;
    width: 55px !important;
}

.datetimepicker-timepicker-no-margin {
	margin-left: 0 !important;
}

.datetimepicker-wrap {
    display: inline-block;
    margin-right: 5px;
}
