
.panel-tools {
	position: absolute;
	right: 5px;
	top: 8px;
}
.panel-tools .btn-link {
	color: #333;
}
.panel-tools .btn-link:hover {
	color: #000000;
}
.panel-tools .btn-link:focus, .panel-tools .btn-link:hover, .panel-tools .btn-link:active {
	text-decoration: none;
	outline: 0 !important;
}
.panel-tools .btn.expand:before {
	content: "\f0d8";
}
.panel-tools .btn.collapses:before {
	content: "\f0d7";
}
.panel-tools .btn.expand:before,
.panel-tools .btn.collapses:before{
	display: inline;
	float: right;
	font-family: FontAwesome;
	font-weight: 300;
	height: auto;
	text-shadow: none;
	font-size: 20px;
}
.panel-tools .btn.expand,
.panel-tools .btn.collapses{
	padding: 0 5px;
	line-height: 1.2;
}
.panel-tools .btn {
	border-style: none;
}

/* ---------------------------------------------------------------------- */
/*	Panels and panel elements
/* ---------------------------------------------------------------------- */
.panel-heading {
	background-color: #F5F4F9;
	background-image: linear-gradient(to bottom, #F5F4F9 0%, #ECEAF3 100%);
	background-repeat: repeat-x;
	border-bottom: 1px solid #CDCDCD;
	border-radius: 6px 6px 0 0;
	box-shadow: 0 1px 0 #FFFFFF inset;
	height: 36px;
	padding-left: 40px;
	position: relative;
}
.panel-heading > [class^="fa-"], .panel-heading > [class*=" fa-"], .panel-heading > [class^="icon-"], .panel-heading > [class*=" icon-"], .panel-heading > [class^="clip-"], .panel-heading > [class*=" clip-"] {
	border-right: 1px solid #CDCDCD;
	box-shadow: 1px 0 0 0 #FFFFFF;
	opacity: 0.7;
	padding: 12px 10px 8px 10px;
	position: absolute;
	left: 0;
	top: 0;
	height: 36px;
}
.panel-white {
	box-shadow: none;
}
.panel-white .panel-heading {
	background-color: #ffffff;
	background-image: none;
	border-bottom: 1px solid #CDCDCD;
	border-radius: 6px 6px 0 0;
	box-shadow: none;
	height: 36px;
	padding-left: 40px;
	position: relative;
}
.panel-white .panel-heading > [class^="fa-"], .panel-white .panel-heading > [class*=" fa-"], .panel-white .panel-heading > [class^="icon-"], .panel-white .panel-heading > [class*=" icon-"], .panel-white .panel-heading > [class^="clip-"], .panel-white .panel-heading > [class*=" clip-"] {
	border-right: 1px solid #CDCDCD;
	box-shadow: none;
	opacity: 0.7;
	padding: 12px 10px 8px;
	position: absolute;
	left: 0;
	top: 0;
	height: 36px;
}
.panel-box {
	background: #FAFAFA;
	border: 1px solid #CDCDCD;
	border-radius: 6px 6px 6px 6px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	clear: both;
	margin-bottom: 16px;
	margin-top: 16px;
	position: relative;
}
.panel.panel-full-screen {
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9998;
}
.panel-scroll {
	position: relative;
	margin: 10px;
	padding: 0px;
	width: auto;
	height: 400px;
	overflow: hidden;
}