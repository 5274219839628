/* ---------------------------------------------------------------------- */
/*	Login
/* ---------------------------------------------------------------------- */
body.login {
	color: #7F7F7F;
	//background-image: url("../images/bg_2.png");
	background-color: #f5f5f5;
	.main-login {
		margin-top: 60px;
	}
	.logo {
		padding: 30px 0;
		text-align: center;
		
		img {
			max-width: 50%;
		}
	}
	.box-login, 
	.box-forgot, 
	.box-register {
		background: #FFFFFF;
		//border-radius: 5px;
		//box-shadow: -30px 30px 50px rgba(0, 0, 0, 0.32);
		overflow: hidden;
		padding: 10px 30px 30px;
	}

	.box-forgot, 
	.box-register {
		display: none;
	}
	.form fieldset {
		border: none;
		margin: 0;
		padding: 10px 0 0;
	}
	a.forgot {
		color: #909090;
		font-size: 12px;
		position: absolute;
		right: 10px;
		text-shadow: 1px 1px 1px #FFFFFF;
		top: 9px;
	}
	input.password {
		padding-right: 130px;
	}
	label {
		color: #7F7F7F;
		font-size: 14px;
		margin-top: 5px;
	}

	.copyright {
		font-size: 11px;
		margin: 0 auto;
		padding: 10px 0;
		text-align: center;
	}
	.form-actions:before, 
	.form-actions:after {
		content: "";
		display: table;
		line-height: 0;
	}
	.form-actions:after {
		clear: both;
	}
	.form-actions {
		margin-top: 15px;
		padding-top: 10px;
		display: block;
	}
	.new-account {
		border-top: 1px dotted #EEEEEE;
		margin-top: 15px;
		padding-top: 10px;
		display: block;
	}
	.language-switch {
		margin-top:20px;
		.dropdown-menu{
			text-align: left!important;
		}
	}
	.usernameWrapper{ 
		padding-left: 7px;
		color:#909090;
		.usernameLabel{
			padding-left: 9px;
			font-weight: bold;
		}
	}

  .terms-agree-box {
    .form-group {
      margin: 15px 0;
    }
    .alert-warning .form-group,
	.alert-warning .form-group label {
      color: #8a6d3b;
	  font-size: 12px;
    }
  }

}

.toggle_register{
	margin: 20px 0;
	display: block;
}