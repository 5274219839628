.details-screen .ag-bootstrap .ag-body {
  background-color: transparent;
}

.details-screen .ag-bootstrap .ag-body,
.details-screen .ag-bootstrap .ag-pinned-left-cols-viewport,
.details-screen .ag-bootstrap .ag-header {
  border-bottom: none;
}

.details-screen {
  .bulk-copy-alert {
    margin-bottom: 0;
    max-height: 500px;
    overflow-y: auto;
  }

  .bulk-copy-uncopyable {
    padding-left: 10px;
  }

  .list-entity-menu {
    z-index: 3;
  }

  .ag-cell:hover .grid-preview-image,
  .ag-cell:hover .grid-preview-text,
  .ag-cell:hover .textValue,
  .ag-cell:hover .twOpenAdPreview {
    opacity: 0.5;
  }

  .ag-cell:hover .twOpenAdPreview.narrower {
    top: 3px;
  }

  .video-preview:before {
    content: '\f03d';
    font-family: 'Font Awesome 5 Free';
    display: block;
    position: absolute;
    z-index: 1;
    color: #707070;
    top: 15px;
    left: calc(50% - 15px);
    font-size: 30px;
    text-shadow: 0 0 3px white;
  }

  .adContainer {
    line-height: 20px !important;
    padding-left: 0 !important;
  }

  .ad-name-bar {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
  }

  .grid-preview-image {
    height: 40px;
    margin-top: -7px;
    width: 90%;
    margin-right: 10px;
    margin-left: 15px;
    position: relative;
    cursor: pointer;

    .image-div-preview {
      background-size: contain;
      background-repeat: no-repeat;
      width: 62px;
      left: 0;
      height: 100%;
      background-position: left;
      max-width: 62px;
    }

    .ad-preview-icon {
      text-align: left;
    }

    div {
      left: 74px;
      position: absolute;
      bottom: 0;
      right: 0;
      line-height: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 11px;
      text-align: left;
      white-space: normal;
      top: 0;
      height: 24px;
    }

    .grid-card-preview-text {
      top: 2px;
    }

    .grid-preview-status {
      line-height: 16px;
      top: 26px;
      height: 16px;

      i {
        font-size: 13px;
        color: #707070;
      }
    }

    .grid-card-btn {
      border-radius: 100px;
      border: 1px solid #aaa;
      white-space: nowrap;
      color: #aaa;
      line-height: 10px;
      padding: 0 5px;
      background: white;
      font-size: 10px;
      text-transform: capitalize;
    }

    .card-icon {
      padding: 0 2px;
    }
  }

  .grid-preview-text {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -7px;
    width: 90%;
    margin-right: 10px;
    margin-left: 20px;
    border-radius: 3px;
    background-position: center;
    text-align: left;
    white-space: normal;
    text-overflow: ellipsis;
    line-height: 13px;
    font-size: 11px;
    cursor: pointer;
    height: 40px;
    overflow: hidden;

    .grid-preview-status {
      line-height: 14px;
      top: 26px;
      height: 14px;
    }
  }

  .entityNameContainer .fa-paper-plane,
  .entityNameContainer .fa-warning {
    font-size: 11px;
  }

  .ag-bootstrap .ag-row-even .ag-cell.ag-cell-range-selected.ag-cell-focus:not(.select-checkbox):not(.select-all-checkbox) {
    background: #fff !important;
  }

  .ag-bootstrap .ag-row-odd .ag-cell.ag-cell-range-selected.ag-cell-focus:not(.select-checkbox):not(.select-all-checkbox) {
    background: #f4f7f9 !important;
  }

  .ag-bootstrap .ag-row-even .ag-cell.ag-cell-range-selected {
    background-color: #e5f6fe;
  }

  .ag-bootstrap .ag-row-odd .ag-cell.ag-cell-range-selected {
    background-color: #e5f6fe;
  }

  .ag-bootstrap .select-checkbox {
    border: none !important;
  }

  .ag-bootstrap .ag-row-odd .ag-cell.ag-cell-focus .text-left-middle-grid .dropdown,
  .ag-bootstrap .ag-row-even .ag-cell.ag-cell-focus .text-left-middle-grid .dropdown {
    border-right: 1px solid #a0d0e8;
  }

  .ag-bootstrap .ag-row-odd .ag-cell.ag-cell-focus:not(.select-all-checkbox):not(.select-checkbox),
  .ag-bootstrap .ag-row-even .ag-cell.ag-cell-focus:not(.select-all-checkbox):not(.select-checkbox) {
    outline: 1px solid #a0d0e8;
    outline-offset: -1px;
    border-top: 0;
    border-left: 0;
  }

  .ag-bootstrap .ag-header-viewport .ag-header-group-cell.ag-header-group-cell-with-group {
    border-left: none;
  }

  .ag-bootstrap .ag-ltr .ag-selection-checkbox,
  .ag-bootstrap .ag-header-select-all {
    padding-right: 19px;
  }

  .ag-bl-overlay {
    background: transparent;
  }

  .ag-bootstrap .ag-overlay-loading-wrapper {
    background: transparent;
  }

  .ag-menu-option {
    cursor: pointer;
  }

  *:not(.ad-name-bar) .open-error {
    padding-left: 5px;
  }

  .open-error {
    cursor: pointer;
    color: #707070;
  }
}

.modal-title .capitalize {
  display: inline-block;
}

.modal-title .capitalize::first-letter {
  text-transform: uppercase;
}

.details-screen .ag-bootstrap.details-page-twitter .ag-header-cell:not(.select-all-checkbox) {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.details-screen .ag-bootstrap .ag-header-cell-text .displayName {
  padding-left: 10px;

  .toggle-show-segmented {
    margin-top: -5px;

    &:hover {
      color: black;
    }
  }
}

.details-screen .ag-layout-normal.ag-root {
  overflow: visible;

  .ag-selection-checkbox {
    height: 100%;
    width: 100%;
  }

  .ag-icon.ag-icon-checkbox-indeterminate {
    padding: 15px 16px 0 15px;
  }
}

.ag-input-field-input.ag-checkbox-input[type="checkbox"] {
  cursor: pointer;
}

.details-screen .ag-layout-normal .ag-body-container {
  margin-bottom: -15px;
}

.details-screen .ag-icon-checkbox-unchecked {
  background-image: url('../../images/new/checkbox-unchecked-neutral.png');
}

.details-screen .ag-icon-checkbox-checked {
  background-image: url('../../images/new/checkbox-checked.png');
}

.details-screen .ag-icon-checkbox-indeterminate {
  background-image: url('../../images/new/checkbox-checked.png');
}

.details-screen .ag-bootstrap .ag-header-cell-label .ag-header-cell-text,
.details-screen .ag-bootstrap .ag-ltr .ag-header-group-text {
  font-weight: normal;
  text-transform: initial;
}

.details-screen .ag-bootstrap .ag-row-odd,
.details-screen .ag-bootstrap .ag-row-even {
  background: transparent;
}

.details-screen #dropdown-multiselect-preset-multiselect b {
  font-size: 11px;
}

.bulk-edit-bid .Select-arrow-zone {
  position: absolute;
  right: 0;
  top: 3px;
}

.details-screen .ag-bootstrap.isAdplatformAndReadOnly {
  margin-left: 0;
}

.bulk-copy-ads-alert {
  margin-bottom: 0;
  max-height: 500px;
  overflow-y: auto;

  ul>li {
    list-style-type: circle;
  }
}

.ads-copy-fail {
  margin-top: 10px;
}

.ads-copy-success {
  margin-top: 10px;
}

.details-screen .ag-bootstrap .ag-body .ag-body-viewport.ag-layout-normal {
  position: relative;
}

.options-toggler {
  cursor: pointer;
}

.textValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-scrollable {
  position: relative;
  overflow-y: auto;
  max-height: ~"calc(60vh - 200px)";
}