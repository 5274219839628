@font-face {
  font-family: 'AdPlatform';
  src: url('@{icon-font-path}/adplatform.eot?ykwoy2');
  src: url('@{icon-font-path}/adplatform.eot?#iefixykwoy2') format('embedded-opentype'), url('@{icon-font-path}/adplatform.woff?ykwoy2') format('woff'), url('@{icon-font-path}/adplatform.ttf?ykwoy2') format('truetype'), url('@{icon-font-path}/adplatform.svg?ykwoy2#adplatform') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-custom {
	font-family: 'AdPlatform' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-carousel:before {
  content: "\e8eb";
}
.icon-minus:before {
  content: "\e15b";
}
.icon-arrow_down:before {
  content: "\e5c5";
}
.icon-arrow-up:before {
  content: "\e5c7";
}
.icon-times-circle:before {
	content: "\f057";
}
.icon-check-circle2:before {
	content: "\f058";
}
.icon-adplatform_appnexus:before {
	content: "\e600";
}
.icon-adplatform_mobile:before {
	content: "\e601";
}
.icon-adplatform:before {
	content: "\e602";
}
.icon-facebook:before {
	content: "\e603";
}
.icon-instagram:before {
  content: "\e900";
}
.icon-pause-circle-fill:before {
	content: "\e604";
}
.icon-play-circle-fill:before {
	content: "\e605";
}
.icon-equalizer:before {
	content: "\e606";
}
.icon-adplatform_custom:before{
	content: "\e607";
}
.icon-twitter:before {
  content: "\ea91";
}
.icon-snapchat:before {
	content: "\e900";
}
.icon-linkedin:before {
	content: "\e901";
}
.icon-grip-vertical-solid:before {
	content: "\e903";
}
.icon-filter-plus:before {
	content: "\e904";
}
.android-head:before{
	content: "\e905";
}
