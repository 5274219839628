.preset-dropdown-checkbox {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  margin-right: 10px;
  width: 5%;
}

.preset-dropdown-empty {
  width: 25px;
  display: inline-block;
  line-height: 20px;
}

.preset-dropdown-cross {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  color: #c0c0c0;
  margin-left: 10px;
  text-align: right;
  width: 5%;
}

.disabled {
  .preset-dropdown-cross {
    cursor: pointer;
  }
}

.preset-dropdown-item {
  vertical-align: middle;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}

#preset-group-ul > li > a {
  display: flex;
}
