.scrollable-dropdown
{
   overflow-x: hidden;
   overflow-y: auto;
   max-height: 400px;
}

.dropdown-menu.scrollable-dropdown {
  top: unset;
}

.scrollable-dropdown::-webkit-scrollbar-track
{
  border-radius: 10px;
}

.scrollable-dropdown::-webkit-scrollbar
{
  width: 5px;
  background-color: transparent;
  padding-right: 30px;
}

.scrollable-dropdown::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: rgb(207, 207, 207);
}
