.grid-loader {
  position: relative;
}

.grid-loader .grid-loader {
  position: absolute;
  z-index: 10;
}

.top-1-5em .grid-loader {
  top: -1.5em;
}

.centered-element {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}
