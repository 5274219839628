@import "fixed-data-table/cells";
@import "fixed-data-table/collapsable";
@import "fixed-data-table/scrollable";

.twGridContainer {
  padding-top: 30px;
}

.condenseGrids .twGridContainer {
  padding-top: 0;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
  border-left: none !important;
  border-right: none !important;
}

.public_fixedDataTableCell_main > div {
  width: 100%;
  height: 100%;
}
.public_fixedDataTableCell_highlighted > div {
  background-color: #f4f4f4;
}

.fixedDataTableLayout_footer p {
  margin-bottom: 5px;
}

.public_fixedDataTableCell_columnResizerKnob {
  background-color: #0284ff;
}

.public_fixedDataTableColumnResizerLine_main {
  border-color: #0284ff;
}

.public_fixedDataTableRow_main {
  background-color: transparent;
}

.public_fixedDataTableRow_main:hover .public_fixedDataTableCell_main {
  background-color: transparent;
}

.public_fixedDataTableRow_main:hover .fdt-cell:not(.fdt-cellTransparent) {
  background-color: #f4f4f4;
}

.fixedDataTableLayout_footer:hover .fdt-cell:not(.fdt-cellTransparent) {
  background-color: #f2f2f2;
}

.public_fixedDataTableRow_main:hover .headerCell .fdt-cell {
  background-color: transparent;
}

.public_fixedDataTableRow_main:hover .twPreviewCell {
  background-color: #fff;
}

.public_fixedDataTableRow_fixedColumnsDivider {
  border-color: #d3d3d3;
}

.fixedDataTableCellGroupLayout_cellGroup {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  white-space: nowrap;
}

.fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.fixedDataTableCellGroupLayout_cellGroupWrapper {
  position: absolute;
  top: 0;
}

.fixedDataTableCellLayout_main {
  border-right-style: solid;
  border-width: 0;
  border-color: #DDDDDD;
  box-sizing: border-box;
  display: block;
  position: absolute;
  overflow: hidden;
  white-space: normal;
}

.fixedDataTableCellLayout_lastChild {
  border-width: 0 0 1px 0;
}

.fixedDataTableCellLayout_alignRight {
  text-align: right;
}

.fixedDataTableCellLayout_alignLeft,
.public_fixedDataTableCell_cellContent.fixedDataTableCellLayout_alignLeft {
  text-align: left;
}

.fixedDataTableCellLayout_alignCenter {
  text-align: center;
}

.fixedDataTableCellLayout_wrap1 {
  display: table;
  width: 100%;
}

.fixedDataTableCellLayout_wrap2 {
  display: table-row;
}

.fixedDataTableCellLayout_wrap3 {
  display: table-cell;
  vertical-align: middle;
}

.ScrollbarLayout_main {
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  position: absolute;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ScrollbarLayout_mainVertical {
  bottom: 0;
  right: 0;
  top: 0;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  width: 7px;
}

.ScrollbarLayout_mainHorizontal {
  bottom: 0;
  height: 7px;
  left: 0;
  top:0;
  -webkit-transition-property: background-color height;
  transition-property: background-color height;
}

.ScrollbarLayout_face {
  left: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  border-radius: 0;
  background-color: rgb(204, 204, 204);
}

.ScrollbarLayout_face:after {
  border-radius: 0;
  content: '';
  display: block;
  position: absolute;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
}

.ScrollbarLayout_faceHorizontal {
  bottom: 0;
  left: 0;
  top: 0;
}

.ScrollbarLayout_faceHorizontal:after {
  bottom: 4px;
  left: 0;
  top: 4px;
  width: 100%;
}

.ScrollbarLayout_faceVertical {
  left: 0;
  right: 0;
  top: 0;
}

.ScrollbarLayout_faceVertical:after {
  height: 100%;
  left: 4px;
  right: 4px;
  top: 0;
}

.fixedDataTableCellLayout_columnResizerContainer {
  position: absolute;
  right: 0;
  width: 6px;
  z-index: 1;
}

.fixedDataTableCellLayout_columnResizerContainer:hover {
  cursor: ew-resize;
}

.fixedDataTableCellLayout_columnResizerContainer:hover .fixedDataTableCellLayout_columnResizerKnob {
  visibility: visible;
}

.fixedDataTableCellLayout_columnResizerKnob {
  position: absolute;
  right: 0;
  visibility: hidden;
  width: 4px;
}

.fixedDataTableColumnResizerLineLayout_mouseArea {
  cursor: ew-resize;
  position: absolute;
  right: -5px;
  width: 12px;
}

.fixedDataTableColumnResizerLineLayout_main {
  position: absolute;
  z-index: 10;
}

body[dir="rtl"] .fixedDataTableColumnResizerLineLayout_main {
  /* the resizer line is in the wrong position in RTL with no easy fix.
   * Disabling is more useful than displaying it.
   * #167 (github) should look into this and come up with a permanent fix.
   */
  display: none !important;
}

.fixedDataTableColumnResizerLineLayout_hiddenElem {
  display: none !important;
}

.fixedDataTableLayout_main {
  overflow: hidden;
  position: relative;
  z-index: 9;
}

.fixedDataTableLayout_topShadow,
.fixedDataTableLayout_bottomShadow {
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

.fixedDataTableLayout_bottomShadow {
  margin-top: -4px;
}

.fixedDataTableLayout_rowsContainer {
  overflow: hidden;
  position: relative;
}

.fixedDataTableLayout_horizontalScrollbar {
  bottom: 0;
  position: absolute;
}

.fixedDataTableRowLayout_main {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.fixedDataTableRowLayout_body {
  left: 0;
  position: absolute;
  top: 0;
}

.fixedDataTableRowLayout_fixedColumnsDivider {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.fixedDataTableRowLayout_columnsShadow {
  width: 4px;
  margin-left: -1px;
}

.public_fixedDataTable_header .public_fixedDataTableRow_columnsShadow {
  margin-top: 35px;
}

.public_fixedDataTable_footer .public_fixedDataTableRow_columnsShadow {
  display: none;
}

.fixedDataTableRowLayout_rowWrapper {
  position: absolute;
  top: 0;
}

.twGridNoBg {
  background-color: transparent;
}

.public_fixedDataTable_footer .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
  border-bottom: none;
}

.twGridMenuCell {
  border-bottom: 1px solid #DDDDDD;
}

.fixedColumnNameHeader {
  text-align: left;
  padding-left: 0;
}
.fixedColumnNameHeader .public_fixedDataTableCell_cellContent {
  text-align: left;
}

.fixedColumnSortingHeader {
  text-align: right;
}

.fixedColumnHeader {
  text-align: right;
}

.twLoader.loader-campaignListStats {
  font-size: 1.5em;
  position: absolute;
  margin-left: 10%;
  z-index: 10;
}

.text-center .twLoader.loader-campaignListStats {
  margin-left: inherit;
}

.twCampaignListIcon {
  width: 10%;
  margin-top: 4px;
}

.twMenuAnchor {
  position: relative;
}

.twPopoverFix {
  margin-left: -8px;
}

.twCampaignIcon {
  padding-left: 5px;
  color: #B7B7B7;
}

.autoBidEnabled {
  opacity: 40%;
}

.twCampaignListWarning .alert {
  margin-bottom: 0;
}

.twColHelpCursor {
  cursor: help;
}

.twCommonHeaderWrap {
  padding-right: 8px;
}

.totalSum {
  margin-bottom: 20px !important;
}
