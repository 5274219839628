textarea, textarea.form-control, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
	background-color: #FFFFFF;
	border: 1px solid #D5D5D5;
	border-radius: 0 0 0 0 !important;
	color: #858585;
	font-family: inherit;
	font-size: 12px;
	line-height: 1.2;
	padding: 5px 8px;
	transition-duration: 0;
	box-shadow: none;
}
textarea:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="time"]:hover, input[type="week"]:hover, input[type="number"]:hover, input[type="email"]:hover, input[type="url"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="color"]:hover {
	border-color: #B5B5B5;
}
textarea:focus, input.value:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
	border-color: rgba(82, 168, 236, 0.8);
	box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
	outline: 0 none;
}
label {
	font-weight: normal;
}
span.input-icon, span.input-help {
	display: block;
	position: relative;
}
.input-icon > input {
	padding-left: 25px;
	padding-right: 6px;
}
.input-icon.input-icon-right > input {
	padding-left: 6px;
	padding-right: 25px;
}
span.input-help > input {
	padding-left: 30px;
	padding-right: 6px;
}
.input-icon > [class*="fa-"], .input-icon > [class*="clip-"] {
	bottom: 0;
	color: #909090;
	display: inline-block;
	font-size: 14px;
	left: 5px;
	line-height: 35px;
	padding: 0 3px;
	position: absolute;
	top: 0;
	z-index: 2;
}

.input-icon.input-icon-right > [class*="fa-"], .input-icon.input-icon-right > [class*="clip-"] {
	left: auto;
	right: 4px;
}
.input-icon > input:focus + [class*="fa-"], .input-icon > input:focus + [class*="clip-"] {
	color: #557799;
}
.help-button {
	background-color: #65BCDA;
	border-radius: 100% 100% 100% 100%;
	color: #FFFFFF;
	cursor: default;
	position: absolute;
	font-size: 14px;
	font-weight: bold;
	height: 20px;
	padding: 0;
	text-align: center;
	width: 20px;
	line-height: 20px;
	top: 7px;
	left: 7px;
}
.help-button:before {
	content: "\f128";
	display: inline;
	font-family: 'Font Awesome 5 Free';
	font-weight: 300;
	height: auto;
	text-shadow: none;
	font-style: normal;
}
select.form-control {
	background-color: #FFFFFF;
	border: 1px solid #D5D5D5;
	border-radius: 0 0 0 0;
	color: #858585;
}
select.form-control option {
	padding: 3px 4px;
}
.form-control.search-select {
	padding: 0 !important;
	box-shadow: none;
	border: none;
}

.limiterBox {
	border-top: 0;
	background-color: #65BCDA !important;
	padding: 3px 6px;
	font-size: 12px;
	color: #FFF;
	margin-top: 6px
}
.limiterBox:after {
	display: none
}
.limiterBox:before {
	display: block;
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	top: -8px;
	left: 50%;
	margin-left: -5px;
	border-color: transparent;
	border-style: solid;
	border-bottom-color: #65BCDA;
	border-width: 0 8px 8px
}
textarea.autosize {
	vertical-align: top;
	transition: height 0.2s;
	-webkit-transition: height 0.2s;
	-moz-transition: height 0.2s;
}

.radio label.radio-inline, .checkbox label.checkbox-inline {
	display: inline-block;
}

.radio-inline, .radio-inline + .radio-inline, .checkbox-inline, .checkbox-inline + .checkbox-inline {
	margin-right: 10px !important;
	margin-top: 5px !important;
	margin-left: 0 !important;
	margin-bottom: 10px !important;
}
.radio label, .checkbox label {
	margin-bottom: 5px;
}
.checkbox-table {
	display: inline-block;
	margin: 2px 0 0 0;
	padding-left: 20px;
	line-height: 10px;
}
.checkbox-table label {
	margin-bottom: 0;
}
[class^="icheckbox_"], [class*="icheckbox_"], [class^="iradio_"], [class*="iradio_"] {
	float: left !important;
	margin: 0 5px 0 -20px !important;
}
.help-inline {
	margin-top: 6px;
	color: #737373;
}

.help-block.error a,
.help-block.error {
	color: #B94A48;
}
.help-block.error a {
	text-decoration: underline;
}

.symbol.required:before {
	content: "*";
	display: inline;
	color: #E6674A;
}
.symbol.ok:before {
	content: "\f00c";
	display: inline;
	font-family: 'Font Awesome 5 Free';
	color: #468847;
}
.has-error .note-editor, .has-error .cke_chrome {
	border-color: #B94A48 !important;
}
.form-group .text {
	display: block;
	font-size: 12px;
	font-weight: 400;
	margin-top: 7px;
}
