#menu-notice-popover {
	top: 35px;
	left: 4px;
	width: 250px;
	color: #777;
}

body.adplatform-style {
	.menu-create {
		.dropdown-menu {
			margin-bottom: 10px !important;
		}
	}
}

.menu-create {
	.btn {
		margin-top: 10px;
		margin-right: 10px;
	}
	.btn-xs {
		padding: 2px 7px;
		margin-top: 15px;
	}
	&.on-channel {
		margin-left: 10px;
		.btn {
			margin-top: -3px;
		}
	}
	.btn {
		.title {
			text-transform: uppercase;
			float: right;
			font-size: 11px;
			padding: 1px 0 0 3px;
		}
		.fa {
			font-size: 12px;
			padding-top: 2px;
		}
	}


}

.btn {
  .title {
    padding-left: 7px;
  }
}
