.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.inline-table {
  display: inline-table;
}

.display-none {
  display: none;
}

.block {
  display: block;
}

.display-flex {
  display: flex;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none !important;
}

.clear-both {
  clear: both;
}

.vmid {
  vertical-align: middle;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.no-border {
  border-width: 0;
}

.no-border-l {
  border-left-width: 0;
}

.no-border-r {
  border-right-width: 0;
}

.no-border-l-r {
  border-left-width: 0;
  border-right-width: 0;
}

.underline {
  text-decoration: underline;
}

.absolute-center {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.vertical-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.align-items-center {
  align-items: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

.list-circle li, ul.list-circle li {
  list-style: disc;
  margin-left: 25px;
}

.pointer-events-none {
  pointer-events: none;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-left-3 {
  margin-left: 3px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.disabled {
  cursor: not-allowed !important;
  color: @input-border-color !important;
}

.dark-grey-font {
  color: #666;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-0 {
  padding: 0 !important;
}

.border-radius-15 {
  border-radius: 15px;
}

.z-index-1000 {
  z-index: 1000;
}

.error-border {
  border: 0.5px solid #E54028 !important;
}

.right-0 {
  right: 0 !important;
}

.z-index-1 {
  z-index: 1;
}