// Side Menu footer
.sidemenu-footer {
  position: absolute;
  bottom: 10px;
  padding: 20px;
  width: 100%;
  height: auto;

  .list-item {
    display: none;
  }

  .language-changer {
    width: 100%;

    .dropdown-menu.open {
      margin-bottom: 8px;
    }

    .sel_lng {
      margin-bottom: 9px;
      color: white;
    }

    .btn-group.bootstrap-select:not([class*=span]):not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: 100%;
    }

    .dropdown-menu.open {
      width: initial;
    }
  }

  .sidemenu-separator {
    border-color: #d5d5d5;
  }

  .sidemenu-info {
    color: #ffffff;
    margin-top: 10px;
    font-size: 12px;

    .sidemenu-phone {
      margin-top: 2px;
    }

    .sidemenu-email {
      padding: 0;

      a {
        padding: 0;
        color: #fff;
      }
    }
  }
}
