body {
	color: @text-color;
	direction: ltr;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	padding: 0;
	margin: 0;
	background: #ffffff;
	font-weight: 400;
}
body.bg_style_1 {
	background: url("../images/bg.png") rgba(0, 0, 0, 0) !important;
}
body.bg_style_2 {
	background: url("../images/bg_2.png") rgba(0, 0, 0, 0) !important;
}
body.bg_style_3 {
	background: url("../images/bg_3.png") rgba(0, 0, 0, 0) !important;
}
body.bg_style_4 {
	background: url("../images/bg_4.png") rgba(0, 0, 0, 0) !important;
}
body.bg_style_5 {
	background: url("../images/bg_5.png") rgba(0, 0, 0, 0) !important;
}
a, a:focus, a:hover, a:active {
	outline: 0 !important;
}
h1, h2, h3, h4 {
	font-family: 'Raleway', sans-serif;
}