.ondemand-box {
	background: #f1f1f1;
	padding: 5px 15px;
	margin-right: 15px;
	position: relative;
	overflow: visible;
	margin-top: -5px;

	&.isHidden {
		visibility: hidden;
		display: none;
	}
}
.ondemand-box:before {
	position: absolute;
	top: -10px;
	left: 20px;
	display: inline-block;
	border-right: 15px solid transparent;
	border-bottom: 10px solid #f1f1f1;
	border-left: 15px solid transparent;
	content: ' ';
}
.ondemand-box > .summary-col {
	padding-left: 25px;
	padding-right: 0;
}
.ondemand-box > .summary-col:first-child {
	padding-left: 0;
}
.no-arrow.ondemand-box:before {
  border-bottom: none;
}

.devicesAdvanced {
	color: #1997f0;
	font-size: 12px;

	span {
		cursor: pointer;

		i {
			padding-left: 5px;
		}
	}
}

.less.devices {
	margin-top: 12px;
}
