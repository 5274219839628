.react-select-wrapper {
  min-width: 100px;
}

.react-select__menu {
  // z-index of react-select-menu must be higher than z-index of react-select-control
  z-index: 991 !important; // at group edit language the menu opens behind the buttons
  color: #858585;
  font-size: 12px;
}

.react-select__option {
  cursor: pointer !important;
}

.react-select__control--is-focused {
  border-color: hsl(0,0%,80%) !important;
  box-shadow: none !important;
}

.react-select-wrapper, #geo-targeting, .limit-max.adjustedItem.languageSelector, .multi-scroll-select {
  .react-select__menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  .react-select__menu::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  .react-select__menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #D62929;
  }

  .react-select__multi-value__remove {
    &:hover {
      &[class^="css-"] {
        background-color: #3cbf6b;
        color: black;
      }
      cursor: pointer;
    }
    color: white;
    background-color: #3cbf6b;
  }

  .react-select__multi-value__label {
    background-color: #3cbf6b;
    color: white;
  }

  .react-select__multi-value {
    background-color: #3cbf6b;
  }

  .react-select__control {
    color: #858585 !important;
    font-size: 12px;
    min-width: 100px;
    min-height: 34px;
    height: auto;
    z-index: 990;

    .kpi-type & {
      height: 32px;
      margin-right: 10px;
    }
  }

  .react-select__input {
    color: #858585;
    input {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .react-select__multi-value {
    border: none;
    border-radius: 3px;

    .react-select__multi-value__label {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }

    .react-select__multi-value__remove {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  .Select-group.show-arrow {
    .react-select__option--is-disabled {
      font-weight: bold;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}


.react-select__clear-indicator {
  cursor: pointer;
}

.react-select-wrapper .react-select__option--is-selected {
  color: #fff !important;
}

.react-select-wrapper .react-select__single-value {
  color: #858585 !important;
  font-size: 12px;
}

.react-select-wrapper .react-select {
  margin-right: 10px;
}

.react-select-wrapper .react-select__single-value {
  max-height: 32px;
}

.custom-derived-metrics .react-select-wrapper {
  min-width: initial;
  margin-top: -3px;
}

.rule-operator .react-select-wrapper,
.rule-condition-entity .react-select-wrapper,
.followers-targeting .react-select-wrapper,
.report-filter-by .react-select-wrapper,
.website-tag .react-select-wrapper,
#geo-targeting .react-select-wrapper,
.precise-devices-specific-device-select .react-select-wrapper,
#test-rule-actions .react-select-wrapper {
  width: 100%;
  min-width: initial;
}

.test-rule-actions .react-select-wrapper {
  height: 34px;
}

.group-edit-keywords .react-select-wrapper .react-select__menu {
  opacity: 0;
}

.valueFilter .react-select {
  max-width: 680px !important;
}

.bid .react-select__control {
  width: 250px !important;
}

.custom-charts-dropdown-container .group-select .react-select__control {
  width: 128px !important;
  border-width: 0 !important;
}

.group-select-remove-container .react-select__control {
  border-width: 0 !important;
}

.custom-charts-dropdown-container .group-select-remove-container {
  max-width: 155px;
}

.custom-charts-dropdown-container .group-select {
  max-width: 128px;
}

.custom-charts-dropdown-container .react-select-wrapper {
  min-width: initial;
  max-width: 128px;
}

.status-select .react-select__control {
  height: 32px;
  font-size: 13px;
  line-height: 1.4;
  margin-top: 0
};

.filter-values {
  .react-select {
    width: 700px;

    .react-select__multi-value {
      border: none;
      border-radius: 3px;

      .react-select__multi-value__label {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }

      .react-select__multi-value__remove {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }
    }
  }
}

.report-filter-by {
  .react-select {
    width: 600px !important;

    &__control {
      height: 38px;
    }

    .react-select__multi-value {
      border: none;
      border-radius: 3px;

      .react-select__multi-value__label {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }

      .react-select__multi-value__remove {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }
    }
  }
}


.adplatform-style {

  .react-select-wrapper {
    .react-select__control {
      min-height: unset;
      height: 27px !important;
      .react-select__value-container, .react-select__indicators {
        position: unset;
        height: 27px;
      }
    }

    width: 360px !important;

  }


  .kpis {
    .react-select__control {
      min-height: unset;
      height: 27px !important;
      margin-right: 10px;
    }
  }

  .bid {
    .react-select__control {
      width: unset !important;
    }
  }
}

.twitter-style {
  .action-amount-type .react-select__control {
    width: 32px;
  }

  .react-select__control  {
    min-height: initial;
  }

  .react-select-wrapper .react-select__value-container {
    max-height: 77px;
    overflow-y: auto;
    overflow-x: hidden;
    .kpi-type & {
      height: inherit;
    }
  }

  .kpi-type .react-select-wrapper {
    width: 180px;
    .react-select__control {
      height: 34px;
    }

    .react-select__indicator {
      padding: 6px;
    }
  }
}

.promotableObject .react-select__control {
  width: 250px !important;
}

.tweetBody .react-select__control {
  max-width: 420px;
}

.report-dimensions .react-select-wrapper {
  width: 870px;
}

.dashboard-page-tw {
  .react-select-wrapper {
    .react-select__control--is-focused {
      box-shadow: unset !important;
    }
  }
}

.action-overlay-changelog .react-select__control {
  height: 27px;
}


.add-custom-metric-modal .react-select-wrapper {
  display: inline-block;
  .react-select__control {
    height: 29px;
  }
}

.bulk-edit-bid input.value {
  line-height: 22px !important;
}

.react-select-wrapper input:focus {
  box-shadow: initial;
}

.lookback-units {
  .react-select__control {
    width: 380px;
  }

  .react-select-wrapper {
    display: inline-block;
  }
}

.react-select-bulk-edit {
  width: 220px;

  .react-select__dropdown-indicator {
    padding: 6px;
  }

  .react-select {
    margin-right: 0;
  }
}

.report-filter-by .react-select__control {
  height: unset;
}

.filter-dropdown-container{
  .react-select-wrapper{
    .react-select__menu{
      .react-select__menu-list {
        max-height: 160px !important;
      }
    }
  }
}
