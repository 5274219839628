
/*******************
  DROP DOWN SUBMENU
********************/
@import "../fonts/font-awesome/_mixins.less";

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-submenu > a {
  padding-right: 36px;
}

.dropdown-submenu:not(.no-submenu)>a:after {
  .fa-icon();
  content: "\f105";
  float: right;
  margin-top: 2px;
  margin-right: -17px;
}