.urls_conversion_pixel {
	font-size: 10px;
	cursor: pointer;
}

.url_pixel  {
	cursor: pointer;
}

.conversion_pixels_box {
	width: 440px;
	height: 192px;
}

.error_name_conversion_pixel {
	color: #E54028;
	font-size: 11px;
	padding-top: 4px;
}

.conversion_pixel:hover {
	background: #F5F5F5;
}

.conversion_pixel_buttons {
	margin-top: -5px;
}
