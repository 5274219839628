.inputs {
  display: inline-table;
}

.input-row {
  margin-bottom: 10px;
}

.input-row-error {
  margin-left: 10px;
}
