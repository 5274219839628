.footer {
	font-size: 12px;
	padding: 8px 20px 5px;
	position: relative;
}
.footer-inner {
	float: left;
}
.footer-items {
	float: right;
}
.footer-fixed .footer {
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	z-index: 1000;
	border-top-width: 1px;
	border-top-style: solid;
}