.badge-primary {
	background-color: @dodgerBlue;
}
.badge-success {
	background-color: #5CB85C;
}
.badge-success[href]:hover, .badge-success[href]:focus {
	background-color: #449D44;
}
.badge-warning {
	background-color: #F0AD4E;
}
.badge-warning[href]:hover, .badge-warning[href]:focus {
	background-color: #EC971F;
}
.badge-info {
	background-color: #5BC0DE;
}
.badge-info[href]:hover, .badge-info[href]:focus {
	background-color: #31B0D5;
}
.badge-danger {
	background-color: #D9534F;
}
.badge-danger[href]:hover, .badge-danger[href]:focus {
	background-color: #C9302C;
}
.badge-new {
    background-color: #007AFF;
    border-radius: 12px 12px 12px 12px ;
    font-size: 11px ;
    font-weight: 300;
    height: 18px;
    padding: 3px 6px;
    text-align: center;
    text-shadow: none ;
    vertical-align: middle;
}
.label {
	font-size: 85%;
	padding: 0.4em 0.6em;
}
.label-inverse, .badge-inverse {
	background-color: #555555;
}
