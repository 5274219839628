
#fbPricingAnchor .Select-menu-outer {
  right: 0;
  width: auto;
  border-top-color: #d3d3d3;
  min-width: 200px;
}
#fbPricingAnchor.inModalPricing .Select-menu-outer {
  width: 100%;
  max-width: 458px;
}
#fbPricingAnchor .has-value>.Select-control>.Select-placeholder{
  color: #858585;
}
#fbPricingAnchor .fbPricingOption {
  white-space: nowrap;
  height: 22px;
  width: auto;
  overflow: hidden;
  padding-right: 15px;
}
#fbPricingAnchor.inModalPricing .Select {
  margin-left: 200px;
  margin-bottom: 11px;
}

#fbPricingAnchor:not(.inModalPricing) .value{
  margin-left: 0;
}

.form #fbPricingAnchor.inModalPricing input.value#max_bid {
  width: 229px;
}

#fbPricingAnchor.inModalPricing .title {
    width: 200px;
    text-align: left;
    font-weight: 400;
    float: left;
    padding: 0 5px 4px;
    clear: left;
}