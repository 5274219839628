
//== Popovers
//
//##
.popover.popover-dark{

  @popover-bg:                            #e4e4e4;
  @popover-max-width:                     376px;
  @popover-border-color:                  rgba(226, 226, 226, 1);
  @popover-fallback-border-color:         #E2E2E2;
  @popover-arrow-color:                   @popover-bg;
  @popover-arrow-outer-color:             @popover-border-color;
  @popover-arrow-outer-fallback-color:    @popover-fallback-border-color;

  // Popover Style
  max-width: @popover-max-width;
  background-color: @popover-bg;
  .box-shadow(none);
  border: 1px solid @popover-fallback-border-color;
  border: 1px solid @popover-border-color;
  border-bottom: 3px solid darken(@popover-border-color, 10%);
  // Arrows
  &.top > .arrow {
    border-top-color: @popover-arrow-outer-fallback-color; // IE8 fallback
    border-top-color: @popover-arrow-outer-color;
    &:after {
      border-top-color: @popover-arrow-color;
    }
  }
  &.right > .arrow {
    border-right-color: @popover-arrow-outer-fallback-color; // IE8 fallback
    border-right-color: @popover-arrow-outer-color;
    &:after {
      border-right-color: @popover-arrow-color;
    }
  }
  &.bottom > .arrow {
    border-bottom-color: @popover-arrow-outer-fallback-color; // IE8 fallback
    border-bottom-color: @popover-arrow-outer-color;
    &:after {
      border-bottom-color: @popover-arrow-color;
    }
  }
  &.left > .arrow {
    border-left-color: @popover-arrow-outer-fallback-color; // IE8 fallback
    border-left-color: @popover-arrow-outer-color;
    &:after {
      border-left-color: @popover-arrow-color;
    }
  }
  // Content
  .popover-content {
    padding: 9px 18px;
    font-size: 13px;
    p{
      margin-bottom: 4px;
    }
    .txt-label{
      display: inline-block;
    }
    .ttitle{
      padding-bottom: 5px;
      margin-bottom: 8px;
      border-bottom: 1px solid darken(@popover-border-color, 5%);
    }
    .label-last{
      margin-left: 5px;
    }
  }

  &.popover.right {
    margin-left: 15px;
  }

}

.twCampaignListInfoPopover.popover.popover-dark .popover-content {
  padding: 5px 10px;
}

.popover .error {
  font-family: 'Open Sans',sans-serif;
  display: inline-block;
  color: #E54028;
  font-size: 11px;
  padding-top: 4px;
  line-height: 1;
}


.app-info-modal-break li {
  word-break: break-all;
  margin-left:31px !important;
}
