.react-panel-content-margin {
  margin: 20px;
}

.react-panel-content-margin {
  .nav-tabs {
    padding-top: 20px;
    margin-left: -21px;
    margin-right: -21px;
    padding-left: 0;
  }
  .nav-tabs-separator {
    margin: 0 -20px;
  }
}

.panel-light .panel-body.react-panel-no-padding,
.panel-body.react-panel-no-padding{
  padding: 0;
}
