.bulk-add {
  .form textarea.value {
    &.focused,
    &:focus {
      height: 200px;
    }
    width: 100%;
    resize: none;
  }

  .bulk-selected {
    border: 1px solid #ccc;
    padding: 10px 28px 5px 10px;
    border-radius: 4px;
    position: relative;
    min-height: 38px;
    cursor: pointer;
    word-break: break-word;
    .label {
      margin-right: 5px;
      margin-bottom: 5px;
      display: inline-block;
      white-space: normal;
      line-height: 1.3em;
      cursor: default;
      font-weight: normal;
      border-radius: 3px;
      a {
        color: #fff;
        margin-left: 5px;
        text-decoration: none;
        font-size: 15px;
        font-weight: bold;
        vertical-align: middle;
        &:hover {
          color: #000;
        }
      }
    }
    .close-all {
      color: #ccc;
      right: 8px;
      top: 7px;
      font-size: 24px;
      position: absolute;
      line-height: 1em;
      text-decoration: none;
      &:hover {
        color: #999;
      }
    }
  }
}

.bulk-errors {
  max-height: 215px;
  overflow: auto;
}
