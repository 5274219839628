/* Triangle Up */
.tri-up:before,
.tri-up:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-top: 0;
}

/* Stroke */
.tri-up:before {
  bottom: 0px;
  left: 0;
  border-bottom-color: #E8E8E8;
  border-width: 14px
}

/* Fill */
.tri-up:after {
  bottom: -1px;
  left: 0;
  border-bottom-color: #fff;
  border-width: 14px;
}