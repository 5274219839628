.twitter-style * {
  &::-webkit-scrollbar {
    width: 10px;
    background: #eee;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #cecece;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
}
