@import "../bootstrap/mixins/vendor-prefixes.less";
@zindexBase: 1000;
@buttonContainerHeight: 32px;

.action-overlay {
  position: fixed;
  bottom: -16px;
  right: 0;
  z-index: @zindexBase;
  background: #fff;
  border: #c5d0d8 solid 0;
  border-radius: 8px 0 0 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  width: calc(~"100% - 60px");
  max-width: 1300px;
  min-width: 964px;
  overflow: hidden;
  height: calc(100% - 50px);

  div.actions-container {
    height: @buttonContainerHeight !important;
  }

  .black-header{
    height: 85px;
    background-color: @dark-grey-header;

    .titleBox{
      padding: 30px;
      color: #fff;
      height: 85px;

      h1{
        font-family: Roboto,sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 300;
        letter-spacing: -1px;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@media (max-width: 1360px) {
  .action-overlay {
    left: 60px;
  }
}

/* SIDE MENU */
.action-overlay-side-menu {
  width: 215px;
  height: 100%;
  float: left;
  border-right: 1px solid #c5d0d8;
}

.action-overlay-side-menu ul {
  margin: 20px 7px 0 7px;
}

.action-overlay-side-menu li {
  padding: 5px 5px 5px 18px;
  position: relative;
  cursor: pointer;
  border: transparent solid 1px;
}

.action-overlay-side-menu li .preset-dropdown-checkbox {
  margin: 0;
  position: absolute;
  left: 3px;
  font-size: 11px;
  color: #1997f0 !important;
  font-weight: normal;
}

.action-overlay-side-menu li:not(.dropdown):hover {
  background: #1997f0;
  border: #1997f0 solid 1px;
  border-radius: 8px;
  color: #fff;

  .preset-dropdown-checkbox {
    color: #fff !important;
  }

  i {
    color: #fff !important;
  }
}

.action-overlay-side-menu {
  .dropdown {
    text-transform: uppercase;
    color: #788087;
    border-top: 1px solid #c5d0d8;
    font-size: 13px;
    padding-top: 10px;
    margin-top: 7px;

    &:after {
      content: '\f105';
      font-family: 'Font Awesome 5 Free';
      font-weight: 400;
      font-size: 17px;
      line-height: 17px;
      vertical-align: middle;
      float: right;
    }
  }

  .dropdown-opened:after {
    content: '\f107';
  }
  li.dropdown:first-child {
    border-top: none;
  }

  .read-only {
    color: #b1b1b1;
  }
}

/* CONTENT */
.action-overlay-content {
  height: 100%;
  overflow-y: auto;
  width: calc(~"100% - 215px");

  &.group-overlay {
    width: calc(~"100% - 312px");
  }

  &.ad-overlay {
    width: calc(~"100% - 312px")
  }

  .ag-bootstrap:not(.ad_account) {
    margin: 0 !important;
  }

  &.no-scroller.changelog {
    .page-header.dashboard-header {
      margin-left: 0;
    }
  }
}

.action-overlay-content .dashboard-header {
  padding: 30px;
  margin-right: 0;
  background: @dark-grey-header;
  position: fixed;
  margin-left: 0;
  z-index: @zindexBase + 1;
  width: calc(~"100%");
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 26px !important;

  h1>ul.menu-action {
    display:none;
  }
}

.action-overlay-content {
  .rules .autopilot, .panel-body, .panel {
    position: inherit;
  }
}
.action-overlay-content .dashboard-header > div:first-child {
  flex-grow: 1;
  padding-right: 20px;
}
.action-overlay-content .dashboard-header h1 {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  color: #fff;
  font-size: 22px;
  line-height: 24px;
  font-weight: 300;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-height: 72px;
  flex-grow: 1;

  span.campaign-name{
    width:55%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display:block;
  }
}

.action-overlay-content .content-bg-grey {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}

.action-overlay-content .notifications .content-bg-grey {
  margin-bottom: 0;
}

.action-overlay-content .button_container {
  min-height: @buttonContainerHeight;
  width: unset;
  z-index: @zindexBase + 1;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;

  .close {
    padding-left: 20px;
    line-height: 10px;
  }
}

.action-overlay-content .button_container {
  span {
    margin-left: 30px;
  }
  .cancel-button {
    background-color: #fff;
    font-weight: normal;
  }
}

.action-overlay-content .actions-container {
  position: relative;
}

.action-overlay-content {
  .form .ondemand-box > div:last-child > button:first-child {
    margin-left: -20px;
  }

  .followers-targeting .form .ondemand-box > div:last-child > button:first-child {
    margin-left: 0;
  }
}

.action-overlay-content {
  .cbp-spmenu {
    position: inherit;
  }

  .action-overlay-changelog {
    padding-top: 30px;

    .filters {
      margin-left: 25px;
      margin-right: 17px;
      padding-bottom: 40px;
    }
  }

  #action-overlay-date-range {
    color: #5a6c7b !important;
  }

  .selected-date-range-btn, .Select-control {
    line-height: 24px;
    height: 34px;
    background-color: #fff !important;
  }

  .changelog-list {
    z-index: @zindexBase + 1;

    &.cbp-spmenu {
      background: #fff;
    }

    .overlay-changelog {
      padding-left: 40px;
      padding-right: 20px;
    }

    .changelog-list-container {
      .changelog-list-item:hover {
        cursor: pointer;
      }
    }
  }

  .floater-estimates.estimates {
    position: fixed;
    width: 24%;
    height: calc(~"100% - 93px");
    min-width: 310px;
    border: none !important;
    border-radius: 0;
    margin-top: 0;
    right: 0;
    z-index: @zindexBase + 2;
    background: #f4f7f9;
    padding: 15px 20px;

    .floater-content-box,
    > div:nth-child(3).floater-content-box,
    > div:nth-child(3).floater-content-box .floater-titled-row,
    .floater-info.grayed-out,
    .bid-amount input {
      background: #f4f7f9;
    }

    .loader {
      left: 0;
      top: 0;
    }

    .button_container {
      top: -100px;

      .submit {
        width: inherit;
        margin: inherit;
        text-transform: none;
        font-size: 13px;
      }
    }
  }
}

.adplatform-style {
  .floater-estimates.estimates {
    .button_container {
      top: -65px;
    }
  }
}

.no-scroller {
  overflow: hidden;
}

/* IFRAME */
.action-overlay-content.in-frame {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;

  #routerAnchor {
    margin-left: 20px;
  }

  .automation-button-container {
    margin-right: 0;
    position: fixed;
    top: 30px;
    z-index: @zindexBase + 2;
  }

  .rules-group {
    margin-right: 30px;
  }

  .edit-automation-button-container {
    position: fixed;
    top: 30px;
    z-index: @zindexBase + 2;
  }

  .has-estimates .button_container {
    width: auto !important;
  }
}

.automation-button-container .popoverLabel {
  height: @buttonContainerHeight;
}

.action-overlay-content .select-account-container {
  width: 100%;
  max-width: 900px;
}

.action-overlay-content .tw-label-align {
  display: flex;
}

.consent-box {
  display:inline-block !important;
  margin-left: 10px;
}

.action-overlay-content .tw-checkbox {
  height: 20px;
}

.action-overlay-content .select-account-container,
.action-overlay-content.in-frame {
  .dashboard-header {
    position: fixed;
    width: 100%;
    z-index: @zindexBase + 1;
    margin: 0;
  }

  .floater-estimates.estimates .button_container {
    top: -10px;
  }

  .button_container {

    .cancel-button,
    .submit {
      display: inline-block;
      margin: 0;
      margin-left: 10px;
      width: auto;
    }

    .cancel-button {
      display: none;
    }
  }

  .content-bg-grey {
    margin-bottom: 0;
    padding-bottom: 160px;
  }
}

.report {
  padding-right: 10px;
}

.action-overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindexBase - 1;
  background-color: @modal-backdrop-bg;
  opacity: .5;
}

.navbar-fixed-top {
  z-index: @zindexBase - 2;
}

.twitter-style {
  .navbar-fixed-top {
    z-index: @zindexBase + 2;
  }

  .overlay-backdrop {
    z-index: @zindexBase + 3;
  }

}

/* Animation */

.action-overlay.slide-left {
  .animation(slideinright .2s 1 both);
}

.action-overlay-backdrop {
  .animation(fadeIn05 .2s ease-in);
}

.action-overlay {
  .notifications {
    .row {
      border-bottom-width: 1px;
      padding-bottom: 5px;
    }

    .entity-name {
      word-wrap: break-word;
    }
  }
  .modified-page-header .page-header h1 {
    font-size: 22px;
    line-height: 24px;
  }
}

.budget-limit-content > .content-bg-grey > .panel {
  margin-left: 0;
}

input#group_name  {
    width: 100% !important;
}

.creative-creator-overlay {
  width: 100% !important;
  padding-right: 230px;
}

.google-advertiser:hover {
    background: #f8f8f8;
}

.margin-top-3 {
  margin-top: 3px;
}

.account-rule-row:hover {
  background: #f8f8f8;
}

.vertical-child div {
  vertical-align: middle !important;
}
