.adAccount-list {
	ul.bootstrap-select.form-control.dropdown-menu {
		height: auto;
		z-index: 999;
		background: white;
	}
	.adAccount-box {
		margin-bottom: 50px;
	}

	.inactive .adAccount-box {
		border-bottom: 1px solid #cfcfcf;
	}

	.user-notif, .user-error {
		background: 0 0;
		color: #555;
		padding: 4px 15px 0;
		font-size: 18px;
		position: relative;
		display: inline-block;
		cursor: pointer;
		border: 0;
		position: absolute;
		right: 0px;
		top: 0px;
	}

	.user-notif {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.inactive .adAccount-chart {
		display: none;
	}

	.nav-tabs-icons + .nav-tabs-icons .user-notif{
		right:40px;
	}
	.user-notif i:before, .user-error i:before {
		transition: all 0.2s;
		height: 13vw;vertical-align: super;
	}

	.user-notif:hover > i:before, .user-error:hover {
		color: #1997f0;
	}

	.user-error:hover > i:before {
		content: '\f27a';
	}

	.adAccount-padding {
		padding: 0 35px;
	}

	.adAccount-padding .panel-light .panel-body {
		border: 0;
		background-color: transparent;
	}

	.adAccount-box > a {
		display: block;
		text-align: center;
	}
	.adAccount-name {
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
		color: #00a1cb;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		margin: 12px 0;
		display:block;
	}
	.popoverLabel {
		text-align: center;
		white-space: pre;
	}

	.nav-tabs {
		padding: 0 24px;
		margin: 0 -24px;
	}

	.adAccount-stats div:not(.popoverLabel) {
		display: block;
	}

	.popoverLabel{
		display:inline;
	}

	.adAccount-stats{
		text-align:center;
		white-space: pre;
	}

	.adAccount-href {
		display: block;
		text-decoration-color: #00a1cb;
	}
}

.ad-account-list-modal{
	height:600px;
	overflow: scroll;
	overflow-x: hidden;
}

.ad-account-list-modal .modalOptions{
	padding-bottom: 30px;
}

.ad-account-list-modal .modalOptions>label{
	padding-top: 8px;
	float:right;
}

.ad-account-list-modal.modal-backdrop.in{
	height:100%;
}

.ad-account-list-modal {
	.singleNotification {
		margin-bottom: 5px;
		width: 98%;
		position: relative;

		label {
			display: block;
			padding: 6px;
			background-color: #fbfbfb;
		}
	}

	.notificationRow {
		display: flex;
		padding: 6px;
	}
}

.show-only-twitter{
	display:none;
}

.text-left-middle-grid {
	line-height: 50px;
	text-align:left;
	padding-left:18px;
}

.entityNameContainer .status-container {
	padding-top: 3px;
}

.text-left-middle-grid .dropdown {
	position:absolute;
	right:0;
	top:0;
	background: #f2f2f2;
	border-left: 1px solid #e4e4e4;
}

.details-page-twitter .ag-header-icon.ag-sort-ascending-icon,
.details-page-twitter .ag-header-icon.ag-sort-descending-icon{
	display: block;
}

.fillScreen{
	width:100%;
	height:100%;
	background: rgba(0,0,0,0.5);
	position:absolute;
	z-index: 9999;
	text-align:center;
	padding-top:25%;

	.twLoader{
		color:white;
		font-size:4em;
	}

	.loadingText{
		font-size:24px;
		font-weight:bold;
		color:white;
	}

}

.ad-account-button-container {
	display: flex;
	justify-content: flex-end;
}

.ad-account-button-container .btn-sm {
	padding: 6px 12px;
	margin-top: 1px !important;

	&:hover {
		padding: 6px 12px;
	}
	&:focus {
		padding: 6px 12px;
	}
}

.notification-bell {
	display: flex;
	align-items: center;
	transition: all .3s ease;
	margin-left: 1rem;

	.fa-bell {
		color: #D1D5DA;
		font-size: 18px;
	}

	&:hover {
		cursor: pointer;

		.fa-bell {
			color: #FFFFFF;
		}
	}
}
