.daypartWidget {
	.days label {
		margin-right: 10px;
	}
	.schedule {
		padding-top: 15px;
		padding-bottom: 7px;
		margin-left: 20px;
		border-top: 1px solid #E4E4E4;
	}
	.schedule:first-child {
		border-top: none;
		padding-top: 0;
	}
}