.annotatedListContainer {
  position: relative;
  overflow: auto;
  display: flex;
  align-items: center;
}

.annotatedList {
  width: 340px;
  height: 192px;
  float:left;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  overflow-x: hidden;
  margin-bottom: 10px;
  color: #888888;
  padding: 5px;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.annotated-max-width {
  max-width: 900px;
}

.higher .annotatedList {
  height: 292px !important;
}

.annotatedList.in-modal {
  width: 314px;
}

.annotatedList.inset {
  margin-top: 27px;
  height: 165px;
}

.higher .annotatedList.inset {
  height: 265px !important;
}

.annotatedListFilter {
  width: 340px;
  position: absolute;
  top: 0;
}

.annotatedListFilter .inputContainer {
  position: relative;
}

.annotatedListFilter .inputContainer input {
  width: 100%;
  outline: none;
  font-size: 12px;
  border: 1px solid #ccc;
  padding: 2px 3px 4px 5px;
  line-height: 1.7em;
}

.annotatedListFilter .inputContainer .clearFilter {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 5px 0 14px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #ccc;
}

.annotatedListFilter .inputContainer .clearFilter:hover {
  color: #D15E5E;
}

/** =SELECTED ELEMENTS */
.annotatedList li {
  position: relative;
  cursor: pointer;
  padding: 3px 15px 3px 5px;
  min-height: 22px;
  -webkit-transition: all 80ms ease;
  -o-transition: all 80ms ease;
  -moz-transition: all 80ms ease;
  -ms-transition: all 80ms ease;
  -kthtml-transition: all 80ms ease;
  transition: all 80ms ease;
}

.annotatedList li:hover,
.annotatedList li.selected-item {
  background: #F5F5F5;
  -webkit-transition: all 80ms ease;
  -o-transition: all 80ms ease;
  -moz-transition: all 80ms ease;
  -ms-transition: all 80ms ease;
  -kthtml-transition: all 80ms ease;
  transition: all 80ms ease;
}

.annotatedListCategories.annotatedList li.selected-item {
  background: #F1F1F1;
}

.annotatedList li:active {
  background: #f3f3f3;
}

.shrink {
  -moz-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}

.annotatedList:not(.categoryList) li:after {
  font-weight: bold;
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 16px;
  color: #747474;
  content: "+";
}

.selectedConversations > ul > li:after {
  content: "-" !important;
}

.annotatedList.categoryList li.selected {
  font-weight: bold;
  background: #F5F5F5;
}

.conversationAnnotatedList {
  display: inline-block;

  .selectedConversations {
    margin-top: 20px;
    float: none;
    width: 478px !important;
  }

  .annotatedList > li {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .annotatedListContainer .annotatedList.categoryList {
    height: 200px !important;
    min-height: 200px !important;
  }

  .annotatedList, .annotatedListFilter {
    width: 100% !important;
  }

  .annotatedListContainer {
    float: left;
    margin-right: 0;
    width: 228px;
    display: initial;
  }

  .annotatedListContainer:not(.selectedConversations) {
    .annotatedList {
      margin-top: 35px !important;
      margin-bottom: 0;
    }
  }

  .annotatedListContainer:nth-child(2) {
    margin-left: 21px;
  }

  .annotatedListGridConversations {
    .emptyOverlay {
      position: absolute;
      text-align: center;
      width: 100%;
      padding-top: 85px;
      color: #888888;
    }

    height: 200px;
    margin-top: 35px !important;

    .ag-header {
      display: none !important;
    }

    .ag-body-viewport.ag-layout-normal {
      overflow-x: hidden;
      color: #888888;
      border: 1px solid #d3d3d3;
    }

    .ag-body-container:not(.ag-layout-print) {
      width: 100% !important;
    }

    .ag-cell {
      background: white;
      width: 100% !important;
      font-size: 12px;
      padding: 3px 15px 3px 5px;
      cursor: pointer;

      &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
        content: '\f067';
        display: block;
        line-height: 23px;
        position: absolute;
        right: 4px;
        top: 0;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.annotatedList li.annotatedListUnselectable {
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
}

.annotatedList li.annotatedListUnselectable:after,
.annotatedList li.category:after {
  content: "";
}

.annotatedList li.annotatedListUnselectable:hover {
  cursor: default;
  -moz-box-shadow:    none;
  -webkit-box-shadow: none;
  box-shadow:         none;
  background: none;
}

.annotatedList.annotatedListSelected > li:after,
.annotatedList.annotatedListSelected li.extendedDescription.annotatedListLevel1:after,
.annotatedList.annotatedListSelected li.annotatedListLevel2:after{
  content: "-";
}

/** =SPACER */
.annotatedListSpacer {
  float: left;
  position: relative;
  width: 28px;
}
.annotatedListSpacer:before {
  content: "⇄";
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  left: 0; right: 0;
  margin: auto;
  width: 18px;
  font-size: 22px;
  color: #ccc;
}

/** =FIRST LEVEL **/
.annotatedList li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.annotatedList li.annotatedListLevel1 {
  padding-left: 8px;
}
.annotatedList li.annotatedListLevel2 {
  padding-left: 16px;
}
.annotatedList li.annotatedListLevel3 {
  padding-left: 24px;
}
.annotatedList li.annotatedListLevel4 {
  padding-left: 32px;
}

@media (max-width: 1199px) {
  .annotatedList, .annotatedListFilter {
    width: 206px;
  }
  .annotatedList li{
    padding-right: 15px;
    font-size: 12px;
    line-height: 15px;
  }

  /** =FIRST LEVEL **/
  .annotatedList li.annotatedListLevel1 {
    padding-left: 5px;
  }
  .annotatedList li.annotatedListLevel2 {
    padding-left: 15px;
  }
  .annotatedList li.annotatedListLevel3 {
    padding-left: 20px;
  }
  .annotatedList li.annotatedListLevel4 {
    padding-left: 25px;
  }
  .annotatedList li:after {
    top: 3px;
  }
}

.annotatedList .read-only span,
.annotatedList .disabled span,
.annotatedList li.read-only,
.annotatedList li.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.annotatedList li.read-only:after,
.annotatedList li.disabled:after {
  content: '';
}

/*Fluid width*/
.fluidAnnotatedList	.annotatedListFilter,
.fluidAnnotatedList	.annotatedList {
  width: 47%!important;
}
.fluidAnnotatedList .annotatedListSpacer{
  width: 6%!important;
}

/** Form */
.annotatedListSpacer {
  cursor: pointer;
  width: 30px !important;
  text-align: center !important;
}

.annotatedListSpacer:before {
  color: #00a1cb !important;
  text-align: center !important;
  width: 22px !important;
}

.annotatedListSpacer:hover:before {
  color: #007998 !important;
}

.annotatedListSpacer.disabled {
  cursor: auto;
}

.annotatedListSpacer.disabled:before {
  color: #ccc !important;
}

.annotatedListCounter {
  right: 0;
  top: 0;
}

.annotatedListSpacer.disabled:hover:before {
  color: #ccc !important;
}
.annotatedListForm {
  width: 300px;
  min-height: 192px;
  float: left;
}
.annotatedListForm .gray-ondemand-box {
  position: relative;
  left: 15px;
  min-height: 192px;
  padding: 18px 15px;
  background: #f1f1f1;
  border-bottom: 3px solid #e8e8e8;
  overflow: visible !important;
}
.annotatedListForm .smallInput{
  width: 55px;
}
.annotatedListForm .arrow-left {
  position: relative;
  display: block;
  border-top: 15px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid #f1f1f1;
  float: left;
}
.annotatedListForm h5 {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-weight: bold;
  font-size: 13px;
}

.annotatedListForm .currencyLabel {
  padding-left: 5px;
  font-size: 11px;
}
.annotatedListForm .error{
  margin-top:-15px;
  margin-bottom: 15px;
  float: right;
  margin-right: 20px;
}
.annotatedListForm .error img {
  padding-bottom: 0;
}
.annotatedListForm .form-group {
  margin-bottom: 12px;
}
@media (min-width: 960px){
  .annotatedListForm {
    min-width: 420px;
  }
  .annotatedListForm .smallInput{
    width: 75px;
  }
}

.annotatedListToggleAllInGroup {
  display: inline-block;
  clear: both;
  padding: 0 5px;
  font-size: 16px;
  font-weight: bold;
  margin-right: -15px;
  margin-top: -3px;
  color: #747474;
}

.annotatedListCategories.annotatedList {
  border: none;
  max-width: 200px;
  overflow-y: auto;
}

.annotatedListCategories.annotatedList.withSearch {
  padding-top: 28px;
}

.annotatedList li:first-letter {
  text-transform: capitalize;
}

.annotatedListAvailable li .excludeTrigger {
  display: none;
}

.annotatedList .excludeTrigger {
  i.fa-check-circle {
    color: #00a1cb;
    float:left;
  }

  i.fa-times-circle {
    color: #e54028;
    float: right;
  }
}

.excludeTrigger {
  height:26px;
  position: absolute;
  top:0px;
  padding-top: 4px;
  left:5px;
  width:40px;
}

.include-exclude-toggle {
  border:1px solid #bbb;
  padding:0px 2px;
  border-radius: 25px;
  height:15px;
  width:25px;
  float:left;
  margin-right:5px;
  margin-top:5px;
}


.title.behavior-list-title {
  padding-left:25px;
}


.behavior-list-title {
	white-space: nowrap !important;
	text-overflow: ellipsis;
	overflow: hidden;
}

.parentEntitySelectorFooter {
  border-top: none;
}

.annotatedListGrid {
  height: 200px;
  color: #888888;

  &.alertShown {
    height: 50px;
  }

  .annotatedListSpacer {
    padding: 10px;
  }

  & > div {
    float: left;
  }

  .ag-icon {
    height: 20px;
    background: none;
    cursor: pointer;
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;

    &.ag-icon-contracted:after {
      content: @fa-var-minus-square!important;
    }

    &.ag-icon-expanded:after {
      content: @fa-var-plus-square!important;
    }
  }

  .annotatedListGridCounter {
    float: none;
    text-align: right;
    padding-right: 10px;
  }

  .annotatedGridSelector {
    border:1px solid #d3d3d3;
    height: 200px;
    width: 47%;
    padding: 10px;
    padding-right: 0;

    &.selectedOptions {
      .ag-row {
        &:not(.ag-row-group-contracted):not(.ag-row-group-expanded) {
          &:after {
            content: '-';
          }
        }
      }
    }
  }

  .ag-row{
    &.ag-row-group-contracted, &.ag-row-group-expanded {
      &:hover {
        background-color: white;
      }
    }

    &:not(.ag-row-group-contracted):not(.ag-row-group-expanded) {
      cursor:pointer;
      padding-left: 10px;

      .ag-row-group-indent-1 {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        padding-left: 25px;
      }

      .ag-row-group-indent-2 {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        padding-left: 35px;
      }

      &.ag-row-selected {
        background: #F5F5F5;

        &:after {
          float: right;
          position: relative;
          height: 12px;
          width: 12px;
          content: '';
          background: url(../../images/new/tw-checked.png);
          background-repeat: no-repeat;
          top: 5px;
          right: -2px;
          margin-right: 10px;
          font-weight: bold;
        }
      }
    }

    &:hover {
      background: #F5F5F5;
    }
  }

  .ag-body-container {
    width: 100% !important;

    .ag-cell {
      width: 100% !important;
      padding: 3px;
      padding-right: 5px;
      padding-left: 5px;
      max-width: 100%;

      &:focus {
        outline: none !important;
      }
    }
  }
}

.ag-cell-level-top {
  font-weight: bold;
}



