.channel-box {
	padding: 5px 15px 60px 15px;
	margin-bottom: 20px;
	background-color: #D8D8D8;
	position: relative;
	h4{
		color: #333;
	}
	.menu-create{
		position: absolute;
		bottom: 20px;
	}
}
.fold-paper:after{
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	border-width: 0 16px 16px 0;
	border-style: solid;
	border-color: transparent #F2F2F2 #E8E8E8 transparent;
	display: block;
	width: 0;
	.box-shadow(-1px 1px 1px rgba(0,0,0,0.2));
}