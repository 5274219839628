
.main-container {
	margin-top: 48px;
}
.page-full-width .main-container{
	margin-top: 98px;
}
.header-default .main-container {
	margin-top: 0 !important;
}
.main-content {
	margin-top: 0;
	background-color: #FFFFFF;
	padding: 0;
}
.container {
	max-width: none !important;
	width:auto !important;
}
.footer-fixed .main-content .container{
	margin-bottom:22px;
}

#leftMainMenu {
	position: relative;
}
