html {
  position: relative;
  min-height: 100%;
}

body {
  background: #f2f2f2;
}

.main-container,
.navbar,
.footer {
  min-width: 768px;
}

.main-container {
  margin-top: 0;
  min-width: 768px;
  position: relative;
  #header {
    padding-left: 0;
  }
}

.container {
  padding-left: 24px;
  padding-right: 24px;
}

.navbar {
  background: #fff;
  .container {
    .navbar-nav {
      margin-left: -2px;
    }
  }
}

.navbar-fixed-top {
  &.affix {
    .transition(box-shadow 0.3s ease);
    .box-shadow(0 2px 15px rgba(72, 72, 72, 0.2));
    opacity: 1;
  }
}

.navbar-nav {
  margin: 0
}

.navbar-inverse {
  background: #f5f5f5;
  .horizontal-menu {
    background: transparent;
    border-top: 1px solid #ECECEC !important;
    border-bottom: 1px solid #ECECEC !important;
  }
}

#content.main-content {
  padding: 0;
  border: 0;
  margin-top: 0;
  overflow: inherit !important;
  background: transparent;
}

.btn {
  white-space: normal;
}

.input-group-btn {
  .btn {
    white-space: nowrap;
  }
}

.breadcrumb {
  padding-bottom: 0;
  border-bottom: 0;
  background: #fff;
  margin-left: 12px;
  margin-right: 0;
  height: auto;
  a {
    text-decoration: none;
  }
  a, li,
  > .active {
    color: #C2BFBF;
  }
  a:hover {
    color: #aeaeae;
  }
}

.list {
  min-width: 100%;
}

.navbar-tools > ul > li.vseparator {
  padding: 16px 0;
  font-size: 16px;
  color: #999 !important;
}

#form-switch-role {
  display: inline-block;
  padding: 15px 0;
}

.navigation-closed .navigation-toggler:hover i:first-child {
  color: #b1b1b1;
}

.navigation-closed .navigation-toggler:hover i:last-child {
  color: #555555;
}

@media (max-width: 768px) {
  .navigation-closed .navigation-toggler:hover i:first-child {
    color: #b1b1b1;
  }

  .navigation-closed .navigation-toggler:hover i:last-child {
    color: #555555;
  }

  .navbar-toggle {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.navbar-header {
  float: none;
  height: 46px;
}

.username {
  font-size: 14px;
}

/*FOOTER*/
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px;
  padding: 40px 24px;
  background: #f2f2f2;
  .language-changer {
    margin-top: -4px;
    .pull-right;
  }
}

h3 {
  margin-bottom: 30px;
}

.form fieldset .alert.rich-preview-warning {
  max-width: 463px;
  margin-bottom: 0;
}

//
// Campagin types & action icons(map to icon classes)
// --------------------------------------------------
.cmp-text {
  .clip-file-2;
}

.cmp-shop {
  .clip-images;
}

.cmp-bigshop {
  .clip-images-2;
}

.cmp-video {
  .fa;
  &:before {
    content: "\f008";
  }
}

.cmp-videoauto {
  .clip-youtube-2;
}

.cmp-rich {
  .clip-pictures;
}

.cmp-tag {
  .clip-embed;
}

.cmp-engage {
  .clip-spinner-4;
}

.cmp-simpleengage {
  .clip-spinner-5;
}

.cmp-appnexus {
}

.cmp-blank {
}

.cmp-network {
}

.cmp-auction {
  .clip-stats;
}

.cmp-fixedprice {
  .clip-banknote;
}

// Sidebar Actions
.cmp-edit {
  .clip-pencil-3;
}

.cmp-deactivate {
  .clip-switch;
}

.cmp-delete {
  .clip-remove;
}

.cmp-online {
  .clip-world;
}

.cmp-mobile {
  .clip-mobile;
}

.cmp-offerpool {
  .clip-stack-empty;
}

.cmp-facebook {
  .clip-facebook;
}

.cmp-addgroup {
  .clip-folder-plus;
}

.cmp-remove {
  .clip-remove;
}

.cmp-copy {
  .clip-copy-2;
}

.cmp-showdeleted {
  .clip-eye-2;
}

.cmp-showactive {
  .clip-eye;
}

.cmp-integration {
  .clip-code;
}

.cmp-pixel {
  .clip-circle-small;
}

.cmp-edit-time-and-budget {
  .clip-banknote;
}

.cmp-showreports {
  .clip-stats;
}

.cmp-facebookgroup {
  .clip-stack-2;
}

.cmp-handpick {
  .fa;
  &:before {
    content: "\f0a6";
  }
}

.cmp-rtb {
  .fa;
  &:before {
    content: "\f0e3";
  }
}

.cmp-download {
  .clip-download;
}

.cmp-rules {
  .fa;
  &:before {
    content: "\f0e3";
  }
}

.cmp-active {
  .clip-checkmark-circle-2;
  color: @brand-success;
}

.cmp-paused {
  .fa;
  color: @brand-warning;
  &:before {
    content: "\f04c";
  }
}

.cmp-preview {
  .clip-search;
}

.cmp-link {
  .clip-globe;
}

.cmp-error {
  .clip-notification;
}

.unlock-button {
  margin-left: 5px;
  margin-top: 1px;
  cursor: pointer;
  &.locked {
    .clip-locked;
  }
  &.unlocked {
    .clip-unlocked;
  }
}

.plus {
  float: right;
  font-size: (@font-size-base * 1.5);
  font-weight: @close-font-weight;
  line-height: 1;
  color: @close-color !important;
  text-shadow: @close-text-shadow;
  &:hover,
  &:focus {
    color: @close-color;
    text-decoration: none;
    cursor: pointer;
    .opacity(.5);
  }
}

.btn-plus {
  .btn;
  .btn-xs;
  .btn-primary;
}

.btn-minus {
  .btn;
  .btn-xs;
  .button-variant(#888; #ebebeb; #d3d3d3);
}

.btn-minus:hover {
  color: #D15E5E;
  background: #f1f1f1;
  border-color: #d3d3d3;
}

.btn-plus,
.btn-minus {
  margin-top: -2px;
  padding: 3px 8px;
  min-height: 27px;
}

.age-container .btn-plus,
.age-container .btn-minus {
  margin-top: 0;
}

//
// Status icons
// --------------------------------------------------

.status-icons,
.creative_icon {
  padding-top: 5px;
  span {
    display: block;
    margin-bottom: 12px;
  }
  a,
  span {
    font-size: 14px;
  }
  a > span {
    color: #999;
    &.cmp-error {
      color: @brand-inactive;
    }
  }
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }
}

//
// Campagin filter
// --------------------------------------------------

#filter {
  margin-bottom: -15px;
}

#filter.no-margin-bottom {
  margin-bottom: 0;
}

#filter .form-group {
  @media (min-width: 992px) {
    //margin-bottom: 0 !important;
  }
}

#filter .form-control {
  margin-bottom: 0 !important;
}

.btn-select {
  .button-variant(#858585; #FFFFFF; #D5D5D5);
  .box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
  border-radius: 0;
}

.filter_products {
  background-color: transparent !important;
}

.filter-container {
  min-height: 20px;
  padding: 10px 0;
  margin-bottom: 20px;
}

.page-header {
  .filter-container {
    margin-bottom: 0;
  }
}

.page-header .parent-agency-name {
  margin: 0;
  padding: 0;
  color: #bcbcbc;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: 0px;
  font-family: 'Open Sans', sans-serif;
}

.page-header .parent-agency-name i {
  display: inline-block;
  margin-right: 5px;
}

.page-header.projects-header h1 {
  margin-bottom: 8px;
}

/* Datetime picker */
#reportrange {
  cursor: pointer;
  .btn-select;
  .form-field;
  height: 27px;
  margin-top: -2px;
  background: #fff;
  .caret {
    .pull-right;
    margin: 10px 0;
  }
  &:hover {
  }
}

/* Bootstrap select */
.bootstrap-select {
  .btn {
    .btn-select;
    .form-field;
    height: 27px;
    //margin-top: -2px;
  }
  &.small {
    width: 75px !important;
  }
  &.mid {
    width: 100px !important;
  }
  &.narrow {
    width: 200px !important;
  }
  &.semilong {
    width: 262px !important;
  }
  &.midlong {
    width: 300px !important;
  }
  &.long {
    width: 350px !important;
  }
  &.full-width {
    width: 100% !important;
  }
}

.has-error {
  .bootstrap-select .btn {
    //border-color: @state-danger-text !important;
    border-color: rgb(229, 64, 40) !important;
    background-color: rgb(255, 245, 244);
  }
}

.bootstrap-select.input-sm {
  .btn {
    height: 30px;
    padding: 6px 4px !important;
    margin-top: 0;
  }
}

.label {
  border-radius: 0;

  a.remove-icon {
    color: #ccc;
    margin-left: 10px;
  }

  a:hover.remove-icon {
    color: #fff;
  }

}

.btn-primary {
  .button-variant(#fff; @brand-cyan; @brand-darkcyan);
  &.disabled,
  &[disabled] {
    opacity: 1;
    .button-variant(@grey-info-text; #E4E4E4; #E4E4E4);
  }
}

.btn-suggest {
  .button-variant(#707070; #f2f2f2; #ebebeb);
  margin: 0 4px 4px 0;
}

.suggest {
  .btn;
  .btn-suggest;
  .btn-sm;
}

.calendar {
  border: none !important;
}

/* Activity */
.campaign-activity {
  margin-bottom: 20px;
  .clearfix;
  .activity-widget {
    margin-bottom: 8px;
    margin-right: 20px;
    .pull-left;
  }
  .activity-title {
    font-size: 14px;
    text-transform: uppercase;
    color: #999999;
    margin: 0;
    display: inline-block;
  }
  .activity-text {
    font-size: 13px;
    font-weight: bold;
    color: #666;
    line-height: 1.4em;
  }
  .time-widget {
    float: none !important;
    clear: left;
    .activity-text {
      font-weight: normal;
    }
  }
  .activity-group {
    float: none !important;
    clear: left;
    .clearfix;
    .activity-text {
      font-weight: normal;
    }
  }
}

/* Redesigned Activity */
.group-summary {
  margin-bottom: 20px;
  .help-icon {
    color: #b7b7b7;
  }
  &.subgroup {
    margin-top: -10px;
    margin-bottom: 0;
    i {
      color: #bbc3ce;
      text-shadow: 1px 1px 0 #ffffff;
    }
  }
}

.summary-col {
  float: left;
  padding-right: 50px;
  min-height: 45px;
}

.group-summary .summary-col {
  margin: 0 0 10px 0;
}

.txt-label {
  font-size: 11px;
  color: #999999;
  text-transform: uppercase;
  display: block;
  padding-bottom: 3px;
  padding-right: 3px;
}

.txt-value,
.status-text,
.txt-status {
  font-size: 12px;
}

.delivery-status .status-text,
.listGrid .campaign-info .status-text {
  text-transform: lowercase;
}

.txt-status {
  position: relative;
  top: 5px;
}

.summary-col .fa {
  font-size: 18px;
}

/* Dropdown button in list */
.list {
  .right {
    .dropdown-menu {
      text-align: right;
    }
  }
}

.list-action-btn {
  .btn;
  .btn-sm;
  min-width: 45px;
  white-space: nowrap;
  color: #00a1cb !important;
  border-color: #00a1cb !important;
  background: transparent;
  span.caret {
    float: none !important;
  }
  label {
    color: #fff !important;
    margin: 0;
  }
}

.list-action-btn:hover {
  background: #fff;
}

.btn-group.open {
  .list-action-btn {
    background-color: #F7F5F5;
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.2);
  }
}

/* jQGrid */
.ui-jqgrid tr,
.ui-jqgrid th,
.ui-jqgrid td,
.ui-jqgrid-sdiv,
.ui-jqgrid-bdiv {
  overflow: visible !important;
}

.panel,
.well {
  .box-shadow(none);
}

.panel-heading {
  display: block;
  cursor: pointer;
  background: #f5f5f5;
  background-image: none;
  border-radius: 0;
  box-shadow: 0 0 0 0;
}

a.panel-heading {
  display: inline-block;
}

a:hover.panel-heading {
  text-decoration: none;
}

/* Tooltip */
.jTip {
  padding-left: 2px;
  font-size: 1em;
  &:hover {
    text-decoration: none;
  }
  i {
    padding: 0;
    margin: 0;
    margin-bottom: -3px;
    display: inline-block;
    color: #999;
  }
}

.help-icon {
  .fa;
  &:before {
    content: "\f05a";
  }
}

.tooltip {
  word-wrap: break-word;
}

.tooltips {
  .help-icon {
    cursor: pointer;
  }
}

.help-icon {
  color: #999;
}

//
// Colors
// --------------------------------------------------
.color-success {
  color: @brand-active;
}

.color-danger {
  color: @brand-inactive;
}

.color-warning {
  color: @brand-paused;
}

.color-info {
  color: @brand-deleted;
}

.color-errors {
  color: @brand-issues;
}

/* ---------->>> jtip <<<--------------------------------------------------------------- */
/* //Style 1
#JT {
  position: absolute;
  z-index: 100;
  border: 1px solid #CCCCCC;
  background-color: #fff;
}
#JT_copy {
  padding: 10px 10px 10px 10px;
  color: #333333;
  position: relative;
}
#JT {
  .dropdown-menu;
  border: none !important;
}
#JT_close_left {
  background-color: transparent !important;
}
#JT_arrow_left {
  background: none !important;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  z-index: 1000;
}
//EoF Style 1 */

//Style 2
#JT {
  border: 0px solid #f1f1f1 !important;
  background-color: #e4e4e4;
  border: 1px solid #e2e2e2;
  border-bottom: 3px solid #c9c9c9;
}

#JT_arrow_left {
  background: none !important;
  top: 0px !important;
  left: -10px !important;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #e4e4e4;
  z-index: 1000;
}

#JT_arrow_right {
  background: none !important;
  top: 0px !important;
  right: -10px !important;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #e4e4e4;
  z-index: 1000;
}

#JT_close_left {
  background-color: #999 !important;
  padding-bottom: 3px !important;
  padding-top: 2px !important;
  color: #fff;
}

//EoF Style 2 */

.jTip {
  &.cellTooltip {
    padding-left: 0;
  }
}

//
// PAGINATION
// --------------------------------------------------
.pagination {
  a,
  a [class^="icon-"],
  a [class*=" icon-"] {
    line-height: 17px;
    font-size: 13px;
  }
}

.panel-heading {
  > .fa-hand-o-up,
  > .fa-bullseye {
    padding-left: 11px;
    padding-right: 11px;
  }
  > .fa-facebook {
    padding-left: 14px;
    padding-right: 13px;
  }
  > .clip-home-3 {
    padding-left: 11px;
    padding-right: 10px;
  }
}

//
// popup UI
// --------------------------------------------------
.ajaxDialog .ui-dialog-titlebar-close {
  top: 8px !important;
  right: 8px !important;
  font-size: 16px !important;
  background-color: transparent !important;
  outline: none;
}

.ui-dialog .ui-dialog-titlebar-close,
.close-icon {
  .fa;
  &:before {
    content: "\f00d";
    color: #666 !important;
  }
  &:hover {
    opacity: 0.8;
  }
}

#reconnect_dialog {
  .message_box_notice {
    .fa;
    &:before {
      content: "\f071";
      font-size: 24px;
      color: @brand-orange;
    }
    background: transparent !important;
  }
}

.logobox {
  background: #fff;
  text-align: center;
}

.progress {
  margin-bottom: 0px;
}

.progress-bar.progress-infinity {
  background-color: transparent;
  width: 100% !important;
}

.ui-dialog {
  label.caption {
    width: 100px;
    text-align: left;
    font-weight: normal;
    float: left;
    padding: 4px 5px;
    clear: left;
  }
  div.value {
    margin-left: 100px;
    margin-bottom: 15px;
  }
  input.value,
  textarea.value {
    width: 270px;
  }
  .button_container {
    font-size: 14px;
  }
  .error {
    color: #E54028;
    font-size: 11px;
    padding-top: 4px;
  }
}

//
// POP UP BOX
// --------------------------------------------------
.popup.box {
  .button_container {
    width: 100%;
  }
}

.close-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  font-size: 16px;
  .fa;
  &:before {
    content: "\f00d";
    color: #666 !important;
  }
  &:hover {
    opacity: 0.8;
  }
}

//
// MODAL BOOTSTRAP
// --------------------------------------------------
.modal {
  font-size: 13px;
  .close {
    font-size: 35px;
  }
  .alert {
    font-size: 13px;
    li {
      padding-top: 10px;
    }
    li:first-child {
      padding-top: inherit;
    }
    .close {
      font-size: 21px;
    }
  }
}

//
// USER ERRORS
// --------------------------------------------------
.gridErrors {
  background: #fff;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
}

.content-bg-grey .gridErrors {
  background: transparent;
}

.user-errors {
  .alert;
  .alert-warning;
  margin-bottom: 0;
  > header {
    font-size: 18px;
    margin-bottom: 12px
  }
  &.warning {
    color: #F09E1A;
  }
  dl,
  .user-errors-content {
    font-size: 12px;
  }
  dt {
    font-weight: bold;
    margin-left: 28px;
  }
  dd {
    margin: 3px 0 3px 28px;
  }
  .user-errors-icon,
  .user-errors-title {
    float: left;
    line-height: 18px;
  }
  .user-errors-icon {
    padding-right: 10px;
  }
  &.hidden-on-onload {
    display: none
  }
  &.interests-error {
    width: 707px;
    margin: 0 0 12px 200px;
  }
}

.groupErrors {
  .user-errors {
    margin-bottom: 20px;
  }
}

.has-estimates {
  .user-errors {
    &.interests-error {
      width: 100%;
      margin: 0 0 12px 0;
    }
  }
}

//
// NOTIFICATIONS
// --------------------------------------------------

.dropdown-menu {
  font-size: 13px;
}

.dropdown-menu .dropdown-header {
  font-weight: 700;
  padding-top: 5px;
  text-transform: uppercase;
  color: #333;
}

.dropdown-menu > li > a.dropdown-header:focus,
.dropdown-menu > li > a.dropdown-header:hover {
  background-color: inherit;
}

.navbar-tools {
  .dropdown-menu.notifications {
    li > a {
      position: relative;
      overflow: hidden;
      padding-bottom: 3px;
      .label {
        display: inline-block;
        float: left;
        margin: 0 5px 0 0;
      }
      .message {
        max-width: 227px;
        display: inline-block;
        line-height: 1.2em;
      }
    }
  }
}

// longer dropdown
.bootstrap-select.longer {
  width: 340px !important;
}

//
// NAVIGATION - SIDEBAR HIDDEN
//-------------------------------------------------------
.sidebar-hidden {
  .main-content {
    margin-left: 0 !important;
  }
  .sidebar-wrapper {
    display: none;
    .navigation-toggler {
      display: none;
    }
  }
}

.navbar-tools .dropdown-menu > li:last-child a {
  border-radius: 0;
}

//
// FB CREATIVES PREVIEW (copy group)
//-------------------------------------------------------
#creativeGallery fieldset {
  border: 1px solid #ccc;
  background: #f0f0f0;
  padding: 20px;
}

//
// Text Count
//-------------------------------------------------------

.bootstrap-maxlength {
  &.label {
    font-size: 1em;
    font-weight: normal;
  }
  &.label-warning {
    background-color: transparent;
    color: #d3d3d3;
  }
  &.label-danger {
    background-color: transparent;
    color: @brand-danger;
  }
}

textarea.noResize {
  resize: none
}

.textXXl {
  font-size: 34px;
}

//
// Popopvers for annotated list
//-------------------------------------------------------
.popover {
  max-width: 240px;
  .popover-content {
    font-size: 13px;
    .item-title {
      text-transform: uppercase;
      float: left;
      width: 75%;
      line-height: 1em;
      margin-bottom: 10px;
    }
    .item-promoted-object-title {
      text-transform: uppercase;
      line-height: 1em;
    }
    .item-number {
      float: right;
      width: 25%;
      text-align: right;
      line-height: 1em;
      margin-top: -5px;
    }
    .item-warning, .item-promoted-object-warning {
      color: @brand-danger;
    }
    .message {
      padding-right: 25%;
      color: #ccc;
      margin-top: 15px;
      clear: both;
      line-height: 1em;
    }
    .item-promoted-object-value {
      text-transform: uppercase;
      line-height: 1em;
      margin-bottom: 7px;
      font-weight: bold;
    }
    .item-promoted-object-presentation {
      line-height: 1em;
      margin-bottom: 7px;
      .summary-col {
        float: none;
        padding: 0;
        min-height: 0;
        .txt-label {
          display: none;
        }
      }
    }
  }
}

.slim-panel .panel-body {
  padding: 0 !important;
}

.budget-strategies-info {
  display: block;
  margin-left: 4px;
}

.budget-strategies-info:hover {
  cursor: pointer;
}

// dynamic input group
.add-input,
.remove-input {
  vertical-align: top;
  margin-left: 5px;
  margin-top: 0;
  float: none;
  min-height: 26px;
}

//
// Drafts
//-------------------------------------------------------

.section-drafts {
  padding: 20px 5px 10px 17px;
}

.section-draft {
  border: 1px dashed #CDCDCD;
  padding: 8px 18px 28px;
  background-color: #f9f9f9;
  margin: -1px -1px;
  .draft-head {
    line-heigt: 1.2em;
    overflow: hidden;
    padding-bottom: 10px;
    height: 80px;
    overflow: auto;
    .draft-title {
      font-size: 14px;
      color: #707070;
      margin-bottom: .5em;
    }
  }
  .draft-action {
    position: absolute;
    bottom: 8px;
  }
}

//
// Campaign list
//-------------------------------------------------------

.info-text a {
  font-size: 14px;
}

.panel .list > tbody > tr > td {
  border-top: none;
}

.__section-campaign {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 250px;
}

//
// Max width
//-------------------------------------------------------
/* .dashboard-header,
.page-header {
  max-width: 1200px;
}
.navbar .container {
  margin-left: 0;
  max-width: 1270px !important;
}
*/

//
// Gutter
//-------------------------------------------------------
.nogutter {
  margin-left: (@grid-gutter-width / -2);
  margin-right: (@grid-gutter-width / -2);
  padding-left: 10px;
  padding-right: 10px;
}

.nogutter-3 {
  margin-left: -30px;
  padding-left: 0;
  margin-right: 30px;
}

.row.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-sm {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.row.no-gutter .gutter-left-s {
  padding-left: 5px;
  @media (min-width: @screen-sm-min) {
    padding-left: 10px;
  }
  @media (min-width: @screen-md-min) {
    padding-left: 15px;
  }
}

.include-subtext {
  span {
    //text-transform: uppercase;
  }
  li:hover .text-muted,
  li:hover .muted {
    color: #363636 !important;
  }
  li.selected .text-muted,
  li.selected .muted {
    color: #363636 !important;
  }
}

.delivery-status .status-text {
  float: left;
  //margin-top: 3px;
}

@import "../components/tables.less";
table.list {
  #table-style > .table-global;
}

// Footer
.footer {
}

.back-to-top-wrapper {
  padding-top: 24px;
  margin-bottom: 40px;
  position: relative;
  border-bottom: 1px solid @border-color;
}

.back-to-top {
  position: absolute;
  right: 12px;
  top: 0;
  color: #999;
  &:hover {
    text-decoration: none;
  }
}

.main-content .container .chart-histogram-container {
  margin: 0;
}

@import "../components/entity-jump.less";
//
// Custom audience popup form
//-------------------------------------------------------

.pt7 {
  padding-top: 7px;
}

.nogut-a1 {
  margin-left: -@grid-gutter-width;
}

.close-a1 {
  font-size: 17px !important;
  float: none;
  margin-left: 10px;
}

.popup-form {
}

.display-block {
  display: block;
}

// image preview

.object_preview a {
  position: relative;
  display: block;
}

.object_preview a .object-html {
  opacity: 1;
}

.listGrid td .object_preview .fb-ad-list img {
  margin: 0;
  height: 72px;
}

.object_preview .fa-search {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 34px;
  height: 34px;
  z-index: 999;
  font-size: 34px;
  color: #00a1cb;
  display: none;
  margin: auto;
  opacity: 0;
}

.object_preview a:hover .fa-search {
  display: block;
  opacity: 0.7;
  .transition(opacity 0.3s ease-out);
}

.object_preview a:hover .object-html {
  opacity: 0.3;
  .transition(opacity 0.3s ease-out);
}

.object_preview .no_preview {
  color: #353535;
  display: block;
  margin-top: 15px;
  text-align: center;
}

/* // SVG icons
@import "components/icons.less";
.icon-custom {
  @size: 21px;
  width: @size;
  height: @size;
  background-repeat: no-repeat;
  background-size: @size @size;
  display: inline-block;
  margin: 8px 13px 0 -1px;
  float: left;
}
*/
.channel-icons {
  font-size: 28px;
  color: #A6A5A5;
}

.error-message {
  font-size: 13px;
}

.alert-danger a,
.error-message a {
  color: #a94442;
  text-decoration: underline;
}

/* Undercover Notification */
.alert-undercover {
  margin-bottom: 0;
  padding: 10px 20px;
}

.alert-warning.alert-undercover {
  border-bottom: 1px solid #eeeade;
}

.alert-warning.alert-undercover{
  a, span.role-switch-style {
    color: @alert-warning-text;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
  }

  span.role-switch-style:hover {
    text-decoration: underline;
  }

  span.role-switch-style.loading {
    margin-left: 5px;
  }

  span.role-switch-style.loading:after {
    display:block;
    float:left;
    content: '\f110';
    line-height: 22px;
    font-family: "Font Awesome 5 Free";
    -webkit-animation: rotation 2s infinite linear;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
}

/* Dropdown A-1 */
.dropdown-a1 {
  top: 5px;
  left: 45px;
  background: @brand-cyan;
  border: 0;
  min-width: 120px;
  padding: 0;
  &.dropdown-menu a {
    color: #fff !important;
    padding: 5px 15px;
    font-size: 15px;
    &:hover,
    &:focus {
      background: darken(@brand-cyan, 10%);
      color: #fff;
    }
  }
  &.dropdown-a1-has-arrow {
    &.dropdown-menu > li:first-child:before {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 9px;
      border-right-color: @brand-cyan;
      margin-left: -18px;
      top: 6px;
    }
    &.dropdown-menu > li:first-child:hover:before {
      border-right-color: darken(@brand-cyan, 10%);
    }
    &.dropdown-menu > li:first-child:hover a {
      background: darken(@brand-cyan, 10%);
      color: #fff;
    }
  }
}

.btn-a1 {
  padding: 14px 7px;
  font-size: 16px;
  line-height: 1em;
  color: @brand-cyan;
  background: #F2F2F2;
  &:active {
    .box-shadow(none);
  }
  &:hover,
  &:focus {
    color: darken(@brand-cyan, 10%)
  }
}

.login-fast-switch {
  font-size: 10px;
  padding: 7px 7px;
}

// Dashed line on grid
.grid-data {
  position: relative;
  .ui-jqgrid .listGrid tr.jqgrow td.grid-checkbox-cell,
  .all_checkbox {
    position: relative;
    .httpool-checkbox-button {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  .ui-dialog {
    background: transparent;
  }
}

.dash-border-t1:after,
.dash-border-t2:after {
  content: "";
  width: 1px;
  border-left: 1px dashed rgb(215, 215, 215);
  height: auto;
  position: absolute;
  left: 9px;
  top: 9px;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.dash-border-t2:after {
  top: 22px;
  bottom: 22px;
  left: 14px;
}

/*Form Control*/
@media (min-width: @screen-sm-min) {
  .form-horizontal .control-label {
    text-align: left;
  }
}

.minh-30 {
  min-height: 30px;
}

.minh-170 {
  min-height: 170px;
}

// UPLOAD AD IMAGE

#upload_image.form label.caption {
  padding: 0;
  width: 0;
}

#upload_image.form .button_container {
  width: inherit;
}

.error-message {
  font-size: 13px;
  word-wrap: break-word;
}

#upload_image.form .uploadSpacing {
  margin-top: 16px;
  right: 0;
  position: absolute;
  margin-right: 30px;
}

#upload_image.form label.caption {
  width: 0;
  padding: 0;
}

#groupListGridDiv .lock-info a {
  font-size: 15px;
  .fa-lock {
    color: #b7b7b7;
  }
}

.fb_ad_status a {
  display: inline;
}

.httpool-checkbox-button {
  margin-right: 4px;
}

.label-text {
  display: inline-table;
  width: 60%;
}

.delivery-status .syncing-status {
  float: left;
  padding-right: 5px;
  margin-top: -1px;
  .icon-status {
    font-size: 14px;
  }
}

.delivery-status .automated-status {
  margin-left: 15px;
}

.delivery-status .automated-status .icon-status {
  font-size: 14px;
}

table .syncing-status {
  padding-left: 5px;
}

table .group-name-status .syncing-status {
  padding-left: 0;
}

.input-clear {
  position: absolute;
  height: 16px;
  line-height: 1em;
  top: 9px;
  right: 23px;
  font-size: 16px;
  font-weight: bold;
  color: #999;
  display: inline-block;
}

.input-clear,
.input-clear:hover,
.input-clear:focus {
  text-decoration: none;
}

.list-group-a1 {
  margin-right: 1px;
  margin-top: 12px;
  font-size: 12px;
  color: #333;
  .list-group-item {
    padding: 8px 10px;
  }
  .list-group-item:hover {
    background-color: #e6e6e6;
  }
}

.user-errors {
  .error-messages {
    ul {
      margin: 15px;

      li {
        list-style-type: disc;
      }
    }
  }
}

body.popup {
  margin-bottom: 0;
}

#previewAd {
  margin-top: 40px;
  .preview-container {
    min-height: 500px;
  }
  .loader-container {
    width: 98%;
    height: 24px;
    text-align: center;
    margin: 10px 0;
  }
  .externalLink {
    background: #fff;
    padding: 0;
    margin: auto;
    .linkPreview {
      color: #b3b3b3;
      padding: 1px 7px 1px 0;
      white-space: nowrap;
      display: inline-block;
      .linkIcon {
        padding-right: 4px;
        vertical-align: middle;
      }
      .linkCaption {
        font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Verdana, sans-serif;
      }
    }
  }
}

.modal-header {
  letter-spacing: 0;
  line-height: 1.4;
}

.modal-body {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0;
  line-height: 1.42857143;
}

.modal .close {
  font-family: 'Open Sans', sans-serif;
}

.annotatedListSpacer.disabled:hover:before {
  color: #ccc !important;
}

.imgContainer label div.httpool-radio-button {
  padding: 0 8px;
  float: left;
  width: 15px;
  height: 15px;
}

#adCreationToggler {
  margin-top: 12px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E8E8E8;
}

#adCreationToggler {
  .tri-up {
    display: none;
  }
  .active .tri-up {
    display: block;
    position: absolute;
    bottom: -23px;
    cursor: default;
    width: 28px;
    height: 14px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}

.mp-container {
  position: relative;
  .tabs-notification {
    position: absolute;
    color: #E44040;
    top: 8px;
    left: 150px;
  }
  .nav-tabs {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

#geo-targeting-container {
  .cornerRow .move-to-left {
    margin-left: 15px;
  }
  .Select-arrow {
    display: block;
  }
}

.value.row-narrow {
  margin-right: 15px;
}

.text-core .text-wrap.p-relative {
  position: relative;
}

.Select.show-arrow .Select-arrow {
  display: block;
}

.dash-border-t1.hideDottedLine:after {
  border-left: none;
}

.form input.has-error {
  background-color: #FFF4F5;
}

.keyword-select-widget .badge {
  background-color: #fff;
  color: #00a1cb;
}

.keyword-select-widget .Select-placeholder {
  padding: 5px 52px 7px 10px;
}

.keyword-select-widget .Select-item-label {
  padding: 4px 5px;
}

.keyword-select-widget .Select-item-icon {
  padding: 4px 5px 2px;
  line-height: 17px;
}

.keyword-select-widget .Select-clear {
  line-height: 21px;
}

.keyword-select-widget .Select-item {
  background-color: #eee;
}

.Select.keyword-select-widget.is-multi .Select-input {
  padding: 2px 0;
}

.form .ondemand-box {
  padding-top: 12px;
}

.followers img {
  border-radius: 50%;
}

.twBudgetLimit {
  display: inline-block;
  padding-left: 5px;
}

.twAudienceEstimatesTable {
  table-layout: fixed;
}

.twAudienceEstimatesTable td, .twAudienceEstimatesTable th {
  width: 50%;
  vertical-align: middle !important;
}

.indent-20 {
  margin-left: 20px;
}

#fieldset_targeting_mobile #targeting-mobile-devices .value .last-row-label > label {
  margin-top: 7px;
  margin-bottom: 0;
}

.twProgress.progress {
  background-color: #ddd;
}

.twProgress .progress-bar {
  background-color: #00a1cb;
}

.twProgress.completed .progress-bar {
  background-color: #7BD148;
}

.twProgress .progress-bar[aria-valuenow="0"] {
  background-color: transparent;
}

.tweets-app {
  height: 250px;
  overflow: auto;
}

.tweets-app blockquote {
  padding: 5px 10px 5px 10px;
  margin: 0 0 5px;
  font-size: 12px;
  border-left: 2px solid #eee;
  position: relative;
  background-color: #fafafa;
}

.tweets-app cite {
  padding-left: 10px;
}

.tweets-app p {
  padding-left: 42px;
  margin-bottom: 0;
  margin-right: 20px;
}

.tweets-app img {
  float: left;
  width: 32px;
}

.tweet-app .tweet-select {
  float: right;
}

.tweets-app blockquote label {
  position: absolute;
  right: 5px;
  top: 5px;
}

.form .tweets-container .full {
  max-width: 100% !important;
  width: 100% !important;
}

.progress-bar > span {
  position: absolute;
  right: 10px;
  color: #444;
}

.estimates .table > tbody > tr > th,
.estimates .table > thead > tr > th,
.estimates .table > tbody > tr > td,
.estimates .table > thead > tr > td {
  padding: 8px 0 8px 0;
}

.annotatedListCategoryToggle {
  cursor: pointer !important;
}

.twLoader {
  font-size: 2em;
}

.modal-header .close {
  margin-top: -5px !important;
}

.twLinkFocusColor:hover, .twLinkFocusColor:focus {
  color: #00a1cb;
}

.adPreview {
  margin-top: 20px;
  position:relative;
}

.select-columns {
  margin-top: 20px;
}

.campaign-list .select-columns {
  margin-top: 0;
}

.column-margin-top-fix.select-columns {
  margin-top: -40px;
}

.campaign-list .menu-create {
  &.on-channel {
    .btn {
      margin-top: 0;
    }
  }
}

.is-disabled > .Select-control {
  background-color: #EEE;
  cursor: not-allowed;
}

.loader-small {
  font-size: 1.2em !important;
}


.loader-smaller {
  font-size: 1.1em !important;
}

.loader-user-switch {
  color: #00A1CB;
  margin-top: 2px;
}

.loader-oneem {
  font-size: 1em !important;
}

.account-row {
  margin-bottom: 10px;
}

.twCondAlert {
  padding-top: 20px;
  clear: both;
}

.slim-panel .panel-body {
  padding: 0 !important;
}

.colWithTooltip {
  border-bottom: 1px dotted #777;
}

.lowercase {
  text-transform: lowercase;
}

div.value.more-options-label {
  margin-bottom: 0;
}

.more-options-label:hover {
  cursor: pointer;
  text-decoration: underline;
}

.form input.value.accountFilterInput {
  width: 99%;
}

textarea.value.input-invalid,
textarea.input-invalid,
input.value.input-invalid,
input.input-invalid,
.input-invalid {
  border: 1px solid rgb(229, 64, 40) !important;
}

.select-input-invalid {
  border: 1px solid rgb(229, 64, 40) !important;
  border-radius: 3px;
}

#products_container .text-uppercase {
  margin-bottom: 5px;
}

#products_container .text-uppercase:first-child {
  margin-top: 0;
}

.error-color .help-icon,
.error-color {
  color: #E54028;
}

.text-capitalize {
  text-transform: capitalize;
}

.thumb-20 {
  width: 20px;
  height: 20px;
}

.thumb-text {
  margin-bottom: 8px;
  margin-top: -3px;
  min-height: 20px;
}

.pageBoxGroup {
  max-width: 700px;
  min-height: 70px;
  background: #f1f1f1;
  margin-right: 15px;
  position: relative;
  overflow: visible;
  padding: 18px 10px 10px 18px;
  border-bottom: 3px solid #e8e8e8;
}

.scroll-y {
  overflow-y: auto
}

.pageBoxGroup .scroll-y {
  max-height: 200px;
}

.refresh-button-wrapper {
  position: absolute;
  top: -20px;
  right: 0;
  z-index: 2;
}

.disabled {
  > a,
  > a:hover,
  > a:focus,
  > span {
    color: #666;
    cursor: not-allowed;
  }
}

.import-progressBar {
  background-color: #666;
  color: #666;
}

.twProgress .progress-bar.animatedBackground {
  background-color: #00a1cb;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}

.link-disabled:hover,
.link-disabled:focus,
.link-disabled {
  color: inherit;
  text-decoration: none;
  cursor: default;
}

.form input.disabled {
  background: rgb(242, 239, 237);
  cursor: not-allowed
}

.max-large {
  max-width: 700px;
}

/** Info row  */
.text-row {
  .caption {
    width: 200px;
    float: left;
    clear: left;
  }

  .value {
    margin-left: 200px;
    padding-right: 10px;
    width: inherit !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  clear: both;
  margin-bottom: 5px;
}

.input-semi-long {
  width: 220px;
}

.user-entity-search .loader-normal {
  position: absolute;
  right: 25px;
  font-size: 1em;
  top: 10px;
}

.user-entity-search a.search-container {
  text-decoration: none;
  color: @text-color;
  cursor: text;
}

.user-entity-search .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 40px;
}

.user-entity-search .dropdown-menu a {
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-entity-search .input-clear {
  right: 10px;
  cursor: pointer;
}

.user-entity-search .input-clear:hover {
  color: @text-color;
}

.user-entity-search .icon-custom {
  padding-right: 5px;
  color: #A6A5A5;
}

.user-entity-search .dropdown.btn-group,
.user-entity-search .btn {
  display: block!important;
}

.user-entity-search .dropdown-toggle.btn.btn-default {
  padding: 0;
  margin: 0;
  display: block!important;
  width: 100%;
  font-weight: normal;
}

.user-entity-search .dropdown.btn-group,
.user-entity-search .dropdown.btn-group .btn:first-child,
.user-entity-search .dropdown.btn-group .btn:last-child {
  border-radius: 0;
  border: none;
}

.url-parameters {
  display: inline-block;
}

.copyGroupsModal span.error {
  display: inline-block;
  color: #E54028;
  font-size: 11px;
  padding-top: 4px;
  line-height: 1;
}

.ad-search .input-clear {
  cursor: pointer;
}

.ad-search .input-clear:hover {
  color: @text-color;
}

.recommendation-info .help-icon {
  color: #b7b7b7;
}

.fb-info-block {
  ul {
    margin-top: 5px;
  }

  li {
    padding-left: 20px;
  }

  li > i {
    position: absolute;
    margin-left: -10px;
    font-size: 18px;
    color: #00a1cb;
  }
}

.bulk-edit-field .Select {
  width: 220px;
}

.bulk-edit-bid {
    .Select {
      width: 220px;
      display: inline-block;
    }

    input.value {
        width: 100px;
    }
}

.bulk-edit-budget-checkbox {
  margin-top: 1px;
}

.bulk-edit-input-type .Select {
    width: 220px;
}

.borderedSubhead {
  border-bottom: 1px solid rgb(238, 238, 238);
  padding-bottom: 10px;
  margin-bottom: 20px;
  padding-left: 4px;
  margin-top: 15px;
}

.hiddenLabel, .hiddenIcon {
  display: none;
  visibility: hidden;
}

ul.main-navigation-menu li.active > ul.sub-menu {
  display: none;
}

.report-search .loader-normal {
  position: absolute;
  right: 25px;
  font-size: 1em;
  top: 10px;
}

.report-search a.search-container {
  text-decoration: none;
  color: @text-color;
  cursor: text;
}

.report-search .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 40px;
}

.report-search .dropdown-menu a {
  text-overflow: ellipsis;
  overflow: hidden;
}

.report-search .input-clear {
  right: 10px;
  cursor: pointer;
}

.report-search .input-clear:hover {
  color: @text-color;
}

.report-search .icon-custom {
  padding-right: 5px;
  color: #A6A5A5;
}

.report-search .dropdown.btn-group,
.report-search .btn {
  display: block!important;
}

.report-search .dropdown-toggle.btn.btn-default {
  padding: 0;
  margin: 0;
  display: block!important;
  width: 100%;
  font-weight: normal;
}

.report-search .dropdown.btn-group,
.report-search .dropdown.btn-group .btn:first-child,
.report-search .dropdown.btn-group .btn:last-child {
  border-radius: 0;
  border: none;
}
