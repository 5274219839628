.pixel-code {
	width: 100%;
	height: 290px;
}

.pixel-toggler-container {
	position: relative;
	z-index: 1;
	margin-bottom: -11px;
}
