.dashboard-description {
  /*.formWidth();*/
  margin-bottom: 20px;
  min-height: 50px;
  position: relative;

  i {
	display: flex;
	padding: 10px 10px 10px 24px;
	font-style: normal;

	&:before {
	  font-family: 'Font Awesome 5 Free';
	  font-style: normal;
	  font-size: 50px;
	}

	span {
	  text-align: justify;
	  padding: 10px 10px 10px 25px;
	}
  }
}
