.gutter-sm {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.gutter-sm-first {
  padding-left: 15px !important;
}

.no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-gutter-first {
  padding-left: 0 !important;
}

.no-gutter-last {
  padding-right: 0 !important;
}

@media (max-width: @screen-sm-max) {
  .no-gutter {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: @screen-lg-min) {
  .no-gutter {
    margin-right: auto;
    margin-left: auto;
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
  }
}

.gutter-m1 {
  margin-left: -10px;
  padding-left: 0 !important;
}

.row.gutter-0 {
  margin-left: 0;
  margin-right: 0;
  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.row.gutter-10 {
  margin-left: -5px;
  margin-right: -5px;
  [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.row.gutter-20 {
  margin-left: -10px;
  margin-right: -10px;
  [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}