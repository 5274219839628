th.center, td.center {
	text-align: center;
}
td.center .dropdown-menu {
	text-align: left;
}
.table thead tr {
	color: #707070;
	font-weight: normal;
}
.table.table-bordered thead tr th {
	vertical-align: middle;
}
.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {

	vertical-align: middle;
}

.editable-element {
	border-bottom: 1px dashed #0088CC;
	text-decoration: none;
	opacity: 0.5;
	display: inline;
}