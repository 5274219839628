.age-container {
	margin-bottom: 5px;
}
.age-container > li,
.age-container div > li{
	float: left;
	min-width: 20px;
	text-align: center;
	display: block;
}

.add-age-segment,
.remove-age-segment {
	padding: 0 0 0 4px;
}

.add-age-segment span,
.remove-age-segment span{
	cursor: pointer;
}
