#table-style {
	.table-global {
		background: #fff;
		thead th,
		tfoot td {
			background: #f2f2f2;
		}
		td {
			padding-top: 4px;
			padding-bottom: 4px;
			border: 1px solid #e4e4e4;
			border-top: 0;
		}
		tfoot {
			td {
				border: none;
				//padding: 10px 8px 10px 0;
			}
		}
		tbody tr td:first-child {
			padding-left: 18px;
			position: relative;
		}
		thead tr th {
			vertical-align: bottom;
			border-bottom: 1px solid #ddd;
		}
		.no-border{
			border: none;
		}
		.action-cell{
			padding: 0;
			background: #f2f2f2;
			width: 20px;
			border-top: 0;
		}
		.campaign-cell {
			border-left: 0;
			width: 298px; //340-42
		}
		.icon-cell:first-child {
			border-right: 0;
			padding: 8px 1px 0 13px;
			max-width: 42px;
		}
		// Fix for last col to have 100%
		.list-first-wide {
			width: 340px!important;
		}
		.minw{
			min-width: 105px;
		}
		.fullw{
			width: 100%;
			font-size: 0px;
			line-height: 0;
			padding: 0!important;
		}
	}
}

.scrollingTable {

	table {
		width: 100%;
		border-spacing: 0;
	}

	 th, td, tr, thead, tbody {
		display: block;
		font-size: 13px;
	 }

	 thead tr {
		/* fallback */
		width: 97%;
		/* minus scroll bar width */
		width: -webkit-calc(100% - 16px);
		width:    -moz-calc(100% - 16px);
		width:         calc(100% - 16px);
	}

	 tr:after {
		content: ' ';
		display: block;
		visibility: hidden;
		clear: both;
	}

	 tbody {
		height: 400px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	 tbody td,
	 thead th {
		width: 50%;
		float: left;
	 	color: #707070;
	 	font-family: 'Roboto', 'sans-serif';
	}

	tr:nth-child(2n+2) {background: #f4f7f9}

	tbody td:last-child, thead th:last-child {
		border-right: none !important;
	}

	tbody td{
		height: 25px;
		padding-top: 3px !important;
		padding-bottom: 0 !important;
	}


	.table-head-dark {
		background-color: #e9ecef !important;
	}

	.table-head-dark > tr > th {
		color: #707070 !important;
		border-style: none;
		border-top: none !important;
		-webkit-user-select: none; /* Safari 3.1+ */
		-moz-user-select: none; /* Firefox 2+ */
		-ms-user-select: none; /* IE 10+ */
		user-select: none; /* Standard syntax */
		cursor: pointer !important;
		font-weight: normal !important;
	}

	.sorting-button{
		padding: 2px 0 0 10px;
		vertical-align: top;
		background-color: #e9ecef !important;
		border-style: none;
		font-size: 11px;
		color: #707070 !important;
		-webkit-user-select: none; /* Safari 3.1+ */
		-moz-user-select: none; /* Firefox 2+ */
		-ms-user-select: none; /* IE 10+ */
		user-select: none; /* Standard syntax */
	}

	.country-names{
		padding-left: 20%;
		overflow:hidden !important;
		text-overflow: ellipsis;
		white-space: nowrap;
		display:inline-block;
	}

	.number-data{
		padding-right: 20%;
	}
}

.highlight-enter,
.highlight-enter-active {
	background-color: #fce4ec;
	transition: background-color 500ms;
}
.highlight-exit,
.highlight-exit-active {
	background-color: #fff;
	transition: background-color 500ms;
}
.highlight-enter-done{
	background-color: transparent;
	transition: none;
}

.table .audienceName {
  max-width: 214px;
  width: 214px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

