.transform(@x, @y) {
  -webkit-transform: translate(@x, @y);
  -moz-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y);
  -o-transform: translate(@x, @y);
  transform: translate(@x, @y);
}

.animation-name(@name) {
  -webkit-animation-name: @name;
  -moz-animation-name: @name;
  -o-animation-name: @name;
  animation-name: @name;
}

.animation-duration(@duration) {
  -webkit-animation-duration: @duration;
  -moz-animation-duration: @duration;
  -o-animation-duration: @duration;
  animation-duration: @duration;
}

.animation-timing-function(@func) {
  -webkit-animation-timing-function: @func;
  -moz-animation-timing-function: @func;
  -o-animation-timing-function: @func;
  animation-timing-function: @func;
}

.animation-delay(@duration) {
  -webkit-animation-delay: @duration;
  -moz-animation-delay: @duration;
  -o-animation-delay: @duration;
  animation-delay: @duration;
}

.in-left-rule {
  from {
    .transform(-270px, 0);
  }
  to {
    .transform(-24px, 0);
  }
}

.out-left-rule {
  from {
    .transform(-24px, 0);
  }
  to {
    .transform(-270px, 0);
  }
}

.keyframes(slideOutLeft, .out-left-rule());

.keyframes(slideInLeft, .in-left-rule());

.in-left-enter-active {
  .animation-name(slideInLeft);
  .animation-duration(0.3s);
  .animation-timing-function(linear);
}

.in-left-exit {
  .transform(-24px, 0);
}

.in-left-exit.in-left-exit-active {
  .animation-name(slideOutLeft);
  .animation-duration(0.3s);
  .animation-timing-function(linear);
}
