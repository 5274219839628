@import (less) "ag-grid/ag-grid.css";
@import (less) "ag-grid/ag-theme-bootstrap.css";

.ag-bootstrap {
  .gridOverlay {
    position: absolute;
    left: -50px;
    right: -50px;
    bottom: 0;
    top: 0;
    height: 100%;
    z-index: 999;
    background-color: #f2f2f2;
    padding-top: 365px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .ag-row-group.ag-row-level-0 > div {
    background: #f2f2f2;
  }

  .ag-row-group.ag-row-level-1 > div {
    background: #f7f7f7;
  }

  .ag-row-group.ag-row-level-2 > div {
    background: #fbfbfb;
  }

  .ag-header-row {
    border-bottom: 1px solid #e4e4e4;
  }

  &.details-page-twitter {
    position: relative;
  }
}



.details-screen {
  .ag-bootstrap {
    margin-bottom: 0;

    .ag-header-cell.select-all-checkbox {
      padding: 15px 7px 7px 0;
    }

    .ag-row-level-1 .name-cell {
      border-left: 1px solid #ced7dd;
    }

    &.details-page-twitter {
      .ag-header-cell:not(.select-all-checkbox) {
        cursor: pointer;
      }
    }

    .ag-row {
      .ag-cell:not(.select-checkbox):not(.select-all-checkbox) {
        line-height: 25px;
      }
    }

    .ag-row-level-0 .name-cell {
      text-align: left;
    }

    .ag-header-group-cell-label {
      text-align: center;
    }

    .ag-header-group-cell {
      padding: 7px;
    }

    .ag-header,
    .ag-body,
    .ag-pinned-left-cols-viewport {
      border-bottom: 2px solid #ced7dd;
    }

    .ag-body,
    .ag-pinned-left-cols-viewport {
      border-bottom-width: 1px;
    }

    .ag-pinned-left-cols-viewport {
      border-left: 1px solid #ced7dd;
      border-right: 1px solid #ced7dd;
    }

    .ag-header-cell {
      background: #fff;
      font-weight: bold;

      .ag-header-cell-label {
        padding: 7px 7px;
      }
    }

    .ag-header-cell,
    .ag-ltr .ag-header-cell,
    .ag-cell-not-inline-editing,
    .ag-ltr .ag-cell-last-left-pinned {
      border-right: 1px solid #ced7dd;
    }

    .ag-header-row {
      border-bottom: none;
    }

    .ag-cell-not-inline-editing {
      padding: 12px 7px;
      border-left: none;
    }

    .ag-cell.select-checkbox {
      text-overflow: initial;
    }

    .ag-icon-expanded,
    .ag-icon-contracted {
      display: inline-block;
      vertical-align: middle;
    }

    .ag-header .ag-pinned-left-header .ag-header-cell,
    .ag-cell.ag-first-column {
      text-align: right;
    }

    .ag-cell-not-inline-editing.text-left,
    .ag-header .ag-pinned-left-header .ag-header-cell.text-left {
      text-align: left;
    }

    .admin-left {
      background-color: #e9ecef;
    }

    .admin-left > .ag-cell-label-container {
      height: 100%;
      .ag-header-cell-label {
        justify-content: flex-start;
        border-bottom: 0;
        border-top: 1px solid #ced7dd;

        .ag-header-cell-text {
          margin-left: 20px;
          padding-left: 11px;
        }
      }
    }

    .admin-left > .ag-cell-label-container{
      height: 100%;
    }

    .admin-center > .ag-cell-label-container{
      height: 100%;
    }

    .ag-row-odd,
    .ag-row-odd .ag-cell-not-inline-editing {
      background-color: #f4f7f9;
    }

    .ag-row-even,
    .ag-row-even .ag-cell-not-inline-editing {
      background-color: #fff;
    }

    .ag-header-viewport {
      .ag-header-group-cell.ag-header-group-cell-with-group {
        font-size: 13px;
        text-transform: uppercase;
        border-right: 1px solid #ced7dd;
        border-bottom: 1px solid #ced7dd;
        border-left: 1px solid #ced7dd;
        //background-color: #e9ecef;
      }

      .ag-header-cell.ag-header-cell-sortable {
        font-size: 13px;

        .totalValue {
          color: #999;
        }

        //text-transform: uppercase;
        //background-color: #e9ecef;
      }

    }

    .text-left-middle-grid {
      line-height: 15px;

      .dropdown {
        bottom: 0;
      }
    }

    .ag-header .ag-pinned-left-header .ag-header-cell.text-left {
      background-color: transparent;
    }

    .ag-header {
      .ag-header-cell-label {
        display: flex;
        justify-content: flex-end;

        .ag-header-cell-text {
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .ag-pinned-left-header .ag-header-cell {
        .ag-header-cell-label {
          display: flex;
          justify-content: flex-start;

          .ag-header-cell-text {
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

        }
      }
    }

    .ag-header-cell {
      &.text-left {
        justify-content: flex-start;
      }
    }

    .ag-pinned-left-cols-viewport {
      .ag-cell.select-checkbox {
        background: 0 0;
        padding: 12px 17px;
      }
    }

    .ag-row-even {
      background: 0 0;
    }

    .ag-row-odd {
      background: 0 0;
    }
  }

  .ag-header-group-cell {
    .ag-header-expand-icon {
      right: 7px;
      position: absolute;
    }

    .ag-header-group-text {
      margin-top: 1px;
      left: 7px;
      right: 20px;
      position: absolute;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ag-header-icon {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 2px;
  }

  .twLinkFocusColor.entityName,
  .twLinkFocusColor.groupName,
  .twLinkFocusColor.campaignName {
    max-width: calc(~'100% - 25px');
  }

  .panel-light .panel-body {
    background-color: transparent;
    border: none;
  }

  .text-metric {
    background-color: transparent;
  }

  .bid-amount-input, .inlineStartTimeDatePickerContainer {
    .errorInput {
      outline: 1px solid #e54028;
      outline-offset: -2px;
    }

    .error-overlay {
      color: #e54028;
      position: absolute;
      left: 8px;
      z-index: 999;
    }

    .edit-overlay {
      left: 8px;
      position: absolute;
      z-index: 999;
      line-height: 20px;
      font-size: 15px;
      padding-top: 5px;
    }

    .edit-overlay i {
      cursor: pointer;
    }

    .edit-overlay {
      .saveBtn {
        color: #00a1cb;
      }
    }

    .edit-overlay {
      .saveBtn:hover {
        color: #53BFDB;
      }

      .cancelBtn:hover {
        color: black;
      }
    }

    .edit-overlay i:hover {
      color: #00a1cb;
    }

    div.errorInput {
      width: calc(100% + 1px) !important;
      height: calc(100% + 2px) !important;
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      text-align: right;
      padding-right: 7px;
      outline: 1px solid #e54028;
      padding-left: 25px;
      font-size: 13px;
    }

    div.datePickerValue {
      width: 100%;
      height: 50px;
      text-align: right;
      padding-right: 6px;
      outline-color: #00a1cb;
    }

    input {
      width: 100% !important;
      height: calc(100% + 2px) !important;
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      text-align: right;
      padding-right: 6px;
      border-color: #00a1cb;
      outline-color: #00a1cb;
      outline-width: 2px;
      padding-left: 25px;
      font-size: 13px;
    }

    line-height: 50px;

    & .Select {
      position: absolute;
      width: 100%;

      .Select-menu-outer {
        width: 200px;
      }

      .Select-control {
        width: 100% !important;
        height: calc(100% + 2px) !important;
        position: absolute;
        top: auto;
        text-align: right;
        padding-right: 6px;
        border-color: #00a1cb;
        outline-color: #00a1cb;
        outline-width: 2px;
        padding-left: 25px;
        font-size: 13px;
      }
    }

    .dropdown.btn-group {
      width: 100%;
      height: 48px;
      position: absolute;
      top: 1px;
      left: 1px;

      #bid-type-dropdown {
        width: calc(100% - 2px);
        height: 100%;
        border: none;
        text-align: right;
        font-weight: 500;
      }
    }

    .bid-type-input {
      padding: 10px 6px 10px 25px;

      &:after {
        background-color: grey;
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        z-index: -1;
      }
    }

    .inline-label {
      color: #a7a7a7;
      right: 8px;
      position: absolute;
      z-index: 999;
      line-height: 20px;
      top: 2px;
      text-transform: uppercase;
      font-size: 10px;
    }
  }

  .edit-overlay {
    top: 1px;
  }

  .showOnHover {
    opacity: 0;
    cursor: pointer;
    top: 20px;
    left: 10px;
    line-height: 10px;
    position: absolute;
    color: #00a1cb;
  }

  .ag-row-level-0 .ag-cell-not-inline-editing:hover .showOnHover {
    opacity: 1;
  }

  .ag-cell .resultCell {
    line-height: 15px;
    margin-top: -3px;

    .result-objective {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ag-cell .kpi-cell {
    margin-top: -9px;

    .kpi-value {
      display: block;
      line-height: 10px;
    }
  }

  .pacing-col {
    margin-top: -10px;
  }

  .progress.ag-grid-progress {
    background-color: #eee;
    height: 10px;
    position: relative;

    > .progress-bar.over {
      background-color: #e54028;
      position: absolute;
      top: 0;
    }
  ;

    > .progress-bar {
      background-color: #00a1cb;
      position: absolute;
      z-index: 999;
    }
  }

  .ag-status-bar {
    line-height: 50px;
    padding-right: 10px;

    .ag-name-value {
      padding-left: 20px;
    }

    .ag-name-value-value {
      font-weight: 700;
    }

    .ag-status-bar-right {
      margin-right: 70px;
    }
  }
}


.ag-bootstrap .ag-cell-not-inline-editing {
  box-sizing: border-box;
  background-color: #fff;
  text-align: right;
  border-bottom: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  padding: 0 8px 4px 0;
  outline: 0;
}

.text-left-middle-grid .dropdown {
  position: absolute;
  right: 0;
  top: 0;
  background: #f2f2f2;
  border-left: 1px solid #e4e4e4;
}

.ag-bootstrap .list-entity-menu {
  width: 25px;
}

.ag-bootstrap .ag-menu-separator {
  border-top: 1px solid #d3d3d3;
}

.ag-bootstrap .ag-header-group-cell-label {
  padding: 2px;
  padding-top: 4px;
}

.details-screen .ag-bootstrap .ag-header-group-cell {
  padding: 7px;
}

.ag-bootstrap .ag-cell-highlight {
  background-color: rgb(204, 239, 255) !important;
  //border-right: 1px solid rgb(150, 150, 150)!important;
  //border-bottom: 1px solid rgb(150, 150, 150)!important;
}

.ag-bootstrap .ag-cell-highlight-animation {
  transition: all 1s !important;
}

.ag-bootstrap {
  .ag-menu {
    background-color: #00a1cb;
    color: white;
    border: none;
    margin-left: 45px;
    margin-top: -18px;
    overflow-y: visible;

    .ag-menu-option-active {
      background-color: #007998
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 9px;
      border-right-color: #00a1cb;
      margin-left: -18px;
      top: 6px;
    }

    .ag-menu-option {
      padding: 6px 11px;
      display: block;
    }
  }
}

.ag-bootstrap {
  .ok  {
    color: green;
  }

  .danger{
    color: red;
  }

}

.name-link-cell {
  color: #1997f0;
}

.name-link-cell:hover {
  color: #1997f0;
}

.ag-cell-inline-editing {
  overflow: visible !important;
}

.admin-entity-name {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  flex-shrink: 0;
}

.admin-entity-name-menu {
  min-width: 25px;
}

.admin-menu-disabled {
  cursor: default !important;
  opacity: 40%;

  &:hover {
    color: #959595 !important;
  }
}

.no-max-width {
  max-width: none !important;
}
