.sans{
	font-family: 'Open Sans';
}
.bold{
	font-weight: bold;
}
.negative-offset-l{
	margin-left: -@content-margin;
}
.negative-offset-l-1{
	margin-left: (-(@content-margin - 5px));
}