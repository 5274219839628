.post-text {
	width: 350px;
	height: 90px !important;
	resize: none;
}

.form-row-narrow .caption {
	width: 130px !important;
	padding: 4px 5px 4px 0;
}

.form-row-narrow .value {
	margin-left: 130px !important;
}

.edit-creative {
	.cancel-btn {
		float: left;
	}
}

.recommendation {
	color: #999;
	padding: 5px;
}

ul .remove-selected-img {
	margin-bottom: 5px;
	margin-top: 0;
	width: 28px;
}

.edit-creative.modal {
	min-width: 610px;
}
.modal.be-greyed-out {
	z-index: 9;
}

.image-select-modal .modal-dialog {
	width: 600px !important;
	position: relative !important;
	top: 50px !important;
}

.image-select-modal-inner .modal-dialog {
	width: 680px !important;
	top: 60px !important;
}

.image-select-modal-deeper .modal-dialog {
	width: 600px !important;
	top: 50px !important;
}

.load-in-progress .modal-body {
	min-height: 200px;
}

.load-in-progress .loader-normal {
	margin-top: 70px;
}

.carousel-in-modal .white-tabs .nav-tabs {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.modal .carousel-in-modal .close {
	font-size: 22px;
}

.carousel-in-modal .nav-tabs-separator {
  margin: 0 -20px;
}

.slideshow .specification {
	float: left;
	margin-bottom: 20px;
}

.slideshow .preview {
	width: 250px;
	height: 250px;
	float: right;
	margin-bottom: 20px;
	position: relative;
}

.slideshow .preview:hover {
	cursor:	pointer;
}

.slideshow .preview .slide {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
}

.slideshow .preview .control i {
	font-size: 60px;
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.5;
}

.slideshow .preview .control:hover i {
	opacity: 0.8;
}

.slideshow .preview .control .pause {
	font-size: 40px;
	display: none;
}

.slideshow .preview .control.playing:hover .pause {
	display: block;
}

.slideshow .preview .control.playing .play {
	display: none;
}

.slideshow .timeline {
	width: 100%;
	height: 130px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
}

.slideshow .timeline .slide {
	width: 90px;
	height: 130px;
	display: inline-block;
	float: left;
}

.slideshow .timeline .slide .time {
	width: 100%;
	height: 20px;
	line-height: 15px;
	border-bottom: 5px solid #cccccc;
	text-align: right;
	padding: 0 3px;
}

.slideshow .timeline .slide .element {
	width: 80px;
	height: 80px;
	margin: 5px;
	box-sizing: initial;
	background-position: center center;
	background-size: cover;
	position: relative;
}

.slideshow .timeline .slide .element .symbol {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(0%,-50%);
	font-size: 20px;
	color: #ffffff;
	display: none;
}

.slideshow .timeline .slide .element:hover .symbol {
	display: block;
}

.slideshow .timeline .slide .element .symbol a {
	color: #ffffff;
	text-shadow: 0 0 2px #000;
}

.slideshow .timeline .slide .element .close {
	opacity: 1;
}

.slideshow .preview .placeholder,
.slideshow .timeline .slide .add-slides,
.slideshow .timeline .slide .placeholder {
	background: #cccccc;
}

.slideshow .timeline .slide .add-slides {
	cursor: pointer;
}

.slideshow .timeline .slide .placeholder {
	cursor: default;
	background-image: none;
}

.slideshow .timeline .slide .add-slides .symbol {
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
	font-size: 40px;
	font-weight: bold;
	display: block;
}
