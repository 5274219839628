.cog-button {
  font-size: 20px;
  color: #192430;

  &:hover {
	color: grey;
	text-decoration: none;
  }

  i:before {
	font-family: 'Font Awesome 5 Free';
	content: '\f013';
	font-style: normal;
	font-weight: 400;
  }
}
