.metric-wrapper {
	min-width: 240px;
	font-size: 15px;
}
.metric-title {
	display: inline-block;
	text-align: right;
}
.metric-title,
.metric-val {
    font-size: 12px;
	overflow: hidden;
}
.metric-val {
    display: block;
	color: inherit;
	white-space: nowrap;
    color: #7d7d7d;
    padding-right: 7px;
}
.metric-arrow {
	color: #CACACA;
}
.metric-title,
.metric-count.badge {
	color: #999999;
}
.metric-arrow,
.metric-count.badge {
	margin-left: 3px;
	display: inline-block;
}
.metric-count.badge {
	background-color: #DDDDDD;
}
.metric-row {
    margin-right: 10px;
}
