.breadcrumb {
	margin: 0 -15px;
	border-radius: 0;
	padding: 15px 0px 15px 0px;
	border-bottom: 1px solid #C8C7CC;
	position: relative;
	height: 51px;
}
.breadcrumb i {
	color: #cccccc;
	font-size: 16px;
	display: inline-block;
}
.breadcrumb {
	margin: 0 -15px;
	border-radius: 0;
	padding: 12px 0px 15px 0px;
	border-bottom: 1px solid #C8C7CC;
	position: relative;
	height: 51px;
}