@-webkit-keyframes rotationRefresh {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.liveStatsRefresh {
  color: #1997F0;
  cursor: pointer;
}

.liveStatsRefresh.loading {
  -webkit-animation: rotationRefresh 2s infinite linear;
}

@media screen {
  @media (max-width: 1170px) {
    .liveStatsRow {
      width: 657px;
    }
  }
}