.pagination li a {
	cursor: pointer;
}
.pagination.squared {
	border-radius: 0 !important;
}
.pagination.squared li:first-child a, .pagination.squared li:first-child > span {
	border-bottom-left-radius: 0px !important;
	border-left-width: 1px;
	border-top-left-radius: 0px !important;
}
.pagination.squared li:last-child > a, .pagination.squared li:last-child > span {
	border-bottom-right-radius: 0px !important;
	border-top-right-radius: 0px !important;
}
.pagination.pagination-purple li a, .pagination-purple .pagination li a {
	background: #F5F4F9;
	border: none !important;
	color: #333333;
	display: inline-block;
	margin-right: 1px;
}
.pagination.pagination-purple li a:hover, .pagination-purple .pagination li a a:hover {
	background: #ECEAF3;
	color: #222222;
	cursor: pointer;
}

.pagination.pagination-purple li:first-child a, .pagination-purple .pagination li:first-child a {
	border: none !important;
}

.pagination.pagination-purple li.disabled a, .pagination-purple .pagination li.disabled a {
	color: #AAAAAA !important;
}
.pagination.pagination-purple li.disabled a:hover, .pagination-purple .pagination li.disabled a:hover, .pagination.pagination-purple li.disabled a:focus, .pagination-purple .pagination li.disabled a:focus {
	background: #F5F4F9;
	cursor: default !important;
}
.pagination.pagination-purple li.active a, .pagination-purple .pagination li.active a {
	background: #57517B !important;
	border: none !important;
	color: #FFFFFF !important;
	cursor: default !important;
	opacity: 1;
}
.pagination-purple [class^="fa-"], .pagination-purple [class*=" fa-"] {
	color: #57517B;
}
.pagination-purple li.disabled [class^="fa-"], .pagination-purple li.disabled [class*=" fa-"] {
	opacity: 0.5;
}

.pagination.pagination-teal li a, .pagination-teal .pagination li a {
	background: #F3F8F8;
	border: none !important;
	color: #333333;
	display: inline-block;
	margin-right: 1px;
}
.pagination.pagination-teal li a:hover, .pagination-teal .pagination li a a:hover {
	background: #D8E4E7;
	color: #222222;
	cursor: pointer;
}

.pagination.pagination-teal li:first-child a, .pagination-teal .pagination li:first-child a {
	border: none !important;
}

.pagination.pagination-teal li.disabled a, .pagination-teal .pagination li.disabled a {
	color: #AAAAAA !important;
}
.pagination.pagination-teal li.disabled a:hover, .pagination-teal .pagination li.disabled a:hover, .pagination.pagination-teal li.disabled a:focus, .pagination-teal .pagination li.disabled a:focus {
	background: #F3F8F8;
	cursor: default !important;
}
.pagination.pagination-teal li.active a, .pagination-teal .pagination li.active a {
	background: #569099 !important;
	border: none !important;
	color: #FFFFFF !important;
	cursor: default !important;
	opacity: 1;
}
.pagination-teal li [class^="fa-"], .pagination-teal li [class*=" fa-"] {
	color: #4D8189;
}
.pagination-teal li.disabled [class^="fa-"], .pagination-teal li.disabled [class*=" fa-"] {
	opacity: 0.5;
}
.pagination.pagination-green li a, .pagination-green .pagination li a {
	background: #EFFBEC;
	border: none !important;
	color: #333333;
	display: inline-block;
	margin-right: 1px;
}
.pagination.pagination-green li a:hover, .pagination-green .pagination li a a:hover {
	background: #CEF3C5;
	color: #222222;
	cursor: pointer;
}

.pagination.pagination-green li:first-child a, .pagination-green .pagination li:first-child a {
	border: none !important;
}

.pagination.pagination-green li.disabled a, .pagination-green .pagination li.disabled a{
	color: #AAAAAA !important;
}
.pagination.pagination-green li.disabled a:hover, .pagination-green .pagination li.disabled a:hover, .pagination.pagination-green li.disabled a:focus, .pagination-green .pagination li.disabled a:focus {
	background: #EFFBEC;
	cursor: default !important;
}
.pagination.pagination-green li.active a, .pagination-green .pagination li.active a {
	background: #3D9400 !important;
	border: none !important;
	color: #FFFFFF !important;
	cursor: default !important;
	opacity: 1;
}
.pagination-green [class^="fa-"], .pagination-green [class*=" fa-"] {
	color: #327B00;
}
.pagination-green li.disabled [class^="fa-"], .pagination-green li.disabled [class*=" fa-"] {
	opacity: 0.5;
}
.pagination.pagination-bricky li a {
	background: #FDF8F7;
	border: none !important;
	color: #333333;
	display: inline-block;
	margin-right: 0;
}
.pagination.pagination-bricky li a:hover {
	background: #FBEEEC;
	color: #222222;
	cursor: pointer;
}
.pagination.pagination-bricky li a {
	margin-right: 1px;
}
.pagination.pagination-bricky li:first-child a {
	border: none !important;
}
.pagination.pagination-bricky li.disabled a {
	color: #AAAAAA !important;
}
.pagination.pagination-bricky li.disabled a:hover, .pagination-bricky .pagination li.disabled a:hover, .pagination.pagination-bricky li.disabled a:focus, .pagination-bricky .pagination li.disabled a:focus {
	background: #FDF8F7;
	cursor: default !important;
}
.pagination.pagination-bricky li.active a {
	background: #C83A2A !important;
	border: none !important;
	color: #FFFFFF !important;
	cursor: default !important;
	opacity: 1;
}
.pagination.pagination-bricky [class^="fa-"], .pagination.pagination-bricky [class*=" fa-"] {
	color: #C83A2A;
}
.pagination.pagination-bricky li.disabled [class^="fa-"], .pagination.pagination-bricky li.disabled [class*=" fa-"] {
	opacity: 0.5;
}