
.apSubGrid.twGridCollapsable {
	.public_fixedDataTable_header.public_fixedDataTableRow_main:after {
		background: transparent;
	}

	.public_fixedDataTableRow_main {
		border: none;
	}

	.fdt-cellHeader {
		border-bottom: 1px solid #e4e4e4;
	}

	.edit-link {
		font-weight: normal;
		font-size: 14px;
	}

	.rowCollapsed, .rowExpanded {
		border-left: 1px solid #e4e4e4;
		border-bottom: 1px solid #e4e4e4;
	}

	.public_fixedDataTableRow_main:hover .headerCell {
		background-color: transparent;
	}

	.headerCell.subgrid {
		border-width: 0 1px 1px 1px;
		background: #f2f2f2;
		padding-left: 30px;
	}

	.headerCell.headerCellCollapsable,
	.headerCell.subgrid {
		border-color: #e4e4e4;
	}

	.headerCell.headerCellCollapsable {
		border: 0;
		margin-top: 0;
		border-right: 1px solid #e4e4e4;
	}

	.headerCell.subgrid,
	.fdt-subgrid-cell {
		background: #f2f2f2;
	}

	.public_fixedDataTableRow_main:hover .public_fixedDataTableCell_main {
		.headerCell.subgrid,
		.fdt-subgrid-cell {
			background: rgba(221, 221, 221, 0.5);
		}
	}

	.rowCollapsed .headerCellCollapsable,
	.rowExpanded .headerCellCollapsable {
		text-align: left;
		padding-left: 30px;
		position: relative;
		box-sizing: border-box;
	}

	.rowCollapsed .headerCellCollapsable,
	.rowExpanded .headerCellCollapsable {
		i {
			position: absolute;
			top: 13px;
			font-size: 18px;
		}

		.toggleLoader i.toggleLoader {
			right: 16px;
		}

		.toggleIcon {
			left: 16px;
		}

		.toggleIcon.disabled {
			color: #e2e2e2;
		}
	}
}
