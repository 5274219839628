.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.category-dropdown-icon {
  padding: 10px 0 0 10px;
  cursor: pointer;
}

.action-modal-filter-input {
  margin-left: 0;
}

.action-modal-category-row-container {
  margin-top: 10px;
  position: relative;
  max-width: 700px;
  max-height: 324px;
  overflow-y: auto;
  overflow-x: hidden;
}

.action-modal-category-row {
  margin-bottom: 5px;
  float: left;
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
}

.error-label {
  color: #E54028;
}

.full-width {
  width: 100%;
}

.scrollbar-width {
  width: 98%;
}

.action-modal-category-row:hover {
  background-color: #fbfbfb;
}

.action-modal-category-icon-container {
  padding: 0;
}

.action-modal-category-icon {
  padding-top: 5px;
  padding-left: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #1997f0;
}

.action-modal-category-icon-loading {
  font-size: 9px;
}

.action-modal-category-row-label {
  font-size: 12px !important;
}

.action-modal-category-filter-input {
  margin-left: 0 !important;
  width: 400px;
  height: 26px;
}

.action-modal-category-error div {
 margin-bottom: 0px !important;
}
