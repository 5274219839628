/* ---------------------------------------------------------------------- */
/*	Activity
/* ---------------------------------------------------------------------- */

.activity-targeting-popup{
	//width: 350px;
	margin: 0;
	color: #666666;
	ul{
		li{
			padding-left: 0;
			text-decoration: none;
		}
		padding-bottom: 5px;
	}
	h3{
		margin: 0 0 20px 0;
	}
	.col-md-6{
		padding-left: 0;
	}
	.col-md-12{
		padding-left: 0;
	}
	.subtitle, .title{
		.txt-label;
	}
	.value {
		padding-left: 0;
		text-decoration: none;
		display: inline-block;
		padding-bottom: 5px;
	}
}