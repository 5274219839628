.twLoader.reqFundingsLoader {
	font-size: 1.5em;
	position: relative;
	top: 2px;
	left: 10px;
}

.filter-container .select2-results li.select2-result-with-children>.select2-result-label {
	font-weight: normal;
}

.tw-campaign-categorization {
    max-width: 745px;
}

.tw-campaign-categorization .annotatedList {
    height: 272px;
}

.appSelectOption {
	i {
		float: right;
		padding:5px;
		background-color: #e4e4e4;
		color: black;
		width:25px;
		text-align: center;
	}

	div {
		width: 75%;
		text-overflow: ellipsis;
		overflow: hidden;
		float: left;
	}

	white-space: nowrap;
	line-height: 25px;
	height: 25px;
	vertical-align: middle;
}

.addAppButton {
	background: #00A1CB;
	width: 27px;
	text-align: center;
	line-height: 27px;
	cursor: pointer;
	color: white;
	display: inline-block;
	margin-left: 10px;
	vertical-align: top;
}

.addAppButton:hover {
	background: #007998;
}

.campaignAppSelector .Select.appSelector {
	width: 745px !important;

	.Select-value {
		width: unset;

		span.label-span {
			max-width: 200px;
		}
	}
}

.Select.appSelector{
	display: inline-block;

	.Select-value-label {
		padding: 0 5px;
	}

	.Select-value-icon {
		padding: 0px 5px 0;
		padding-right: 10px;
	}

	.Select-value {
		margin: 2px;
		width:90px;

		span.label-span {
			display:block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 51px;
		}
	}

	&.platformPicked {
		.Select-control {
			border-color: #E54028;
		}
	}

	.Select-clear {
		padding: 3px 5px;
	}
}
