@import "../bootstrap/variables.less";

//@grid-gutter-width: 15px;

// 5 col grid
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-left:  (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
}

.col-xs-5ths {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

// 7 col grid
.col-xs-7ths,
.col-sm-7ths,
.col-md-7ths,
.col-lg-7ths {
  position: relative;
  min-height: 1px;
  padding-left:  (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
}

.col-xs-7ths {
  width: 14.285714285714285714285714285714%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-7ths {
    width: 14.285714285714285714285714285714%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-7ths {
    width: 14.285714285714285714285714285714%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-7ths {
    width: 14.285714285714285714285714285714%;
    float: left;
  }
}


.resizeAgGridIcon() {
  padding: 26px 20px 20px 20px;
  transform: translate(-14px,-12px);
  cursor: pointer;
}

.resizeAgHeaderGridIcon() {
  padding: 26px 20px 20px 20px;
  transform: translate(-4px,-15px);
  cursor: pointer;
}


.ag-icon-checkbox-checked, .ag-icon-checkbox-unchecked {
  .resizeAgGridIcon();
}

.ag-header-select-all .ag-icon-checkbox-unchecked {
  .resizeAgHeaderGridIcon();
}

.ag-header-select-all .ag-icon-checkbox-checked {
  .resizeAgHeaderGridIcon();
}
