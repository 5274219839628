.external-dashboard {
  .list-item {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: none;
    }
  }

  .menu-create {
    padding-bottom: 20px;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
  }

  .dash-url {
    font-size: 10px;
    color: #999;
  }

  .fullscreen-toggle {
    color: #c2bfbf;
    background: none;
    border: none;
  }

  .fullscreen-toggle:hover {
    color: #a7a7a7;
  }

  .fullscreen-open {
    position: absolute;
    top: 165px;
    right: 0;
  }

  .fullscreen-close {
    margin-top: 5px;
  }

  .fullscreen-close-bar {
    height: 40px;
    background: #fff;
  }
}

.fullscreen-close-bar img {
    height: 34px;
    width:  133px;
    margin-top: -7px;
}

.external-dashboard-edit-submit {
  margin-left: 10px;
}
