/*Font face mixin*/
.font-face(@short-name:sans-serif; @fontName: sans-serif; @fontWeight: normal; @fontStyle: normal) {
    @font-face {
        font-family: @short-name;
        src: url('@{font-path}@{fontName}.eot');
        src: url('@{font-path}@{fontName}.eot?#iefix') format('embedded-opentype'),
             url('@{font-path}@{fontName}.woff') format('woff'),
             url('@{font-path}@{fontName}.ttf') format('truetype'),
             url('@{font-path}@{fontName}.svg#@{fontName}') format('svg');
        font-weight: @fontWeight;
        font-style: @fontStyle;
        font-display: block;
    }
}

.form-field{
    background-color: white;
    border: 1px solid #d3d3d3!important;
    color: @gray-bg-mid;
    font-size: 12px!important;
    padding: 4px 8px!important;
    line-height: 17px;
}


.state-status(@state-bg;@state-text;@state-border){
    background-color: @state-bg;
    color: @state-text;
    border-bottom: 1px solid @state-border;
}


.cell-status(@cell-bg;@cell-text;@cell-border){
    td, th {
        .state-status(@cell-bg;@cell-text;@cell-border);
    }
    &:hover {
        > td, > th {
             background-color: darken(spin(@cell-bg, -10), 5%);
        }
    }
}

.placeholder(@color;@font-style) {
    *::-webkit-input-placeholder {
      /* WebKit browsers */
      color: @color !important;
      font-style: @font-style;
    }

    *:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: @color !important;
      font-style: @font-style;
      opacity: 1;
    }

    *::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: @color !important;
      font-style: @font-style;
      opacity: 1;
    }

    *:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: @color !important;
      font-style: @font-style;
    }
}

.keyframes(@name,@rules) {

    @-webkit-keyframes @name {
        @rules();
    }

    @-moz-keyframes @name {
        @rules();
    }

    @-ms-keyframes @name {
        @rules();
    }

    @-o-keyframes @name {
        @rules();
    }

    @keyframes @name {
        @rules();
    }
}
