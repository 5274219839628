.dzu-dropzone {
  display: flex  !important;;
  flex-direction: column  !important;
  align-items: center  !important;
  width: 100%  !important;
  min-height: 120px  !important;
  overflow: scroll  !important;
  margin: 0 auto  !important;
  position: relative !important;
  box-sizing: border-box !important;
  transition: all .15s linear !important;
  border: 2px solid #d9d9d9 !important;
  border-radius: 4px !important;
}

.dzu-dropzoneActive {
  background-color: #DEEBFF !important;
  border-color: #2484FF !important;
}

.dzu-dropzoneDisabled {
  opacity: 0.5 !important;
}

.dzu-dropzoneDisabled *:hover {
  cursor: unset !important;
}

.dzu-input {
  display: none !important;
}

.dzu-inputLabel {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  font-family: 'Helvetica', sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #2484FF !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  cursor: pointer !important;
}

.dzu-inputLabelWithFiles {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-self: flex-start !important;
  padding: 0 14px !important;
  min-height: 32px !important;
  background-color: #E6E6E6 !important;
  color: #2484FF !important;
  border: none !important;
  font-family: 'Helvetica', sans-serif !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  margin-left: 3% !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  cursor: pointer !important;
}

.dzu-previewContainer {
  padding: 40px 3% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  position: relative !important;
  width: 100% !important;
  min-height: 60px !important;
  z-index: 1 !important;
  border-bottom: 1px solid #ECECEC !important;
  box-sizing: border-box !important;
}

.dzu-previewStatusContainer {
  display: flex !important;
  align-items: center !important;
}

.dzu-previewFileName {
  font-family: 'Helvetica', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #333333 !important;
}

.dzu-previewImage {
  width: auto !important;
  max-height: 40px !important;
  max-width: 140px !important;
  border-radius: 4px !important;
}

.dzu-previewButton {
  background-size: 14px 14px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  width: 14px !important;
  height: 14px !important;
  cursor: pointer !important;
  opacity: 0.9 !important;
  margin: 0 0 2px 10px !important;
}

.dzu-submitButtonContainer {
  margin: 24px 0 !important;
  z-index: 1 !important;
}

.dzu-submitButton {
  padding: 0 14px !important;
  min-height: 32px !important;
  background-color: #2484FF !important;
  border: none !important;
  border-radius: 4px !important;
  font-family: 'Helvetica', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #FFF !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  cursor: pointer !important;
}

.dzu-submitButton:disabled {
  background-color: #E6E6E6 !important;
  color: #333333 !important;
  cursor: unset !important;
}
