/* ----- Selection ----- */
.selection-container {
  max-height: 200px;
  max-width: 450px;
  width: 100%;
}

.info {
  padding: 15px 0 0 10px;
}

.no-margin {
  margin: 0 !important;
}
