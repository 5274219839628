.floater-estimates.estimates.affix {
  top: 60px;
}

.floater-estimates.estimates {
  background: transparent;
  border: none !important;
  padding: 0;

  .floater-content-box {
    background: #fff;
    border: 1px solid #e4e4e4;
  }

  .floater-spacer {
    height: 30px;
  }

  @media (min-width: 992px) {
    width: 230px;
  }

  @media (min-width: 1200px) {
    width: 240px;
  }

  .floater-info.grayed-out {
    background: #f6f6f6;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #eaeaea;
  }

  .floater-padding {
    padding: 5px 10px;
  }

  .floater-info {
    width: 100%;
    font-size: 11px;

    tr td:first-child {
      padding: 5px 0 5px 10px;
      text-transform: uppercase;
      color: inherit;
      font-weight: bold;
    }

    tr td:last-child {
      padding: 5px 10px 5px 20px;
      text-align: right;
    }
  }

  .floater-titled-row {
    .title {
      padding: 5px 10px;
      text-transform: uppercase;
      font-size: 11px;
    }
  }

  .help-icon {
    font-size: 13px;
  }

  .bid-amount {
    padding: 20px 10px 20px 10px;

    input {
      width: 100%;
    }

    input.has-markup {
      width: 90%;
    }

    .label {
      text-align: left;
      padding: 0;
      color: inherit;

      span {
        font-size: 16px;
        line-height: 1.5;
        text-transform: uppercase;
      }

      .help-block {
	font-weight: normal;
	margin-top: 0;
      }

    }

    input {
      padding: 0;
      border: none;
      border-bottom: 1px dotted;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .disabled {
      background: inherit !important;
      border-bottom: none;
    }
  }

  .floater-titled-row {
    .btn-suggest {
      width: 31.3%;
      margin: 0;
    }

    .btn-suggest:not(.last) {
      margin-right: 3%;
    }
  }

  .bid-slider {
    width: 100%;
    height: 5px;

    .handle::after {
      top: -4px;
    }
  }

  .button_container {
    margin-top: 0;
    margin-bottom: 0;
    .cancel-button {
      margin-top: 5px;
      background-color: transparent;
      border-width: 0;
      text-align: center;
    }
  }

  .back_btn,
  .submit {
    display: block;
    font-size: 12px;
    width: 100%;
    text-transform: uppercase;
  }

  .back_btn {
    text-decoration: none;
    text-align: center;
    color: inherit;
    display: block;
    margin-top: 5px;
  }

  .back_btn:hover {
    cursor: pointer;
  }

  .estimates-loading {
    height: 15px;
    line-height: 1.2;
    font-size: 13px;
  }

  .audience-progress {
    height: 15px;

    .tw-progress-label {
      margin-top: -2px;
      font-size: 10px;
    }
  }

  .daily-estimates {
    .daily-estimates-title {
      font-size: 11px;
      text-transform: uppercase;
      padding: 10px 0 5px 10px;
    }
  }

  .loader {
    position: absolute;
    width: 100%;
    top: -5px;
    height: 5px;
  }

  .loader .progress-bar {
    background-color: #e6e6e6;
  }

  .loader .progress-bar {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.50) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.50) 50%,rgba(255,255,255,.50) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.50) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.50) 50%,rgba(255,255,255,.50) 75%,transparent 75%,transparent)
  }

  .estimates.cancel-btn-container {
    background: inherit;
    border: none !important;
    margin-top: 0;
  }

  .fa-exclamation-triangle {
    color: #999;
  }

  .open-chart-btn {
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 0 10px 10px;
    color: #00a1cb;
    cursor: pointer;
    display: block;
  }

  /* facebook ads */
  #js-current-bid-info {
    td {
      border: none !important;
    }
    th:first-child,
    td:first-child {
      padding-left: 10px;
      max-width: 80%;
      display: block;
    }

    th:last-child,
    td:last-child {
      padding-right: 10px;
    }

    th {
      border: none;
      padding: 5px 0 5px 10px;
      font-weight: bold;
    }
  }
}

.daily-est-graph.popover {
  max-width: 650px;
}
