#budget-search-query,
.entities-validation,
.toggle-all-currency {
  .formWidth();
}

input#budget-search-query {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.toggle-all-currency,
.toggle-selector-box {
  padding: 12px 25px 12px 31px;
  border: 1px solid #d5d5d5;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: @background-color;
}

.toggle-selector-box .slider {
  background-color: #ccc;
}

.budget-items-selector {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #d5d5d5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 5px 10px;
  color: #888888;
  font-weight: bold;
  .formWidth();
}

.toggle-all {
  display: inline-block;

  .only-selected {
    top: 3px;
  }
}

.only-selected {
  margin-left: 15px;
}

.item-line {
  display: inline-flex;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-bottom: 5px;

  &:last-of-type {
    border-bottom: none;
  }
}

.entity-selector-disabled {
  display: inline-block;
  line-height: 20px;
  width: 28px;
  text-align: center;
  float: right;
  color: #989898 !important;

  i:before {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-size: 18px;
  }
}

.align-right {
  display: inline-block;
  float: right;
}

.bottom-lined > :not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 3px;
}

.toggle-button-overlay-wrapper {
  display: inline-block;
  float: right;
  vertical-align: text-bottom;
}

.toggle-selector-row-name {
  width: 530px;
  text-align: justify;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-disabled {
  color: #bbb !important;
}

.toggle-disabled {
  color: #bbb;
  cursor: not-allowed;
}

.child-font {
  font-weight: normal;
}
