.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 38px;
  left: 0;
  bottom: 0;
  background-color: #777;
  line-height: 30px;
  -webkit-transition: .4s;
  transition: .4s;
}

.budget-selector-switch {
  margin-top: 2px;
  width: 28px; /*30*/
  height: 15px; /*16*/
  vertical-align: text-bottom;
  float: right;
}

.forward-audience-switch {
  width: 28px;
  height: 15px;
  vertical-align: middle;
  margin-bottom: 0;
}

.slider-chart:before {
  content: "\f080";
  font-family: 'Font Awesome 5 Free';
  color: #fff;
  text-align: center;
}

input:checked + .slider-chart:before {
  background-color: #1997f0;
}

input:checked + .slider:before {
  .transform(22px, 0);
}

/* Small slider */
.switch-small {

  &.switch {
    width: 28px;
    height: 15px;
    vertical-align: text-bottom;
    float: right;
    padding-top: 3px;
    margin-bottom: 0;
    margin-left: 15px;
    top: 3px;
  }

  .slider:before {
    height: 11px;
    width: 11px;
    background-color: white;
    top: 2px;
    left: 3px;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:checked + .slider:before {
    .transform(11px, 0);
  }

  input:disabled + .slider {
    background-color: rgba(204, 204, 204, .4);
    cursor: not-allowed;
  }

  input:checked:disabled + .slider {
    background-color: rgba(33, 150, 243, .4);
  }
}

/* Budget slider */
.budget-slider {
  background-color: #ccc;
}

.budget-slider:before {
  height: 11px; /*12*/
  width: 11px; /*12*/
  background-color: white;
  top: 2px;
  left: 3px;
}

.slider.round.budget-slider {
  border-radius: 10px;
}

.slider.round.budget-slider:before {
  border-radius: 50%;
}

input:checked + .budget-slider {
  background-color: #2196F3;
}

input:checked + .budget-slider:before {
  .transform(11px, 0); /*12*/
}

input:disabled + .budget-slider {
  background-color: #e9ecef;
}

/* Rounded sliders */
.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 15px;
}

/* Live Stats Slider */
.liveStatsSlider .slider {
  background-color: #777;
}

.liveStatsSlider .slider.round {
  border-radius: 13px;
}

.liveStatsSlider .slider.round:before {
  border-radius: 13px;
}

.liveStatsSlider .slider.active {
  background-color: #188118
}

.liveStatsSlider .slider:before {
  width: 11px;
  height: 11px;
  margin: 2px;
  background-color: white;
}

.liveStatsSlider {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.liveStatsSlider input:checked + .slider:before {
  .transform(15px, 0);
}

.switch-float-left {
  float: left;
  margin-right: 10px;
}
