.icons > section {
	margin-top: 20px;
}
.the-icons li {
	line-height: 40px;
}
.the-icons li i {
	transition: font-size 0.2s ease 0s;
}
.the-icons li:hover i {
	font-size: 26px;
}