@import "../facelift/variables.less";
@import "../lesshat/lesshat-prefixed.less";

@uploader-white:          #FFFFFF;
@uploader-light:          #FAFAFA;
@uploader-medium:         #EEEEEE;
@uploader-border:         #D5D5D5;
@uploader-dark:           #CCCCCC;
@uploader-font:           #888888;
@uploader-active:         #1997f0;
@uploader-complete:       @brand-active;
@uploader-complete-light: #6cd96c;
@uploader-error:          @brand-inactive;
@uploader-error-light:    #ff615e;
@uploader-black: #000000;

.moxie-shim {
  display: none;
}

video {
  width: 100%;
  padding: 0;
  cursor: default;
}

.video-picker-container, .video-info-container {
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}

.video-picker-container {
  cursor: pointer;
  text-align: center;
  min-height: 147px;
  padding-top: 105px;
}

.video-player {
  max-height: 0;
  background: @uploader-black;
  overflow: hidden;
  .lh-transition(max-height 0.5s ease-in-out);
}

.video-player.expand {
    max-height: 600px;
}

.video-icon {
  color: @uploader-dark;
  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  width: 60px;
  font-size: 70px;
  .lh-transition(color 300ms ease);
}

.video-icon.dragover{
  .lh-animation-name(dropPulse);
  .lh-animation-duration(2s);
  .lh-animation-iteration-count(infinite);
  color: @uploader-active;
}

.video-icon.mouseover{
  color: @uploader-active;
}

.video-icon:hover {
  color: @uploader-active;
}

.video-info {
  font-size: 12px;
  min-height: 23px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-info .btn {
  position: absolute;
  right: 0;
  top: 5px;
}

.video-info .toggle-crop-button {
  padding: 10px 0;
  margin-right: 65px;
  min-width: 85px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #1997f0;

  &:hover {
    cursor: pointer;
    text-decoration: unset;
    color: #788087;

    .crop-icon {
      color: #788087;
    }
  }
}

.video-info .toggle-crop-button-disabled {
  padding: 10px 0;
  margin-right: 65px;
  min-width: 85px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #bbbbbb;

  &:hover {
    cursor: default;
    text-decoration: unset;
  }
}

.video-info .crop-icon {
  color: #1997f0;
}

.video-info .crop-icon-disabled {
  color: #bbbbbb;
}

.video-text {
  font-size: 12px;
  padding: 10px 0;
}

.video-name {
  margin-left: 4px;
  color: @uploader-font;
}

.video-size {
  font-weight: 700;
  margin-left: 10px;
  color: @uploader-font;
}

.video-control-container {
  position: relative;
}

.video-control-container .video-controls {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;

  .btn {
    width:23px;
  }
}

.video-progress {
  background: @uploader-medium;
  height: 22px;
  margin-bottom: 10px;
  margin-right: 25px;
  display: none;
}

.video-control-full-width .progress {
  width: 90%;
  height: 22px;
}

.progress-bar {
  margin: 0;
  padding: 0;
  background: @uploader-active;
  box-shadow: none;
}

  .progress-bar.completed {
    background: @uploader-complete;
    position: relative;
    .lh-animation-name(completePulse);
    .lh-animation-duration(3s);
    .lh-animation-iteration-count(infinite);
  }

  .progress-bar.completed:before {
    position:absolute;
    font-family: 'Font Awesome 5 Free';
    top: 2px;
    right: 5px;
    color: @uploader-white;
    content: "\f00c";
  }

  .video-uploader-errors {
    margin-bottom: 10px;
    color: @uploader-error;
  }

  .progress-bar.error {
    background: #d9534f;
    position: relative;
    .lh-animation-name(errorPulse);
    .lh-animation-duration(3s);
    .lh-animation-iteration-count(infinite);
  }

  .progress-bar.error:before {
    position:absolute;
    font-family: 'Font Awesome 5 Free';
    top: 2px;
    right: 5px;
    color: @uploader-white;
    content: "\f00d";
  }

.notransition {
  -webkit-transition: none !important;
  -moz-transition:    none !important;
  -o-transition:      none !important;
  -ms-transition:     none !important;
  transition:         none !important;
}

@keyframes completePulse {
  from { background-color: @uploader-complete; }
  50% { background-color: @uploader-complete-light; }
  to { background-color: @uploader-complete; }
}
@-webkit-keyframes completePulse {
  from { background-color: @uploader-complete; }
  50% { background-color: @uploader-complete-light; }
  to { background-color: @uploader-complete; }
}

@keyframes errorPulse {
  from { background-color: @uploader-error; }
  50% { background-color: @uploader-error-light; }
  to { background-color: @uploader-error;  }
}
@-webkit-keyframes errorPulse {
  from { background-color: @uploader-error; }
  50% { background-color: @uploader-error-light; }
  to { background-color: @uploader-error;  }
}

@keyframes dropPulse {
  from { color: @uploader-dark; transform: scale(1); }
  50% { color: @uploader-active; transform: scale(0.8); }
  to { color: @uploader-dark; transform: scale(1); }
}

@-webkit-keyframes dropPulse {
  from { color: @uploader-dark; -webkit-transform: scale(1); }
  50% { color: @uploader-active; -webkit-transform: scale(0.8); }
  to { color: @uploader-dark; -webkit-transform: scale(1); }
}

.disable-delete-button {
  color: #d5d5d5 !important;
  border-color: #d5d5d5 !important;

  &:hover {
    background-color: transparent !important;
    cursor: default;
  }
  &:active {
    box-shadow: none !important;
  }
}
