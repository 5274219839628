.headerCell {
  margin-top: 5px;
  padding: 10px;
  background-color: #fff;
  height: 46px;
  line-height: 1.8em;
  position: relative;
}

.headerCellCollapsable {
  font-weight: bold;
  cursor: pointer;
}

.headerCellCollapsable .public_fixedDataTableCell_cellContent {
  padding: 0;
  margin: 0;
}

.twGridCollapsable {
  font-size: 12px;
}

.twGridCollapsable .public_fixedDataTable_header {
  text-align: right;
}

.twGridCollapsable .firstCollCell {
  padding-left: 10px;
}

.twGridCollapsable .rowCollapsed .collapsingIcon,
.twGridCollapsable .rowExpanded .collapsingIcon {
  text-align: left;
  padding-left: 30px;
  position: relative;
  box-sizing: border-box;
}

.twGridCollapsable .rowCollapsed .collapsingIcon:before,
.twGridCollapsable .rowExpanded .collapsingIcon:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.twGridCollapsable .rowCollapsed .collapsingIcon:before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #707070;
  top: 15px;
  left: 18px;
}

.twGridCollapsable .rowExpanded .collapsingIcon:before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #707070;
  top: 18px;
  left: 13px;
}

.twGridCollapsable .rowExpanded .headerCell {
  border-bottom: 0;
}

.twGridCollapsable .fdt-cellHeader {
  border: none;
}

.twPullLeft .public_fixedDataTableCell_cellContent {
  text-align: left;
}

/*Shadow on fixed column*/
.public_fixedDataTableRow_columnsShadow {
  background: 0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y;
}

/*No shadow on header */
.twGridCollapsable .public_fixedDataTable_header .public_fixedDataTableRow_columnsShadow {
  margin-top: 24px;
}

.twGridCollapsable .public_fixedDataTable_topShadow {
  background: 0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x;
}

.twSortWrap {
  cursor: pointer;
}

.twSortIcon {
  position: relative;
  top: -3px;
  display: inline;
  padding-left: 5px;
  font-size: 14px;
  color: #909090;
}

.twGridCollapsable .public_fixedDataTable_header.public_fixedDataTableRow_main:after {
  position: absolute;
  content: "";
  background: #ddd;
  width: 1px;
  /* (js/twitter/config) @rowHeight + 1px border */
  height: 46px;
  bottom: 0;
  right: 0;
}

.twGridCollapsable .public_fixedDataTableRow_main {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.twGridCollapsable .public_fixedDataTable_header.public_fixedDataTableRow_main {
  border-left: 0;
  border-right: 0;
}

.twGridCollapsable .public_fixedDataTableCell_main .headerCell {
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 0 1px 1px;
  box-sizing: border-box;
}

.twGridCollapsable .public_fixedDataTable_header,
.twGridCollapsable .public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-color: #F2F2F2;
}

.twGridCollapsable .public_fixedDataTableCell_main .rowExpanded .headerCell {
  border-bottom-width: 0;
}

.twGridCollapsable .fixedDataTableCellLayout_lastChild .headerCell {
  border-width: 0 0 1px 0;
}

.twGridCollapsable .headerCell .fdt-cell {
  border: 0;
  padding: 0;
}

.twGridCollapsable .fdt-statCol .fdt-statValue {
  margin-top: 0;
}