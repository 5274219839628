.popover-content, .saved-audience-container{
  .targeting-line{
    text-align: left;
    padding-bottom: 3px;
  }

  .targeting-line-title{
    font-weight: bold;
    text-transform: uppercase;
  }

  .targeting-line-children{
    padding-left: 20px;
  }
}

.saved-audience-container .targeting-lines{

}

.custom-audience-context-menu {
  padding: 9px 5px 0 6px;
}
