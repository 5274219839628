.navbar-breadcrumb {
  float: left;
  border-left: 1px solid #ddd;
  max-width: 75%;
  margin-bottom: 8px;
}

@media (max-width: 767px) {

  .navbar-breadcrumb {
    border-left: 0;

    .breadcrumb {
      padding: 0;
    }

  }

}