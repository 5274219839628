.left-menu-toggler {
  position: fixed;
  top: 4px;
  left: 8px;
  width: 50px;
  height: 46px;
  z-index: 1040;
  text-align: center;
}

.isSwitched,
.isLoggedOut,
.isPlatformUpdated {
  .left-menu-toggler {
    top: 42px;
  }
}

.isSwitched.isLoggedOut,
.isSwitched.isPlatformUpdated {
  .left-menu-toggler {
    top: 84px;
  }
}

.burger-button {
  font-size: 22px;
  color: #ddd;

  &:hover {
	color: #c4c4c4;
	cursor: pointer;
  }

  i:before {
	font-family: 'Font Awesome 5 Free';
	content: '\f0c9';
    font-weight: 400;
  }
}
