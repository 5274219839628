
/*body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td {
	margin:0;
	padding:0;
}*/
fieldset,img {
	border:0;
}
dl,dt,dd,ul,ol,li {
	list-style:none;
	margin:0;
	padding:0;
}
q:before,q:after {
	content:'';
}
abbr,acronym {
	border:0;
}
em { font-style: italic; }
strong { font-weight: bold; }