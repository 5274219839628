.rules-container {
  border-top: 1px solid #e1e1e1;
  padding-top: 15px;
  display: flex;
}

.rule-info-stat-name {
  color: #5a6c7b;

  &::first-letter {
    text-transform: uppercase;
  }
}

.rule-info-stat-value, .rule-info-stat-unit {
  font-weight: 700;
  margin-left: 0.3em;
}

.rule-info {
  display: inline-block;
  width: 50%;
}

.rule-info-combined-container{
  display: inline-block;
  width: 50%;
  .rule-info {
    display: inline-block;
    width: 100%;
  }
}
