.twPopover,
.twPopover-content {
  color: #999;
  max-width: 600px;
  min-width: 200px;
  background-color: #e4e4e4;
  border: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.twPopover {
  position: absolute;
  padding: 10px;
  z-index: 99;
  white-space: nowrap;
}

.twPopover.left {
  white-space: break-spaces;
}

.twPopover > .arrow {
  border-width: 11px;
}

.twPopover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.twPopover > .arrow:after {
  border-width: 11px;
  content: "";
}

/* Popover on right*/
.twPopover.right {
  margin-left: 10px;
}

.twPopover.right .arrow {
  top: 11px;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #e4e4e4;
}

.twPopover.right > .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border: 0;
}

/* Popover on left*/
.twPopover.left .arrow {
  top: 11px;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #e4e4e4;
}

.twPopover.left > .arrow:after {
  content: " ";
  right: 1px;
  bottom: -10px;
  border: 0;
}

.twPopover.beSmall {
  white-space: initial !important;
  max-width: 400px;
}

.twPopover.min-width300 {
  min-width: 300px;
}

.twPopover.width100 {
  width: 100px;
}

.twPopover.width300 {
  width: 300px;
}

.twPopover.width400 {
  width: 400px;
}

.twPopover.width500 {
  width: 500px;
}

.twPopover.width600 {
  width: 600px;
}

.twPopover.popover-small {
  white-space: initial;
  max-width: 300px;
}

.twPopover.right20 {
  margin-left: 20px;
}

.twPopover.wrap-text {
  white-space: initial;
}

.twPopover .arrow.arrowMid {
  top: 50%;
}

/* Popover label*/
.popoverLabel {
  padding: 0;

  span.list-popover-submit {
    pointer-events: auto;
    margin-left: 12px !important;
  }
}

.popoverLabel:hover {
  cursor: help;
}

.popoverLabel .helptextLeft {
    border-bottom: 1px dotted #000;
    display: inline-block;
    margin-left: 15px
}
