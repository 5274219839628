// React Simple Tooltip

.rst-container {
  display: flex;
  position: fixed;
  z-index: 1000;
}

.rst-triangle-container {
  display: flex;
  flex-direction: column;
  align-self:center;
}

.rst-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13.5px 12px 12.5px 0;
  border-color: transparent #192430 transparent transparent;
}

.rst-tooltip {
  background: rgb(25,36,48);
  min-height: 26px;
  max-width: 300px;
  display:flex;
  align-items: center;
}


.rst-tooltip-title {
  color: white !important;
  word-break: break-word;
}

.rst-tooltip-description {
  word-break: break-word;
  text-transform: uppercase;
  color: rgb(190, 191, 193);
}

.rst-tooltip-value {
   color: white;
}

.rst-tooltip span {
  font-weight: bold;
}

.rst-tooltip-row {
  padding-top: 3px;
  padding-bottom: 3px;
}

.rst-focus-element {
  background-color: blue;
  width: 100px;
  height: 100px;
  position:fixed;
  top: 150px;
  left: 105px;
}

.rst-tooltip-content {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}