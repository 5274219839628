.adplatform-style .changelog-list {
    .selected-date-range-btn {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

.changelog-list {
	background: #525555;
	color: #fff;

	.notification-title {
		background: #484848;
		text-align: left;
		font-size: 30px;
		margin-bottom: 4px;
		padding-left: 0;
	}

	.navbar-inverted {
		background: #484848;
		float: none;

		.navbar-submenu:after {
			border-top-color: #484848;
			border-bottom-color: #525555;
			box-shadow: inset 0 -1px 0 0 #484848;
			left: 40px;
		}
	}

	.navbar-nav {
		padding-left: 40px;
		margin-left: 0 !important;

		&.changelog-list-container {
			overflow-y: scroll;
			height: ~"calc(100vh - 200px)";
		}
	}

	.sidebar-main-container {
		margin-top: 20px;
	}

	.table {
        td:first-child {
            width: 15%;
        }

		.table-row-date, .table-row-modified {
			width: 120px;
		}

		.table-row-entity, .table-row-changes {
			max-width: 240px;

			span {
				width: 100%;
			}
		}

		.table-row-caret {
			width: 30px;
			vertical-align: middle;
		}

		.overview-col-container {
			width: 200px;
			display: inline-block;
			overflow: hidden;
		}

		.overview-col {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			max-width: 200px;
			margin: 0;
		}

		.modifier-col-container {
			width: 100px;
			display: inline-block;
			overflow: hidden;
		}

		.modifier-col {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			max-width: 100px;
			margin: 0;
		}

		thead {
			tr {
				color: #076a94;
				font-weight: bold;

				th {
					color: #fff;
					border-bottom: 1px solid #c4c4c4;
				}
				td {
					border-top: none;
				}
			}

			&:nth-child(1) {
				tr {
					th {
						border-top: 1px solid #c4c4c4;
					}
				}
			}

			&.parent-table-header {
				tr {
					th {
						border-top: 0;
					}
				}
			}
		}
		tbody {
			&.has-diff {
				&:hover {
					background: #767878;
					cursor: pointer;
				}
			}

			&.has-diff > tr:nth-child(2) {
				&:hover {
					cursor: default;
				}

				> td {
					padding: 0;
					> table {
						margin-bottom: 0;
					}
				}
			}

			&.show-diff {
				background: #767878;
			}
		}

		tbody+tbody {
			border-top: none;
		}
	}

	.table>tbody>tr>td,
	.table>tbody>tr>th,
	.table>tfoot>tr>td,
	.table>tfoot>tr>th,
	.table>thead>tr>td,
	.table>thead>tr>th
	{
		border-top: none;
	}

	.table>tbody>tr>td {
		vertical-align: top;
	}

	.show-diff-col {
		min-width: 30px;
	}

	table.diff-details {
		background-color: transparent;

		.table-row-empty {
			width: 100px;
			border-bottom: none;
		}

		.table-row-setting {
			width: 200px;
		}

		.table-row-change-type {
			width: 150px;
		}

		tbody {
			tr {
				background-color: transparent;
			}
		}

        td:first-child {
            width: 15%;
        }
	}

	.close {
		padding-right: 0;
		color: #eee;

		&:hover,
		&:focus {
			color: #fff;
		}
	}

	.pointer {
		cursor: default;
	}

	.has-diff .pointer {
		cursor: pointer;
	}

	button.selected-date-range-btn {
		color: @modal-content-bg !important;
		background-color: @grey-changelog-dark;
	}

	.load-more {
		text-align: center;
		font-size: 0.9em;
		font-weight: normal;
	}

    .filters {
        .Select-control {
            background-color: #484848;
        }

        .Select-arrow {
            border-color: #fff transparent transparent;
        }

        .Select-value span {
            color: #fff !important;
            font-weight: 400;
        }
    }
}

@sidebar-width: 50%;

.changelog-list.cbp-spmenu-right {
	min-width: 745px;
	width: @sidebar-width;
}

.changelog-list.cbp-spmenu-right.cbp-spmenu-open {
	animation: open 0.3s forwards;
}

.changelog-list.cbp-spmenu a {
	font-weight: bold;
}

.changelog-list-item {
    border-bottom: 1px solid #666;
}

@keyframes open {
	0%   { right: -50vw; }
	100% { right: 0; }
}
