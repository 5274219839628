@import "../bootstrap/mixins/vendor-prefixes.less";
@import "../facelift/mixins.less";
@import "../elements.less";

/*Animation*/

@default-transition-duration: .3s;

.keyframes(slideoutleft, { from {
  transform: translate(0, 0);
} to {
    transform: translate(-100%, 0);
  } });

.keyframes(slideinleft, { from {
  transform: translate(-100%, 0);
} to {
    transform: translate(0, 0);
  } });

.keyframes(slideoutright, { from {
  transform: translate(0, 0);
} to {
    transform: translate(200%, 0);
  } });

.keyframes(slideinright, { from {
  transform: translate(100%, 0);
} to {
    transform: translate(0, 0);
  } });

@panelSlideAnimation: @default-transition-duration 1 both;

.slideOutLeft {
  .animation(slideoutleft @panelSlideAnimation);
  position: absolute;
}

.slideInLeft {
  .animation(slideinleft @panelSlideAnimation);
}

.slideOutRight {
  .animation(slideoutright @panelSlideAnimation);
  position: absolute;
}

.slideInRight {
  .animation(slideinright @panelSlideAnimation);
}

.keyframes(fadeIn05, { from {
 opacity: 0;
} to {
    opacity: .5;
  } });


@keyframes shadow-pulse {
  50% {
    box-shadow: 0 0 0 6px rgba(white, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(white, 0);
  }
}
