.list {
	/*.table;
	.table-hover;*/
	> tbody > tr:hover {
	  background-color: @table-bg-hover;
	}
}
.list {
	th {
		font-weight: normal;
		padding: 8px;
	}
	td{
		padding: 8px;
	}
}
.list span.tooltips,
.list acronym {
	cursor: help;
	border-bottom: 1px dotted black;
}
.list a.help {
	color: @text-color;
	text-decoration: none !important;
	border-bottom: 1px dotted black;
}
.list .clicks {
	width: 74px
}
.list .title {
	float: left;
	width: 85px;
	clear: both;
	padding-bottom: 5px;
}
.list .value {
	float: left;
	padding-bottom: 5px;
}
.list tr.big-sum {
	font-size: 15px;
}
.list .label {
	display: inline-block;
	min-width: 75px;
}
.list .info-text {
	color: #999;
	display: block;
	text-transform: lowercase;
	line-height: 1em;
}
.list thead th {
	clear: both;
	text-align: left;
	line-height: 1em !important;
	color: #707070;
}
.list thead th.right,
.list tbody td.right,
.list tfoot td.right {
	text-align: right;
}
.list thead th.center,
.list tbody td.center,
.list tfoot td.center {
	text-align: center;
}
.list thead th.left,
.list tbody td.left,
.list tfoot td.left {
	text-align: left;
}
.list thead th.center,
.list tbody td.center,
.list tfoot td.center {
	text-align: center;
}
.list thead th.vtop,
.list tbody td.vtop,
.list tfoot td.vtop {
	vertical-align: top;
}
.list thead th.vmid,
.list tbody td.vmid,
.list tfoot td.vmid,
.vmid.list>tbody>tr>td {
	vertical-align: middle;
}
.list tr.bottom-separate {
	border-bottom: 1px solid #D3D3D3
}
.list thead th.six-column,
.list tbody td.six-column,
.list tfoot td.six-column {
	width: 16%;
}
.list td.indent {
	padding-left: 30px
}
.list td.deleted {
	text-decoration: line-through
}
.list tbody td img,
.list tbody td i {
	border: none;
	//margin-bottom: -3px;
}

/* deleted campaigns have no links */
.list tbody tr td span.big {
	text-decoration: none;
	font-size: 14px;
	padding: 0;
}
.list tbody tr td a.big {
	text-decoration: none;
	font-size: 14px;
	padding: 0;
}
.list tbody tr td a.big:hover {
	text-decoration: underline;
}
.list tbody .even {
	background-color: inherit;
}

/* Different bg colors for cells */
.list {
	tr.state-success {
		.cell-status(@state-success-bg;
		@state-success-text;
		@state-success-border);
	}
	tr.state-info {
		.cell-status(@state-info-bg;
		@state-info-text;
		@state-info-border);
	}
	tr.state-warning {
		.cell-status(@state-warning-bg;
		@state-warning-text;
		@state-warning-border);
	}
	tr.state-danger {
		.cell-status(@state-danger-bg;
		@state-danger-text;
		@state-danger-border);
	}
	tr.state-neutral {
		.cell-status(@gray-bg-light;
		#555;
		@border-color);
	}
}

/* Status */
.status {
	text-transform: lowercase;
}
.status .time {
	color: #7a7a7a;
}
.status_ad span {
	margin-left: 10px;
}

table .status_ad span.syncing-status {
	padding-left: 0;
}

.status_ad span.syncing-status {
	margin-left: -10px;
}

.status.inline {
	display: inline-block;
	font-size: 12px;
	font-style: normal;
	color: inherit;
}
.status img {
	padding-right: 6px;
	padding-bottom: 3px;
}
.tooltipHelp a {
	color: #999;
	font-size: 14px;
	display: inline;
	width: 16px;
	height: 16px;
	padding: 0 !important;
}
.tooltipHelp a i {
	display: inline;
	padding: 3px;
}

/* footer */
.list tfoot tr td {
	background-color: #E4E5E6;
	background-color: #f9f9f9;
}
.list tfoot tr td.big {
	text-transform: uppercase;
	font-size: 1em;
}
.list tfoot tr td.big,
.list tfoot tr td,
.list tfoot tr td a {
	color: #666 !important;
}


/* kr neki */
.antitransparent {
	background: url("../../images/anti_transparent_bg.png") transparent;
}
.unverified {
	background: url("../../images/error_bg.png") transparent;
	border: 1px solid #ff8080;
}
.invalid {
	background: url("../../images/error_bg.png") transparent;
}
.campaign tbody td {
	vertical-align: top;
}
.campaign tbody tr td .campaign_status {
	padding-top: 0px;
	color: inherit;
	position: absolute;
	width: inherit;
	min-width: 200px;
	height: 22px;
	padding-right: 35px;
	overflow: hidden;
}
.delete_button_container {
	padding-top: 10px;
}

/* RMX */
#rmxlinker_rmx_advertisers {
	width: 330px;
	float: left;
}
#rmxlinker_link {
	width: 30px;
	float: left;
}
#rmxlinker_db_advertisers {
	width: 300px;
	float: left;
}
table.list tr.heading td {
	border-bottom: 1px solid #D3D3D3;
	background-color: #F2EFED;
}
table.list tr.heading {
	border-bottom: 1px solid #D3D3D3;
	background-color: #F2EFED;
}
table.list tr.footer td {
	border-bottom: 1px solid #D3D3D3 !important;
	font-weight: bolder;
}
table.list th.one-six-column,
table.list td.one-six-column {
	width: 13%;
	text-align: right;
}
table.list th.seven-column,
table.list td.seven-column {
	width: 14%;
	text-align: right;
}
table.list th.seven-column.mobile,
table.list td.seven-column.mobile {
	padding-right: 20px;
}
table.list th.eight-column,
table.list td.eight-column {
	width: 12%;
}
table.list th.nine-column,
table.list td.nine-column {
	width: 11%;
}
.list thead th.right,
.list tbody td.right,
.list tfoot td.right,
.list tfoot th.right {
	text-align: right;
}
.list thead th.left,
.list tbody td.left,
.list tfoot td.left {
	text-align: left;
}
.list thead th.center,
.list tbody td.center,
.list tfoot td.center {
	text-align: center;
}
.list img.expand {
	margin-bottom: -1px
}
.list img.expand:hover {
	cursor: pointer;
}
div.list_ad_info {
	float: left;
	padding: 2px;
	margin-left: 8px;
}
table.list thead th.list-six-first {
	//width: 260px !important;
	min-width: 260px !important;
	text-align: left;
	padding-left: 0px;
}

table.list thead th.list-first-wide{
	width: 25%;
	min-width: 340px;
	padding-left: 1px;
}
table.list thead th.list-six-first-wide {
	min-width: 340px;
	text-align: left;
	padding-left: 2px;
}
table.list thead th.list-six,
table.list tbody td.list-six {
	width: 110px;
	text-align: right;
}
table.list thead th.list-six-small,
table.list tbody td.list-six-small {
	width: 55px;
	text-align: right;
}
table.list thead th.list-six-xsmall,
table.list tbody td.list-six-xsmall {
	width: 42px;
}
.list tbody td.subtd {
	padding-left: 30px
}

/* list hpo */
table.list thead tr th.list-hpo-first {
	width: 190px;
	text-align: left;
}
table.list thead tr th.list-hpo {
	width: 170px;
	text-align: right;
}

/* ad list */
.list th.ad-list-first {
	width: 200px
}
.list th.ad-list-info {
	width: 20px
}

/* reports */
table.reports h2.flat,
table.reports h3.flat {
	margin: 0
}
table.reports td,
table.reports th {
	padding-right: 2px
}
table.reports td.report-row {
	border-top: 1px solid #E0E0E0;
	background-color: #F2EFED;
	text-align: right
}
table.reports td.left,
table.reports th.left {
	text-align: left
}
table.reports td.right,
table.reports th.right {
	text-align: right
}
table.reports td.left-indent {
	text-align: left;
	padding-left: 30px;
}
table.reports td.gray-bg,
table.reports tr.gray-bg {
	background-color: #e0e0e0
}

/* reports sum */
table.reports th.sum-first {
	width: 150px
}
table.reports th.sum-second {
	width: 100px
}
table.reports th.sum-third {
	width: 100px
}
table.reports th.sum-fourth {
	width: 60px
}
table.reports th.sum-fifth {
	width: 70px
}
table.reports th.sum-sixth {
	width: 90px
}
table.reports th.sum-seventh {
	width: 70px
}

/* reports group */
table.reports td.group-title {
	text-align: left;
	border-bottom: solid 1px #000;
}
table.reports th.group-first {
	width: 100px
}
table.reports th.group-second {
	width: 100px
}
table.reports th.group-third {
	width: 100px
}
table.reports th.group-fourth {
	width: 60px
}
table.reports th.group-fifth {
	width: 130px
}
table.reports th.group-sixth {
	width: 70px
}
table.reports th.group-seventh {
	width: 100px
}
table.list th.username {
	width: 300px
}
table.list a.nolink {
	color: #7A7A7A
}

/* status */
span.active {
	color: #10AD00
}
span.paused {
	color: #FFC438
}
span.inactive {
	color: #ED263E
}

/* ads */
.list .ad-border {
	background: #FFFFFF;
	border: 1px solid #00A6C8;
	overflow: hidden;
}
table.list th.username {
	width: 300px
}
.list tbody td img.ad-product-display {
	vertical-align: top;
	margin-top: 2px;
}
.list tbody td img.ad-product-display.top {
	margin: 0 0 0 -6px
}

/* campaign reports */
.list td.toggle {
	cursor: pointer
}
.list th.high {
	padding: 15px 3px 3px 3px
}

/* bogus ads list */
.bogus-ad-container {
	clear: both;
	padding: 5px;
}
.bogus-ad-container .actions {
	padding: 2px 0 0 5px;
	vertical-align: top;
}
.bogus-ad-container .preview {
	border: none;
	overflow: hidden;
}
.bogus-ad-container .preview .title {
	font-weight: bold;
	color: #00f;
	padding: 0;
	margin: 0;
	border: none;
	clear: both;
	text-decoration: underline;
	overflow: hidden;
	display: block;
}
.bogus-ad-container .preview .description,
.bogus-ad-container .preview .link {
	font-weight: normal;
	color: #000;
	padding: 0;
	margin: 0;
	border: none;
	overflow: hidden;
	display: block;
}
.bogus-ad-container .alternative-container {
	background: #fff;
	border: 1px solid #00A6C8;
	width: 326px;
	height: 90px;
	border-collapse: separate;
	margin-bottom: 7px;
	text-align: center;
}
.bogus-ad-container .alternative-container .alternative {
	padding: 2px;
	margin: 0;
	border: none;
	vertical-align: top;
	width: 304px;
}
.bogus-ad-container .alternative-container .alternative .preview .title {
	width: 300px;
	font-size: 15px;
}
.bogus-ad-container .alternative-container .alternative .preview .description {
	width: 300px;
	font-size: 15px;
}
.bogus-ad-container .text-container {
	background: #fff;
	border: 1px solid #00A6C8;
	width: 163px;
	height: 45px;
	border-collapse: separate;
	margin-bottom: 7px;
	text-align: left;
}
.bogus-ad-container .text-container .text {
	padding: 2px;
	margin: 0;
	border: none;
	vertical-align: top;
	width: 152px;
}
.bogus-ad-container .text-container .text .preview .title {
	width: 150px;
	font-size: 12px;
}
.bogus-ad-container .text-container .text .preview .description {
	width: 150px;
	font-size: 11px;
}
.bogus-ad-container .text-container .text .preview .link {
	width: 150px;
	font-size: 11px;
	color: #006D39;
}
.list .fb-ad-list img,
span.ajax_loader_img {
	display: block;
	margin: 0 auto;
	margin-top: 15px;
}
.list tbody .grey_border {
	background-color: white;
	border-bottom: 1px solid #F2EFED;
}
.list tbody tr {
	border-bottom: 1px solid #F2EFED;
}
.targeting {
	cursor: pointer;
}
.list tbody .fb_bind_error,
.list tbody .tr_toggle {
	display: none;
}
.list acronym.no-border {
	cursor: help;
	border: none;
}
.list div.facebook-targeting-combination-brief,
.list div.facebook-targeting-combination-text,
.facebook-targeting-combination-subtext {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.list div.facebook-targeting-combination-brief {
	width: 220px;
}
.ad_list_responsive_columns {
	background-color: #F2EFED;
	margin-bottom: 20px;
	padding: 12px;
}
.facebook-targeting-combination-text a {
	color: #353535;
	text-decoration: none;
}
#dimensions_arrow img {
	padding: 10px;
}
.segmentation_filter_options {
	font-size: 12px;
}
.segmentation_filter_options option {
	cursor: pointer;
}
#selected_dimensions .values_box li {
	padding: 3px 4px;
}
.ui-jqgrid #adListGrid tr.jqgrow td.preview_icon {
	vertical-align: top;
}
.creative_icon {
	padding: 10px 0;
	height: 72px;
}
.creative_icon .color-danger {
	color: @brand-inactive;
}
.ad_creative_preview {
	position: relative;
}
.fb_ad_status {
	text-transform: lowercase;
	line-height: 16px;
	width: 186px;
	position: absolute;
	top: 53px;
	left: 1px;
	height: 18px;
	font-size: 11px;
	background-color: #f9f9f9;
	opacity: 0.9;
	filter: alpha(opacity=90); /* For IE8 and earlier */
}
.position_left {
	float: left;
}
.position_right {
	float: right;
}
.ad_bid_template {
	width: 90%;
	line-height: 12px;
	white-space: normal;
	float: right;
}
.ad_bid_template,
.ad_bid_template p {
	color: #7A7A7A;
	margin: 0;
}
.ad_bid_template p.grey_line {
	background-color: #e9e9e9;
	height: 1px;
}
/*.ui-jqgrid #groupListGrid tr.ui-row-ltr td {
	line-height: 20px;
	padding: 4px 8px;
	border: 1px solid #e4e4e4;
}
.ui-jqgrid #taUserListGrid tr.ui-row-ltr td,
.ui-jqgrid #clientFbStatGrid tr.ui-row-ltr td {
	line-height: 16px;
	padding: 7px 0 7px 5px;
}
.ui-jqgrid #taUserListGrid .tree-wrap,
.ui-jqgrid #clientFbStatGrid .tree-wrap {
	top: 0;
}*/
.err_grid_cell {
	background-color: #FFC0CB;
}
.ad_list_responsive_columns span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
	width: 100px;
}
.grid_campaign_name,
.grid_group_name {
	padding: 7px;
	font-size: 12px;
	padding: 0;
	color: #7A7A7A;
}
#taUserListGrid .status,
#clientFbStatGrid .status {
	font-size: 12px;
}
.group_dates {
	line-height: 18px;
	color: #7A7A7A;
	text-align: center;
}
.dates_text {
	display: inline-block;
	width: 37px;
}
.dates_date {
	text-align: right;
}
.percentText {
	color: #7A7A7A;
	font-size: 10px;
	position: relative;
	line-height: 0;
	top: -6px;
}
.infinity_size {
	font-size: 16px;
}
.group_dates #gTip {
	margin: -20px 0 0 120px;
}
.ajax_loader_img {
	background: url("../../images/ajax_loader.gif") 0 0;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.ajax_loader_big_img {
	background: #F2F2F2 url("../../images/ajax-loader.gif") 0 0 no-repeat;
	display: inline-block;
	width: 34px;
	height: 34px;
	vertical-align: middle;
	padding-right: 20px;
}
.admin_filter {
	color: #7A7A7A;
	font-style: italic;
	background-color: #F2EFED;
	padding: 0 5px;
}
.separator {
	padding: 7px 0 3px 0;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2NkYGCQBAAAIwAbDJgTxgAAAABJRU5ErkJggg==');
	background-repeat: repeat-x;
	background-position: 50% 50%;
	text-align: center;
}
.grid_checkbox_header {
	background-color: #F2EFED;
}
.ad_list_grid_checkbox_header {
	max-width: 840px;
}
.seg_targ_action {
	line-height: 0;
}
.facebook-targeting-combination-text .male_img {
	top: 1px;
	position: relative;
}
.error_messages li {
	background: url("../../web/images/new/arrow-right.png") no-repeat scroll 10px 4px transparent;
	padding: 0 0 0 20px;
}
.listGrid .ui-widget-content .facebook-targeting-combination-text a {
	color: #00A1CB;
	text-decoration: none;
}
.facebook-targeting-combination-subtext {
	line-height: 24px;
	color: #00A1CB;
}
.ui-dialog #action_dialog {
	background-color: #FFF;
}
.error_message {
	color: red;
}
.errorsDiv {
	background-color: #FFDAE0;
	display: none;
}
#fb-errors {
	padding: 20px;
	color: #E54028;
}
.ad_action {
	line-height: 1px;
}
.ui-widget-content .button_container a {
	color: #fff;
}
.ad_creative_preview .no_preview {
	color: #353535;
	display: block;
	margin-top: 15px;
	text-align: center;
}
#group_selection_dialog {
	max-width: 650px;
	min-width: 500px;
}
#group_select_options,
.campaign_select_options {
	width: 400px;
}
.listGrid .sync_status {
	color: #FFC438;
}
.listGrid .sync_status img {
	position: relative;
	left: 2px;
}
#refresh_grid_button {
	padding-right: 0;
	color: #fff;
}
#refresh_grid_button a {
	color: #fff;
	font-weight: normal;
}
#refresh_grid_button a:hover {
	text-decoration: underline;
	font-weight: normal;
}
#filters .floated {
	float: left;
}
#filters .label {
	width: 150px;
	padding-right: 10px;
}
#filters .inputFileds {
	padding: 4px 0;
}
.clear_both {
	clear: both;
}
#maintenance_div {
	padding: 10px;
}
#maintenance_warning_div {
	float: left;
	height: 60px;
	width: 50px;
}
#maintenance_warning_picture {
	background: url("../../images/messagebox_warning.png") transparent;
	width: 32px;
	height: 32px;
}
#maintenance_body_div {
	float: left;
	margin-top: 2px;
}
.filter_products .even {
	background-color: @table-bg-hover;
}
.list-actions {
	padding-right: 10px;
}
.all_selected_ads_action {
	margin-left: 7px;
}
.group-name-status {
	line-height: 16px;
	padding-left: 5px;
}

.ui-jqgrid .ui-jqgrid-htable th#groupListGrid_name {
	padding-left: 13px;
}
.group-name-status .status a {
	float: left;
}
.ui-jqgrid #columns_options_popup_button {
	padding-bottom: 5px;
}
.ui-jqgrid .txt-value {
	font-size: 13px;
}
.ui-jqgrid .summary-col {
	float: none;
	padding: 0;
	min-height: 0;
	line-height: 16px;
}
.bid-info-description {
	color: #999;
	font-size: 11px;
}
.suggestion-buttons {
	display: inline-block;
}
.success-box {
	background-color: rgba(92, 184, 92, 0.7);
	color: #707070;
}
.error-box {
	background-color: #fcf8e3;
	color: #8a6d3b;
}
.campaign-info {
	color: #999999;
	font-size: 12px;
	display: block;
	text-transform: capitalize;
	.channel-name {
		text-transform: uppercase;
	}
    .review-status-icons {
      padding-left: 5px;
      a, a.jTip {
        color: #999999;
        font-size: 12px;
      }
    }
}
.list {
	.campaign-info {
		text-transform: lowercase;
		.channel-name,
		.supplier-name {
			display: none;
		}
	}

	.confirm-dialog {
		text-align: left;
	}
}

.modal-scrolllable {
	max-height: 500px;
	overflow-y: scroll;
	padding: 20px;
}
.auto-bid-groups-list {
	ul > li {
		list-style-type: disc;
		margin-left: 20px;
	}
}
