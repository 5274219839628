.conversations-list {
    height:     250px;
    position:   relative;
    width:      200px;
    margin-top: 10px;
}

.selected-list {
    margin-top: 14px;
}

.transfer-icon {
    font-size:    20px;
	cursor:       pointer;
	margin-right: 24px;
}

.conversations-counter {
    width:      100%;
    display:    block;
    text-align: right; 
}

.conversations-item-container {
    padding-left:  20px;
    padding-right: 20px;
}

.conversations-item-label {
    font-size: 11px;
    max-width: 140px;
    color:   #888888
}

.conversations-item-icon {
    font-size: 8px;
    color:   #888888;
}

.conversations-item-loader {
    margin: 20px;
    height: 0 !important; 
}

.conversations-category-list-item {
    cursor:   pointer;
	padding:  0 !important;
    position: sticky !important;
    top:      0;
    z-index:  1; 
}

.conversations-category-container {
    padding-left:  10px;
    padding-right: 10px
}

.conversations-category-icon {
    transition-duration: 0.2s;
    color:             #888888;
    transform:           none;
}

.rotate-180 {
    transform: rotate(180deg)
}
 
.conversations-category-label {
    font-size:      11px;
	text-transform: uppercase;
	max-width:      160px;
	color:        #888888;
	display:        block;
}