@import "../helpers/spacer.less";
.header-toolbar {
	float: right;
	font-size: 28px;
	margin-top: 12px;
	a {
		color: #dddddd;
		padding: 0 20px;
		border-right: 1px solid #eeeeee;
	}
	a:last-child {
		border-right: 0px solid #eeeeee;
	}
	a:hover {
		color: #bbbbbb;
	}
}
.dashboard-content {
	padding: 0px 35px;
}
.client.nocamp {
	.client-brand {
		margin-bottom: 0;
	}
}
.client {
    padding: 15px 0;
	position: relative;
    margin-bottom: 10px;
	//Arrow down
	.arrow-collapse {
		.fa;
		&:before {
			content: "\f0d7";
		}
	}
	//Arrow right
	.collapsed {
		.arrow-collapse {
			&:before {
				content: "\f0da";
			}
		}
	}
	//Arrow right faded
	.noclick {
		.arrow-collapse {
			color: #eee;
			&:before {
				content: "\f0da";
			}
		}
	}
	.txt-info {
		color: #888888;
	}
	h4 > a,
	[data-toggle="collapse"] {
		cursor: pointer;
	}
	.noclick {
		cursor: initial;
	}
	.profile-image-col{
		max-width:70px;
	}
}
.client-brand {
	margin-bottom: 1em;
	margin-right: 10px;
    max-width: 80%;
	a {
		color: #333;
		display: inline-block;
	}
	a:hover,a:focus{
		text-decoration: none;
	}

	.client-name {
		font-size: 20px;
		font-weight: bold;
		line-height: 24px;
		color: #00A1CB;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
	}
	.client-name.standalone {
		line-height: 40px;
	}
	.client-lastseen {
		display: block;
		color: #878787;
		font-size: 14px;
		font-weight: normal;
	}
}
.overview-client-campaign {
	padding: 15px 0;
	padding-left: 30px;
	border-bottom: 1px solid #dfdfdf;
	h4 i {
		color: #ccc;
		text-shadow: 1px 1px 0px rgba(255, 255, 255, .6);
		margin-right: 5px;
		width: 10px;
		text-align: center;
	}
	h4 .label {
		position: relative;
		margin-left: 5px;
	}
	.overview-campaign-summmary,
	.channel-metrics {
		line-height: 40px;
		font-size: 14px;
		text-align: right;
		i {
			margin-left: 12px;
			color: #cccccc;
		}
		span.value {
			display: inline-block;
		}
	}
}
.client {
	.chart-histogram-container {
		border: 0;
		padding: 0;
	}
}
@media (min-width:768px) and (max-width:992px) {
	.overview-campaign-summmary span.value {
		width: auto;
		margin-right: 30px;
	}
	.overview-campaign-summmary i {
		margin-left: 5px;
	}
}
@media (max-width:992px) {
	.channel-metrics {
		span.value {
			display: inline-block;
			width: auto;
		}
	}
}

.dashboard {
	color: #333333;
	.client-brand {
		.profile_image {
			display: none;
		}
		.client-name {
			color: #bbb;
			font-size: 16px;
			text-transform: uppercase;
			padding-left: 20px;
			&.standalone {
				line-height: 1em;
			}
		}
	}
	.overview-client-campaign {
		padding-left: 0;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
.agency-container {
	color: #777;
	.value {
		display: inline-block;
		padding-right: 20px;
		margin-right: 20px;
		font-size: 18px;
		line-height: 20px;
		border-right: 1px solid #dddddd;
		min-width: 180px;
		.value-label {
			display: block;
			color: #bbb;
			font-size: 10px;
			font-weight: normal;
			text-transform: uppercase;
		}
	}
	.best-result {
		min-width: 180px;
	}
	.no-chart {
		color: #777777;
		cursor: initial;
	}
	a.no-chart:hover {
		text-decoration: none;
	}
	.value small {
		font-size: 60%;
	}
	.toolbar {
		display: inline-block;
		position: relative;
		top: -8px;
		color: #dddddd;
		font-size: 28px;
		text-shadow: 1px 1px 0px rgba(255, 255, 255, .8);
		line-height: 10px;
	}
	.toolbar i {
		margin: 0 5px;
	}
	.toolbar a {
		color: #C2C2C2 !important;
	}
	.toolbar a:hover,
	.toolbar a:active,
	.toolbar a:focus {
		color: #0081a2 !important;
		text-decoration: none;
	}
}
.dashboard-header {
	background: #ffffff;
	padding: 53px 36px;
	margin-left: -24px;
	margin-right: -24px;
	position: relative;
	border-bottom: 0;
	margin-bottom: 0;
	h1 {
		//margin-top: 10px;
		//padding-left: 20px;
		.subtitle {
			display: block;
			font-size: 24px;
			color: #999999;
			&.grey {
				display: inline;
				color: #C2C2C2;
			}
		}

	}
}

.has-arrow:after {
	position: absolute;
	bottom: -24px;
	left: 93px;
	display: inline-block;
	border-top: 25px solid #FFF;
	border-right: 25px solid transparent;
	border-left: 25px solid transparent;
	content: '';
}
.content-bg-grey {
	padding: 25px 35px;
	background: #f2f2f2;
	margin-left: -24px;
	margin-right: -24px;
	//min-height: 740px;
}
.clients-filter {
	.placeholder(#bbbbbb;
	italic);
	input#search[type="text"] {
		font-size: 13px;
		line-height: 1.4em;
	}
	label[for="reportrange"] {
		display: none;
	}
	.form-control,
	#reportrange,
	.bootstrap-select .btn {
		width: 100%;
		height: 34px;
		padding: 5px 10px;
		font-size: 13px !important;
		line-height: 1.4em !important;
		margin-top: 0 !important;
	}
	.bootstrap-select.form-control {
		height: 34px;
	}
	#reportrange {
		padding-top: 8px !important;
	}
}
.form-inline {
	.form-control,
	.bootstrap-select .form-control
	.bootstrap-select .btn {
		width: auto !important;
	}
}
.help-tooltip-container {
	position: absolute;
	min-width: 136px;
	right: 30px;
	top: 57px;
	z-index: 1;
}
//FF fix
@-moz-document url-prefix() {
	.help-tooltip-container {
		top: 62px;
	}
}

/* Label metrics */
.header-metric-labels {
	padding: 12px 40px;
	background: #FAFAFA;
	border-bottom: 1px solid #c8c7cc;
	.channel-metrics .value-label {
		color: #666;
	}
}
.overview-client-campaign:hover {
	//background: #FAFAFA;
}
.dashboard-header {
	.header-filter {
		padding-top: 20px;
		padding-bottom: 20px;
		background: rgba(255, 255, 255, 1);
	}
	.header-filter.affix-top {
		position: static;
	}
	.header-filter.affix {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		background: rgba(255, 255, 255, .88);
		box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
		z-index: 1;
		padding-left: 37px;
		padding-right: 36px;
		.transition(~"background, box-shadow" .2s ease-in);
	}
}
.dashboard-header.affixed:before {
	display: none;
}
.na {
	color: #bbb;
}
.filter-select {
	.bootstrap-select .btn {
		width: 100%;
		height: 34px;
		padding: 5px 10px;
		font-size: 13px !important;
		line-height: 1.4em !important;
		margin-top: 0 !important;
	}
	.dropdown-menu>.active>a,
	.dropdown-menu>.active>a:hover,
	.dropdown-menu>.active>a:focus {
		background-color: #f5f5f5;
	}
}

.dashboard-header {
	.group-summary {
		margin-top: 30px;
	}
	.filter-container {
		min-height: 20px;
		padding: 0;
		margin-bottom: 20px;
		background-color: transparent;
		border: 0;
	}
	.gridErrors {
		margin-top: 30px;
		margin-bottom: -40px;
		.user-errors {
			margin-bottom: 0;
		}
	}
}

.vline-dashed:before{
	content: "";
	width: 1px;
	border-left: 1px dashed rgb(215, 215, 215);
	height: auto;
	position: absolute;
	left: -14px;
	top: 24px;
	bottom: 20px;
	margin: auto;
}
.vline-client:before{
	left: 22px;
	top: 55px;
}

.page-header .profile-image-col {
	max-width: 70px;
}
