.expand-item {
  color: #999;
  margin-left: -21px;
  margin-right: 10px;

  &:hover {
	cursor: pointer;
  }

  i:before {
	font-family: 'Font Awesome 5 Free';
    font-weight: 400;
  }
}
