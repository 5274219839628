.spacerDiv{
	width: 100%;
	font-size: 0;
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
	content: "";
}

.generate-spacer(200);

.generate-spacer(@n, @i: 5) when (@i =< @n) {
  .spacer-@{i} {
    height: ~"@{i}px";
    .spacerDiv;
  }
  .top-spacer-@{i} {
    margin-top: ~"@{i}px";
  }
  .btm-spacer-@{i} {
    margin-bottom: ~"@{i}px";
  }
  .generate-spacer(@n, (@i + 5));
}

.no-top-marg{
  margin-top: 0;
}
.no-btm-marg{
  margin-bottom: 0;
}

.top-negative-10 {
  top: -10px;
}
.top-negative-20 {
  top: -20px;
}
