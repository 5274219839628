.menu-action{
	display: inline-block;
	> li{
		float: left;
		padding-left: 14px;
	}
	> li,
	> li > a{
		font-size: 16px;
	}
  a:hover {
	cursor:pointer;
  }

	#import_entity{
		float: left;
		font-size: 16px;
		padding-left: 14px;
	}

	.progress {
		width: 50px;
		height: 14px;
		background-color: #f1f1f1;
	}

	.importIcon, .importProgress {
		float:left;
	}

	.importProgress {
		padding-left: 14px;
	}
}


