.level-switcher {
  position: absolute;
  bottom: 0;
  left: 250px;
}

td.vmid.action-cell {
  div.dropdown.btn-group {
    width: 100%;

    .divider {
      margin:0;
    }

    button.switch-screen-dropdown {
      background: 0;
      width: 100%;
      text-align: center;
      border: 0;
      padding: 0;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;

      i {
        color: #00A1CB;
      }
    }
  }
}

.level-switcher > button {
  opacity: 1 !important;
}

.level-switcher > button.disabled {
  cursor: not-allowed !important;
}
