@import 'scrollbar';

.twFileListItem {
  font-size: 0.9em;
}

#twdrop-target.dragover {
  background: #ccc !important;
}

.twImageRemove.close {
  font-size: 1em !important;
  margin-right: -1px;
}

.removeImgFromQueue {
  cursor: pointer;
  color: #ddd;
}

.removeImgFromQueue:hover {
  color: #999;
}

.inline {
  display: inline-block;
}

.twLoader.loader-preview {
  font-size: 1.4em;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.loader-imgs {
  height: 30px;
  display: inline-block;
  padding: 0 15px 10px 15px;
}

.twLoader.loader-videos {
  height: 20px;
  font-size: 1.5em;
  margin: 15px;
}

.twLoader.loader-processingVideos {
  font-size: 1.3em;
}

.twCondAlert.stillProcessingMsg {
  clear: initial;
  padding-top: 0;
}

.tweetBodyCloseBtn {
  font-size: 1.1em;
}

.tweetBody {
  margin-bottom: -15px;
  position: relative;

}

.tweetBodyTextarea {
  height: 125px !important;
  resize: none;
}

.tweetBodyCounter {
  position: relative;
  color: #bbb;
  left: 245px;
  width: 100px;
  text-align: right;

  &.shrunkTweetBodyCounter {
    left: 207px;
  }
}

.twCardImgUploaderSubmit {
  float: none !important;
}

.twAdUploaderSubmit {
  margin-left: 10px;
}

div.images.twCards label {
  height: auto;
}

.twCardType {
  text-align: left;
  display: block;
  float: left;
  height: 13px;
  width: 85px;
  color: #808080;
  font-style: italic;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 3px;
}

.clear {
  clear: both;
}

div.videoRefresh {
  text-align: right;
  max-width: 700px;
  margin-bottom:5px;
  line-height: 25px;
  max-height: 25px;

  .twLoader.loader-videos {
    margin: 0;
    height: auto;
  }

  a {
    cursor: pointer;
    margin-left:5px;
  }
}

.twCardDetails.videosList > div {
  float: left;
  margin-right: 10px;
  width: 50px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  img {
    max-height: 36px !important;
  }
}

.twCardDetails p {
  text-align: left;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twCardDetails a, .twCardDetailsNoLink {
  text-align: left;
  font-size: 11px;
  margin: 0;
}

.twSingleCard {
  margin-bottom: 5px;
  width: 98%;
  float: left;
  position:relative;
  height: 58px;

  .col-xs-10-7 {
    width: 90%;
  }

  .col-xs-1-3 {
    width: 10%;
  }
}

div.images .twSingleCard label img {
  max-width: 100%;
  max-height: 50px;
}

div.images .twSingleCard {
  border: 1px solid #f0f0f0;
}

div.images .twSingleCard.selectedCard {
  border: 1px solid #D8D8D8;
  border-left: 3px solid #1997f0;
}

div.images .twSingleCard {
  border-left: 3px solid #D8D8D8;
}

div.images .twSingleCard label {
  border: none;
  text-align: left;
  cursor: pointer;
  padding-right: 10px;
  height: 100%;
}

.twCardCheckbox {
  padding: 0 7px;
}

.twSingleCard .twImageRemove.close {
  position:absolute;
  right:3px;
  top:3px;
}



.card-button {
  display: inline-block;
  position: relative;
  padding: .4em 1em;
  background-color: #f5f8fa;
  border: 1px solid #e1e8ed;
  border-radius: .25em;
  color: #292f33;
  cursor: pointer;
  background: linear-gradient(0,#f5f8fa,#fff);
  font-size: 12px;
  line-height: 1.1858em;
  font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
  font-weight: 700;
  float: right;
  margin: 5px 15px 5px 0;
}

.twImages .imgContainer .imgFormat {
  width: 80px;
}

.preview-remove, .preview-add {
  display: none;
  position: absolute;
  z-index: 1000000;
  background: #000;
  width: 55px;
  padding: 10px;
  height: 55px;
  font-size: 34px;
  text-align: center;
  opacity: 0.3;
  border-radius: 2px;
  cursor: pointer;
  top:10px;
  left:520px;

  &:before {
    float:right;
    color:white;
    transition: font-size 0.5s;
  }
}

.preview-add-remove-to-right {
  left:90% !important;
}

.copyAdsPreview .preview-remove, .copyAdsPreview .preview-add {
  left:320px !important;
}

.twCardPreviewControls {
  .preview-remove, .preview-add {
    top: 5px;
  }
}

.preview-platform-selector {
  display: inline-block;
  margin-bottom: 20px;
}

.preview-disabled {
  opacity: 0.3;
}

.twCards .preview-remove, .twCards .preview-add {
  margin: 9px 0 0 3px;
  width: 30px;
  height: 30px;
  font-size: 14px;
  opacity: .6;
  padding: 8px;
}

.twBackAndNextBtns {
  margin: 0 10px;
}

.inline-block {
  display: inline-block;
}

.twBtnGroupIfMarginFix {
  margin-right: -1px;
}

.twCardPreviewControls:hover > i {
  display: block;
}

.twCards.images {
  position: relative;
}

.showPreviewControls {
  display: block;
}

.twTargetingPopover {
  min-width: 450px;
}

.twTargetingLevel1 {
  margin: 5px 0 -3px 0;
}

.twTargetingContainer1 p {
  margin: 2px 0;
}

.twTargetingContainer1,
.twTargetingContainer2 {
  white-space: initial;
  color: #6C6C6C;
}

.twTargetingContainer2 {
  margin-left: 10px;
}

.twAdName {
  position: relative;
  top: -12px;
  text-align: left;
}

.twAdStatus {
  position: relative;
  top: -14px;
  left: 1px;
  width: 192px;
  line-height: 16px;
  height: 18px;
  font-size: 11px;
  background-color: #f9f9f9;
  opacity: 0.9;
  text-transform: lowercase;
  padding-left: 10px;
  text-align: left;
}

.loader-twAdPreview {
  margin-top: 20px;
}

.twAdPreviewError {
  margin-top: 20px;
}

.twCardsModal.modal-footer {
  text-align: left;
}

.twModal.modal-footer {
  text-align: left;
}

.twPopover.right.twVideoErrors {
  max-width: 300px;
  white-space: initial;
}

span.help.twVideoDeleteIcon {
  margin: 2px 2px 0 6px;
  font-size: .9em;
  opacity: 0.8;
}

.twVideoDeleteIcon.fa-times:hover {
  cursor: pointer;
}

.copyAdsPreview {
  min-width: 400px;
  min-height: 200px;
  float: left;
}

.loader-copyAdsPreview {
  margin-top: 60px;
}

.twCopyAdToggleAllSelected {
  padding-left: 20px;
}

.loader-existing-tweets {
  position: absolute;
  top: -30px;
}

.existingTweetsContainer {
  position: relative;
}

.refreshTweets {
  position: absolute;
  right: 0;
  top: 5px;
}

.twLoader.videoUploaderLoadingIcon {
  font-size: 1em;
  margin-right: 10px;
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
}

.twCardRow {
  display: flex;
  align-items: center;
  height: 100%;
}

.twCardRow > [class*="col-"] {
  padding-right: 5px !important;
}

.cardActionContainer {
  padding: 0;
  background-color: #fbfbfb;
  cursor: default;
  justify-content: center;
}

.cardActionIcon, .cardEditIcon, .cardCopyIcon, .cardPreviewIcon {
  float: right;
  margin: 0 2px;
  font-size: 16px;
  cursor: pointer;

  a {
    padding: 5px;
  }
}

.cardActionIcon a {
  color: #666;
}

.cardActionIcon a:hover {
  color: #E54028;
}

.cardCorrupted {
  cursor: default !important;
}

.cardCorrupted > * {
  opacity: 0.5;
}

.corruptedCardCta {
  cursor: default !important;
}

#addCtaGroupButton {
  position: absolute;
  height: 65px;
}

.singleCtaGroupButton {
  margin-left: 555px;
}

.neighbouringCtaGroupButton {
  margin-left: 591px;
}

#removeCtaGroupButton {
  position: absolute;
  margin-left: 587px;
  height: 65px;
}

#removeCtaGroupButton:hover > span {
  color: #E54028;
}

.creative-creator-overlay #addCtaGroupButton {
  top: 47px;
  height: 110px !important;
}

.creative-creator-overlay #removeCtaGroupButton {
  top: 47px;
  margin-left: 356px !important;
  height: 110px !important;
}

.creative-creator-overlay .neighbouringCtaGroupButton {
  margin-left: 400px;
}

.creative-creator-overlay .singleCtaGroupButton {
  margin-left: 356px;
}

.welcomeMsgSelect > .Select-control {
  background-color: #eee !important;
}

.cardModalHidden {
	display: none !important;
}

.cardListRightButton {
  margin: 5px 0 !important;
}

.cardDmButton {
  position: relative;
  float: right;
  padding: 0.2em 0.5em;
  color: #1da1f2;
  border: 1px solid #1da1f2;
  border-radius: 2px;
}

.cardDmButton:hover {
  color: #666666;
  border-color: #666666;
}

.welcomeMessageEditIcon {
  cursor: pointer;
  float: right;
  margin-right: 26px;
  margin-top: -23px;
  color: #00A1CB;
}

.welcomeMessageEditIcon:hover {
  color: #666666;
}

.welcomeMessageCopyIcon {
  cursor: pointer;
  float: right;
  margin-right: 26px;
  margin-top: -23px;
  color: #00A1CB;
}

.welcomeMessageCopyIcon:hover {
  color: #666666;
}

.welcomeMessageDeleteIcon {
  cursor: pointer;
  float: right;
  margin-right: 8px;
  margin-top: -23px;
  color: #666666;
}

.welcomeMessageDeleteIcon:hover {
  color: #E54028;
}

.welcomeMessagePopover {
  white-space: normal;
  max-width: 250px;
}

.welcomeMessageContent {
  margin: 0;
  padding: 20px 0 20px 0;
  overflow: hidden;
  max-height: 450px;
}

.directMessageContainer {
  width: 60%;
  border: 1px solid #e6ecf0;
  border-radius: 5px;
  margin-top: 20px;
}

.directMessageHeader {
  border-bottom: 1px solid #e6ecf0;
  padding: 10px 15px;
  text-align: center;

  .messageAccountName {
    display: inline-block;
    margin: 0;
  }
}

.directMessageBody {
  margin: 10px 0;
  min-height: 250px;
  max-height: 350px;
  overflow: auto;

  .mockMessage {
    margin-left: 20px;
    max-width: 60%;
  }

  .mockTextContainer {
    padding: 8px 12px;
    border-radius: 20px 20px 20px 0;
    background-color: #E0E0E0;
  }
}

.directMessageFooter {
  padding: 10px;
  border-top: 1px solid #e6ecf0;
  background-color: #E8F5FD;
  display: flex;

  .mockInput {
    border-color: #C6E7FB !important;
    background-color: #fff;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-flex: 1;
    flex-grow: 1;
    min-height: 30px!important;
    min-width: 0;
    position: relative;
    width: 440px;
  }

  .mockExtra {
    margin: 0 5px;
    flex-shrink: 0;

    .mockExtraItem {
      display: inline-block;
      position: relative;
      margin: 0;
      padding: 7px 5px;
      color: #1DA1F2;
      opacity: .8;
    }
  }

  .mockSendButton {
    opacity: .3;
    background-color: #4AB3F4;
    border-color: transparent;
    border-radius: 100px;
    box-shadow: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    white-space: nowrap;

    .mockButtonText {
      display: inline;
      color: white;
    }
  }
}

.carouselCard {
  height: 70px;

  .twCardRow {
    margin-left: 0;
  }
}

.cardThumbnailSlider {
  position: relative;
  height: 65px;
  white-space: nowrap;
  overflow-x: scroll;
  padding-left: 0;

  &> img:not(:last-child) {
    max-height: 47px !important;
    margin-right: 10px;
    margin-bottom: 2px;
  }
}

.adplatformAdPreviewColumnBody {
  margin: 5px;
  text-align: center;

  i {
    font-size: 40px;
    color: #888888;
  }
}

.poll-length-help {
  line-height: 1.4;
  margin-top: 14px !important;
}
