.navbar {
	margin: 0 !important;
	padding: 0 !important;
	min-height: 47px;
	border-radius: 0;
	border-right: none;
	border-left: none;
	border-top: none;
	background: none;
	border-color: #ffffff;
    min-width: 1024px!important;
}
.header-default .navbar {
	position: relative !important;
}
.navbar > .container .navbar-brand, .logo {
	padding: 3px 13px 0 0;
	margin-left: 13px;
	font-family: 'Raleway', sans-serif;
	font-size: 25px;
}
.navbar > .container .navbar-brand i, .logo i {
	font-size: 20px;
}
.navbar-brand > img {
	padding: 0;
	height: 100%;
	max-height: 34px;
}
.navbar-tools > ul {
	list-style: none;
}
.navbar-tools > ul > li > a,
.navbar-tools > ul > li > div > a {
	padding: 12px 15px;
	font-size: 16px;
	color: #696969;
}
.navbar-tools li.view-all a {
	padding: 8px 8px 6px !important;
}
.navbar-tools > ul > li {
	float: left;
}
.navbar-tools .dropdown-menu {
	background: none repeat scroll 0 0 white;
	border: 1px solid #C7CBD5;
	box-shadow: none;
	list-style: none outside none;
	margin: 0;
	max-width: 300px;
	min-width: 166px;
	padding: 0;
	position: absolute;
	text-shadow: none;
	top: 100%;
	z-index: 1000;
}
.navbar-tools .drop-down-wrapper {
	height: 250px;
	width: 270px;
	overflow: hidden;
	position: relative;
}
.navbar-tools .drop-down-wrapper ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
ul.notifications li, ul.todo li, ul.posts li {
	min-width: 260px;
}
.navbar-tools > ul > li.dropdown {
	margin-left: 2px;
	margin-right: 2px;
}

.navbar-tools .dropdown-menu > li > a:hover, .navbar-tools .dropdown-menu > li > a:focus, .navbar-tools .dropdown-submenu:hover > a, .navbar-tools .dropdown-submenu:focus > a, .navbar-tools .drop-down-wrapper li a:hover, .navbar-tools .drop-down-wrapper li a:focus {
	background-color: #F4F6F9 !important;
	background-image: none;
	filter: none;
	color: #000;
	text-decoration: none;
}
.drop-down-wrapper ul > li:last-child a {
	border-bottom: none;
}
.navbar-tools .dropdown-menu li .dropdown-menu-title {
	display: block;
	font-weight: bold;
	margin: -1px;
	padding: 5px 10px;
}
.navbar-tools .dropdown-menu li p, .navbar-tools .dropdown-menu li a, .navbar-tools .drop-down-wrapper li p, .navbar-tools .drop-down-wrapper li a {
	color: #333333;
	font-size: 12px;
	font-weight: 300;
	margin: 0;
	padding: 8px 8px 6px;
	border-bottom: 1px solid rgba(100, 100, 100, 0.22);
	white-space: normal !important;
	display: block;
}
.navbar-tools .dropdown-menu > li:last-child a {
	border-bottom: none !important;
	border-radius: 0 0 6px 6px;
}
li.dropdown.current-user .dropdown-toggle {
	padding: 10px 4px 18px 9px;
}
li.dropdown.current-user .dropdown-menu li a {
	border-bottom: none !important;
}
.navbar-tools .dropdown-menu li p {
	font-weight: bold;
}
.navbar-tools .dropdown-menu li a .author {
	color: @brand-cyan;
	display: block;
}
.navbar-tools .dropdown-menu li a .preview {
	display: block;
}
.navbar-tools .dropdown-menu li a .time {
	font-size: 12px;
	font-style: italic;
	font-weight: 600;
	display: block;
	float: right;
}
.navbar-tools .dropdown-menu li.view-all a i {
	float: right;
	margin-top: 4px;
}
.navbar-tools .dropdown-menu.notifications li > a > .label {
	margin-right: 2px;
	margin-bottom: 10px;
	padding: 2px 4px;
	text-align: center !important;
}
.navbar-tools .thread-image {
	margin-right: 8px;
	float: left;
	height: 50px;
	width: 50px;
}
.nav .badge {
	z-index: 1;
	border: 1px solid #f2f2f2;
	border-left: transparent;
	border-top: transparent;
}
.navbar-toggle {
	border: none;
	border-radius: 0;
	margin-top: 5px;
	margin-bottom: 4px;
}
.navbar-toggle span {
	font-size: 16px;
}
