.inputCounterWrapper {
  position: relative;
}

.inputCounterWrapper .inputCounter {
  display: inline-block;
  color: #d3d3d3;
  font-size: 1em;
  line-height: 1em;
  font-weight: 400;
  white-space: nowrap;
  padding-left: .6em;
}

.inputCounterWrapper .inputCounter.maxReached {
  color: @brand-brightred;
}