.tweets-text-list-container {

  .tweets-text-list-input {
      width: 100% !important;
      margin-bottom: 10px;
  }

  .tweetBody {
    padding-bottom: unset;
  }

}
