.cdm-remove-confirm-modal {
  margin-top: 200px;
}

.metric-remove-btn {
  float: right;
  cursor: pointer;
  line-height: 1.5;
}

.metric-remove-btn-disabled {
  float: right;
  line-height: 1.5;
  color: #d5d5d5;
}

.add-custom-metric-btn {
  float:right;
  color: #1997f0;
  line-height: 1.5;
  cursor: pointer;
}

.add-custom-metric-btn:hover {
  color: #0662cf;
}

.add-custom-metric-btn-disabled {
  float:right;
  color: #d5d5d5;
  line-height: 1.5;
}

.report-metrics-picker {
  height: 100%;
  margin: 0;

  & > div {
    height: 100%;

    .categories-column {
      width: 250px;
      display: inline-block;
      vertical-align: top;
      overflow-y: scroll;
      height: 100%;

      &> div.category-list  {
          padding: 0 8px;

          &.active {
            border-left: 4px solid #00a1cb;
            padding-left: 4px;
          }

          & ul > div {
            margin: 4px 0px;
            border-bottom: 1px solid #e9eaeb;

            a {
              padding: 8px 16px 8px 16px;
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: bold;
              color: black;
              font-size: 14px;
              line-height: 18px;
              text-decoration: none;
              cursor: pointer;
              color: #788087;
              text-transform: uppercase;
              font-weight: 400;

              &.sub-category {
                padding-left: 32px;
                font-weight: 400;
                color: #929598;
                font-size: 14px;

                &.active {
                  padding-left: 32px;
                  color: #00a1cb;
                }
              }

              &.active {
                color: #00a1cb;
              }
            }
          }
      }
    }

    .metrics-column {
      width: 400px;
      height: 100%;
      display:inline-block;

      .search-filter-container {
        padding: 19px 0 0 19px;
        height: 70px;
        display: block;


        border-bottom: 1px solid #e9eaeb;
        background-color: white;
        position: fixed;
        width: 390px;
        z-index: 1;

        .search-filter {
          border: 1px solid #c5d0d9;
          width: 200px;
          padding: 3px 5px;

          input {
            padding: 3px 5px;

            &:focus {
              box-shadow: none;
              border: 1px solid #c5d0d9;
            }
          }

          i:first-child {
            line-height: 21px;
          }

          i:last-child {
            float: right;
            line-height: 21px;
            cursor: pointer;
          }
        }
      }


      .columns-container {
        display: block;
        height: 100%;
        overflow-y: scroll;
        position: relative;
        padding-top: 70px;

        .report-metrics-container & {
          padding-top: 70px;
          top: -70px;
        }

        .category-header {
          background-color: #f5f7f8;
          padding: 5px 8px;
          font-size: 10px;
          font-weight: 600;

          &> span {
            text-transform: uppercase;
          }

          .select-all {
            display: inline-block;
            float: right;
            cursor: pointer;

            a {
              text-decoration: none;
            }
          }
        }

        .root-values {
          padding: 5px 8px;

          label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 330px;
          }
        }

        .sub-category-header {
          border-bottom: 1px solid #dadcde;
          padding: 5px 8px;
          text-transform: uppercase;
          font-size: 10px;
        }

        .values {
          padding: 5px 8px;
        }
      }
    }

    .metrics-order {
      width: 250px;
      display: inline-block;
      vertical-align: top;
      background-color: #F5F8FA;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      .counter {
        width: 90%;
        margin: 15px 5%;
        padding: 10px;
        color: #788087;
        text-transform: uppercase;

        b {
          color: black;
        }
      }

      .card {
        cursor: initial;
        background-color: white;
        width: 90%;
        margin: 7px 5%;
        padding: 6px;

        &.draggable {
          cursor: move;

          i {
            line-height: 100%;
            margin-right: 10px;
            margin-left: 5px;
          }

          .text {
            padding-left: 0;
            width: 77%;
            vertical-align: middle;
            display: inline-block;
            position:relative;
            top: -2px;
          }

          i.fa-close {
            cursor: pointer;
            margin-right: 0;
          }

          &:hover {
            cursor: move;
          }
        }



        .text {
          padding-left: 20px;
          display: inline-block;
        }

        &.is-dragging {
          border: 1px dashed black;
          color: white;

          i {
            color: white;
          }

          i.fa-close {
            color: white;
          }

        }

      }

      [draggable=true] {
        opcity: 1;
      }
    }
  }

}

.report-metrics-container {
  height: 518px;
}

.custom-metrics-modal {
  min-width: 902px;
  height: 90%;


  .modal-content {
    height: 100%;

    .modal-body {
      height: calc(~'100% - 75px - 56px');
      padding: 0;
    }

    .align-left {
      float: left;
    }

    .save-preset {
      line-height: 29px;
    }

    .modal-footer {
      input {
        height: 30px;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
          border: 1px solid #c5d0d9;
        }

        &:hover {
          box-shadow: none;
          border: 1px solid #c5d0d9;
        }
      }

      .preset-error {
        line-height: 30px;
        color: red;
        margin-left: 10px;
      }
    }
  }

}

.metric-add-li {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.add-custom-metric-modal {
  margin-top: 200px;

  input.value.long {
    width: 100% !important;
  }

  .add-custom-metric-select {
    width: 130px;
    display: inline-block;

    .Select-value {
      padding-right: 20px;
    }

    .Select-menu-outer {
      width: 400px;
    }
  }

  .custom-metric-exp-div {
    float: right;

    input.value.long.custom-metric-expression-input {
      width: 283px !important;
      float:right;
      margin-left: 5px;
    }
  }
}
