.optional {
  color: gray;
}

.form div.value {
  margin-left: 200px;
  margin-bottom: 15px;

  &.short-value {
    margin-left: 150px !important;
  }
}

.form.media-container div.value {
  margin-left: 0px;
}

.form div.value.payin {
  margin-left: 210px;
}

.form div.value.short {
  margin-left: 40px;
}

.form div.red {
  color: #E54028;
}

.form div.green {
  color: green;
}

.form div.value img {
  vertical-align: top;
}

.form div.break {
  clear: both;
  height: 20px;
}

.form label.vertical-label {
  color: #666;
  font-size: 12px;
  font-weight: 700;
}

.form label.caption {
  width: 200px;
  text-align: left;
  font-weight: normal;
  float: left;
  padding: 0 5px 4px;
  clear: left;
  //color: #353535;

  &.top-label {
    line-height: 20px !important;
  }

  &.short-label {
    width: 150px !important;
  }
}

.form label.metrics-caption {
  width: 200px;
  text-align: left;
  font-weight: normal;
  padding: 0 5px 4px;
}

.right-align .form label.caption {
  text-align: right;
  margin: 0;
  padding: 4px 7px;
}

.form label .help-block {
  margin: 0;
}

.form {
  div.value {
    margin-bottom: 11px;
  }

  .value label {
    padding-top: 3px;
    margin-bottom: 0;
  }

  .radio {
    padding-left: 0;
  }
}

.rs-checkbox-checker:not(.rs-check-item *)>label {
  padding-top: 0 !important;
}

.form .value.confirm label {
  color: green;
  font-weight: bold;
}

.form label {
  color: #666;
}

.form .url-correct label {
  color: green;
  font-weight: bold;
}

.form {
  .radio,
  .checkbox {
    label {
      display: inline-block;
    }
  }
}

.form.inline-row {
  display: inline-block;
  margin-top: 0;

  .inline-value {
    margin-left: 3px;
  }
}

.form .value.row-no-margin {
  margin-left: 0;
}

.form label.caption.payin {
  width: 210px;
}

.form label.caption.short {
  width: 40px;
  padding: 2px;
}

.form label.caption_short {
  text-align: left;
  font-weight: normal;
  float: left;
  padding: 3px;
  clear: left;
  //color: #353535;
  margin-right: 5px;
}

.captionNormal {
  font-weight: 700;
  font-size: 12px;
}

.edit-create-kpi-form {
  .kpi-form-checkbox {
    float: left;
    padding-left: 140px;
  }
}

.dashboard-kpi-form {
  .kpi-form-checkbox {
    float: left;
    padding-left: 210px;
  }

  .kpi-form-input {
    padding-left: 70px;
  }
}

.form label.caption.admin-iprange {
  width: 134px
}

.form input.value {
  min-height: 27px;
  //line-height: 17px;
}

.form input.value,
.form select.value,
.form textarea.value {
  .form-field;
  .placeholder(#d3d3d3);
  width: 220px;
  min-width: 50px !important;

  &.fluid {
    width: 100% !important;
  }

  &.x-small {
    width: 50px !important;
  }

  &.small {
    width: 100px !important;
  }

  &.narrow {
    width: 200px !important;
  }

  &.semilong {
    width: 262px !important;
  }

  &.midlong {
    width: 300px !important;
  }

  &.long {
    width: 350px !important;
  }
}

.form .value_edit {
  height: inherit !important;
  font-size: inherit !important;
  padding: inherit !important;
  .form-control;
  .form-field;
}

.text-core .text-wrap textarea,
.text-core .text-wrap input {
  /* .form-field withouth important on padding */
  background-color: white;
  border: 1px solid #d3d3d3 !important;
  color: @gray-bg-mid;
  font-size: 12px;
  padding: 4px 8px;
}

.form .value.inlineSmall {
  height: 27px;
  width: 183px;
}

.form .value.inlineSemilong {
  height: 27px;
  width: 271px;
}

.form .narrow {
  width: 200px !important;
}

.form .semilong {
  width: 262px !important;
}

.form .long {
  width: 350px !important;
}

.form .superlong {
  width: 578px !important;
}

table.edit td.value .input_file,
div.value .input_file {
  .form-field;
  width: 262px !important;
}

div.value .input_file.narrow,
.form textarea.value.narrow {
  width: 200px !important;
}

div.value .input_file.long,
.form textarea.value.long {
  width: 350px !important;

  &.tweetBodyTextarea {
    padding-right: 45px !important;

    &.shrunkTweetBodyArea {
      width: 307px !important;
    }
  }
}

/* Read Only */
.form input.readonly,
.form select.readonly,
.form textarea.readonly {
  background-color: #eee !important;
  color: #7A7A7A
}

.form input[readonly]:hover,
.form input[readonly]:focus {
  border-color: #D5D5D5;
  box-shadow: none;
}

.form input.value[disabled],
.form select.value[disabled],
.form textarea.value[disabled],
.form input.emulate-disabled,
input[disabled] {
  cursor: not-allowed;
  background-color: #eee !important;
  opacity: 1;
}

.form textarea.value {
  height: 50px;
}

.form select.value {
  width: 186px;
}

.form div.value.input-file {
  margin-left: 206px
}

.form td.value.input-file {
  width: 230px;
  padding-right: 5px;
  text-align: right;
}

.form div.budget-info {
  .alert;
  .alert-warning;
}

.form div.value.admin-iprange {
  margin-left: 139px;
}

.form textarea.offers-wide {
  width: 450px;
  margin-bottom: 22px;
}

.form textarea.offers-wide.link {
  height: 40px
}

.form textarea.offers-wide.iframe {
  height: 60px
}

.form textarea.offers-wide.popup {
  height: 80px;
  margin-bottom: 0;
}

.form input.value.mobile_edit,
.form textarea.value.mobile_edit {
  width: 234px
}

.form textarea.value.iprange {
  width: 244px;
  height: 150px;
}

.form input.offers-short,
.form input.value.short,
.smallInput {
  width: 75px;
}

.form div.subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.form fieldset div.subtitle {
  margin-top: 1.5em;
  margin-bottom: .75em;
}

.form fieldset>div.subtitle {
  margin-top: 0;
}

.form .label-inline {
  padding-left: 10px;
}

.form .label-inline.first {
  padding-left: 0;
}

//
// Submit buttons
// --------------------------------------------------

.button_container {
  width: 570px;
  margin-top: 20px;
  margin-bottom: 20px;
  .clearfix;

  .loader {
    width: 24px;
    height: 24px;
    float: right;
  }
}

.button_container.small {
  width: 420px
}

.button_container.wide {
  width: 570px
}

.button_container.semi {
  width: 482px
}

.button_container.mobile_edit {
  width: 441px
}

.button_container.payin {
  width: 395px
}

.button_container.value_edit {
  width: 465px
}

/*Submit button main class*/
.btn-submit {
  .btn;
  .btn-primary;
  .btn-squared;
  position: relative;
  min-width: 120px;

  &.btn-lg {
    padding: 15px 30px;
    font-size: 18px;
  }

  &.facebook {
    background-color: #4868ae;
    color: white;
    padding: 1px;
    font-family: Graphik LCG Web, Graphik Arabic Web Regular, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, Lucida Grande, Sans-Serif;
    font-weight: 700;
    border-radius: 4px !important;
    border: none;
    padding: 6px 10px;

    .fa-facebook-square {
      font-size: 24px;
      padding-right: 6px;
      vertical-align: sub;
      line-height: 20px;
    }

    &[disabled][disabled] {
      background-color: #e4e4e4;
      border-color: #e4e4e4;
      color: #aeaeae;
    }
  }
}

#fb_connect_user {
  background-color: #4868ae;
  color: white;
  padding: 1px;
  font-family: Graphik LCG Web, Graphik Arabic Web Regular, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, Lucida Grande, Sans-Serif;
  font-weight: 700;
  border-radius: 4px !important;
  border: none;
  padding: 6px 10px;

  .fa-facebook-square {
    font-size: 24px;
    padding-right: 6px;
    vertical-align: sub;
    line-height: 20px;
  }

  &[disabled][disabled] {
    background-color: #e4e4e4;
    border-color: #e4e4e4;
    color: #aeaeae;
  }
}

.submit,
.submit-button,
.next,
.nex-button {
  .btn-submit;
  .pull-right;
}

.submit.submit-multi {
  margin-left: 10px;
}

.submit-button.left {
  .pull-left;
  margin-left: 0;
}

.submit-red {
  .btn-submit;
  .btn-danger;
}

.submit_filter,
.submit-button.gray {
  .btn-submit;
  .btn-default;
}

.submit-full-width {
  margin-bottom: 10px;
  width: 100%;
}

.cancel,
.cancel-button,
.back,
.back-button {
  .btn-submit;
  .btn-default;
  .pull-left;
  margin-left: 0;
  color: #333333 !important;
}

.skip-button {
  .btn;
  .btn-submit;
  .btn-default;
  color: #00a1cb !important;
  border: 1px solid #00a1cb !important;
}

.checkbox,
.checkbox:hover,
.checkbox:focus {
  width: auto;
  background: transparent;
  border: 0px solid gray;
  margin-left: 0px;
  padding: 0px;
  vertical-align: middle;
}

.form input.value:focus,
.form input.value:hover,
.form textarea.value:focus,
.form textarea.value:hover {
  border: 1px solid #d3d3d3;
}

.button {
  background-color: #f4f4f4;
  border: 1px solid #d3d3d3;
  color: black;
  clear: both;
}

.input {
  background-color: white;
  border: 1px solid #d3d3d3;
  color: black;
  width: 170px;
  font-size: 11px;
  padding: 3px;
  margin-bottom: 5px;
}

.clearleft {
  clear: left;
}

/* login */
form input.login_edit {
  width: 95%;
  margin: 0px;
  background-color: #f4f4f4;
  border: 1px solid #d3d3d3;
  color: black;
}

.login_label {
  text-align: right;
}

form input.login_submit {
  width: 100px;
  float: right;
  background: #FFFFFF url(../images/new/arrow-thin-100.png) no-repeat;
  border: none;
  color: #FFFFFF;
}

/* error */
.form .error {
  display: inline-block;
  color: #E54028;
  font-size: 11px;
  padding-top: 4px;
  line-height: 1;

  img {
    padding: 0px 2px 2px 0;
    vertical-align: text-top;
  }
}

.form .select-error {
  margin-top: -11px;
}

.form .warning {
  color: #8A6D3B;
  font-size: 11px;
  padding-top: 4px;

  img {
    padding: 0px 2px 2px 0;
    vertical-align: text-top;
  }
}

form .field_tip {
  color: #9a9a9a;
  font-size: 11px;
  padding: 0;
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0 0 20px 20px;
  padding-bottom: 21px;
  border-bottom: 1px solid #eee;
  margin-bottom: 21px;

}

/** Custom by klmn **/
fieldset.generator {
  border: 1px inset #bbb;
  padding: 5px;
  margin-right: 10px;
  float: left;
}

fieldset.floater {
  border: 1px inset #bbb;
  padding: 5px;
  margin-right: 10px;
  float: left;
}

fieldset.ad_target_preview {
  margin-right: 10px;
  width: 99%;
}

fieldset.ad_keywords {
  margin-right: 10px;
  width: 99%;
}

legend {
  margin: 0;
  font-weight: bold;
}

optgroup {
  font-style: normal;
}

.field_disabled {
  background-color: #ccc;
}

.field_hint {
  font-size: 85%;
  color: #999;
}

#fb-creative-gallery-widget label.caption {
  float: none;
}

#fb-creative-gallery-widget div.value {
  margin-left: 0px;
  padding-top: 10px;
}

/*
#location-city-container label{
    cursor: pointer;
    display: block;
    float: left;
    margin-top: 4px;
}

#location-city-container-label-fix{
    display: block;
	float:left;
    margin: 4px 0 0 6px;
}
*/

.form input.widerInput {
  width: 440px;
}

/*CSS DROPDOWN*/

select.cssDropdown {
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  outline: none;
  .btn-select;
  .form-field;
  height: 27px;
  background: #fff;
}

label.cssDropdown {
  position: relative;
  outline: none;
}

label.cssDropdown:after {
  content: '';
  right: 12px;
  top: 50%;
  margin-top: -2px;
  .caret;
  position: absolute;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #888;
}

/* Targetting Webkit browsers only. FF will show the dropdown arrow with so much padding. */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select.cssDropdown {
    //padding-right: 18px;
  }

  /* Arrow fix for webkit */
  label.cssDropdown:after {
    //top: 2px;
  }
}

label.cssDropdown option {
  color: #353535;
  font-weight: normal;
}

.submitButton .deactivated {
  opacity: 0.4;
  filter: alpha(opacity=40);
  /* For IE8 and earlier */
}

.submitButton .deactivated:hover {
  text-decoration: none;
  cursor: default;
}

a.action {
  background-color: #00A6C8;
  color: white;
  border: none;
  font-size: 12px;
  line-height: 21px;
  height: 22px;
  padding: 5px 10px;
  cursor: hand;
  cursor: pointer;
}

//Redesign
.form {
  fieldset {
    border: 1px solid #eee;
    padding-top: 40px;
    position: relative;
    min-height: 26px;

    .alert,
    .budget-info {
      max-width: 550px;
    }

    &.closed {
      padding-top: 0;
    }
  }

  .alert {
    ul:not(:first-child) {
      margin-top: 11px;
    }

    ul {
      padding-left: 20px;

      li {
        list-style: disc;
      }
    }
  }

  fieldset div.subtitle {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 11px !important;
    background: #f2f2f2;
    padding: 4px 10px 5px;
    font-weight: 400 !important;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 1);
    color: #888888 !important;
    text-transform: uppercase;
  }

  .ondemand-box {
    background: #f1f1f1;
    padding: 10px;
    padding-top: 18px;
    max-width: 550px;
    margin-top: 12px;
    margin-bottom: 10px;
    border-bottom: 3px solid #e8e8e8;
    position: relative;
    overflow: visible !important;

    div.value {
      margin-left: 0 !important;

      div.value.nolabel {
        margin-top: 3px;
      }
    }

    div.value.nolabel {
      margin-left: 190px !important;
    }

    label.caption {
      width: 190px;
    }

    .budget-info {
      margin-top: 10px;
    }
  }

  .ondemand-box:before {
    position: absolute;
    top: -10px;
    left: 20px;
    display: inline-block;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #f1f1f1;
    border-left: 15px solid transparent;
    content: ' ';
  }

  .ondemand-box.devices:before {
    content: none;
  }

  .toogleBtn {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #888888 !important;

    span {
      float: right;
      padding-right: 8px;
      padding-top: 3px;
    }

    div.subtitle {
      float: left;
      position: relative;
      display: inline-block;
      padding: 4px 10px 5px;
      margin-top: 0;
    }

    &:hover {
      color: #333333 !important;
    }
  }
}

.form {
  .preview-subtitle {
    margin: 0 0 5px 0;
    color: #666;
  }
}

.help-block,
.url-info,
.form-optional {
  color: #888;
  font-size: 85%;
}

.form-optional {
  color: #192430;
}

.help-block {
  margin-top: 7px;
}

/*Scrolling cheboxes*/
#zoneLoaderContainer li:hover,
#zoneSubmit li:hover,
#pubZoneLoaderContainer li:hover,
#pubZoneSubmit li:hover,
#zoneLoaderContainer li:hover label,
#zoneSubmit li:hover label,
#pubZoneLoaderContainer li:hover label,
#pubZoneSubmit li:hover label {
  background: #fff !important;
  color: #666 !important;
}

#zoneSubmit li label.error,
#zoneSubmit li:hover label.error {
  color: #E54028 !important;
}

.form .value .page-posts label {
  padding-top: 0;
}

/* ---------------------------------------------------------------------- */
/*	Has estimates
/* ---------------------------------------------------------------------- */
.has-estimates {
  fieldset {
    padding-right: 20px;
  }

  input.value,
  .ondemand-box {
    max-width: 100% !important;
  }

  .ondemand-box,
  .scrolling_checkboxes,
  .text-core .text-wrap,
  .text-core .text-wrap input,
  .button_container {
    width: 100% !important;
  }

  .annotatedList,
  .annotatedListFilter {
    width: 47% !important;
  }

  .annotatedListSpacer {
    width: 6% !important;
  }

  .annotatedList>li {
    padding-right: 12px;
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .has-estimates {
    fieldset {
      padding-left: 15px;
      padding-right: 15px;
    }

    div.value {
      margin-left: 100px;
      margin-left: 0;
    }

    label.caption {
      width: 100px;
      width: 100%;
      float: none;
      padding-left: 0;
    }

    .annotatedListContainer {
      max-width: 100%;
    }
  }
}

@media (min-width: 992px) {
  .has-estimates {

    .text-core .text-wrap,
    .annotatedListContainer,
    input.value,
    .ondemand-box,
    .scrolling_checkboxes {
      max-width: 640px !important;
    }

    .button_container {
      max-width: 860px !important;
    }
  }
}

#fieldset_targeting_mobile {

  &.ondemand-box {
    padding-left: 20px;
  }

  #targeting-mobile-devices {

    .value>label {
      margin-bottom: 7px;
    }

    .cornerRow {
      label {
        margin-right: 10px;
      }
    }

  }
}

.cornerLine {
  border-bottom: 1px solid #d3d6da;
  border-left: 1px solid #d3d6da;
  float: left;
  height: 23px;
  width: 15px;
  position: relative;
  left: -1px;
  top: -4px;
  margin-top: -3px;
}

.corner-line {
  border-bottom: 1px solid #d3d6da;
  border-left: 1px solid #d3d6da;
  float: left;
  height: 28px;
  width: 18px;
  position: relative;
  left: 18px;
  top: -10px;

  &.last {
    height: 37px;
    top: -18px;
  }
}

.devices-selector-radio>div>label {
  padding-top: 0 !important;
}

.vertLine {
  border-left: 1px solid #d3d6da;
  float: left;
  height: 30px;
  left: 6px;
  position: relative;
  top: 0px;
  width: 1px;
}

.cornerRow {
  border-left: 1px solid #d3d6da;
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: 6px;

  &.last {
    border: 0;
    margin-left: 7px;
  }

  label {
    max-width: 90%;
  }

}

.move-to-left20 {
  margin-left: 20px;
}

.move-to-left10 {
  margin-left: 10px;
}

.move-to-left22 {
  margin-left: 22px;
}

.parameterTargeting span.error {
  margin-left: 12px;
}

input.move-input-left5 {
  margin-left: 5px;
}

.lifetime-budget-media-cost .error,
.lifetime-budget-impression .error {
  margin-left: 10px;
}

.pixel-code textarea,
.segment-pixel-code textarea {
  font-family: "Courier New", Courier, monospace;
  color: #000000;
  font-size: 12px;
}

.multi-select .text-core .text-wrap {

  input {
    color: #333333;
    font: 11px "Open Sans", sans-serif;
    line-height: 16px;
  }

  .text-dropdown {
    border: 1px solid #d3d3d3;
    border-radius: 0px;
    -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
    color: #333333;
    max-height: 200px !important;
    font: 11px "Open Sans", sans-serif;

    .text-list {
      padding: 0 4px;

      .text-suggestion {
        padding: 6px 5px;
      }

      .text-suggestion.text-selected {
        color: #333333;
        background: #F5F5F5;
      }
    }
  }

  .text-tags {
    .text-tag {
      .text-button {
        padding: 2px 17px 2px 3px;
        height: 21px;
        color: #333333;
        cursor: default;
        border: 1px solid #d3d3d3;
        border-radius: 0px;
        -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
        background-clip: padding-box;
        font: 11px "Open Sans", sans-serif;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #e4e4e4;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#f4f4f4', GradientType=0);

        a.text-remove:focus {
          background: #d4d4d4;
        }

        a.text-remove {
          display: block;
          width: 12px;
          height: 13px;
          top: 3px;
          right: 0px;
          font-size: 1px;
          outline: none;
          background: url("../../images/select2.png") right top no-repeat;
        }

        a.text-remove:hover {
          background-position: right -11px;
        }
      }
    }
  }
}

html[dir="rtl"] .multi-select .text-core .text-wrap .text-tags .text-tag .text-button a.text-remove {
  right: auto;
  left: 3px;
}

textarea.script-tag {
  font-family: monospace;
}

.max-width-700 {
  max-width: 700px;
}

.max-width-900 {
  max-width: 900px;
}

.after-help-text {
  padding-left: 5px;
  color: #999;
}

.cornerRow .padding-top-12.devices-min-version-toggle {
  padding-top: 12px;
}

.copy-tracking {
  color: @dodgerBlue;
  font-size: 20px;
  margin-top: 4px;
  vertical-align: text-top;

  &:hover {
    cursor: pointer;
    color: @scienceBlue;
  }
}

.modal .form .value div.value {
  margin-left: 0;
}

.btm-line {
  border-bottom: 1px solid #e5e5e5;

  &:last-child {
    border-bottom: none;
  }
}

.twitter-style .bulk-edit-field.btm-line .caption {
  line-height: 29px;
}

.link-preview-container {
  min-height: 32px;
  max-width: 350px;
  word-break: break-all;
  padding-top: 7px;
}