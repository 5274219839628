//
// Status indicators
// --------------------------------------------------

.status-indicator {
  width: 4px;
  height: auto;
  max-height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  display: inline-block;
  content: " ";
  position: absolute;
  background-color: #ddd;
}
.status-indicator-small {
  width: 3px;
  height: 12px;
  max-height: 12px;
  overflow: hidden;
  margin-top: 3px;
  margin-right: 5px;
  float: left;
  display: inline-block;
  content: " ";
}
.status-active {
  background-color: @brand-active;
}
.status-inactive {
  background-color: @brand-inactive;
}
.status-paused {
  background-color: @brand-paused;
}
.status-paused_inside {
  background-color: @brand-paused-inside;
}
.status-deleted {
  background-color: @brand-deleted;
}
.status-archived {
  background-color: @brand-archived;
}
.status-hidden {
  background-color: @brand-hidden;
}
.status-empty {
  background-color: @brand-empty;
}
.status-scheduled {
  background-color: @brand-scheduled;
}
.status-in_moderation {
  background-color: @brand-in-moderation;
}
.status-issues {
  background-color: @brand-issues;
}
.status-completed {
  background-color: @brand-completed;
}
.status-in_creation {
  background-color: @brand-in_creation;
}
.status-pending_review {
  background-color: @brand-pending_review;
}
.status-campaign_paused {
  background-color: @brand-campaign_paused;
}
.status-campaign_deleted {
  background-color: @brand-campaign_deleted;
}
.status-campaign_completed {
  background-color: @brand-campaign_completed;
}
.status-group_completed {
  background-color: @brand-group_completed;
}
.status-group_deleted {
  background-color: @brand-group_deleted;
}
.status-group_paused {
  background-color: @brand-group_paused;
}
.status-groups_in_creation {
  background-color: @brand-groups_in_creation;
}
.status-ads_in_creation {
  background-color: @brand-ads_in_creation;
}
.status-pending_billing_info {
  background-color: @brand-pending_billing_info;
}
.status-no_budget {
  background-color: @brand-no_budget;
}
.status-budget_exceeded {
  background-color: @brand-budget_exceeded;
}
.status-no_groups {
  background-color: @brand-no_groups;
}
.status-no_ads {
  background-color: @brand-no_ads;
}
.status-bad_targeting {
  background-color: @brand-bad_targeting;
}
.status-disapproved {
  background-color: @brand-disapproved;
}

.status-funding-problem {
  background-color: @brand-brightred;
}

.status-budget-depleted {
  background-color: @brand-budget_depleted;
}

.status-disconnected {
  background-color: @brand-disconnected;
}

.icon-status-default {
  color: @brand-inactive;
}
.icon-status-active {
  color: @brand-active;
}
.icon-status-inactive {
  color: @brand-inactive;
}
.icon-status-paused {
  color: @brand-paused;
}
.icon-status-paused_inside {
  color: @brand-paused-inside;
}
.icon-status-deleted {
  color: @brand-deleted;
}
.icon-status-archived {
  color: @brand-archived;
}
.icon-status-hidden {
  color: @brand-hidden;
}
.icon-status-empty {
  color: @brand-empty;
}
.icon-status-scheduled {
  color: @brand-scheduled;
}
.icon-status-in_moderation {
  color: @brand-in-moderation;
}
.icon-status-pending_billing_info {
  color: @brand-pending_billing_info;
}
.icon-status-completed {
  color: @brand-completed;
}
.icon-status-in_creation {
  color: @brand-in_creation;
}
.icon-status-pending_review {
  color: @brand-pending_review;
}
.icon-status-campaign_paused {
  color: @brand-campaign_paused;
}
.icon-status-campaign_deleted {
  color: @brand-campaign_deleted;
}
.icon-status-campaign_completed {
  color: @brand-campaign_completed;
}
.icon-status-group_completed {
  color: @brand-group_completed;
}
.icon-status-group_deleted {
  color: @brand-group_deleted;
}
.icon-status-group_paused {
  color: @brand-group_paused;
}
.icon-status-issues {
  color: @brand-issues;
}
.icon-status-no_budget {
  color: @brand-no_budget;
}
.icon-status-budget_exceeded {
  color: @brand-budget_exceeded;
}
.icon-status-no_groups {
  color: @brand-no_groups;
}
.icon-status-no_ads {
  color: @brand-no_ads;
}
.icon-status-bad_targeting {
  color: @brand-bad_targeting;
}
.icon-status-groups_in_creation {
  color: @brand-groups_in_creation;
}
.icon-status-ads_in_creation {
  color: @brand-ads_in_creation;
}
.icon-status-disapproved {
  color: @brand-disapproved;
}



.text-status-default {
  color: @brand-inactive;
}
.text-status-active {
  color: @brand-active;
}
.text-status-inactive {
  color: @brand-inactive;
}
.text-status-paused {
  color: @brand-paused;
}
.text-status-paused_inside {
  color: @brand-paused-inside;
}
.text-status-deleted {
  color: @brand-deleted;
}
.text-status-archived {
  color: @brand-archived;
}
.text-status-hidden {
  color: @brand-hidden;
}
.text-status-empty {
  color: @brand-empty;
}
.text-status-scheduled {
  color: @brand-scheduled;
}
.text-status-in_moderation {
  color: @brand-in-moderation;
}
.text-status-issues {
  color: @brand-issues;
}
.text-status-completed {
  color: @brand-completed;
}
.text-status-in_creation {
  color: @brand-scheduled;
}
.text-status-pending_billing_info {
  color: @brand-pending_billing_info;
}
.text-status-completed {
  color: @brand-completed;
}
.text-status-in_creation {
  color: @brand-in_creation;
}
.text-status-pending_review {
  color: @brand-pending_review;
}
.text-status-campaign_paused {
  color: @brand-campaign_paused;
}
.text-status-campaign_deleted {
  color: @brand-campaign_deleted;
}
.text-status-campaign_completed {
  color: @brand-campaign_completed;
}
.text-status-group_completed {
  color: @brand-group_completed;
}
.text-status-group_deleted {
  color: @brand-group_deleted;
}
.text-status-group_paused {
  color: @brand-group_paused;
}
.text-status-issues {
  color: @brand-issues;
}
.text-status-no_budget {
  color: @brand-no_budget;
}
.text-status-budget_exceeded {
  color: @brand-budget_exceeded;
}
.text-status-no_groups {
  color: @brand-no_groups;
}
.text-status-no_ads {
  color: @brand-no_ads;
}
.text-status-bad_targeting {
  color: @brand-bad_targeting;
}
.text-status-groups_in_creation {
  color: @brand-groups_in_creation;
}
.text-status-ads_in_creation {
  color: @brand-ads_in_creation;
}
.text-status-disapproved {
  color: @brand-disapproved;
}

//
// Status icons
// --------------------------------------------------

.status-icon {
  .icon-custom;
  font-size: 18px;
  padding-right: 5px;
  margin: 0;
}
.text-active {
  color: @brand-green;
}
.text-inactive {
  color: @grey-info-text;
}
.text-scheduled {
	color: @brand-scheduled;
}
.text-issues {
	color: @brand-issues;
}
//Active
.status-icon-active:before {
	content: "\f058";
	.text-active;
}
//Inactive
.status-icon-empty:before,
.status-icon-hidden:before,
.status-icon-paused:before,
.status-icon-deleted:before,
.status-icon-inactive:before,
.status-icon-archived:before,
.status-icon-completed:before,
.status-icon-paused_inside:before,
.status-icon-group_paused:before,
.status-icon-group_deleted:before,
.status-icon-group_completed:before,
.status-icon-campaign_paused:before,
.status-icon-campaign_deleted:before,
.status-icon-campaign_completed:before,

.status-icon-no_ads:before,
.status-icon-no_groups:before,
.status-icon-no_budget:before{
	content: "\f057";
	.text-inactive;
}
//Scheduled
.status-icon-scheduled:before,
.status-icon-in_creation:before,
.status-icon-in_moderation:before,
.status-icon-pending_review:before,
.status-icon-ads_in_creation:before,
.status-icon-groups_in_creation:before,
.status-icon-pending_billing_info:before {
	content: "\f058";
	.text-scheduled;
}
//Issues
.status-icon-issues:before,
.status-icon-disapproved:before,
.status-icon-bad_targeting:before,
.status-icon-budget_exceeded:before {
	content: "\f057";
	.text-issues;
}
//
.status-icon-synced:before {
  content: "\f058";
}
.status-icon-notsynced:before {
  content: "\f057";
}


